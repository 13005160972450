import { typesTasks } from '../types/types';

export const setTaskState = ({ number, data }) => ({
    type: typesTasks.changeTaskState,
    payload: {
        number, 
        data
    }
});

export const setActiveTask = (data) => ({
    type: typesTasks.changeActiveTask,
    payload: data
});

export const onSetLastTask = (data) => ({
    type: typesTasks.setLastTask,
    payload: data
});

export const onSetEcommerceSelected = (data) => ({
    type: typesTasks.setEcommerceSelected,
    payload: data
});

export const onSetFinalTasksTutorial = (data) => ({
    type: typesTasks.setFinalTasksTutorial,
    payload: data
});

export const onSetVideoComplete = (data) => ({
    type: typesTasks.setVideoComplete,
    payload: data
})

export const onSetOnBoardingChatComplete = (data) => ({
    type: typesTasks.setOnboardingChatComplete,
    payload: data
})