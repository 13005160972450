import { Box, Slide, Typography } from '@mui/material'
import React from 'react'
import { isWorkingEmployee } from '../../../helpers/chat';
import { PartnerGenericMessage } from './General';

const channelToText = (channel) => {
    switch (channel) {
        case 'sms':
            return 'SMS';
        case 'whatsapp':
            return 'WhatsApp';
        case 'cellphone':
            return 'Llamada Telefónica';
        default:
            return 'WhatsApp';
    }
}

const displayInputChat = ({ employee, selectedChannel }) => {
    return (isWorkingEmployee(employee) || employee.allowOutOfScheduleMessage) && ["SMS", "WhatsApp"].includes(selectedChannel);
}

const displayOptions = ({ employee }) => {
    return (isWorkingEmployee(employee) || employee.allowOutOfScheduleMessage);
}

const EmployeeChannelOption = ({ channel, headerColour, fontColour, selectedChannel, setSelectedChannel, setEmployeePage, employeePage }) => {

    const handleClick = () => {
        setSelectedChannel(channel);
        setEmployeePage({
            ...employeePage,
            inputChat: {
                show: displayInputChat({ employee: employeePage.employee, selectedChannel: channel }),
                channel: channel
            }
        });
        if (channel === 'Llamada Telefónica') {
            const { countryCode, phoneNumber, prefix } = employeePage.employee.phoneNumber;
            const phone = `${countryCode}${prefix}${phoneNumber}`;
            window.open(`tel:${phone}`);
        }
    }

    return (
        <Slide direction="up" in={true} mountOnEnter unmountOnExit>
            <Box
                sx={{
                    background: selectedChannel ? headerColour : '#FFF',
                    borderRadius: '14px',
                    maxWidth: '80%',
                    width: 'fit-content',
                    padding: '15px 20px',
                    display: 'inline-block',
                    border: `1px solid ${headerColour}`
                }}
                onClick={handleClick}
            >
                <Typography
                    variant="body2"
                    sx={{ color: selectedChannel ? '#FFF' : fontColour }}
                    fontWeight={400}
                    fontSize={16}
                >
                    {channel}
                </Typography>
            </Box>
        </Slide>
    )
}

export const EmployeesChannels = ({ employee, employeePage, setEmployeePage, headerColour, fontColour }) => {

    const moreThanOneChannel = employee.channels.length > 1;

    const [channels, setChannels] = React.useState([]);

    React.useEffect(() => {
        const channels = employee.channels.map(channel => channelToText(channel));
        channels.sort();
        setChannels(channels);
        if (!moreThanOneChannel && ["SMS", "WhatsApp"].includes(channels[0])) {
            setEmployeePage({
                ...employeePage,
                inputChat: {
                    show: displayInputChat({ employee, selectedChannel: channels[0] }),
                    channel: channels[0]
                }
            });
        }
    }, [employee]);

    const [selectedChannel, setSelectedChannel] = React.useState(null);

    const showOptions = (moreThanOneChannel || employee.channels[0] === 'cellphone') && displayOptions({ employee });

    return (
        <Box
            display={'flex'}
            flexDirection={'column'}
            gap={'17px'}
        >
            {showOptions && <PartnerGenericMessage message="Seleccioná el medio por el cual te querés contactar:" />}
            <Box
                display="flex"
                gap="10px"
                flexWrap="wrap"
            >
                {showOptions && channels.map((channel, index) => {
                    return (
                        <EmployeeChannelOption
                            key={index}
                            channel={channel}
                            headerColour={headerColour}
                            fontColour={fontColour}
                            selectedChannel={selectedChannel === channel}
                            setSelectedChannel={setSelectedChannel}
                            setEmployeePage={setEmployeePage}
                            employeePage={employeePage}
                        />
                    )
                })}
            </Box>
        </Box>
    )
}
