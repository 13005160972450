import React, { useState } from 'react';

import { Navigate, Route, Routes, useLocation } from 'react-router-dom';
import { connect } from 'react-redux';

// Components
import IntegrationsScreen from '../pages/IntegrationsScreen/IntegrationsScreen';
import AdvancedScreen from '../pages/AdvancedScreen/AdvancedScreen';
import PaymentScreen from '../pages/PaymentScreen/PaymentScreen';
import BillingScreen from '../pages/BillingScreen/BillingScreen';
import HelpScreen from '../pages/HelpScreen/HelpScreen';
import UserCreate from '../pages/CreateUser/userCreate';
import ChatScreen from '../pages/ChatScreen/ChatScreen';
import MyUsers from '../pages/MyUsers/MyUsersScreen';
import HomeScreen from '../pages/Home/HomeScreen';
import Navbar from '../components/Navbar/Navbar';
import BenefitModal from '../components/Modals/BenefitModal/BenefitModal';

const AdminRoutes = (props) => {

    // States
    const { endTrialPeriod } = props;

    const [showReviewModal, setShowReviewModal] = useState(false);

    const { pathname } = useLocation();

    const handleClickReviewDrawer = () => {
        setShowReviewModal( !showReviewModal );
    }

    return (
        <>
            { pathname !== '/card' &&  <Navbar showReview={ showReviewModal } handleCloseReview={ handleClickReviewDrawer }/> }
            <Routes>
                <Route path="createUser" element={<UserCreate />} />
                <Route path="myUsers" element={<MyUsers />} />
                <Route path="help" element={<HelpScreen />} />
                <Route path="home" element={<HomeScreen />} />
                <Route path="chat" element={<ChatScreen />} />
                <Route path="advanced" element={<AdvancedScreen />} />
                <Route path="billings" element={<BillingScreen />} />
                <Route path="integrations" element={<IntegrationsScreen />} />
                <Route path="card" element={
                    !endTrialPeriod ? (
                        <PaymentScreen />
                    ) : (
                        <Navigate to="/home" replace />
                    )
                } />
                <Route path="/*" element={<Navigate to="/home" replace />} />
            </Routes>
            <BenefitModal open={ showReviewModal } handleClose={ handleClickReviewDrawer } />
        </>
    )
}

const mapStateToProps = (state) => {
    return {
        closeBannerSus: state.auth.closeBannerSus,
        endTrialPeriod: state.auth.endTrialPeriod,
    };
};

export default connect(mapStateToProps, null)(AdminRoutes);