export const typesRegister = {
    register: '[Register] register'
}

export const typesAuth = {
    initLoginBackMercadoShops: '@auth/initLoginBackMercadoShops',
    changeOnBoardingComplete: '@auth/changeOnBoardingComplete',
    failConnectionRegister: '@auth/failConnectionRegister',
    changeCloseBannerSus: '@auth/changeCloseBannerSus',
    changeEndTrialPeriod: '@auth/changeEndTrialPeriod',
    changeEndTutorial: '@auth/changeEndTutorial',
    showRecoverPass: '@auth/showRecoverPass',
    showConfirmPass: '@auth/showConfirmPass',
    finishLoginBack: '@auth/finishLoginBack',
    initLoginBack: '@auth/initLoginBack',
    changeIsLogIn: '@auth/changeIsLogIn',
    initRegister: '@auth/initRegister1',
    setEndTutorial: '@auth/endTutorial',
    successLogin: '@auth/successLogin',
    failRegister: '@auth/failRegister',
    changeTokens: '@auth/changeToken',
    changeStore: '@auth/changeStore',
    saveResData: '@auth/saveResData',
    register: '@auth/initRegister',
    initLogin: '@auth/initLogin',
    failLogin: '@auth/failLogin',
    setLogout: '@auth/setLogout',
    billings: '@auth/billings',
    loading: '@auth/loading',
    login: '@auth/login',
}

export const typesUserSteps = {
    changeStartRangeHours: '[Step 2] changeStartRangeHours',  
    setCounterActiveHours: '[Step 2] setCounterActiveHours',
    setEnabledRangeHours: '[Step 2] setEnabledRangeHours',
    ShowErrorUserModal: '[Advanced] ShowErrorUserModal',
    changeEndRangeHours: '[Step 2] changeEndRangeHours',
    showUserModalComplete: '[Step 4] showModalComplete',
    saveImgPersonalize: '[Step 1] saveImgPersonalize',
    ShowSaveUserModal: '[Advaced] ShowSaveUserModal',
    setActiveDaysUser: '[Step 2] setActiveDaysUser',  
    changeProfileImg: '[Step 1] changeProfileImg',
    setComunication: '[Advanced] setComunication',
    setIdUserDelete: '[My users] setIdUserDelete',
    changeStateUser: '[My users] changeStateUser',
    setIdHourDelete: '[Step 2] setIdHourDelete',
    userCreate: '[User create] userCreate',
    setActiveHours: '[Step 2] activeHour',
    completeStep: '[Step 0] completeStep',
    setMessages: '[Steps 3] setMessages',
    userEditing: '[Editing] userEditing',
    setUserData: '[Step 1] setUserData',
    createHours: '[Step 2] createHours',
    deleteUser: '[My users] deleteUser',
    activeStep: '[Step 0] activeStep',
    deleteHour: '[Step 2] deleteHour',
    resetHours: '[Step 2] resetHours',
    errorHour: '[Step 2] errorHour',
    saveUser: '[Advanced] saveUser',
    setLoading: '[Step 0] loading',
    fail: '[Step 4] fail',
    id: '[Step 0] id',
}

export const globalsTypes = {
    changeDateRangeState: '[globalTypes] changeDateRangeState',
    resetAll: '[globalTypes] resetAll',
}

export const typesChatSteps = {
    setLeaveSaveChangesChat: '[Step 1] setLeaveSaveChangesChat',
    questionsActiveArrayType: '[Step 2] questionsActiveArray',
    showSaveChangesModal: '[Step 3] showSaveChangesModal',
    showErrorSaveChanges: '[Step 3] showErrorSaveChanges',
    editAdvancedOptions: '[Advanced] editAdvancedOptions',
    setAdvancedOptions: '[Advanced] setAdvancedOptions',
    setOnboardingChat: '[Step 1] setOnboardingChat',
    completeStepChat: '[Step 0] completeStepChat',
    welcomeTextChat: '[Step 1] welcomeTextChat',
    setEditingChat: '[Step 2] setEditingChat',
    activeStepChat: '[Step 0] activeStepChat',
    setStylesChat: '[Step 3] setStylesChat',
    reorderQuestions: '[Step 2] reorderQuestions',
    createNewQuestion: '[Step 2] createNewQuestion',
    setActiveTemplate: '[Step 2] setActiveTemplate',
    updateQuestion: '[Step 2] updateQuestion',
    setQuestions: '[Step 2] setQuestions',
    setTemplates: '[Step 2] setTemplates',
    titleChat: '[Step 1] titleChat',
}

export const typesIntegrations = {
    setQuestionSelected: '@integrations/questions',
    activeSite: '@auth/activeSite',
}

export const typesUsers = {
    changeNewUserState: '@users/changeNewUserState',
    getEditUser: '@users/getEditUser',
    getUser: '@users/getUsers',
}

export const typesTasks = {
    setFinalTasksTutorial: '@task/setFinalTasksTutorial',
    setEcommerceSelected: '@task/setEcommerceSelected',
    setVideoComplete: '@task/setVideoComplete',
    setOnboardingChatComplete: '@task/setOnboardingChatComplete',
    changeActiveTask: '@task/changeActiveTask',
    changeTaskState: '@task/changeTaskState',
    setLastTask: '@task/setLastTask'
}