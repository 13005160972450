import { Box, Slide, Typography } from '@mui/material'
import React from 'react'

export const ClientGenericMessage = ({message = '', fontColour= '#01C66B', handleAnswer, answer}) => {
  return (
      <Box
          sx={{
              background: '#EEEEEE',
              borderRadius: '14px',
              maxWidth: '80%',
              width: 'fit-content',
              padding: '15px 20px',
              display: 'inline-block',
              alignSelf: 'flex-end',
              cursor: 'pointer',
          }}
          onClick={() => handleAnswer(answer)}
      >
          <Typography
              variant="body2"
              sx={{color: fontColour}}
              fontWeight={500}
              fontSize={16}
          >
              {message}
          </Typography>
      </Box>
  )
}

export const ClientSelectedMessage = ({message = '', botonColour = '#01C66B', handleTalkToEmployees}) => {
  return (
      <Box
          sx={{
              background: botonColour,
              borderRadius: '14px',
              maxWidth: '80%',
              width: 'fit-content',
              padding: '15px 20px',
              display: 'inline-block',
              alignSelf: 'flex-end',
              cursor: 'pointer',
          }}
          onClick={handleTalkToEmployees}
      >
          <Typography
              variant="body2"
              sx={{color: '#FFFFFF'}}
              fontWeight={500}
              fontSize={16}
          >
              {message}
          </Typography>
      </Box>
  )
}

export const ClientOptions = ({answers = [], hasEmployees = false, chat = {}, handleAnswer, handleTalkToEmployees}) => {
  return (
    <Slide direction="up" in={true} mountOnEnter unmountOnExit>
    <Box
        display={'flex'}
        flexDirection={'column'}
        gap={'17px'}
    >
        {answers.map((answer, index) => (
            <ClientGenericMessage
                key={index}
                message={answer.question}
                {...chat}
                answer={answer}
                handleAnswer={handleAnswer}
            />
        ))}
        {hasEmployees && (<ClientSelectedMessage 
                              message={"Hablar con un asesor"} 
                              {...chat}
                              handleTalkToEmployees={handleTalkToEmployees} 
                              />)}
    </Box>
    </Slide>
  )
}

