import { Box, Grid, IconButton, TextField } from '@mui/material'
import React from 'react'
import SendIcon from '@mui/icons-material/Send';
import { getMobileOperatingSystem } from '../../../helpers/chat';

export const SendMessageInput = ({ employeePage, botonColour }) => {

    const { employee, inputChat } = employeePage;
    const [message, setMessage] = React.useState('');

    const handleClick = () => {
        const { countryCode, phoneNumber, prefix } = employee.phoneNumber;
        const phone = `${countryCode}${prefix}${phoneNumber}`;

        if (inputChat.channel === 'WhatsApp') {
            window.open(`https://wa.me/${phone}?text=${message}`, '_blank');
        } else {
            //send SMS
            const device = getMobileOperatingSystem();
            if (device === 'iOS') {
                window.open(`sms:${phone}&body=${message}`, '_blank');
            } else {
                window.open(`sms:${phone}?body=${message}`, '_blank');
            }
        }
        window.parent.postMessage('Send', '*');
        setMessage('');
    }

    return (
        <Grid
            container
            direction="row"
            alignItems="center"
            sx={{
                backgroundColor: 'transparent',
                borderRadius: '10px',
                position: 'fixed',
                bottom: 20,
                left: '50%',
                transform: 'translateX(-50%)',
                width: '90%',
                zIndex: '1000',
            }}
        >
            <Grid item xs={10} sx={{ background: 'white', padding: 1, borderRadius: '10px' }}>
                <TextField
                    id="outlined-multiline-static"
                    multiline
                    maxRows={4}
                    minRows={1}
                    variant="standard"
                    value={message}
                    onChange={(e) => {
                        setMessage(e.target.value);
                        window.parent.postMessage(`text${e.target.value}`, '*');
                    }
                    }
                    fullWidth
                    placeholder="Hola, quería saber..."
                    InputProps={{
                        disableUnderline: true, // <== added this
                    }}
                />
            </Grid>
            <Grid item xs={2}
                alignSelf='flex-end'
                display={'flex'}
                alignItems={'center'}
                justifyContent={'center'}
                marginBottom={0.5}
            >
                <IconButton
                    size="medium"
                    onClick={handleClick}
                    sx={{
                        background: botonColour,
                        '&:hover': {
                            background: botonColour,
                        },
                    }}
                >
                    <SendIcon sx={{ color: '#FFF' }} />
                </IconButton>
            </Grid>
        </Grid>
    )
}
