const Task3Icon = ({ color, fontColor }) => {
    return (
        <svg width="52" height="52" viewBox="0 0 52 52" fill="none" xmlns="http://www.w3.org/2000/svg">
            <rect width="52" height="52" rx="10.5507" fill={ color }/>
            <path d="M25.9966 17.7737C23.257 17.7737 21.0254 20.0043 21.0254 22.7449C21.0254 23.3549 21.5217 23.8496 22.1301 23.8496C22.7414 23.8496 23.2349 23.3549 23.2349 22.7449C23.2349 21.2194 24.4774 19.9846 25.9966 19.9846C27.5221 19.9846 28.7642 21.2196 28.7642 22.7449C28.7642 24.2645 27.5221 25.5066 25.9966 25.5066C24.9424 25.5066 24.0955 26.2719 23.8908 27.2731C23.832 27.4037 23.801 27.5481 23.801 27.7048V30.4109C23.801 31.0209 24.2973 31.5156 24.9057 31.5156C25.5198 31.5156 26.0133 31.021 26.0133 30.4109V27.7179C28.747 27.7094 30.9706 25.4802 30.9706 22.7449C30.971 20.0043 28.7416 17.7737 25.9966 17.7737Z" fill={ fontColor }/>
            <path d="M24.9061 32.2446C24.1716 32.2446 23.5742 32.8418 23.5742 33.5777C23.5742 34.3155 24.1716 34.9127 24.9061 34.9127C25.6461 34.9127 26.2379 34.3155 26.2379 33.5777C26.2379 32.8422 25.6461 32.2446 24.9061 32.2446Z" fill={ fontColor }/>
            <path d="M25.9972 12C18.2806 12 12 18.2801 12 25.9972C12 28.8532 12.8604 31.591 14.4898 33.9438V37.4751C14.4898 38.266 14.4898 40.6815 16.3349 40.6815C16.8618 40.6815 17.395 40.4263 17.9639 39.9019C18.3595 39.5371 18.9033 39.0074 19.52 38.3975C21.5247 39.4477 23.7486 39.9986 25.9971 39.9986C33.7194 39.9986 40 33.7179 40 25.9972C40 18.2811 33.7189 12.0005 25.9971 12.0005L25.9972 12ZM25.9972 37.7881C23.8608 37.7881 21.7575 37.1966 19.9094 36.0776L19.1694 35.6332L18.5553 36.2417C17.8514 36.9414 17.201 37.5821 16.7134 38.0431C16.705 37.8905 16.6993 37.6997 16.6993 37.4752L16.6991 33.2314L16.4832 32.9398C14.997 30.9027 14.2117 28.5 14.2117 25.9977C14.2117 19.4994 19.4972 14.2114 25.9964 14.2114C32.4957 14.2114 37.7874 19.4994 37.7874 25.9977C37.7878 32.5 32.4967 37.7886 25.997 37.7886L25.9972 37.7881Z" fill={ fontColor }/>
        </svg>
    )
}

export default Task3Icon;