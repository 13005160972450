import React, { useState } from 'react';

// Avatar
import AvatarTask4Mobile from '../../../assets/images/Home/AvatarTask4Mobile.png';
import AvatarTask4 from '../../../assets/images/Home/AvatarTask4.png';

// Icon
import CheckCircleOutlineIcon from '@mui/icons-material/CheckCircleOutline';

// Layout
import LayoutCongratsModals from '../../../layout/LayoutCongratsModals';

// Material ui
import { Box, Typography, useMediaQuery } from '@mui/material';

// CSS
import Styles from './CongratsModal.module.css';
import { connect } from 'react-redux';

const TitleStyles = {
    fontSize: '1.2rem',
    fontWeight: '600',
    '@media(min-width: 300px)': {
        fontSize: '1.3rem',
    }
}

const SubtitleStyles  = {
    margin: '.5rem 0 1rem 0',
    fontSize: '.9rem',
}

const GiftTextContainer = {
    border: '2px solid #E1C478',
    backgroundColor: '#FFFFFF',
    padding: '.6rem 1rem',
    borderRadius: '20px',
    width: 'max-content',
    alignItems: 'center',
    margin: '2rem auto',
    display: 'flex',
    gap: '5px',
}

const GiftText = {
    marginBottom: '-.1rem',
    fontSize: '.55rem',
    fontWeight: '600',
    '@media(min-width: 350px)': {
        fontSize: '.7rem',
    },
    '@media(min-width: 900px)': {
        fontSize: '.8rem',
    }
}

const Task4SubCongratsModal = (props) => {


    // External props
    const { open, handleClose, task, origin } = props;

    const LastTextStyles = {
        margin: '1rem auto 1rem auto',
        fontSize: '.8rem',
        fontWeight: '300',
        width: '100%',
        '@media(min-height: 500px)': {
            margin: '1rem auto 3rem auto',
        },
        '@media(min-width: 900px)': {
            width: '385px'
        }
    }

    const matches = useMediaQuery('(min-width: 400px)');

    return (
        <LayoutCongratsModals
            disabledButtonNext={false}
            srcAvatar={ matches ? AvatarTask4 : AvatarTask4Mobile } 
            handleClose={ handleClose }
            width2={'400px'} 
            width3={'550px'}
            width1={'100%'} 
            open={ open }
            task={ task }
            origin={ origin }
        >
            <Typography sx={ TitleStyles }>Todo listo para comenzar</Typography>
            <Typography sx={ SubtitleStyles }>'Por completar las tareas obtuviste:</Typography>
            <Box sx={ GiftTextContainer }>
                <CheckCircleOutlineIcon sx={{ color: '#E1C478', height: '1.2rem' }}/>
                <Typography sx={ GiftText }>30 DÍAS <span style={{ fontWeight: '700' }}>GRATIS</span> PARA USAR EL COMPLEMENTO</Typography>
            </Box>
            <Typography sx={ LastTextStyles }>Para terminar de instalar whatspro en tu tienda y comenzar tu periodo gratuito suscribite gratis' </Typography>
        </LayoutCongratsModals>
    )
}

export default connect(null , null)( Task4SubCongratsModal );

