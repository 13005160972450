import React from 'react';

// Material Ui
import { useMediaQuery } from '@mui/material';

const Step2Icon = ({ color }) => {

    const matches = useMediaQuery('(min-width:600px)');

    return (
        <svg width={ matches ? "32" : "26" } height={ matches ? "32" : "26" } viewBox="0 0 32 32" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M15.6167 6.44141C12.5601 6.44141 10.0703 8.93012 10.0703 11.9878C10.0703 12.6684 10.624 13.2203 11.3029 13.2203C11.9849 13.2203 12.5354 12.6684 12.5354 11.9878C12.5354 10.2858 13.9217 8.90808 15.6167 8.90808C17.3186 8.90808 18.7045 10.286 18.7045 11.9878C18.7045 13.6832 17.3186 15.069 15.6167 15.069C14.4405 15.069 13.4956 15.9229 13.2673 17.04C13.2017 17.1856 13.1671 17.3467 13.1671 17.5215V20.5407C13.1671 21.2214 13.7208 21.7733 14.3996 21.7733C15.0848 21.7733 15.6353 21.2214 15.6353 20.5407V17.5361C18.6853 17.5267 21.1662 15.0396 21.1662 11.9878C21.1666 8.93012 18.6793 6.44141 15.6167 6.44141Z" fill={ color }/>
            <path d="M14.4001 22.5869C13.5806 22.5869 12.9141 23.2532 12.9141 24.0743C12.9141 24.8974 13.5806 25.5637 14.4001 25.5637C15.2257 25.5637 15.8859 24.8974 15.8859 24.0743C15.8859 23.2536 15.2256 22.5869 14.4001 22.5869Z" fill={ color }/>
            <path d="M15.6167 0C7.0073 0 0 7.00672 0 15.6167C0 18.8031 0.960001 21.8577 2.77789 24.4827V28.4225C2.77789 29.305 2.77789 32 4.83648 32C5.42434 32 6.01916 31.7153 6.65397 31.1301C7.09531 30.7232 7.70204 30.1322 8.39012 29.4517C10.6267 30.6234 13.108 31.238 15.6166 31.238C24.2323 31.238 31.2396 24.2307 31.2396 15.6167C31.2396 7.00787 24.2317 0.000575264 15.6166 0.000575264L15.6167 0ZM15.6167 28.7718C13.2332 28.7718 10.8865 28.1118 8.82456 26.8633L7.99894 26.3676L7.3138 27.0465C6.52845 27.8271 5.80281 28.542 5.25878 29.0564C5.24934 28.886 5.24305 28.6732 5.24305 28.4226L5.24283 23.6879L5.00193 23.3626C3.34379 21.0898 2.46761 18.409 2.46761 15.6172C2.46761 8.36714 8.36464 2.46723 15.6158 2.46723C22.8671 2.46723 28.771 8.36714 28.771 15.6172C28.7714 22.8719 22.8682 28.7723 15.6164 28.7723L15.6167 28.7718Z" fill={ color }/>
        </svg>
    )
}

export default Step2Icon;