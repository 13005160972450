import React, { useRef } from 'react';

// Material Ui
import { Button, Fade, Modal } from '@mui/material';
import { createTheme } from '@mui/material/styles';
import Backdrop from '@mui/material/Backdrop';
import { ThemeProvider } from '@mui/system';
import { Box } from '@mui/system';

// CSS
import Styles from './SuccessSub.module.css';

const theme = createTheme({
    palette: {
        primary: {
            main: '#2ED588',
        },
        error: {
            main: '#FF0000'
        }
    },
});

const SuccessPaymentUpdated = (props) => {

    const style = {
        transform: 'translate(-50%, -50%)',
        border: '1px solid #BFBBBD',
        position: 'absolute',
        borderRadius: '10px',
        textAlign: 'center',
        bgcolor: 'white',
        p: '1rem 1.5rem',
        width: '75%',
        left: '50%',
        top: '50%',
        ":focus-visible": {
            outline: 'none'
        },
        "@media (min-width: 500px)": {
            width: '320px'
        },
        "@media (min-width: 900px)": {
            width: '340px'
        }
    };

    const buttonOK = {
        boxShadow: '0px 2.91309px 2.91309px rgba(0, 0, 0, 0.25)',
        margin: '.5rem auto',
        backgroundColor: '#2ED588',
        padding: '.3rem 4rem',
        fontFamily: 'Poppins',
        textTransform: 'none',
        borderRadius: '8px',
        fontWeight: '500',
        fontSize: '.9rem',
        color: 'white',
        '&:hover': {
            backgroundColor: '#2ED588'
        }
    };

    const { open, handleClose } = props;

    const submitButton = useRef(null);

    return (
        <Modal
            aria-labelledby="transition-modal-title"
            aria-describedby="transition-modal-description"
            open={ open }
            closeAfterTransition
            BackdropComponent={ Backdrop }
            BackdropProps={{
                timeout: 500,
            }}
        >
            <Fade in={ open }>
                <Box sx={ style }>
                    <div className={ Styles.IconContainer }>
                        <svg width="50" height="50" viewBox="0 0 72 72" fill="none" xmlns="http://www.w3.org/2000/svg">
                            <path d="M36 68.25C53.8112 68.25 68.25 53.8112 68.25 36C68.25 18.1888 53.8112 3.75 36 3.75C18.1888 3.75 3.75 18.1888 3.75 36C3.75 53.8112 18.1888 68.25 36 68.25Z" stroke="#2ED588" strokeWidth="7.16667" strokeLinecap="round" strokeLinejoin="round"/>
                            <path d="M21.667 36L32.417 46.75L50.3337 28.8333" stroke="#2ED588" strokeWidth="7.16667" strokeLinecap="round" strokeLinejoin="round"/>
                        </svg>
                    </div>
                    <div className={Styles.SuccessSubContainer}>
                        <h1>Método de pago actualizado</h1>
                        <p>A partir de ahora se te debitará la suscripción en el nuevo medio cargado</p>
                        <ThemeProvider theme={ theme }>
                        </ThemeProvider>
                        <div className={Styles.ButtonsContainer}>
                            <Button
                                sx={ buttonOK }
                                ref={ submitButton }
                                onClick={handleClose}
                            >
                                Continuar
                            </Button>
                        </div>
                    </div>
                </Box>
            </Fade>
        </Modal>
    )
}

export default SuccessPaymentUpdated;