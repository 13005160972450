import React from 'react'
import { LayoutAuth } from '../../layout/LayoutAuth'
import { PasswordRecoveryNotValidTokenComponent } from '../../components/Auth/views/PasswordRecoveryNotValidTokenComponent'

export const PasswordRecoveryNotValidToken = () => {
  return (
    <LayoutAuth>
        <PasswordRecoveryNotValidTokenComponent/>
    </LayoutAuth>
  )
}
