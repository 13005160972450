import React from 'react';
import { connect } from 'react-redux';

// Actions
import { setActiveStepChat, setCompleteStepChat } from "../../actions/stepsChat";
import { setActiveStep, setCompleteStep } from "../../actions/stepsUser";

// Components
import StepsButton from './StepsButton';

// Material Ui
import { Stack } from '@mui/material';

const containerStyles = {
    margin: '1.5rem auto 0 auto',
    border: '2px solid #E3E3E3',
    backgroundColor: 'white',
    boxSizing: 'border-box',
    padding: '.5rem 1rem',
    borderRadius: '16px',
    maxWidth: '630px',
    width: '100%',
    '@media(min-width: 600px)': {
        padding: '1rem 2rem',
        marginTop: '0rem',
    }
};

const NewStepper = (props) => {

    // State
    const { activeStep, activeStepChat } = props;

    // Array steps
    const { steps } = props;

    // Type
    const { screen } = props;

    return (
        <Stack sx={containerStyles} direction="row" alignItems="center" justifyContent="space-between" spacing={3}>
            {steps.map((number, index) => (
                <StepsButton 
                    key={ number } 
                    step={ index + 1 }  
                    activeStep={ screen === 'Chat' ? (activeStepChat + 1) : (activeStep + 1) }
                    screen={ screen } 
                />
            ))}
        </Stack>
    )
}

const mapStateToProps = state => {
    return {
        activeStep: state.stepsUser.activeStep,
        completedStep: state.stepsUser.completeStep,

        activeStepChat: state.stepsChat.activeStepChat,
        completedStepChat: state.stepsChat.completeStepChat,
    }
}

const mapDispatchToProps = dispatch => {
    return {
        completeStepChat: (value) => dispatch(setCompleteStepChat(value)),
        activeStepChatAction: (value) => dispatch(setActiveStepChat(value)),

        activeStepRedux: (value) => dispatch(setActiveStep(value)),
        completeStep: (value) => dispatch(setCompleteStep(value)),
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(NewStepper);