import React, { useEffect, useState } from 'react';

// Components
import MenuComponent from '../../Menu/MenuComponent';

// CSS
import Styles from './UserComponentMobile.module.css';

const UserComponentMobile = ({ srcAvatar, checked, phone, userName, onClickDisabled, onClickEdit, onClickDelete }) => {

    const [showDots, setShowDots] = useState( false );

    const vw = Math.max(document.documentElement.clientWidth || 0, window.innerWidth || 0);

    useEffect(() => {
        if (vw < 400 && userName.length > 10 ) {
            setShowDots( true );
        } else {
            setShowDots( false );
        }
    }, [ vw, userName ]);

    return (
        <div className={Styles.UserContainerMobile} style={{ border: checked ? '1px solid #2ED588' : '1px solid #BFBBBD'}}>
            <div className={Styles.UserDataContainer}>
                <div className={Styles.AvatarSection}>
                    <img 
                        alt="Logo" 
                        src={srcAvatar} 
                        style={{ 
                            border: checked ? '1px solid #2ED588' : '1px solid #BFBBBD', 
                            opacity: checked ?  '1' : '.7',
                            borderRadius: '50%', 
                        }} 
                    />
                </div>
                <div className={Styles.NameSection} >
                    {
                        showDots ?
                            <p className={Styles.UserName} style={{ color: checked ? '#473C41' : '#BFBBBD' }}>
                                { userName.substring(0, 8) }...
                            </p>
                        :
                            <p className={Styles.UserName} style={{ color: checked ? '#473C41' : '#BFBBBD' }}>
                                { userName }
                            </p>

                    }
                    <p className={Styles.UserPhone}>
                        { phone }
                    </p>
                </div>
            </div>
            <div className={Styles.OptionsSection}>
                <MenuComponent
                    onClickDisabled={ onClickDisabled }
                    onClickDelete={ onClickDelete }
                    onClickEdit={ onClickEdit }
                    checked={ checked }
                    type={'user'}
                />
            </div>
        </div>
    )
}

export default UserComponentMobile;
