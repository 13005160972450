import React, { useMemo } from 'react';

// Components
import EcommerceComponent from './EcommerceComponent';
import ManualComponent from './ManualComponent';
import CloseIcon from '@mui/icons-material/Close';

// Images
import MercadoshopsIcon from '../../../assets/images/Integrations/MercadoshopsIcon.png';
import HechoAMedidaIcon from '../../../assets/images/Integrations/HechoAMedidaIcon.png';
import WooCommerceIcon from '../../../assets/images/Integrations/WooCommerceIcon.png';
import TiendanubeIcon from '../../../assets/images/Integrations/TiendanubeIcon.png';
import WhatsPROIcon from '../../../assets/images/Integrations/WhatsPROIcon.png';

// Material ui
import { Box, Fade, Modal, Typography } from '@mui/material';

// CSS
import Styles from './IntegrationsModal.module.css';
import { APP_ID } from '../../../configuration';

const Title =  {
    margin: '1rem auto 0 auto',
    fontWeight: '500',
    fontSize: '.9rem',
    width: '90%',
    '@media(min-width: 500px)': {
        margin: '1rem auto',
        fontSize: '1.2rem',
    }
}

const ecommercesInfo = [
    {
        name: 'Tiendanube',
        icon: TiendanubeIcon,
        body: 'Al hacer clic en aceptar, acepta las Políticas de Privacidad de la aplicación. Podrá verlo o eliminarlo en Mis Aplicaciones. Tiendanube no se hace responsable de la aplicación y no garantiza su calidad, precisión y seguridad, quedando la integración a este servicio a criterio exclusivo del Usuario. En caso de duda, consulte los Términos de Uso de Tiendanube.',
        redirectUrl: `https://www.tiendanube.com/apps/${APP_ID}/authorize?state=csrf-code`,
    },
    {
        name: 'Mercado Shops',
        icon: MercadoshopsIcon,
        body: 'Al hacer clic en aceptar, acepta las Políticas de Privacidad de la aplicación. Podrá verlo o eliminarlo en Mis Aplicaciones. Mercado Shops no se hace responsable de la aplicación y no garantiza su calidad, precisión y seguridad, quedando la integración a este servicio a criterio exclusivo del Usuario. En caso de duda, consulte los Términos de Uso de Mercado Shops.',
        redirectUrl: 'https://auth.mercadolibre.com.ar/authorization?response_type=code&client_id=4667354953492772',
    },
    {
        name: 'Woo Commerce',
        icon: WooCommerceIcon,
        body: 'Al hacer clic en aceptar, acepta las Políticas de Privacidad de la aplicación. Podrá verlo o eliminarlo en Mis Aplicaciones. Woo Commerce no se hace responsable de la aplicación y no garantiza su calidad, precisión y seguridad, quedando la integración a este servicio a criterio exclusivo del Usuario. En caso de duda, consulte los Términos de Uso de Woo Commerce.',
        redirectUrl: 'https://woocommerce.com/',
    },
];

const FinalModalIntegration = ({ open, ecommerce = '', handleShowModal, handleShowCongratsModal }) => {

    const StepsArray = [
        {
            nro: 1,
            step: 'Copiá el siguiente código:'
        },
        {
            nro: 2,
            step: 'Insertalo justo por encima de la etiqueta final </body> del código de tu página.'
        },
        {
            nro: 3,
            step: '¡Listo! Ya tenés integrado WhatsPRO en tu tienda.'
        },
    ];

    const selectedEcommerce = useMemo(() => {
        const ecommerceInfo = ecommercesInfo.find( e => e.name === ecommerce );
        if( ecommerceInfo ) {
            return ecommerceInfo;
        }
        return null;
    }, [ecommerce]);

    return (
        <Modal
            open={ open }
            aria-labelledby="modal-modal-title"
            aria-describedby="modal-modal-description"
        >
            <Fade in={ open }>
                <Box className={ Styles.ModalContainer }>
                    <Box>
                        <img src={ WhatsPROIcon } alt="Icons" className={ Styles.WhatsPROIcon }/>
                        <img src={selectedEcommerce ? selectedEcommerce.icon : HechoAMedidaIcon} alt="icon" className={ Styles.EcommerceIcon }/>
                    </Box>
                    <CloseIcon
                        sx={{ cursor: 'pointer', position: 'absolute', top: '1rem', right: '1rem', color: '#2ED588' }}
                        onClick={ handleShowModal }
                    />
                    { 
                        selectedEcommerce ? 
                            <Typography sx={ Title }>Integrá WhatsPRO a { selectedEcommerce.name }</Typography>
                        :
                            <Typography sx={ Title }>Integrá WhatsPRO manualmente</Typography>
                    }
                    {
                        selectedEcommerce ?
                            <EcommerceComponent
                                onClose={ handleShowModal }
                                body={ selectedEcommerce.body}
                                origin={ selectedEcommerce.name }
                                redirectUrl={ selectedEcommerce.redirectUrl }
                            />
                        :
                            <ManualComponent 
                                onClose={ handleShowModal }
                                stepsArray={ StepsArray }
                            />
                    }
                </Box>
            </Fade>
        </Modal>
    )
}

export default FinalModalIntegration;