import React from 'react';

// Components
import ArrowBottom from '../../../assets/images/Advanced/ArrowBottom';
import ArrowTop from '../../../assets/images/Advanced/ArrowTop';

// CSS
import Styles from './DelayButtonComponent.module.css';

const DelayButtonComponent = (props) => {

    // External props
    const { delay, onChange } = props;

    const handleMoreSeconds = () => {
        if ( delay >= 60 ) {
            return;
        } else {
            onChange( delay + 1 );
        }
    }

    const handleLessSeconds = () => {
        if ( delay <= 0 ) {
            return;
        } else {
            onChange( delay - 1 );
        }
    }

    return (
        <div className={Styles.DelayButttonContainer}>
            <div className={Styles.SecondsSection}>
                <p>{ delay } segundos</p> 
            </div>
            <div className={Styles.ButtonsSection}>
                <div className={Styles.ButtonTop} onClick={ () => handleMoreSeconds() } >
                    <ArrowTop color={ '#2ED588' } />
                </div>
                <div className={Styles.ButtonBottom} onClick={ () => handleLessSeconds() } >
                    <ArrowBottom color={ '#2ED588' } />
                </div>
            </div>
        </div>
    )
}

export default DelayButtonComponent;