import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import useFetch from 'use-http';

// Actions
import { setAdvancedOptions, setEditAdvancedOptions } from '../../actions/stepsChat';

// Components
import BulletPointDelayComponent from '../../components/Advanced/BulletPointDelayComponent/BulletPointDelayComponent';
import ButtonDesignComponent from '../../components/Advanced/ButtonDesignComponent/ButtonDesignComponent';
import DelayButtonComponent from '../../components/Advanced/DelayButtonComponent/DelayButtonComponent';
import AlignIconComponent from '../../components/Advanced/AlignIconComponent/AlignIconComponent';
import SaveButtonAdvanced from '../../components/Chat/SaveButtonAdvanced/SaveButtonAdvanced';
import ButtonVisibility from '../../components/Advanced/ButtonVisibility/ButtonVisibility';
import SnackbarAdvanced from '../../components/Banners/SnackbarAdvanced/SnackbarAdvanced';
import DrawerAdvanced from '../../components/Drawers/DrawerAdvanced/DrawerAdvanced';
import GreenSwitch from '../../components/GreenSwitch/GreenSwitch';

// import SaveChangesAdvanced from '../../components/Modals/SaveChangesAdvanced/SaveChangesAdvanced';
// import HelpComponent from '../../components/HelpComponent/HelpComponent';
// import ChatWindow from '../../components/Chat/ChatWindow/ChatWindow';

// Configurations
import { MAIN_API_URL } from '../../configuration';

// Icons
import HelpOutlineOutlinedIcon from '@mui/icons-material/HelpOutlineOutlined';

// Layout
import LayoutCreateUser from '../../layout/LayoutCreateUser';
import LayoutPages from '../../layout/LayoutPages';

// Material Ui
import { Box, Button, Typography } from '@mui/material';

// CSS
import Styles from './AdvancedScreen.module.css';

const AdvancedScreenContainer = {
    userSelect: 'none',
    textAlign: 'left',
}

const ButtonTextsStyles = {
    animation: 'pulseIntegrationAnimation 2s 1',
    transform: 'rotate(-90deg)',
    backgroundColor: '#2ED588',
    textTransform: 'none',
    animationDelay: '2s',
    borderRadius: '8px',
    position: 'fixed',
    fontSize: '1rem',
    right: '-2.5rem',
    display: 'flex',
    width: '110px',
    height: '40px',
    top: '140px',
    '@keyframes pulseAdvancedAnimation': {
        '0%': {
            fontSize: '1rem',
            width: '110px',
            height: '40px',
        },
        '25%': {
            fontSize: '1rem',
            width: '110px',
            height: '40px',
        },
        '50%': {
            fontSize: '1.05rem',
            width: '115px',
            height: '45px',
        },
        '75%': {
            fontSize: '1rem',
            width: '110px',
            height: '40px',
        }
    },
    ':hover': {
        backgroundColor: '#2ED588',
    },
    '@media(min-width: 1300px)': {
        top: '100px',
    }
}

const AdvancedScreen = (props) => {

    // Actions
    const { advancedOptions, setEditAdvancedOptions } = props;

    // State
    const {
        editAdvancedOptions,
        enabledTextButton,
        bulletPointDelay,
        buttonVisibility,
        whatsAppIcon,
        alignButton,
        delayButton,
        textButton,
    } = props;

    const TextareaStyles = {
        border: enabledTextButton ? '1px solid #2ED588' : '1px solid #BFBBBD',
        marginTop: '1px',
        height: '63px',
    }

    const [saveIncompleteChanges, setSaveIncompleteChanges] = useState(false);
    // const [editAdvanced, setEditAdvanced] = useState(false);
    // const [objectBack, setObjectBack] = useState({});
    const [showSuccess, setShowSuccess] = useState(false);
    const [showError, setShowError] = useState(false);
    const [showTexts, setShowTexts] = useState(false);
    const [chatArray, setChatArray] = useState([]);

    const { error, response, get, put } = useFetch(`${MAIN_API_URL}/chat`, {
        headers: {
            "x-token": `${localStorage.getItem("token")}`
        }
    });

    useEffect(() => { getChat(); }, []);

    const getChat = async () => {
        const resp = await get("/");
        // console.log('RESP --> ', resp);
        if (response.ok) {
            setChatArray(resp);
        } else {
            console.log(error);
        }
    }

    useEffect(() => {
        if (chatArray.length === undefined) {
            advancedOptions({
                bulletPointDelay: (chatArray.feedbackMessage.timeReveal / 1000),
                alignButton: chatArray.xPosition.left ? 'left' : 'right',
                enabledTextButton: chatArray.feedbackMessage.state,
                delayButton: (chatArray.delayButtonAppear / 1000),
                textButton: chatArray.feedbackMessage.message,
                buttonVisibility: chatArray.visible,
                whatsAppIcon: chatArray.whatsAppIcon
            });
        }
    }, [chatArray]);

    const handleEnabledTextButton = () => {
        setEditAdvancedOptions({
            editAdvancedOptions: true
        });

        advancedOptions({
            enabledTextButton: !enabledTextButton
        });
    }

    const handleChangeTextButton = (e) => {
        setEditAdvancedOptions({
            editAdvancedOptions: true
        });

        if (e.target.value.length > 30) return;
        advancedOptions({
            textButton: e.target.value
        });
    }

    const handleSaveChanges = async () => {

        const resp = await put("/", {
            "visible": buttonVisibility,
            "delayButtonAppear": delayButton * 1000,
            "xPosition": {
                "left": alignButton === 'right' ? false : true,
                "right": alignButton === 'right' ? true : false
            },
            "feedbackMessage": {
                "message": textButton,
                "state": enabledTextButton,
                "timeReveal": bulletPointDelay * 1000
            },
            "whatsAppIcon": whatsAppIcon
        });

        if (response.ok) {
            setSaveIncompleteChanges(!saveIncompleteChanges);
            setEditAdvancedOptions({
                editAdvancedOptions: false
            });
            setShowSuccess(true);

            setTimeout(() => {
                window.location.href = "/advanced";
            }, 1000);
        } else {
            setShowError(true);
            console.log(error);
        }
    }

    // const handleShowModal = () => {
    //     setSaveIncompleteChanges( !saveIncompleteChanges );
    // }

    const onChange = (value) => {
        setEditAdvancedOptions({
            editAdvancedOptions: true
        });

        advancedOptions({
            delayButton: value
        });
    }

    const onChangeBulletPoint = (value) => {
        setEditAdvancedOptions({
            editAdvancedOptions: true
        });

        advancedOptions({
            bulletPointDelay: value
        });
    }

    const handleClose = (event, reason) => {
        if (reason === 'clickaway') {
            return;
        }

        setShowSuccess(false);
        setShowError(false);
    };

    const handleShowHelpTexts = (open) => (event) => {
        if (event.type === 'keydown' && (event.key === 'Tab' || event.key === 'Shift')) {
            return;
        }

        setShowTexts(open);
    }

    return (
        <LayoutPages>
            <Box sx={AdvancedScreenContainer}>
                <LayoutCreateUser>
                    <h1 className={Styles.Title}>Configuración avanzada</h1>
                    <div className={Styles.TopSection}>
                        <ButtonVisibility />
                        <div className={Styles.ButtonDesignSection}>
                            <div className={Styles.TitleSection}>
                                <h2>Diseño del botón de WhatsPRO</h2>
                            </div>
                            <ButtonDesignComponent />
                        </div>
                    </div>
                    <section className={Styles.FormContainer}>
                        <div className={Styles.AligSection}>
                            <div className={Styles.TitleSection}>
                                <h2>Posición del botón WhatsPRO</h2>
                                {/* <HelpComponent
                                    helperText={'Indica si el botón de WhatsPRO se coloca a la izquierda o a la derecha en la tienda'}
                                /> */}
                            </div>
                            <AlignIconComponent />
                        </div>
                        <div className={Styles.TextButtonSection}>
                            <div className={Styles.TitleSection}>
                                <h2>Viñeta del botón WhatsPRO</h2>
                                {/* <HelpComponent
                                    helperText={'Indica el texto que se mostrará por encima del botón de WhatsPRO'}
                                /> */}
                                <GreenSwitch
                                    checked={enabledTextButton}
                                    onClick={() => handleEnabledTextButton()}
                                />
                            </div>
                            <Typography variant='body2' color={'gray'} marginBottom={1}>Cuadro de diálogo que invita al usuario a abrir el chat</Typography>
                            <textarea
                                className={Styles.TextAreaButton}
                                placeholder="Texto de la viñeta"
                                onChange={handleChangeTextButton}
                                disabled={!enabledTextButton}
                                rows="1"
                                value={textButton}
                                style={TextareaStyles}
                            />
                            <p className={Styles.CharactersMaxText} >Máx 30 caracteres</p>
                        </div>
                        <div className={Styles.DelayButtonSection}>
                            <div className={Styles.TitleSection}>
                                <h2>Delay del botón de WhatsPRO</h2>
                                {/* <HelpComponent
                                    helperText={'Indica a partir de cuántos segundos después de cargada la página, se mostrará el botón de WhatsPRO'}
                                /> */}
                            </div>
                            <Typography variant='body2' color={'gray'} marginBottom={1}>Cuantos segundos después de que cargue la página querés que aparezca el botón</Typography>
                            <DelayButtonComponent delay={delayButton} onChange={onChange} />
                        </div>
                        <div className={Styles.DelayBulletsPointSection}>
                            <div className={Styles.TitleSection}>
                                <h2>Delay de viñeta de WhatsPRO</h2>
                                {/* <HelpComponent
                                    helperText={'Indica a partir de cuántos segundos después de que aparezca el botón, se mostrará la viñeta de WhatsPRO'}
                                /> */}
                            </div>
                            <Typography variant='body2' color={'gray'} marginBottom={1}>Cuantos segundos después de que cargue la página querés que aparezca la viñeta con el mensaje</Typography>
                            <BulletPointDelayComponent delay={bulletPointDelay} onChange={onChangeBulletPoint} />
                        </div>
                        {editAdvancedOptions ? <SaveButtonAdvanced onClick={handleSaveChanges} /> : null}
                        {/* { saveIncompleteChanges ? 
                                <SaveChangesAdvanced
                                    open={ saveIncompleteChanges }
                                    handleClose={ handleShowModal }
                                /> 
                            : 
                                null 
                        } */}
                        {showSuccess && <SnackbarAdvanced open={true} handleClose={handleClose} success={true} />}
                        {showError && <SnackbarAdvanced open={true} handleClose={handleClose} success={false} />}
                    </section>
                </LayoutCreateUser>
            </Box>
            <Button
                sx={ButtonTextsStyles}
                variant='contained'
                onClick={handleShowHelpTexts(true)}
            >
                <HelpOutlineOutlinedIcon />&nbsp;Ayuda
            </Button>
            <DrawerAdvanced open={showTexts} toggleDrawer={handleShowHelpTexts} />
        </LayoutPages>
    )
}

const mapStateToProps = state => {
    return {
        enabledTextButton: state.stepsChat.advancedOptions.enabledTextButton,
        buttonVisibility: state.stepsChat.advancedOptions.buttonVisibility,
        bulletPointDelay: state.stepsChat.advancedOptions.bulletPointDelay,
        whatsAppIcon: state.stepsChat.advancedOptions.whatsAppIcon,
        alignButton: state.stepsChat.advancedOptions.alignButton,
        delayButton: state.stepsChat.advancedOptions.delayButton,
        editAdvancedOptions: state.stepsChat.editAdvancedOptions,
        textButton: state.stepsChat.advancedOptions.textButton,
    }
}

const mapDispatchToProps = dispatch => {
    return {
        setEditAdvancedOptions: (value) => dispatch(setEditAdvancedOptions(value)),
        advancedOptions: (value) => dispatch(setAdvancedOptions(value)),
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(AdvancedScreen);

