// Material Ui
import { Box } from '@mui/material';

const LayoutPages = ({ children }) => {

    const MyUsersScreenContainer = {
        paddingBottom: '2rem', 
        paddingRight: '2rem', 
        paddingLeft: '2rem',
        paddingTop: '0rem',
        userSelect: 'none',
        '@media(min-width: 500px)': {
            paddingTop: '.5rem',
        },
        '@media(min-width: 600px)': {
            paddingTop: '1rem',
        },
        '@media(min-width: 800px)': {
            paddingTop: '4rem',
        },
        '@media(min-width: 1000px)': {
            paddingBottom: '2rem', 
            paddingRight: '2rem', 
            paddingLeft: '2rem',
            paddingTop: '3rem',
        },
        '@media(min-width: 1300px)': {
            paddingTop: '0rem',
        },
    }

    return (
        <Box component="main" sx={ MyUsersScreenContainer }>
            { children }
        </Box>
    )
}

export default LayoutPages