import React from 'react'

// Material Ui
import { FormControlLabel, Switch } from '@mui/material';
import { styled } from '@mui/material/styles';

const IOSSwitch = styled((props) => (
    <Switch focusVisibleClassName=".Mui-focusVisible" disableRipple {...props} onChange={ props.onClick } checked={props.checked}/>
    ))(({ theme }) => ({
        width: 50,
        height: 26,
        padding: 0,
        '& .MuiSwitch-switchBase': {
            margin: 2,
            padding: 0,
            transitionDuration: '300ms',
            '&.Mui-checked': {
                transform: 'translateX(24px)',
                color: '#fff',
                '& + .MuiSwitch-track': {
                backgroundColor: theme.palette.mode === 'dark' ? '#2ED588' : '#2ED588',
                opacity: 1,
                border: 0,
                },
                '&.Mui-disabled + .MuiSwitch-track': {
                opacity: 0.5,
                },
            },
            '&.Mui-focusVisible .MuiSwitch-thumb': {
                color: '#33cf4d',
                border: '6px solid #fff',
            },
            '&.Mui-disabled .MuiSwitch-thumb': {
                color:
                theme.palette.mode === 'light'
                    ? theme.palette.grey[100]
                    : theme.palette.grey[600],
            },
            '&.Mui-disabled + .MuiSwitch-track': {
                opacity: theme.palette.mode === 'light' ? 0.7 : 0.3,
            },
        },
        '& .MuiSwitch-thumb': {
            boxSizing: 'border-box',
            width: 22,
            height: 22,
        },
        '& .MuiSwitch-track': {
            borderRadius: 26 / 2,
            backgroundColor: theme.palette.mode === 'light' ? '#E9E9EA' : '#2ED588',
            opacity: 1,
            transition: theme.transitions.create(['background-color'], {
                duration: 500,
            }),
        },
}));

const GreenSwitch = ({ onClick, checked, disabled = false }) => {
    return (
        <FormControlLabel
            control={<IOSSwitch sx={{ m: 1 }} onClick={ onClick }/>}
            checked={ checked }
            disabled={ disabled }
            sx={{
                marginRight: '0px',
            }}
        />
    )
}

export default GreenSwitch