import VisaCreditoIcon from "../../../assets/icons/PaymentOptions/VisaCreditoIcon";
import Americanexpress from "../../../assets/icons/PaymentOptions/Americanexpress";
import MastercardIcon from "../../../assets/icons/PaymentOptions/MastercardIcon";
import DinersClubIcon from "../../../assets/icons/PaymentOptions/DinersClubIcon";
import VisaDebitoIcon from "../../../assets/icons/PaymentOptions/VisaDebitoIcon";
import MercadopagoIcon from "../../../assets/icons/PaymentOptions/MercadoPago";
import ArgencardIcon from "../../../assets/icons/PaymentOptions/ArgencardIcon";
import DiscoverIcon from "../../../assets/icons/PaymentOptions/DiscoverIcon";
import NaranjaIcon from "../../../assets/icons/PaymentOptions/NaranjaIcon";
// import MaestroIcon from "../../../assets/icons/PaymentOptions/MaestroIcon";
import NativaIcon from "../../../assets/icons/PaymentOptions/NativaIcon";
import CabalIcon from "../../../assets/icons/PaymentOptions/CabalIcon";
import JCBIcon from "../../../assets/icons/PaymentOptions/JCBIcon";

export const ProviderPaymentList = [
    {
        code: 'master',
        name: 'Mastercard',
        icon: <MastercardIcon fill={'#fff'}/>
    },
    {
        code: 'visa',
        name: 'Visa',
        icon: <VisaCreditoIcon fill={'#fff'}/>
    },
    {
        code: 'cabal',
        name: 'Cabal',
        icon: <CabalIcon fill={'#fff'}/>
    },
    {
        code: 'account_money',
        name: 'MercadoPago',
        icon: <MercadopagoIcon fill={'#fff'}/>
    },
    {
        code: 'debvisa',
        name: 'Visa Débito',
        icon: <VisaDebitoIcon fill={'#fff'}/>
    },
    {
        code: 'debmaster',
        name: 'Mastercard Débito',
        icon: <MastercardIcon fill={'#fff'}/>
    },
    {
        code: 'amex',
        name: 'American Express',
        icon: <Americanexpress fill={'#fff'}/>
    },
    {
        code: 'debargencard',
        name: 'Argencard Débito',
        icon: <ArgencardIcon fill={'#fff'}/>
    },
    {
        code: 'credit_card',
        name: 'Tarjeta de crédito',
        icon: <VisaCreditoIcon fill={'#fff'}/>
    },
    {
        code: 'debit_card',
        name: 'Tarjeta de débito',
        icon: <VisaDebitoIcon fill={'#fff'}/>
    },
    {
        code: 'prepaid_card',
        name: 'Tarjeta Prepaga',
        icon: <VisaCreditoIcon fill={'#fff'}/>
    },
    {
        code: 'diners',
        name: 'Diners',
        icon: <DinersClubIcon fill={'#fff'}/>
    },
    {
        code: 'discover',
        name: 'Discover',
        icon: <DiscoverIcon fill={'#fff'}/>
    },
    {
        code: 'jcb',
        name: 'JCB',
        icon: <JCBIcon fill={'#fff'}/>
    },
    {
        code: 'naranja',
        name: 'Naranja',
        icon: <NaranjaIcon fill={'#fff'}/>
    },
    {
        code: 'nativa',
        name: 'Nativa',
        icon: <NativaIcon fill={'#fff'}/>
    },
    // {
    //     code: 'nativa',
    //     name: 'Nativa',
    //     icon: <MaestroIcon fill={'#fff'}/>
    // },
]