import React from 'react';
import { connect } from 'react-redux';

// Components
import OfferDurationComponent from './OfferDurationComponent/OfferDurationComponent';
import StepperComponent from './StepperComponent/StepperComponent';
import StepsComponent from './StepsComponent/StepsComponent';
import CardComponent from './CardComponent/CardComponent';

// Material Ui
import { Box, createTheme, Grid, ThemeProvider, Typography } from '@mui/material';

const theme = createTheme({
    breakpoints: {
        values: {
            xs: 0,
            sm: 600,
            md: 900,
            lg: 1600,
            xl: 1800,
        },
    },
});

const GeneralContainer = {
    marginBottom: '1rem',
    paddingRight: 0, 
    '@media(min-width: 350px)': {
        paddingRight: '1rem', 
    }
}

const TopContainer = {
    justifyContent: 'space-between',
    boxSizing: 'border-box',
    paddingLeft: '.5rem',
    alignItems: 'center',
    display: 'block',
    width: '100%',
    '@media(min-width: 600px)': {
        display: 'flex',
    }
}

const TitleContainer = {
    paddingBottom: '.5rem',
    alignItems: 'center', 
    display: 'block', 
    gap: '5px',
    '@media(min-width: 320px)': {
        alignItems: 'center',
        display: 'flex',
    },
    '@media(min-width: 600px)': {
        alignItems: 'center',
        paddingBottom: 0,
        display: 'flex',
    }
}

const Title = {
    fontSize: '1.2rem',
    textAlign: 'left',
    fontweight: '500',
}

const CardsGeneralContainer = {
    overflowX: 'auto',
    '::-webkit-scrollbar': {
        backgroundColor: 'white',
        right: '10px',
    },
    '::-webkit-scrollbar:horizontal': {
        height: '9px',
    },
    '::-webkit-scrollbar-thumb': {
        border: '9px solid transparent',
        backgroundColor: '#F0EFEF',
        borderRadius: '20px',
    }
}

const CardsContainer = {
    justifyContent: 'space-between',
    margin: '1rem auto 0rem auto',
    minWidth: '1400px',
    maxWidth: '1440px',
    display: 'flex', 
    width: '100%',
    gap: '10px',
}

const StepperContainer = {
    margin: '2rem auto 0rem auto',
    paddingBottom: '1rem',
    minWidth: '1410px',
    maxWidth: '1500px',
    width: '100%',
}

const TasksComponent = (props) => {

    // States
    const { taskArray, activeTask } = props;

    // External props
    const { handleShowCongratsModal } = props;

    return (
        <ThemeProvider theme={ theme }>
            <Grid container sx={ GeneralContainer }>
                <Grid item lg={12} sx={ TopContainer }>
                    <Box sx={ TitleContainer }>
                        <Typography sx={ Title }>Tus tareas</Typography>
                        <StepsComponent step={ activeTask }/>
                    </Box>
                    <OfferDurationComponent/>
                </Grid>
                <Grid item lg={12} sx={ CardsGeneralContainer }>
                    <Box sx={ CardsContainer }>
                        {
                            taskArray.map( task => (
                                <CardComponent
                                    handleShowCongratsModal={ handleShowCongratsModal }
                                    description={ task.description }
                                    task={ task.number }
                                    state={ task.state }
                                    name={ task.name }
                                    key={ task.number }
                                />
                            ))
                        }
                    </Box>
                    <Box sx={ StepperContainer }>
                        <StepperComponent task={ 4 } />
                    </Box>
                </Grid>
            </Grid> 
        </ThemeProvider>
    )
}

const mapStateToProps = state => {
    return {
        activeTask: state.tasks.activeTask,
        taskArray: state.tasks.taskArray,
    }
}

const mapDispatchToProps = dispatch => {
    return {
        
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(TasksComponent);