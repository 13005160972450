import { typesIntegrations } from '../types/types'

export const setActiveSite = ( data ) => ({
    type: typesIntegrations.activeSite,
    payload: data,
});

export const onSetQuestionSelected = ( data ) => ({
    type: typesIntegrations.setQuestionSelected,
    payload: data,
})