import React, { useState, useEffect } from 'react';
import YouTube from 'react-youtube';
import useFetch from 'use-http';

// Configurations
import { MAIN_API_URL } from '../../../configuration';

// Images
import AvatarTask1 from '../../../assets/images/Home/AvatarTask1.png';

// Layout
import LayoutCongratsModals from '../../../layout/LayoutCongratsModals';

// CSS
import Styles from './CongratsModal.module.css';
import { connect } from 'react-redux';
import { Typography } from '@mui/material';

const Task1CongratsModal = (props) => {

    // External props
    const { open, handleClose } = props;

    const [disabledNext, setDisabledNext] = useState(false);
    const [store, setStore] = useState(null);

    const { error, response, get } = useFetch(`${MAIN_API_URL}/stores`, {
        headers: {
            "x-token": `${localStorage.getItem("token")}`
        }
    });

    useEffect(() => { getStore(); }, []);

    const getStore = async () => {
        const resp = await get("/");
        if (response.ok) {
            setStore(resp);
        } else {
            console.log(error);
        }
    }

    // const handleVideoPlay = () => {
    //     setDisabledNext(false);
    // }

    return (
        <LayoutCongratsModals
            disabledButtonNext={disabledNext}
            handleClose={handleClose}
            srcAvatar={AvatarTask1}
            width1={'100%'}
            width2={'420px'}
            width3={'580px'}
            open={open}
        >
            <div className={Styles.DataSection}>
                <h1>Felicitaciones, {store?.name}</h1>
                <h2>¡Ganaste tus primeros 15 días <span style={{ fontWeight: '600' }}>GRATIS!</span> 🎉 </h2>
                <p>Mirá este video para comenzar a configurar tu Whatspro:</p>
                <section className={Styles.VideoSection}>
                    <YouTube
                        videoId="v3mI_fZ6Fao"
                        className={Styles.Video}
                        // onEnd={handleVideoPlay}
                    />
                </section>
                {/* <Typography variant="body2" color="text.secondary" sx={{ mt: 2 }}>
                    *Para continuar, debes ver primero el video completo.
                </Typography> */}
            </div>
        </LayoutCongratsModals>
    )
}

const mapDispatchToProps = dispatch => {
    return {
    }
}

export default connect(null, mapDispatchToProps)(Task1CongratsModal);