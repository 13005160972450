import React from 'react'
import { LayoutAuth } from '../../layout/LayoutAuth'
import { PasswordRecoverySentComponent } from '../../components/Auth/views/PasswordRecoverySentComponent'

export const PasswordRecoverySent = () => {
  return (
    <LayoutAuth>
        <PasswordRecoverySentComponent/>
    </LayoutAuth>
  )
}
