import React from 'react'
import { LayoutAuth } from '../../layout/LayoutAuth'
import { LoginComponent } from '../../components/Auth/views/LoginComponent'

export const Login = () => {
    return (
        <LayoutAuth>
            <LoginComponent/>
        </LayoutAuth>
    )
}
