export const translateLabels = (
  labels = [],
  groupedBy = "month",
  language = "es"
) => {
  switch (groupedBy) {
    case "dayOfWeek":
      return labels.map((label) => {
        return label;
        // const day = label - 1;
        // const date = new Date(2020, 0, day);
        // return date.toLocaleString(language, { weekday: "long" });
        //first letter in uppercase
        // return date.toLocaleString(language, { weekday: "long" }).charAt(0).toUpperCase() + date.toLocaleString(language, { weekday: "long" }).slice(1);
      });
    case "month":
      return labels.map((label) => {
        // const month = parseInt(label);
        // const date = new Date(2020, month, 1);
        const month = label - 1;
        const date = new Date(2020, month, 1);
        return date.toLocaleDateString(language, { month: "long" }).charAt(0).toUpperCase() + date.toLocaleDateString(language, { month: "long" }).slice(1);
      });
    default:
      return labels;
  }
};
