import React from 'react';
import { connect } from 'react-redux';

// Actions
import { setAdvancedOptions, setEditAdvancedOptions } from '../../../actions/stepsChat';

// Icons
import WhatsAppIcon from '../../../assets/icons/Advanced/WhatsAppIcon';
import WhastPROIcon from '../../../assets/icons/Advanced/WhastPROIcon';

// Material Ui
import { Box, Grid } from '@mui/material';

const GeneralContainer = {
    border: '1px solid #BFBBBD',
    backgroundColor: 'white',
    boxSizing: 'border-box',
    height: 'max-content',
    borderRadius: '10px',
    padding: '.5rem',
    width: '100%',
    '@media(min-width: 450px)': {
        width: '350px',
    }
}

const ButtonDesignComponent = (props) => {

    // Actions
    const { setEditAdvancedOptions, advancedOptions } = props;

    // States
    const { whatsAppIcon } = props;

    const IconContainerWPRO = {
        backgroundColor: !whatsAppIcon ? '#E3FFF2' : 'transparent', 
        justifyContent: 'center',
        alignItems: 'center',
        borderRadius: '10px',
        cursor: 'pointer',
        display: 'flex',
        width: '100%', 
    }

    const IconContainerWAPP = {
        backgroundColor: whatsAppIcon ? '#E3FFF2' : 'transparent',
        justifyContent: 'center',
        alignItems: 'center',
        borderRadius: '10px',
        cursor: 'pointer',
        display: 'flex',
        height: '100%', 
        width: '100%',
    }

    const handleSelectIcon = ( type ) => {
        setEditAdvancedOptions({
            editAdvancedOptions: true
        });
        if (type === 'whatsapp') {
            advancedOptions({
                whatsAppIcon: true
            });
        } else {
            advancedOptions({
                whatsAppIcon: false
            });
        }
    }

    return (
        <Grid container sx={ GeneralContainer }>
            <Grid item xs={ 6 } onClick={ () => handleSelectIcon('whatspro') }>
                <Box sx={ IconContainerWPRO }>
                    <WhastPROIcon color={ !whatsAppIcon ? '#2ED588' : '#C4C4C4' }/>
                </Box>
            </Grid>
            <Grid item xs={ 6 } onClick={ () => handleSelectIcon('whatsapp') }>
                <Box sx={ IconContainerWAPP }>
                    <WhatsAppIcon color={ whatsAppIcon ? '#2ED588' : '#C4C4C4' }/>
                </Box>
            </Grid>
        </Grid>
    )
}

const mapStateToProps = state => {
    return {
        whatsAppIcon: state.stepsChat.advancedOptions.whatsAppIcon,
    }
}

const mapDispatchToProps = dispatch => {
    return {
        setEditAdvancedOptions: (value) => dispatch(setEditAdvancedOptions(value)),
        advancedOptions: (value) => dispatch(setAdvancedOptions(value)),
    }
}

export default connect(mapStateToProps, mapDispatchToProps)( ButtonDesignComponent );