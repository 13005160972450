import React, { useEffect, useRef, useState } from "react";

import * as yup from 'yup';
import useFetch from "use-http";
import { useFormik } from 'formik';
import Card from "react-credit-cards";
import { useSnackbar } from 'notistack';
import "react-credit-cards/es/styles-compiled.css";
import { usePaymentInputs } from 'react-payment-inputs';
import CheckCircleOutlineIcon from '@mui/icons-material/CheckCircleOutline';

// Components
import SelectPayment from "../Payment/Select/SelectPayment";

// Configuration
import { MAIN_API_URL } from "../../configuration";

// Helpers
import { suscriptionErrorsToSpanish } from "../../helpers/mercadopago-codes";

// Hooks
import useMercadoPago from "../../hooks/useMercadoPago";

// Icon
import InfoOutlinedIcon from '@mui/icons-material/InfoOutlined';

// Images
import WhatsproLogo from '../../assets/logos/WhatsPRO.svg';
import BackPayment from '../../assets/images/Payment/BackPayment.png';
import MPImage from '../../assets/images/Payment/MPImage.png';

// Material Ui
import { LoadingButton } from "@mui/lab";
import { Box, Grid, Typography } from "@mui/material";

// CSS
import Styles from './MercadoPago.module.css';
import useScript from "../../hooks/useScript";
import axios from "axios";

const INITIAL_STATE = {
    cvc: "",
    cardExpirationMonth: "",
    cardExpirationYear: "",
    focus: "cardNumber",
    cardholderName: "",
    cardNumber: "",
    issuer: "",
};

const typeDoc = [
    {
        value: '',
        label: 'Tipo de documento',
    },
    {
        value: 'dni',
        label: 'DNI',
    },
    {
        value: 'lc',
        label: 'LC',
    },
];

const validationSchema = yup.object({
    expiryDate: yup
        .string('Ingresar fecha de expiración')
        .min(7, 'El minimo es de 4 numeros')
        .required('Campo obligatorio'),
    cardNumber: yup
        .string('El numero de tarjeta es obligatorio')
        .min(10, 'El minimo es de 10 numeros')
        .max(20, 'El máximo es de 16 numeros')
        .required('Campo obligatorio'),
    docNumber: yup
        .string('El numero de tarjeat es obligatorio')
        .min(8, 'El minimo es de 8 numeros')
        .required('Campo obligatorio'),
    userName: yup
        .string('El nombre es obligatorio')
        .min(4, 'El minimo es de 4 caracteres')
        .required('Campo obligatorio'),
    email: yup
        .string('Ingresar email')
        .email('El email ingresado no es valido')
        .required('Campo obligatorio'),
    cvc: yup
        .string('Ingresar cvv')
        .min(3, 'El minimo es de 3 caracteres')
        .required('Campo obligatorio'),
    typeDoc: yup
        .string('Ingresar tipo de documento')
        .required('Campo obligatorio')
});

const StyleContainer = {
    margin: '1rem auto .5rem auto',
    border: '1px solid #E5E5E5',
    height: 'max-content',
    borderRadius: '18px',
    overflowY: 'auto',
    width: '100%',
    '@media (min-width: 500px)': {
        margin: '2rem auto auto auto',
        width: '80%',
    },
    '@media (min-width: 900px)': {
        margin: 'auto',
        width: '90%',
    },
    '@media (min-width: 1700px)': {
        width: '60%',
    },
}

const GiftTextContainer = {
    border: '2px solid #E1C478',
    backgroundColor: '#FFFFFF',
    padding: '.6rem 1rem',
    borderRadius: '20px',
    width: 'max-content',
    alignItems: 'center',
    margin: '2rem auto',
    display: 'flex',
    gap: '5px',
}

const GiftText = {
    marginBottom: '-.1rem',
    fontSize: '.55rem',
    fontWeight: '600',
    '@media(min-width: 350px)': {
        fontSize: '.7rem',
    },
    '@media(min-width: 900px)': {
        fontSize: '.8rem',
    }
}

const CardSection = {
    backgroundImage: `url(${BackPayment})`,
    borderRadius: '18px 18px 0px 0px',
    backgroundRepeat: 'no-repeat',
    backgroundColor: '#F2F6FA',
    paddingTop: '1rem',
    display: 'none',
    '@media (min-width: 900px)': {
        borderRadius: '18px 0px 0px 18px',
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'center',
        alignItems: 'center',
    },
}

const buttonSubStyles = {
    backgroundColor: '#2ED588',
    textTransform: 'none',
    fontFamily: 'Poppins',
    padding: '.4rem 0',
    fontSize: '.7rem',
    color: 'white',
    width: '100%',
    ':hover': {
        backgroundColor: '#2ED588',
    },
    '@media (min-width: 1200px)': {
        padding: '.5rem 0',
        fontSize: '1rem',
    },
}

const priceWithoutIVAAndRoundedTo99 = (price) => {
    if (price < 1000) return price;
    const priceWithoutIVA = price / 1.21;
    //now if last two digits are not 99, convert to 99
    const lastTwoDigits = priceWithoutIVA % 100;
    if (lastTwoDigits !== 99) {
        return priceWithoutIVA - lastTwoDigits + 99;
    }
    return priceWithoutIVA;
}

function MercadoPagoForm(props) {

    //states
    const { buttonText, paymentProcessador, paymentType, store } = props;
    const [loading, setLoading] = useState(false);
    const { error, success, cardToken } = useMercadoPago(123);
    const [stripe, setStripe] = useState(null);
    const { Stripe } = useScript(
        "https://js.stripe.com/v2/",
        "Stripe"
    );
    const [stripeCardToken, setStripeCardToken] = useState(null);
    const { enqueueSnackbar } = useSnackbar();
    const submitButtonMp = useRef(null);
    const token = localStorage.getItem("token");
    const { response, post } = useFetch(MAIN_API_URL + '/subscriptions', {
        headers: {
            'Content-Type': 'application/json',
            'x-token': token
        }
    })

    useEffect(() => {
        const script = document.createElement('script')
        script.src = "https://www.mercadopago.com/v2/security.js";
        script.view = "";
        script.async = true

        document.body.appendChild(script);


        return () => {
            document.body.removeChild(script);
        }
    }, [])

    useEffect(() => {
        if (Stripe) {
            setStripe(Stripe('pk_test_51KnRliEEgSNKcyvOmYI5ca0hw74hrZOFUpXLcPQruUN9KK95NOeFpHlAp00WunudhFH8VdFlkD55UAhKZH1HllqP00pcZ8QC4u'));
        }
    }, [Stripe]);


    const { getCardNumberProps, getExpiryDateProps, getCVCProps } = usePaymentInputs();
    // meta de usePaymentInputs es para verificar si la tarjeta ingresada es correcta 

    const [state, setState] = useState(INITIAL_STATE);
    const [expDate, setExpDate] = useState(['', '']);


    const formik = useFormik({
        initialValues: {
            expiryDate: '',
            cardNumber: '',
            docNumber: '',
            userName: '',
            typeDoc: '',
            email: '',
            cvc: '',
        },
        validationSchema: validationSchema,
        onSubmit: async (values) => {
            Object.keys(values).forEach(key => {
                if (typeof values[key] === 'string') {
                    values[key] = values[key].trim();
                }
            })
            setLoading(true);
            if (props.paymentProcessador === 'MERCADO_PAGO') {
                submitButtonMp.current.click();
            } else {
                const params = new URLSearchParams();
                params.append('card[number]', values.cardNumber);
                params.append('card[exp_month]', expDate[0]);
                params.append('card[exp_year]', expDate[1]);
                params.append('card[cvc]', values.cvc);
                try {
                    const { data } = await axios.post('https://api.stripe.com/v1/tokens', params, {
                        headers: {
                            'Authorization': 'Bearer pk_test_51KnRliEEgSNKcyvOmYI5ca0hw74hrZOFUpXLcPQruUN9KK95NOeFpHlAp00WunudhFH8VdFlkD55UAhKZH1HllqP00pcZ8QC4u',
                            'Content-Type': 'application/x-www-form-urlencoded'
                        }
                    });
                    setStripeCardToken(data.id);
                    setLoading(false);
                } catch (error) {
                    enqueueSnackbar(error.response.data.error.message, {
                        variant: 'error',
                        autoHideDuration: 3000,
                    });
                }
            }
        },
    });

    useEffect(() => {
        if (formik.values.expiryDate && formik.values.expiryDate.length === 7) {
            setExpDate(formik.values.expiryDate.split(' / '));
        }
    }, [formik.values.expiryDate]);

    useEffect(() => {
        async function finishOnBoarding() {
            const urlHost = window.location.host;
            const mainUrl = 'https://' + urlHost;
            if (success) {
                const payload = {
                    email: formik.values.email,
                    cardToken,
                    dni: formik.values.docNumber,
                }
                if (paymentType === 'subscription') {
                    const res = await post('/mercadopago/create', payload);
                    if (response.ok && res.status >= 200 && res.status < 300) {
                        window.location.href = `${mainUrl}/home?payment=success&payment_procesador=mercadopago`;
                    } else {
                        localStorage.setItem('subError', JSON.stringify({
                            error: (res.origin === 'MERCADO_PAGO'
                                ? (res.message === 'User cards api internal server error' || res.message === 'User cards bad request'
                                    ? 'MercadoPago: El email ingresado no tiene una cuenta de MercadoPago asociada o no es la titular de la misma. Intente con otro email.'
                                    : 'MercadoPago: ' + suscriptionErrorsToSpanish(res.code ?? res.message))
                                : 'Error interno al crear la suscripción. Porfavor intente de nuevo'),
                        }))
                        window.location.reload();
                    }
                } else {
                    const res = await post('/mercadopago/update_payment', payload);
                    if (response.ok && res.status >= 200 && res.status < 300) {
                        window.location.href = `${mainUrl}/home?payment=success&payment_procesador=mercadopago&payment_type=update`;
                    } else {
                        localStorage.setItem('paymentError', JSON.stringify({
                            error: (res.origin === 'MERCADO_PAGO'
                                ? (res.message === 'User cards api internal server error' || res.message === 'User cards bad request'
                                    ? 'MercadoPago: El email ingresado no tiene una cuenta de MercadoPago asociada o no es la titular de la misma. Intente con otro email.'
                                    : 'MercadoPago: ' + suscriptionErrorsToSpanish(res.code ?? res.message))
                                : 'Error interno al crear el pago. Porfavor intente de nuevo'),
                        }))
                        window.location.reload();
                    }
                }
            } else if (stripeCardToken) {
                const payload = {
                    email: formik.values.email,
                    cardToken: stripeCardToken,
                    dni: formik.values.docNumber,
                }
                if (paymentType === 'subscription') {
                    const res = await post('/stripe/create', payload);
                    if (response.ok && res.status >= 200 && res.status < 300) {
                        window.location.href = `${mainUrl}/home?payment=success&payment_procesador=stripe`;
                    } else {
                        localStorage.setItem('subError', JSON.stringify({
                            error: (res.origin === 'STRIPE'
                                ? 'Stripe: ' + suscriptionErrorsToSpanish(res.code ?? res.message)
                                : 'Error interno al crear la suscripción. Porfavor intente de nuevo'),
                        }))
                        window.location.reload();
                    }
                } else {
                    const res = await post('/stripe/update_payment', payload);
                    if (response.ok && res.status >= 200 && res.status < 300) {
                        window.location.href = `${mainUrl}/home?payment=success&payment_procesador=stripe&payment_type=update`;
                    } else {
                        localStorage.setItem('paymentError', JSON.stringify({
                            error: (res.origin === 'STRIPE'
                                ? 'Stripe: ' + suscriptionErrorsToSpanish(res.code ?? res.message)
                                : 'Error interno al crear el pago. Porfavor intente de nuevo'),
                        }))
                        window.location.reload();
                    }
                }
            }
        }
        finishOnBoarding();
    }, [success, cardToken, stripeCardToken])

    useEffect(() => {
        setLoading(false);
        if (error && error.length > 0 && error[0].message === 'Request timed out') {
            enqueueSnackbar('Error interno al crear la suscripción. Porfavor intente de nuevo', { variant: 'error' })
        }
    }, [error]);

    useEffect(() => {
        if (props.subError && props.subError.error) {
            enqueueSnackbar(props.subError.error, {
                variant: 'error',
                anchorOrigin: {
                    vertical: 'top',
                    horizontal: 'right',
                },
                autoHideDuration: 4000,
            });
        }
    }, [props.subError]);

    return (
        // loader ? <LoaderFlowy/> :
        <Grid container spacing={0} sx={StyleContainer}>
            <Grid item sx={CardSection} xs={12} md={6} >
                <div className={Styles.TopContainer}>
                    <img src={WhatsproLogo} alt="Logo" />
                    <p>Vendé <span>más,</span> atendé <span>mejor.</span></p>
                </div>
                <div className={Styles.CardContainer}>
                    <Card
                        style={{
                            margin: '1rem auto auto auto',
                            gridColumn: '1/2',
                        }}
                        cvc={formik.values.cvc}
                        expiry={expDate[0] + expDate[1]}
                        name={formik.values.userName}
                        number={formik.values.cardNumber}
                        focused={state.focus}
                        brand={state.issuer}
                    />
                    <Box>
                        {paymentType === 'subscription'
                            ? <Typography variant="h6" sx={{ color: '#626262', fontSize: '1.5rem', textAlign: 'center', marginTop: '.5em' }}>
                                Suscribite por <b>${priceWithoutIVAAndRoundedTo99(store?.plan?.basePrice)}</b>+ IVA al mes
                            </Typography>
                            : <Typography variant="h6" sx={{ color: '#626262', fontSize: '1.5rem', textAlign: 'center' }}>
                                Cambiá tu método de pago
                            </Typography>
                        }
                        {store && store.isInTrial && store.trialDays > 0 && paymentType === 'subscription' &&
                            <Box sx={GiftTextContainer}>
                                <CheckCircleOutlineIcon sx={{ color: '#E1C478', height: '1.2rem' }} />
                                <Typography sx={GiftText}>30 DÍAS <span style={{ fontWeight: '700' }}>GRATIS</span> PARA USAR EL COMPLEMENTO</Typography>
                            </Box>
                        }
                    </Box>
                    <div className={Styles.AlertContainer}>
                        <div className={Styles.Title}>
                            <InfoOutlinedIcon sx={{ color: '#2C3357' }} />
                            <p>¿Me puedo dar de baja en cualquier momento gratis?</p>
                        </div>
                        <p>Sí, en cualquier momento. Lo único que tenes que hacer es desinstalar el complemento</p>
                    </div>
                </div>
            </Grid>
            <Grid item xs={12} md={6}>
                <div className={Styles.TopContainerForm}>
                    <img src={WhatsproLogo} alt="Logo" />
                    <p>Vendé <span>más,</span> atendé <span>mejor.</span></p>
                    <Box>
                        {paymentType === 'subscription'
                            ? <Typography variant="h6" sx={{ color: '#626262', fontSize: '1.5rem', textAlign: 'center' }}>
                                Suscribite por <b>${priceWithoutIVAAndRoundedTo99(store?.plan?.basePrice)}</b>+ IVA al mes
                            </Typography>
                            : <Typography variant="h6" sx={{ color: '#626262', fontSize: '1.5rem', textAlign: 'center' }}>
                                Cambiá tu método de pago
                            </Typography>
                        }
                        {store && store.isInTrial && store.trialDays > 0 && paymentType === 'subscription' &&
                            <Box sx={GiftTextContainer}>
                                <CheckCircleOutlineIcon sx={{ color: '#E1C478', height: '1.2rem' }} />
                                <Typography sx={GiftText}>30 DÍAS <span style={{ fontWeight: '700' }}>GRATIS</span> PARA USAR EL COMPLEMENTO</Typography>
                            </Box>
                        }
                    </Box>
                </div>
                <form className={Styles.FormContainer}>
                    <h2>Datos de la tarjeta</h2>
                    <div className={Styles.CardData} >
                        <div>
                            <input
                                style={{ border: formik.touched.cardNumber && Boolean(formik.errors.cardNumber) ? '1px solid red' : '1px solid #BFBBBD' }}
                                {...getCardNumberProps({ onBlur: formik.handleBlur, onChange: formik.handleChange })}
                                placeholder={'Numero de tarjeta'}
                                value={formik.values.cardNumber}
                                className={Styles.InputsCard}
                                name="cardNumber"
                                id="cardNumber"
                            />
                            <p className={Styles.HelperErrorText}>{formik.touched.cardNumber && formik.errors.cardNumber}</p>
                        </div>
                        <Grid container spacing={0}>
                            <Grid
                                item
                                xs={12}
                                sm={6}
                                md={6}
                                sx={{
                                    padding: '0',
                                    '@media (min-width: 600px)': {
                                        padding: '0 10px 0 0',
                                    },
                                }}
                            >
                                <input
                                    style={{ border: (formik.touched.expiryDate && Boolean(formik.errors.expiryDate)) ? '1px solid red' : '1px solid #BFBBBD' }}
                                    {...getExpiryDateProps({ onChange: formik.handleChange })}
                                    value={formik.values.expiryDate}
                                    className={Styles.InputsCard}
                                    placeholder={'MM/AA'}
                                    name="expiryDate"
                                    id="expiryDate"
                                />
                                <p className={Styles.HelperErrorText}>{formik.touched.expiryDate && formik.errors.expiryDate}</p>
                            </Grid>
                            <Grid item xs={12} sm={6} md={6}>
                                <input
                                    style={{ border: formik.touched.cvc && Boolean(formik.errors.cvc) ? '1px solid red' : '1px solid #BFBBBD' }}
                                    {...getCVCProps({ onBlur: formik.handleBlur, onChange: formik.handleChange })}
                                    className={Styles.InputsCard}
                                    value={formik.values.cvc}
                                    placeholder={'CVV'}
                                    name="cvc"
                                    id="cvc"
                                />
                                <p className={Styles.HelperErrorText}>{formik.touched.cvc && formik.errors.cvc}</p>
                            </Grid>
                        </Grid>
                        {/* <SelectPayment 
                            handleChange={ handleChangeBank }
                            value={ banksState }
                            items={ Banks } 
                        /> */}
                    </div>
                    <h2>Datos personales</h2>
                    <div className={Styles.CardData}>
                        <div>
                            <input
                                style={{ border: formik.touched.userName && Boolean(formik.errors.userName) ? '1px solid red' : '1px solid #BFBBBD' }}
                                placeholder={'Titular de la tarjeta'}
                                value={formik.values.userName}
                                onChange={formik.handleChange}
                                className={Styles.InputsCard}
                                onBlur={formik.handleBlur}
                                name="userName"
                                id="userName"
                            />
                            <p className={Styles.HelperErrorText}>{formik.touched.userName && formik.errors.userName}</p>
                        </div>
                        <Grid container spacing={0} >
                            <Grid
                                item
                                xs={12}
                                sm={4}
                                md={4}
                                sx={{
                                    padding: '0',
                                    '@media (min-width: 600px)': {
                                        padding: '0 10px 0 0',
                                    },
                                }}
                            >
                                <SelectPayment
                                    handleChange={formik.handleChange}
                                    value={formik.values.typeDoc}
                                    index={'typeDoc'}
                                    items={typeDoc}
                                    error={formik.touched.typeDoc && formik.errors.typeDoc}
                                />
                                <p className={Styles.HelperErrorText}>{formik.touched.typeDoc && formik.errors.typeDoc}</p>
                            </Grid>
                            <Grid item xs={12} sm={8} md={8}>
                                <input
                                    style={{ border: formik.touched.docNumber && Boolean(formik.errors.docNumber) ? '1px solid red' : '1px solid #BFBBBD' }}
                                    value={formik.values.docNumber}
                                    onChange={formik.handleChange}
                                    className={Styles.InputsCard}
                                    placeholder={'Número'}
                                    name="docNumber"
                                    id="docNumber"
                                />
                                <p className={Styles.HelperErrorText}>{formik.touched.docNumber && formik.errors.docNumber}</p>
                            </Grid>
                        </Grid>
                        <div>
                            <input
                                style={{ border: formik.touched.email && Boolean(formik.errors.email) ? '1px solid red' : '1px solid #BFBBBD' }}
                                onChange={formik.handleChange}
                                className={Styles.InputsCard}
                                value={formik.values.email}
                                placeholder={'Email de la cuenta titular de Mercado Pago'}
                                name="email"
                                id="email"
                            />
                            <p className={Styles.HelperErrorText}>{formik.touched.email && formik.errors.email}</p>
                            <p className={Styles.HelperText}>{!formik.errors.email && 'Por políticas de Mercadopago, es necesario que ese correo tenga asociada una cuenta de Mercadopago y sea además titular de la cuenta.'}</p>

                        </div>

                        <div className={Styles.ButtonSection}>
                            <LoadingButton
                                sx={buttonSubStyles}
                                onClick={formik.handleSubmit}
                                loading={loading}
                                disabled={loading}
                            >
                                {buttonText}
                            </LoadingButton>
                            <div className={Styles.MPSection}>
                                <p>Powered by</p>
                                <img src={MPImage} alt="MP" />
                            </div>
                        </div>
                    </div>
                </form>
                {/*MP - Form original*/}
                <form id="form-checkout">
                    <input
                        type="text"
                        name="cardNumber"
                        id="form-checkout__cardNumber"
                        data-checkout="cardNumber"
                        data-stripe="number"
                        style={{ display: 'none' }}
                        value={formik.values.cardNumber.replace(/\s/g, '')}
                    />
                    <input
                        type="text"
                        name="cardExpirationMonth"
                        id="form-checkout__cardExpirationMonth"
                        data-checkout="cardExpirationMonth"
                        data-stripe="exp_month"
                        style={{ display: 'none' }}
                        value={formik.values.expiryDate.substring(0, 2)}
                    />
                    <input
                        type="text"
                        name="cardExpirationYear"
                        id="form-checkout__cardExpirationYear"
                        data-checkout="cardExpirationYear"
                        data-stripe="exp_year"
                        style={{ display: 'none' }}
                        value={'20' + formik.values.expiryDate.substring(5, 7)}
                    />
                    <input
                        type="text"
                        name="cardholderName"
                        id="form-checkout__cardholderName"
                        style={{ display: 'none' }}
                        value={formik.values.userName}
                    />
                    <input
                        type="email"
                        name="cardholderEmail"
                        id="form-checkout__cardholderEmail"
                        style={{ display: 'none' }}
                        value={formik.values.email}
                    />
                    <input
                        type="text"
                        name="securityCode"
                        id="form-checkout__securityCode"
                        style={{ display: 'none' }}
                        value={formik.values.cvc}
                    />
                    <select
                        name="issuer"
                        id="form-checkout__issuer"
                        style={{ display: 'none' }}
                    ></select>
                    <select
                        name="identificationType"
                        id="form-checkout__identificationType"
                        style={{ display: 'none' }}
                        value={'DNI'}
                    // value={formik.values.identificationType}
                    ></select>
                    <input
                        type="text" name="identificationNumber"
                        id="form-checkout__identificationNumber"
                        style={{ display: 'none' }}
                        value={formik.values.docNumber}
                    />
                    <select
                        name="installments"
                        id="form-checkout__installments"
                        style={{ display: 'none' }}
                    ></select>
                    {/* <input type="hidden" id="deviceId" style={{ display: 'none' }}/> */}
                    <button
                        type="submit"
                        id="form-checkout__submit"
                        style={{ display: 'none' }}
                        ref={submitButtonMp}
                    ></button>
                </form>
            </Grid>
        </Grid>
    );
}

export default MercadoPagoForm;