import React, { useRef, useEffect } from 'react';
import { connect } from "react-redux";

// Actions
import { setColorsChat } from '../../../actions/stepsChat';

// Components
import GreenCheckColors from '../../../assets/images/Chat/GreenCheckColors';

// Icon
import BlockIcon from '@mui/icons-material/Block';

// Images
// import BackImg1 from '../../../assets/images/Chat/BackChat/backchat1.png';
// import BackImg2 from '../../../assets/images/Chat/BackChat/backchat2.png';
// import BackImg3 from '../../../assets/images/Chat/BackChat/backchat3.png';
// import BackImg4 from '../../../assets/images/Chat/BackChat/backchat4.png';
// import BackImg5 from '../../../assets/images/Chat/BackChat/backchat5.png';

// Material Ui
import { Typography, useMediaQuery } from '@mui/material';

// CSS
import Styles from './SelectImgChat.module.css';

const BackImg1 = 'https://firebasestorage.googleapis.com/v0/b/whatspro-e3124.appspot.com/o/imgBackgroundChat%2Fbackchat1.png?alt=media&token=36a52494-e329-44df-bb2d-448d281224af';
const BackImg2 = 'https://firebasestorage.googleapis.com/v0/b/whatspro-e3124.appspot.com/o/imgBackgroundChat%2Fbackchat2.png?alt=media&token=8c3f7483-24aa-45dc-87af-bd86d498d62f';
const BackImg3 = 'https://firebasestorage.googleapis.com/v0/b/whatspro-e3124.appspot.com/o/imgBackgroundChat%2Fbackchat3.png?alt=media&token=fca2ceb9-e30a-4073-bddb-093993a88be7';
const BackImg4 = 'https://firebasestorage.googleapis.com/v0/b/whatspro-e3124.appspot.com/o/imgBackgroundChat%2Fbackchat4.png?alt=media&token=42e2153e-6ead-454b-baa1-e1e815973d4e';
const BackImg5 = 'https://firebasestorage.googleapis.com/v0/b/whatspro-e3124.appspot.com/o/imgBackgroundChat%2Fbackchat5.png?alt=media&token=0f5901a9-bbf2-4f32-8d04-aa4ef7cea968';

const TitleStyle = {
    fontSize: '1.2rem',
    fontWeight: '500',
    maxWidth: '680px',
    margin: '1rem auto',
    '@media(min-width: 1600px)': {
        margin: '1rem 0'
    }
}

const SelectImgChat = (props) => {

    // Actions
    const { setColorsChat } = props;

    // States
    const { backImgChatState } = props;

    const arrayBackImgs = [ BackImg1, BackImg2, BackImg3, BackImg4, BackImg5 ];

    const matches = useMediaQuery('min-width: 600px');
    const imgsContainer = useRef(null);

    useEffect(() => {
        if (!matches) imgsContainer.current.scrollLeft += 20;
    }, [imgsContainer, matches]);

    const handleChangeImgBack = ( srcImg, url ) => {
        setColorsChat({
            backImgChat: srcImg,
            urlImg: url
        });
    }
    
    const handleClearBackImage = () => {
        setColorsChat({
            backImgChat: ''
        });
    } 

    return (
        <section className={Styles.SelectImgBackContainer}>
            <Typography sx={ TitleStyle }>Fondos</Typography>
            <div className={Styles.PalletsImgsContainer}  ref={ imgsContainer }>
                <div className={Styles.LinearGradientContainer}></div>
                <div className={Styles.SelectImgsContainer}>
                    <div
                        className={Styles.ImagesContainer}
                        style={{
                            backgroundColor: 'white'
                        }}
                        onClick={ handleClearBackImage }
                    >
                        <BlockIcon
                            sx={{
                                fontSize: '3rem',
                                color: '#DCDCDC',
                            }}
                        />
                    </div>
                    {
                        arrayBackImgs.map((srcImg, index) => (
                            <div 
                                key={index} 
                                className={Styles.ImagesContainer}
                                style={{
                                    backgroundImage: `url(${srcImg})`,
                                    boxShadow: backImgChatState === `srcImg${index}` ? '0px 4px 4px rgba(0, 0, 0, 0.25)' : 'none'
                                }}
                                onClick={ () => handleChangeImgBack(`srcImg${index}`, srcImg) }
                            >
                                { backImgChatState === `srcImg${index}` ? <GreenCheckColors/> : '' }
                            </div>
                        ))
                    }
                </div>
            </div>
        </section>
    )
}

const mapStateToProps = state => {
    return {
        backImgChatState: state.stepsChat.backImgChat,
    }
}

const mapDispatchToProps = dispatch => {
    return {
        setColorsChat: (value) => dispatch(setColorsChat(value)),
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(SelectImgChat);