import React, { useEffect } from 'react';
import { connect } from 'react-redux';

// Actions
import { getStore } from '../../../actions/auth';

// Configuration
import { MAIN_API_URL } from '../../../configuration';

// Icon
import FileCopyOutlinedIcon from '@mui/icons-material/FileCopyOutlined';

// Material Ui
import { Box, Button, Grid, Typography } from '@mui/material';

const GeneralContainerManual = {
    padding: '0 2rem 1rem 2rem',
    '@media(min-width: 500px)': {
        padding: '0 2.5rem 1rem 2.5rem',
    }
}

const Subtitle = {
    margin: '.8rem auto 0 auto',
    fontSize: '.7rem',
    fontWeight: '400',
    '@media(min-width: 350px)': {
        fontSize: '.8rem',
    },
    '@media(min-width: 500px)': {
        margin: '1rem auto',
        fontSize: '.9rem',
    }
}

const StepItemContainer = {
    margin: '1rem 0',
    '@media(min-width: 400px)': {
        margin: '2rem 0',
    }
}

const TextStepContainer = {
    paddingLeft: '.6rem',
    textAlign: 'left',
    '@media(min-width: 350px)': {
        paddingLeft: '.2rem',
    },
    '@media(min-width: 450px)': {
        paddingLeft: 0,
    }
}

const StepText = {
    fontSize: '.8rem',
    '@media(min-width: 500px)': {
        fontSize: '1rem',
    }
}

const AclaratoryText = {
    fontSize: '.7rem',
    fontWeight: '300',
    marginBottom: '1rem',
    '@media(min-width: 500px)': {
        fontSize: '.8rem',
    }
}

const StepNumber = {
    backgroundColor: '#2ED588',
    justifyContent: 'center',
    boxSizing: 'border-box',
    alignItems: 'center',
    borderRadius: '50%',
    fontSize: '.9rem',
    height: '1.4rem',
    width: '1.4rem',
    display: 'flex',
    color: 'white',
}

const CodeGeneralContainer = {
    border: '1px solid #ECECEC',
    backgroundColor: '#F9F8FF',
    height: 'max-content',
    borderRadius: '10px',
    position: 'relative',
    paddingTop: '1.5rem',
    alignItems: 'end',
    marginTop: '1rem',
    display: 'flex',
    width: '100%',
}

const CodeContainer = {
    textAlign: 'left',
    margin: '0 auto',
    width: {
        xs: '95%'
    }
}

const CodeStyle = {
    wordBreak: 'break-all',
    whiteSpace: 'pre-wrap',
    fontFamily: 'Poppins',
    userSelect: 'text',
    fontSize: '.7rem',
    fontWeight: '500',
    color: '#393939',
}

const CopyCodeButtonStyles = {
    backgroundColor: 'transparent',
    textDecoration: 'underline',
    boxSizing: 'border-box',
    padding: '.4rem .9rem',
    textTransform: 'none',
    fontFamily: 'Poppins',
    position: 'absolute',
    borderRadius: '8px',
    boxShadow: 'none',
    fontWeight: '500',
    fontSize: '.5rem',
    color: '#2ED588',
    right: 0,
    top: 0,
    "@media (min-width: 400px)": {
        fontSize: '.7rem',
    },
    ":hover": {
        backgroundColor: 'transparent',
        textDecoration: 'underline',
        boxShadow: 'none',
    }
}

const ButtonStyle = {
    backgroundColor: '#2ED588',
    textTransform: 'none',
    fontFamily: 'Poppins',
    borderRadius: '6px',
    margin: '0 auto',
    color: 'white',
    width: '190px',
    ':hover': {
        backgroundColor: '#2ED588',
    },
    '@media(min-width: 400px)': {
        width: '60%',
    }
}

const ManualComponent = (props) => {

    // States
    const { store } = props;

    // External props
    const { getStore, stepsArray, onClose } = props;

    useEffect(() => {

        let token = localStorage.getItem('token');
        // console.log(token)
        getStore(token);

    }, [getStore]);

    const scriptTag = `<script src="${MAIN_API_URL}/script/particular/onLoad.js?store=${store?.key}" async defer></script>`;

    return (
        <Box sx={GeneralContainerManual}>
            <Typography sx={Subtitle}>
                Seguí los siguientes pasos para poder integrar WhatsPRO a tu página web:
            </Typography>
            <Box>
                {
                    stepsArray.map((step, index) => (
                        <Box key={index} sx={StepItemContainer}>
                            <Grid container>
                                <Grid item xs={1}>
                                    <Box sx={StepNumber}>{step.nro}</Box>
                                </Grid>
                                <Grid item xs={11} sx={TextStepContainer}>
                                    <Typography sx={StepText}>{step.step}</Typography>
                                </Grid>
                            </Grid>
                            {
                                step.nro === 1 ?
                                    <Box sx={CodeGeneralContainer}>
                                        <Box sx={CodeContainer}>
                                            <pre style={CodeStyle}>
                                                {scriptTag}
                                            </pre>
                                        </Box>
                                        <Button
                                            sx={CopyCodeButtonStyles}
                                            variant="contained"
                                            onClick={() => navigator.clipboard.writeText(scriptTag)}
                                        >
                                            <FileCopyOutlinedIcon
                                                sx={{
                                                    height: '1.1rem',
                                                    "@media (min-width: 400px)": {
                                                        height: '1rem',
                                                    },
                                                }}
                                            />
                                            Copiar código
                                        </Button>
                                    </Box>
                                    :
                                    null
                            }
                        </Box>
                    ))
                }
                <Typography sx={AclaratoryText}>En caso de que tener dudas, podés ver el video tutorial o contactarnos a través de nuestro chat.</Typography>

            </Box>
            <Button sx={ButtonStyle} onClick={() => onClose('Hecho a medida')}>Entendido</Button>
        </Box>
    )
}

const mapStateToProps = state => {
    return {
        store: state.auth.store,
    }
}

const mapDispatchToProps = dispatch => {
    return {
        getStore: (value) => dispatch(getStore(value)),
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(ManualComponent);