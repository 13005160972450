import React, { useState, useEffect, useMemo } from 'react';
import { connect } from "react-redux";

// Actions
import { 
    changeStartRangeHours, 
    setCounterActiveHours, 
    changeEndRangeHours, 
    setIdHourDeleteA, 
    activeDaysUser, 
    setActiveHour, 
    setRangeHours, 
    setErrorHour,
} from '../../../actions/stepsUser';

// Components
import HoursComponentMobile from '../HourComponentMobile/HoursComponentMobile';
import GreenSwitch from '../../GreenSwitch/GreenSwitch';
import SelectHours from '../SelectHours/SelectHours';

// Material Ui
import DeleteOutlineOutlinedIcon from '@mui/icons-material/DeleteOutlineOutlined';
import { IconButton } from '@mui/material';

// CSS
import Styles from './HoursComponent.module.css';

const HoursComponent = (props) => {

    // Actions
    const {
        changeStartRangeHours,
        changeEndRangeHours,
        setIdHourDelete,
        setHoursAction,
        activeDaysUser,
        setRangeHours,
        setErrorHour,
    } = props;

    // States
    const { userEditing } = props;

    // External props
    const {
        enabledRangeHours,
        handleDelete,
        activeDays,
        startHour,
        DaysArray,
        hourName,
        disabled,
        endHour,
        active,
        index,
        hours,
        id,
    } = props;

    const [activeHourState, setActiveHourState] = useState(active);
    const [openDays, setOpenDays] = useState(DaysArray);
    const [editHour, setEditHour] = useState(false);

    useEffect(() => {
        setActiveHourState(active);
    }, [active]);


    const styleBox = {
        border: activeHourState ? '1px solid #2ED588' : '1px solid #BFBBBD',
    };

    useEffect(() => {
        if (hours[id].start > hours[id].end) {
            changeEndRangeHours({
                index,
                endHour: hours[id].start
            });

            changeStartRangeHours({
                index,
                startHour: hours[id].end
            });

            setErrorHour({
                id: index,
                errorSetHour: true
            });

        } else {
            return;
        }

    }, [hours]);

    useEffect(() => {
        if (!enabledRangeHours) {
            setOpenDays(DaysArray);
            return;
        }
        if (activeDays) {
            setOpenDays(activeDays);
        } else {
            setOpenDays(DaysArray);
        }
    }, [userEditing, enabledRangeHours, hours]);

    const handleActiveHour = (id, newChecked) => {

        let allInactive = true;

        hours.forEach( (hour, index) => {
            if(index === id){
                if (newChecked === true) allInactive = false;
            } else{
                if (hour.activeHour) allInactive = false;
            }
        });
        if (allInactive) {
            setRangeHours( false );
        }

        setActiveHourState(!activeHourState);

        if (hours.length === 1 && activeHourState === true) {
            setRangeHours( !enabledRangeHours );
        }

        setHoursAction({
            activeHour: activeHourState,
            index: index,
            id: id,
        });
    };

    const handleSelectDay = (day, isActiveComponent) => {
        if (isActiveComponent) {
            const cloneOpenDay = [...openDays];
            cloneOpenDay[day] = !cloneOpenDay[day];
            
            setOpenDays(cloneOpenDay);
            activeDaysUser({
                index,
                activeDays: cloneOpenDay
            });
        } else {
            return;
        }
    };

    const handleEdit = () => {
        setEditHour(!editHour);
    };

    const showDays = (day) => {
        switch (day) {
            case 0:
                return 'D'
            case 1:
                return 'L'
            case 2:
                return 'M'
            case 3:
                return 'M'
            case 4:
                return 'J'
            case 5:
                return 'V'
            case 6:
                return 'S'
            default:
                return 'L'
        }
    };

    const handleDeleteHour = () => {
        setIdHourDelete(id);
        handleDelete();
    }

    const isActiveComponent = useMemo(() => activeHourState && enabledRangeHours, [activeHourState, enabledRangeHours]);

    return (
        <>
            <div
                className={Styles.HoursComponentsContainer}
                style={{
                    ...styleBox,
                    border: activeHourState ? '1px solid #2ED588' : '1px solid #BFBBBD',
                }}
            >
                <div
                    className={Styles.TitleSection}
                    style={{
                        height: '30px'
                    }}>
                    <GreenSwitch
                        onClick={(e) => handleActiveHour(id, e.target.checked)}
                        // checked={ activeHourState }
                        checked={enabledRangeHours ? activeHourState : true}
                        disabled={!enabledRangeHours}
                    />
                    <h2
                        style={{
                            color: isActiveComponent ? 'black' : '#BFBBBD'
                        }}
                    >{hourName}</h2>
                </div>
                <div className={Styles.Division}></div>
                <div
                    className={isActiveComponent ? Styles.RangehoursActive : Styles.Rangehours}
                >
                    <div className={Styles.RangehourItem}>
                        {/* <h3 style={{ color: (isActiveComponent) ? '#473C41' : '#BFBBBD' }}>Desde</h3> */}
                        <SelectHours
                            active={isActiveComponent}
                            startHour={startHour}
                            disabled={disabled}
                            id={'startHour'}
                            index={index}
                        />
                    </div>
                    <div className={Styles.RangehourItem}>
                        {/* <h3 style={{ color: isActiveComponent ? '#473C41' : '#BFBBBD' }}>Hasta</h3> */}
                        <SelectHours
                            active={isActiveComponent}
                            disabled={disabled}
                            endHour={endHour}
                            id={'endHour'}
                            index={index}
                        />
                    </div>
                </div>
                <div className={Styles.Division}></div>
                <div className={Styles.BottomContainer}>
                    <div
                        className={isActiveComponent ? Styles.DaysContainerActive : Styles.DaysContainer}
                    >
                        {
                            openDays.map((day, index) =>
                                <div
                                    key={index}
                                    className={Styles.DayContainer}
                                    onClick={() => handleSelectDay(index, isActiveComponent)}
                                    style={{
                                        backgroundColor: isActiveComponent ? day ? '#2ED588' : '#F0EFEF' : day ? '#C7C6C6' : '#F0EFEF',
                                        cursor: isActiveComponent ? 'pointer' : 'not-allowed',
                                        marginLeft: index === 0 ? '0' : '8px',
                                        fontFamily: 'Poppins, sans-serif',
                                        userSelect: 'none',
                                        fontWeight: '600',
                                    }}
                                >
                                    <h2>{showDays(index)}</h2>
                                </div>
                            )
                        }
                    </div>
                    { hours.length > 1 && <div className={Styles.LastDivision}></div> }
                    <div
                        className={'ButtonsContainer'}
                    >
                        {enabledRangeHours && hours.length > 1 && <IconButton sx={{ padding: '.5rem' }} aria-label="delete" onClick={handleDeleteHour}>
                            <DeleteOutlineOutlinedIcon
                                sx={{ color: '#BFBBBD', height: '1.5rem !important', width: '1.5rem !important' }}
                            />
                        </IconButton>}
                    </div>
                </div>
            </div>
            <HoursComponentMobile
                onClickDisabledHour={handleActiveHour}
                enabledRangeHours={enabledRangeHours}
                isActiveComponent={isActiveComponent}
                onClickDelete={handleDeleteHour}
                selectDay={handleSelectDay}
                onClickEdit={handleEdit}
                active={active}
                startHour={startHour}
                DaysArray={openDays}
                hourName={hourName}
                disabled={disabled}
                showDays={showDays}
                hoursArray={hours}
                endHour={endHour}
                edit={editHour}
                index={index}
                id={id}
            />
        </>
    )
}

const mapStateToProps = state => {
    return {
        counterActiveHours: state.stepsUser.counterActiveHours,
        countryCodePhone: state.stepsUser.countryCodePhone,
        userEditing: state.stepsUser.userEditing,
        activeHour: state.stepsUser.activeHour,
        hours: state.stepsUser.hours,
    }
}

const mapDispatchToProps = dispatch => {
    return {
        changeStartRangeHours: ({ startHour, index }) => dispatch(changeStartRangeHours({ startHour, index })),
        setHoursAction: ({ activeHour, id, index }) => dispatch(setActiveHour({ activeHour, id, index })),
        changeEndRangeHours: ({ endHour, index }) => dispatch(changeEndRangeHours({ endHour, index })),
        activeDaysUser: ({ activeDays, index }) => dispatch(activeDaysUser({ activeDays, index })),
        setErrorHour: ({ id, errorSetHour }) => dispatch(setErrorHour({ id, errorSetHour })),
        setCounterActiveHours: (value) => dispatch(setCounterActiveHours(value)),
        setIdHourDelete: (value) => dispatch(setIdHourDeleteA(value)),
        setRangeHours: (value) => dispatch(setRangeHours(value)),
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(HoursComponent);




