import React from 'react';

// Material Ui
import { Alert } from '@mui/material';

// Icons
import InfoOutlinedIcon from '@mui/icons-material/InfoOutlined';

const BannerHours = ({ type }) => {

    const BannerStyles = {
        width: type === 'hours' ? '100%' : '95%',
        borderLeft: '6px solid #2ED588',
        backgroundColor: '#F0F0F0',
        boxSizing: 'border-box',
        wordWrap: 'break-word',
        fontFamily: 'Poppins',
        marginBottom: '1rem',
        borderRadius: '10px',
        borderBottom: '0',
        borderRight: '0',
        borderTop: '0',
        color: 'black',
    }

    return (
        <Alert 
            variant="outlined" 
            sx={BannerStyles}
            icon={
                <InfoOutlinedIcon sx={{ color: '#2ED588' }} fontSize="inherit" />
            } 
        >
            {
                type === 'hours' ?
                    <p style={{ margin: '0'}}>Si deshabilitas los horarios de atención personalizados, tu asesor estará disponible las 24 hs </p>
                :
                    <p style={{ margin: '0'}}>Si tenés esta opción deshabilitada, el asesor podrá recibir mensajes las 24 hs</p>
            }
        </Alert>
    )
}

export default BannerHours;