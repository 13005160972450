import React from 'react';
import { connect } from 'react-redux';

// Configuration
import LayoutStepsChat from '../../../layout/LayoutStepsChat';

// Components
import QuestionsComponent from '../QuestionsComponent/QuestionsComponent';

// Material Ui
import { Box } from '@mui/material';

// CSS
import Styles from './Step3Chat.module.css';

const TitleContainerStyles = {
    maxWidth: '680px',
    margin: 'auto',
}

const Step3Chat = ( props ) => {

    return (
        <LayoutStepsChat>
            <Box sx={ TitleContainerStyles }>
                <h1 className={Styles.Title}>Preguntas frecuentes</h1>
                <p>Seleccioná y configurá las preguntas frecuentes de tu chat</p>
            </Box>
            <QuestionsComponent/>
        </LayoutStepsChat>
    )
}

const mapStateToProps = state => {
    return {
        questionsTemplates: state.stepsChat.questionsTemplates,
        questions: state.stepsChat.questions,
    }
}

export default connect(mapStateToProps, null)( Step3Chat );