import { Box, Slide, Typography } from '@mui/material'
import React from 'react'
import { detectURL } from '../../../helpers/chat'

export const PartnerGenericMessage = ({message = ''}) => {
    return (
        <Slide direction="up" in={true} mountOnEnter unmountOnExit>
        <Box
            sx={{
                background: '#EEEEEE',
                borderRadius: '14px',
                maxWidth: '80%',
                width: 'fit-content',
                padding: '15px 20px',
                display: 'inline-block',
            }}
        >
            <Typography
                variant="body2"
                sx={{color: '#393939'}}
                fontWeight={400}
                fontSize={16}
            >
                {
                    // The message is a string and can contain links that start with http or https or www
                    message.split(' ').map((word, index) => {
                        if (word.startsWith('http')) {
                            return (
                                <a href={word} target="_blank" rel="noreferrer" key={index}>{word}</a>
                            )
                        } else if(word.startsWith('www')) {
                            return (
                                <a href={`http://${word}`} target="_blank" rel="noreferrer" key={index}>{word}</a>
                            )
                        }
                        return word + ' '
                    })

                }
            </Typography>
        </Box>
        </Slide>
    )
}

export const ClientGenericMessage = ({message = '', fontColour= '#01C66B'}) => {
    return (
        <Slide direction="up" in={true} mountOnEnter unmountOnExit>
        <Box
            sx={{
                background: '#EEEEEE',
                borderRadius: '14px',
                maxWidth: '80%',
                width: 'fit-content',
                padding: '15px 20px',
                display: 'inline-block',
                alignSelf: 'flex-end',
                cursor: 'pointer',
            }}
        >
            <Typography
                variant="body2"
                sx={{color: fontColour}}
                fontWeight={500}
                fontSize={16}
            >
                {message}
            </Typography>
        </Box>
        </Slide>
    )
}

export const ClientSelectedMessage = ({message = '', botonColour = '#01C66B'}) => {
    return (
        <Slide direction="up" in={true} mountOnEnter unmountOnExit>
        <Box
            sx={{
                background: botonColour,
                borderRadius: '14px',
                maxWidth: '80%',
                width: 'fit-content',
                padding: '15px 20px',
                display: 'inline-block',
                alignSelf: 'flex-end',
                cursor: 'pointer',
            }}
        >
            <Typography
                variant="body2"
                sx={{color: '#FFFFFF'}}
                fontWeight={500}
                fontSize={16}
            >
                {message}
            </Typography>
        </Box>
        </Slide>
    )
}
