import { Box, Typography } from '@mui/material'
import React from 'react'
import { UserPasswrdNewPassword } from '../components/UserPasswordNewPassword'

export const NewPasswordComponent = () => {
    return (
        <>
            <Typography variant={'h4'} sx={{ fontSize: '1.5rem', fontWeight: 400 }}>
                Introduzca una nueva contraseña
            </Typography>
            <Typography variant={'subtitle1'} sx={{ fontSize: '0.9rem', fontWeight: 400 }}>
                Introduzca una nueva contraseña debajo para poder cambiar su contraseña actual.
            </Typography>
            <Box
                display={'flex'}
                flexDirection={'column'}
                justifyContent={'center'}
                alignItems={'flex-start'}
                gap={1.5}
                sx={{
                    width: '100%',
                    padding: '5px 20px',
                }}
            >
                <UserPasswrdNewPassword />
            </Box>
        </>
    )
}
