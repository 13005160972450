import React from 'react';

// Icon
import CloseIcon from '@mui/icons-material/Close';

// Material Ui
import { IconButton, Snackbar } from '@mui/material';

const SnackbarStyles = {
    backgroundColor: '#2ED588',
    borderRadius: '8px',
    color: 'white',
    '.MuiPaper-root': {
        backgroundColor: '#2ED588',
        fontFamily: 'Poppins',
        borderRadius: '8px',
        fontWeight: '500'
    }
}

const SnackbarTutorialHome = ({ handleClose, open }) => {

    const action = (
        <React.Fragment>
            <IconButton
                sx={{ backgroundColor: '#14C774' }}
                onClick={ handleClose }
                aria-label="close"
                color="inherit"
                size="small"
            >
                <CloseIcon fontSize="small" />
            </IconButton>
        </React.Fragment>
    );

    return (
        <Snackbar
            message={ "Para desbloquear las funcionalidades completá tus tareas" }
            autoHideDuration={ 3000 }
            onClose={ handleClose }
            sx={ SnackbarStyles }
            action={ action }
            open={ open }
            anchorOrigin={{ 
                vertical: "bottom", 
                horizontal:"right" 
            }}
        />
    )
}

export default SnackbarTutorialHome;