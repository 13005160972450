import { useRef } from 'react';

// Helpers
import { useResize } from '../../../helpers/useResize';

// Icon
import CloseOutlinedIcon from '@mui/icons-material/CloseOutlined';

// Material Ui
import { Box, Drawer, Typography } from '@mui/material';

// CSS
import Styles from './DrawerIntegrations.module.css';

const DrawerStyles = {
    '.MuiModal-root.MuiDrawer-root': {
        zIndex: '0',
    },
    '.MuiDrawer-root': {
        zIndex: '0',
    },
    '.MuiPaper-root': {
        zIndex: '1',
    },
    '.MuiDrawer-paper': {
        boxSizing: 'border-box',
        flexDirection: 'column',
        alignItems: 'center',
        maxWidth: '360px',
        display: 'flex',
        padding: '1rem',
        width: '100%',
        '@media(min-width: 800px)': {
            maxWidth: '380px',
            width: '325px',
        }
    },
}

const CrossContainer = {
    position: 'absolute',
    cursor: 'pointer',
    top: '.5rem',
    left: '3%',
}

const HelpTextContainer = {
    margin: '2rem auto auto auto',
    flexDirection: 'column',
    alignItms: 'center',
    userSelect: 'none',
    padding: '1rem',
    display: 'flex',
}

const TitleChatDrawer = {
    marginBottom: '1rem',
    fontSize: '1.1rem',
    fontWeight: '600',
    marginTop: '0',
}

const ResizeLine = {
    tranform: 'translate(-50%, -50%)',
    padding: '0 .2rem 0 .1rem',
    position: 'absolute',
    cursor: 'col-resize',
    alignItems: 'center',
    display: 'flex',
    left: '.3rem',
    top: '50%',
}

const LineResize = {
    backgroundColor: '#616161',
    height: '100px',
    width: '2px',
}

const DrawerIntegrations = ({ open, toggleDrawer }) => {

    const resizeDrawer = useRef();

    const options = {
        step: 10,
        axis: 'horizontal'
    }

    const { initResize } = useResize(resizeDrawer, options);

    return (
        <Drawer
            onClose={toggleDrawer(false)}
            ref={ resizeDrawer }
            hideBackdrop={true}
            sx={ DrawerStyles }
            anchor={'right'}
            open={open}
            ModalProps = {{
                disableEnforceFocus: true
            }}
        >
            <Box sx={ CrossContainer } onClick={ toggleDrawer(false) }>
                <CloseOutlinedIcon
                    sx={{
                        color: '#393939 !important',
                        height: '2rem !important',
                        marginRight: '.5rem',
                        fontSize: '1.2rem',
                    }}
                />
            </Box>
            <Box sx={ HelpTextContainer }>
                <Typography sx={ TitleChatDrawer }>¿Cómo instalar WhatsPRO?</Typography>
                <div className={ Styles.TextContainer }>
                    <h3>Instalar WhatsPRO en Tiendanube</h3>
                    <p>Para poder conectar WhatsPRO a tu Tiendanube hacé click en el botón conectar, aceptá los permisos de la aplicación y listo!</p>
                    <h3>Instalar WhatsPRO en Mercado Shops</h3>
                    <p>Para poder conectar WhatsPRO a Mercado Shops hacé click en el botón conectar, aceptá los permisos de la aplicación y listo!</p>
                    <h3>Instalar WhatsPRO en Woo Commerce</h3>
                    <p>Para poder conectar WhatsPRO a Woo Commerce hacé click en el botón conectar, aceptá los permisos de la aplicación y listo!</p>
                    <h3>Instalar WhatsPRO Manualmente</h3>
                    <p>Para poder conectar WhatsPRO a tu página web manualmente, seguí los pasos:</p>
                    <ul className={ Styles.ListText }>
                        <li>
                            <p>1- Hacé click en el botón conectar</p>
                        </li>
                        <li>
                            <p>2- Copiá el código que aparece en la ventana</p>
                        </li>
                        <li>
                            <p>{'3- Insertalo justo por encima de la etiqueta final </body> del código de tu página'}</p>
                        </li>
                        <li>
                            <p>4- ¡Listo!</p>
                        </li>
                    </ul>
                </div>
            </Box>
            <Box 
                sx={ ResizeLine }
                onMouseDown={initResize}
            >
                <Box sx={ LineResize } ></Box>
            </Box>
        </Drawer>
    )
}

export default DrawerIntegrations;