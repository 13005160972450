import { useRef } from 'react';
import { connect } from 'react-redux';

// Components
import TextStep1Chat from '../../Chat/Step1/TextStep1Chat';
import TextStep3Chat from '../../Chat/Step2/TextStep3Chat';
import TextStep2Chat from '../../Chat/Step3/TextStep2Chat';
import ChatWindow from '../../Chat/ChatWindow/ChatWindow';

// Helpers
import { useResize } from '../../../helpers/useResize';

// Icon
import CloseOutlinedIcon from '@mui/icons-material/CloseOutlined';

// Material Ui
import { Box, Drawer, Typography } from '@mui/material';

const CrossContainer = {
    position: 'absolute',
    cursor: 'pointer',
    top: '.5rem',
    right: '0',
}

const ChatContainer = {
    margin: '2rem auto auto auto',
    flexDirection: 'column',
    alignItms: 'center',
    display: 'flex',
}

const TitleChatDrawer = {
    marginBottom: '1rem',
    fontSize: '.85rem',
    fontWeight: '500',
    marginTop: '0',
}

const DrawerStyles = {
    '.MuiModal-root.MuiDrawer-root': {
        zIndex: '0',
    },
    '.MuiDrawer-root': {
        zIndex: '0',
    },
    '.MuiPaper-root': {
        zIndex: '1',
    },
    '.MuiDrawer-paper': {
        boxSizing: 'border-box',
        flexDirection: 'column',
        alignItems: 'center',
        overflowX: 'hidden',
        userSelect: 'none',
        maxWidth: '360px',
        display: 'flex',
        padding: '1rem',
        width: '100%',
        '@media(min-width: 800px)': {
            maxWidth: '380px',
            width: '650px',
        }
    },
}

const ResizeLine = {
    tranform: 'translate(-50%, -50%)',
    padding: '0 .2rem 0 .1rem',
    position: 'absolute',
    cursor: 'col-resize',
    alignItems: 'center',
    display: 'flex',
    left: '.3rem',
    top: '50%',
}

const LineResize = {
    backgroundColor: '#616161',
    height: '100px',
    width: '2px',
}

const DrawerChat = (props) => {

    // External props
    const { open, toggleDrawer } = props;

    // States
    const { activeStepChat } = props;

    const resizeDrawer = useRef();

    const options = {
        step: 10,
        axis: 'horizontal'
    }

    const handleShowText = () => {
        switch (activeStepChat) {
            case 0:
                return <TextStep1Chat/>;
            case 1:
                return <TextStep2Chat/>;
            case 2:
                return <TextStep3Chat/>;
        
            default:
                break;
        }
    }

    const { initResize } = useResize(resizeDrawer, options);

    return (
        <Drawer
            onClose={toggleDrawer(false)}
            ref={ resizeDrawer }
            sx={ DrawerStyles }
            hideBackdrop={true}
            anchor={'right'}
            open={open}
            ModalProps = {{
                disableEnforceFocus: true
            }}
        >
            <Box sx={ CrossContainer } onClick={ toggleDrawer(false) }>
                <CloseOutlinedIcon
                    sx={{
                        color: '#393939 !important',
                        height: '2rem !important',
                        marginRight: '.5rem',
                        fontSize: '1.2rem',
                    }}
                />
            </Box>
            <Box sx={ ChatContainer }>
                {
                    handleShowText()
                }
                <Typography sx={ TitleChatDrawer }>Previsualización de tu chat:</Typography>
                <ChatWindow open={ open } activeStep={ activeStepChat } handleClose={ toggleDrawer }/>
            </Box>
            <Box 
                sx={ ResizeLine }
                onMouseDown={initResize}
            >
                <Box sx={ LineResize } ></Box>
            </Box>
        </Drawer>
    )
}

const mapStateToProps = state => {
    return {
        activeStepChat: state.stepsChat.activeStepChat,
    }
}

export default connect(mapStateToProps, null)(DrawerChat);