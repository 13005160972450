import React from 'react';

// Material Ui
import { Button, Fade, Modal } from '@mui/material';
import { Box } from '@mui/system';

// CSS
import Styles from './WelcomeModal.module.css';
import WhatsPROLogo from '../../../assets/images/Modal/WhatsPROLogo';

const WelcomeModal = ({ open, handleClose }) => {

    const style = {
        transform: 'translate(-50%, -50%)',
        border: '1px solid #BFBBBD',
        position: 'absolute',
        borderRadius: '10px',
        textAlign: 'center',
        bgcolor: 'white',
        p: '1rem 1.5rem',
        width: '80%',
        left: '50%',
        top: '50%',
        "@media (min-width: 500px)": {
            width: '340px'
        },
        "@media (min-width: 900px)": {
            width: '360px'
        }
    };

    const buttonOK = {
        backgroundColor: '#2ED588',
        padding: '.5rem 2rem',
        fontFamily: 'Poppins',
        textTransform: 'none',
        borderRadius: '8px',
        fontWeight: '500',
        fontSize: '.9rem',
        color: 'white',
        '&:hover': {
            backgroundColor: '#2ED588'
        }
    };

    return (
        <Modal
            open={open}
            onClose={handleClose}
            aria-labelledby="modal-modal-title"
            aria-describedby="modal-modal-description"
        >
            <Fade in={ open }>
                <Box sx={ style }>
                    <WhatsPROLogo/>
                    <div className={Styles.ModalContainer}>
                        <h2>Bienvenido a WhatsPRO 👋🏻</h2>
                        <p style={{ fontSize: '.9rem', fontWeight: '500' }}>Para poder comenzar a usar el complemento, es necesario que sigas los pasos que te recomendamos a continuación</p>
                        
                        <Button
                            onClick={ handleClose }
                            sx={ buttonOK }
                        >Comenzar</Button>
                    </div>
                </Box>
            </Fade>
        </Modal>
    )
}

export default WelcomeModal;
