import { Box, Divider, Typography } from '@mui/material'
import React from 'react'

export const OrComponent = () => {
    return (
        <Box
            display={'flex'}
            flexDirection={'row'}
            justifyContent={'center'}
            alignItems={'center'}
            sx={{
                width: '100%',
            }}
        >
            <Divider sx={{ width: '40%' }} />
            <Typography variant={'subtitle1'} sx={{ fontSize: '0.7rem', fontWeight: 400, margin: '0 10px' }}>
                O
            </Typography>
            <Divider sx={{ width: '40%' }} />
        </Box>
    )
}
