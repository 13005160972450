import { Visibility, VisibilityOff } from '@mui/icons-material'
import { LoadingButton } from '@mui/lab'
import { FormControl, FormHelperText, IconButton, InputAdornment, InputLabel, OutlinedInput, TextField, Typography } from '@mui/material'
import React, { useEffect, useState } from 'react'
import { primaryColor } from '../../../constants'
import { useNavigate } from 'react-router-dom'
import { useFormik } from 'formik'
import * as Yup from 'yup';
import { useFetch } from 'use-http'
import { MAIN_API_URL } from '../../../configuration'

const validationSchema = Yup.object({
    mail: Yup.string().email('Ingrese un correo electrónico válido').required('El correo electrónico es requerido'),
    password: Yup.string().required('La contraseña es requerida')
});

export const UserPasswordLogin = () => {
    const navigate = useNavigate();
    const { post, loading, response } = useFetch(MAIN_API_URL);
    const [errorMsg, setErrorMsg] = useState('');
    const [showPassword, setShowPassword] = useState(false);
    const handleClickShowPassword = () => setShowPassword((show) => !show);
    const handleMouseDownPassword = (event) => {
        event.preventDefault();
    };
    const handleGoToRegister = () => {
        navigate('/register')
    }
    
    const handleForgotPassword = () => {
        navigate('/password_reset?mail=' + formik.values.mail)
    }

    const formik = useFormik({
        initialValues: {
            mail: '',
            password: '',
        },
        validationSchema: validationSchema,
        onSubmit: async (values) => {
            values.mail = values.mail.toLowerCase().trim();
            const data = await post('/auth/login', values)
            if (response.ok) {
                localStorage.setItem('token', data.token);
                localStorage.setItem('refreshToken', data.refreshToken);
                localStorage.setItem('store', data.user._id);
                localStorage.setItem('origin', data.user.origin);
                window.location.href = '/home';
            } else {
                setErrorMsg(data.msg)
            }
        }
    })

    useEffect(() => {
        if(errorMsg.length > 0) setErrorMsg('')
    }, [formik.values])

    return (
        <>
            <TextField
                variant="outlined"
                margin="normal"
                required
                fullWidth
                id="mail"
                label="Correo electrónico"
                name="mail"
                autoComplete="mail"
                autoFocus
                sx={{ margin: 0, fontFamily: 'Poppins' }}
                value={formik.values.mail}
                onChange={formik.handleChange}
                error={formik.touched.mail && Boolean(formik.errors.mail)}
                helperText={formik.touched.mail && formik.errors.mail}
            />
            <FormControl variant="outlined" fullWidth required>
                <InputLabel htmlFor="outlined-adornment-password">Contraseña</InputLabel>
                <OutlinedInput
                    id="password"
                    type={showPassword ? 'text' : 'password'}
                    endAdornment={
                        <InputAdornment position="end">
                            <IconButton
                                aria-label="toggle password visibility"
                                onClick={handleClickShowPassword}
                                onMouseDown={handleMouseDownPassword}
                                edge="end"
                            >
                                {showPassword ? <VisibilityOff /> : <Visibility />}
                            </IconButton>
                        </InputAdornment>
                    }
                    label="Contraseña"
                    sx={{ margin: 0 }}
                    value={formik.values.password}
                    onChange={formik.handleChange}
                    error={formik.touched.password && Boolean(formik.errors.password)}
                />
                <FormHelperText sx={{ color: 'red' }}>{formik.touched.password && formik.errors.password}</FormHelperText>
            </FormControl>
            {
                errorMsg.length > 0 && (
                    <Typography variant={'subtitle1'} sx={{ fontSize: '0.9rem', fontWeight: 400, color: 'red' }}>
                        {errorMsg}
                    </Typography>
                )
            }
            <Typography variant={'subtitle1'} sx={{ fontSize: '0.9rem', fontWeight: 500, cursor: 'pointer', color: primaryColor }}
                onClick={handleForgotPassword}
            >
                ¿Olvidó su contraseña?
            </Typography>
            <LoadingButton
                variant="contained"
                loadingPosition="center"
                loading={loading}
                disabled={loading}
                loadingIndicator="Cargando..."
                sx={{
                    width: '100%',
                    height: '54px',
                    borderRadius: '6px',
                    color: primaryColor,
                    background: '#fff',
                    boxShadow: 'none',
                    border: '1px solid #c4c4c4',
                    '&:hover': {
                        backgroundColor: primaryColor,
                        color: '#fff',
                    },
                }}
                onClick={formik.handleSubmit}
            >
                <Typography variant={'subtitle1'} sx={{ fontSize: '1rem', fontWeight: 500 }}>
                    Iniciar sesión
                </Typography>
            </LoadingButton>
            <Typography variant={'subtitle1'} sx={{ fontSize: '0.9rem', fontWeight: 400, cursor: 'pointer' }}>
                ¿No tiene una cuenta? <span style={{ color: primaryColor, fontWeight: 500 }} onClick={handleGoToRegister}>Regístrese</span>
            </Typography>
        </>
    )
}
