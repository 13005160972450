import React from 'react';
import GreenSwitch from '../../../GreenSwitch/GreenSwitch';

// CSS
import Styles from './SwitchComponent.module.css';

const SwitchComponent = ({ title, onClick, value, text1, text2 }) => {
    return (
        <div className={Styles.SwitchComponentContainer}>
            <div className={Styles.SwitchSection}>
                <GreenSwitch
                    onClick={ onClick }
                    checked={ value }
                />
                <h2>{title}</h2>
            </div>
            <div className={Styles.TextsContainer}>
                <p>{text1}</p>
                <p className={Styles.Text2}>{text2}</p>
            </div>
        </div>
    )
}

export default SwitchComponent;