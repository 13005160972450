import React, { useState } from 'react';
import moment from 'moment';

import { connect } from 'react-redux';

import DatePicker from "react-multi-date-picker";

// Actions
import { setDateRangeData } from '../../../actions/users';

// Material Ui
import { Box, Typography, useMediaQuery } from '@mui/material';

const GeneralContainer = {
    alignItems: 'center',
    display: 'block',
    margin: 'auto',
    gap: '10px',
    '@media(min-width: 500px)': {
        paddingRight: '1rem',
        display: 'flex',
        margin: 0,
    }
}

const TextStyles = {
    marginBottom: '1rem',
    fontWeight: '500',
    fontSize: '1rem',
    '@media(min-width: 500px)': {
        fontSize: '1.3rem',
        marginBottom: 0,
    }
}

const DateFilterComponent = (props) => {

    // Actions
    const { setDateRangeData } = props;
    const isMobile = useMediaQuery('(max-width: 600px)');

    const [value, setValue] = useState([
        moment().startOf('month').toISOString(),
        moment().endOf('month').toISOString()
    ]);

    const handleChangeDate = ( value ) => {
        const start = value[0].format();
        const end = value[1] ? value[1].format() : value[0].format();

        setValue( value );

        console.log(start, end);

        setDateRangeData({
            startDate: start,
            endDate: end
        });
    }

    return (
        <Box sx={ GeneralContainer }>
            <Typography sx={ TextStyles }>Filtro temporal</Typography>
            <DatePicker
                mapDays={({ date, today, selectedDate, currentMonth, isSameDate }) => {
                    let props = {}

                    props.style = {
                        ':hover': {
                            backgroundColor: date.month.index === currentMonth.index ? "#2ED588" : ""
                        }
                    }

                    if (isSameDate(date, today)) props.style.backgroundColor = "#2ED588"
                    if (isSameDate(date, selectedDate)) props.style = {
                        ...props.style,
                        backgroundColor: "#2ED588",
                    }

                    return props
                }} 
                value={ value }
                onChange={ (value) => handleChangeDate(value) }
                // format iso string
                format="YYYY-MM-DD"
                inputClass="Datepicker-input"
                range
                numberOfMonths={ isMobile ? 1 : 2 }
            />
        </Box>
    )
}

const mapDispatchToProps = dispatch => {
    return {
        setDateRangeData: (data) => dispatch(setDateRangeData(data)),
    }
}

export default connect(null, mapDispatchToProps)(DateFilterComponent);