import { typesTasks } from "../types/types";

const initialState = {
    taskArray: [
        {
            number: 1,
            name: 'Registro',
            description: 'Crea una cuenta en whatspro para comenzar a utilizar la herramienta',
            state: 'completed'
        },
        {
            number: 2,
            name: 'Preguntas frecuentes',
            description: 'Configurá los textos de bienvenida, las preguntas frecuentes de tu chat y su estética.',
            state: 'active'
        },
        {
            number: 3,
            name: 'Asesores',
            description: 'Creá un asesor en caso de que tus preguntas creadas no respondan las dudas de tus clientes.',
            state: 'blocked'
        },
        {
            number: 4,
            name: 'Instalación',
            description: 'Instalá WhatsPRO en tu tienda para poder hacer visible el chat en tu página web.',
            state: 'blocked'
        }
    ],
    stepperTexts: ['+15 DÍAS ¡GRATIS!', '+5 DÍAS ¡GRATIS!', '+5 DÍAS ¡GRATIS!', '+5 DÍAS ¡GRATIS!'],
    activeTask: 2,
    lastTask: false,
    videoComplete: true,
    ecommerceSelected: '',
    finalTasksTutorial: false,
    onBoardingChat: false,
};

export const tasksReducer = (state = initialState, action) => {
    switch (action.type) {
        case typesTasks.changeTaskState:
            const taskArrayCopy = [...state.taskArray];
            let index = taskArrayCopy.findIndex((item) => item.number === action.payload.number);

            if (index === -1) {
                return {
                    ...state
                }
            }

            taskArrayCopy[index].state = action.payload.data;

            return {
                ...state,
                taskArray: [...taskArrayCopy]
            }
        case typesTasks.changeActiveTask:
            return {
                ...state,
                activeTask: action.payload,
                lastTask: action.payload === 4 ? true : false
            }
        case typesTasks.setLastTask:
            return {
                ...state,
                lastTask: action.payload
            }
        case typesTasks.setVideoComplete:
            return {
                ...state,
                videoComplete: action.payload,
            }
        case typesTasks.setEcommerceSelected:
            return {
                ...state,
                ecommerceSelected: action.payload
            }
        case typesTasks.setFinalTasksTutorial:
            return {
                ...state,
                finalTasksTutorial: action.payload
            }
        case typesTasks.setOnboardingChatComplete:
            return {
                ...state,
                onBoardingChat: action.payload
            }
        default:
            return state;
    }
}