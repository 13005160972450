import { Avatar, Box, Slide, Typography } from '@mui/material'
import React from 'react'
import { isWorkingEmployee } from '../../../helpers/chat';

const EmployeeProfile = ({ employee, headerColour = '#2ED588', handleTalkEmployee }) => {
    const isWorking = isWorkingEmployee(employee);
    const conectado = isWorking ? 'en línea' : 'offline';
    const acceptsMessage = isWorking || employee.allowOutOfScheduleMessage;
    const {name = '', avatar = 'https://material-ui.com/static/images/avatar/1.jpg', role = 'Atención'} = employee;
    return (
        <Box
            display={'flex'}
            gap={'10px'}
            alignItems={'center'}
        >
            <Avatar
                alt={name}
                src={avatar}
                sx={{ width: 55, height: 55, border: `2px solid ${acceptsMessage ? headerColour : '#BFBBBD'}` }}
                onClick={() => handleTalkEmployee(employee)}
            />
            <Box
                padding={'10px'}
                sx={{
                    borderRadius: '10px',
                    border: `1px solid ${acceptsMessage ? headerColour : '#BFBBBD'}`,
                    background: '#EEEEEE',
                }}
                onClick={() => handleTalkEmployee(employee)}
            >
                <Typography
                    variant="body2"
                    fontSize={16}
                    fontWeight={600}
                    sx={{color: acceptsMessage ? '#393939' : '#BFBBBD'}}
                >
                    {name}
                </Typography>
                <Typography
                    variant="body2"
                    fontSize={14}
                    fontWeight={400}
                    sx={{color: acceptsMessage ? '#393939' : '#BFBBBD'}}
                >
                    {role} - <span style={{
                        color: acceptsMessage ? '#6E6E6E' : '#BFBBBD',
                        fontStyle: 'italic',
                    }}>({conectado})</span>
                </Typography>
            </Box>
        </Box>
    )
}



export const PartnerEmployees = ({ employees = [], chat = {}, handleTalkEmployee }) => {
    return (
        <Slide direction="up" in={true} mountOnEnter unmountOnExit>
        <Box
            display={'flex'}
            flexDirection={'column'}
            gap={'17px'}
        >
            {employees.map((employee, index) => (
                <EmployeeProfile
                    key={index}
                    employee={employee}
                    {...chat}
                    handleTalkEmployee={handleTalkEmployee}
                />
            ))}
        </Box>
        </Slide>
    )
}
