import Tiendanube from '../assets/images/Platforms/Tiendanube.svg';
import LogoTiendanube from '../assets/logos/tiendanube.jpeg';
import MercadoShops from '../assets/images/Platforms/MercadoShops.svg';
import LogoMercadoShops from '../assets/logos/mercadoshops.png';
import WooCommerce from '../assets/images/Platforms/WooCommerce.svg';
import { APP_ID } from '../configuration';


export const PlatformList = [
    {
      name: 'tiendanube',
      redirectHref: `https://www.tiendanube.com/apps/${APP_ID}/authorize?state=csrf-code`,
      urlImage: Tiendanube,
      id: 'Tiendanube',
      logo: LogoTiendanube,
      hasLoginIntegration: true,
      commercialName: 'Tiendanube',
    },
    {
      name: 'mercadoshops',
      redirectHref: 'https://auth.mercadolibre.com.ar/authorization?response_type=code&client_id=4667354953492772',
      urlImage: MercadoShops,
      id: 'MercadoShops',
      logo: LogoMercadoShops,
      hasLoginIntegration: true,
      commercialName: 'Mercado Shops',
    },
    {
      name: 'woocommerce',
      redirectHref: '',
      urlImage: WooCommerce,
      id: 'WooCommerce',
      logo: '',
      hasLoginIntegration: false,
      commercialName: 'WooCommerce',
    },
  ];