import React from 'react';

const SaveIcon = () => {
    return (
        <div>
            <svg width="25" height="25" viewBox="0 0 113 113" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path d="M16.9492 28.2502C16.9492 25.2532 18.1398 22.379 20.2589 20.2599C22.3781 18.1407 25.2523 16.9502 28.2492 16.9502H75.5906C78.5873 16.9508 81.461 18.1418 83.5797 20.2611L92.7383 29.4197C94.8576 31.5384 96.0486 34.4121 96.0492 37.4088V84.7502C96.0492 87.7471 94.8587 90.6213 92.7395 92.7405C90.6204 94.8597 87.7462 96.0502 84.7492 96.0502H28.2492C25.2523 96.0502 22.3781 94.8597 20.2589 92.7405C18.1398 90.6213 16.9492 87.7471 16.9492 84.7502V28.2502ZM28.2492 22.6002C26.7507 22.6002 25.3136 23.1955 24.2541 24.255C23.1945 25.3146 22.5992 26.7517 22.5992 28.2502V84.7502C22.5992 86.2487 23.1945 87.6858 24.2541 88.7453C25.3136 89.8049 26.7507 90.4002 28.2492 90.4002V64.9752C28.2492 62.7275 29.1421 60.5718 30.7315 58.9825C32.3209 57.3931 34.4765 56.5002 36.7242 56.5002H76.2742C78.5219 56.5002 80.6776 57.3931 82.267 58.9825C83.8563 60.5718 84.7492 62.7275 84.7492 64.9752V90.4002C86.2477 90.4002 87.6848 89.8049 88.7444 88.7453C89.804 87.6858 90.3992 86.2487 90.3992 84.7502V37.4088C90.3989 35.9105 89.8034 34.4736 88.7438 33.4143L79.5851 24.2556C78.5258 23.196 77.0889 22.6005 75.5906 22.6002H73.4492V36.7252C73.4492 38.9729 72.5563 41.1286 70.967 42.7179C69.3776 44.3073 67.2219 45.2002 64.9742 45.2002H42.3742C40.1265 45.2002 37.9709 44.3073 36.3815 42.7179C34.7921 41.1286 33.8992 38.9729 33.8992 36.7252V22.6002H28.2492ZM39.5492 22.6002V36.7252C39.5492 37.4744 39.8469 38.193 40.3766 38.7228C40.9064 39.2526 41.625 39.5502 42.3742 39.5502H64.9742C65.7235 39.5502 66.442 39.2526 66.9718 38.7228C67.5016 38.193 67.7992 37.4744 67.7992 36.7252V22.6002H39.5492ZM79.0992 90.4002V64.9752C79.0992 64.226 78.8016 63.5074 78.2718 62.9776C77.742 62.4478 77.0235 62.1502 76.2742 62.1502H36.7242C35.975 62.1502 35.2564 62.4478 34.7266 62.9776C34.1969 63.5074 33.8992 64.226 33.8992 64.9752V90.4002H79.0992Z" fill="black"/>
            </svg>
        </div>
    )
}

export default SaveIcon;