import React from 'react';

const StartIcons = ({ top, right, display }) => {
    return (
        <div style={{ position: 'absolute', top: top, right: right, display: display }}>
            <svg width="18" height="18" viewBox="0 0 18 18" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path d="M16.9175 9.00007C16.9192 9.23076 16.849 9.45626 16.7167 9.64526C16.5844 9.83425 16.3966 9.97738 16.1792 10.0548L11.6863 11.686L10.055 16.179C9.97509 16.3943 9.83119 16.58 9.64266 16.7111C9.45412 16.8422 9.22997 16.9125 9.00031 16.9125C8.77066 16.9125 8.54651 16.8422 8.35797 16.7111C8.16943 16.58 8.02554 16.3943 7.94563 16.179L6.31438 11.686L1.82141 10.0548C1.60611 9.97484 1.42042 9.83095 1.2893 9.64241C1.15817 9.45387 1.08789 9.22972 1.08789 9.00007C1.08789 8.77042 1.15817 8.54627 1.2893 8.35773C1.42042 8.16919 1.60611 8.0253 1.82141 7.94538L6.31438 6.31413L7.94563 1.82116C8.02554 1.60586 8.16943 1.42018 8.35797 1.28905C8.54651 1.15793 8.77066 1.08765 9.00031 1.08765C9.22997 1.08765 9.45412 1.15793 9.64266 1.28905C9.83119 1.42018 9.97509 1.60586 10.055 1.82116L11.6863 6.31413L16.1792 7.94538C16.3966 8.02276 16.5844 8.16588 16.7167 8.35488C16.849 8.54388 16.9192 8.76937 16.9175 9.00007Z" fill="#FFDE2F"/>
            </svg>
        </div>
    )
}

export default StartIcons;