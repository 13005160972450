import { typesUserSteps } from "../types/types";
import { MAIN_API_URL } from '../configuration';

export const setActiveStep = (activeStep) => ({
    type: typesUserSteps.activeStep,
    payload: {
        activeStep
    }
});

export const setCompleteStep = (completeStep) => ({
    type: typesUserSteps.completeStep,
    payload: {
        completeStep
    }
});

export const setUserCreate = (data) => ({
    type: typesUserSteps.userCreate,
    payload: data
});

export const setUserEditing = ({ userEditing, userUid }) => ({
    type: typesUserSteps.userEditing,
    payload: {
        userEditing,
        userUid
    }
});

export const changeProfilePhoto = (data) => ({
    type: typesUserSteps.changeProfileImg,
    payload: data
});

export const setHours = (data) => ({
    type: typesUserSteps.createHours,
    payload: data
});

export const setActiveHour = ({ activeHour, id, index }) => ({
    type: typesUserSteps.setActiveHours,
    payload: { 
        activeHour, 
        id, 
        index 
    }
});

export const changeStartRangeHours = ({ startHour, index }) => ({
    type: typesUserSteps.changeStartRangeHours,
    payload: {
        startHour,
        index
    }
});

export const changeEndRangeHours = ({ endHour, index }) => ({
    type: typesUserSteps.changeEndRangeHours,
    payload: {
        endHour,
        index
    }
});

export const activeDaysUser = ({ activeDays, index }) => ({
    type: typesUserSteps.setActiveDaysUser,
    payload: {
        activeDays,
        index
    }
});

export const setIdHourDeleteA = (data) => ({
    type: typesUserSteps.setIdHourDelete,
    payload: data
});

export const deleteHour = ({ id }) => ({
    type: typesUserSteps.deleteHour,
    payload: { 
        id  
    }
});

export const setUserData = (data) => ({
    type: typesUserSteps.setUserData,
    payload: data
});

export const setMessages = (data) => ({
    type: typesUserSteps.setMessages,
    payload: data
});

export const setComunication = (data) => ({
    type: typesUserSteps.setComunication,
    payload: data
});

export const setShowSaveUserModal = (data) => ({
    type: typesUserSteps.ShowSaveUserModal,
    payload: data
});

export const setShowErrorUserModal = (data) => ({
    type: typesUserSteps.ShowErrorUserModal,
    payload: data
});

export const setUser = (data) => ({
    type: typesUserSteps.saveUser,
    payload: data
});

export const setIdUserDeleteA = (data) => ({
    type: typesUserSteps.setIdUserDelete,
    payload: data
});

export const deleteUser = ({ id }) => ({
    type: typesUserSteps.deleteUser,
    payload: { 
        id  
    }
});

export const setActiveUser = ({ idUser, userState }) => ({
    type: typesUserSteps.changeStateUser,
    payload: {
        idUser, 
        userState
    }
});

export const setRangeHours = ( enabledRangeHours ) => ({
    type: typesUserSteps.setEnabledRangeHours,
    payload: {
        enabledRangeHours
    }
});

export const setResetHours = ( newHours ) => ({
    type: typesUserSteps.resetHours,
    payload: newHours
});

export const setErrorHour = ( data ) => ({
    type: typesUserSteps.errorHour,
    payload: data
});

export const setNewPersonalizeImg = (data) => ({
    type: typesUserSteps.saveImgPersonalize,
    payload: data
});

export const setLoading = ( data ) => ({
    type: typesUserSteps.setLoading,
    payload: data
});

export const setShowUserModalComplete = (data) => ({
    type: typesUserSteps.showUserModalComplete,
    payload: data
});

export const setCounterActiveHours = ( data ) => ({
    type: typesUserSteps.setCounterActiveHours,
    payload: data
});

export const getEmployeeImgs = () => {
    return async (dispatch, getState) => {
        const { auth } = getState();
        const { token } = auth;
        try {
            let response = await fetch(`${MAIN_API_URL}/employees/image`, {
                method: 'GET',
                headers: {
                    'x-token': `${token}`
                }
            })

            if (response.status === 200) {
                let res = await response.json();
                for (let i = 0; i < res.images.length; i++) {
                    dispatch(setNewPersonalizeImg({
                        src: res.images[i].url,
                        id: ( 11 + i )
                    }));
                }
                return;
            }

            throw new Error('Expired token');
        } catch (error) {
            console.log("Error obteniendo imagenes: ", error);
        }
        return false;
    }
}



