import React from 'react';

// CSS
import Styles from './HelpScreen.module.css';

const HelpScreen = () => {
    return (
        <main className={Styles.HelpScreenContainer}>
            HelpScreen
        </main>
    )
}

export default HelpScreen;