import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { NavLink } from 'react-router-dom';
import { connect } from "react-redux";
import useFetch from 'use-http';

// Actions
import { setUserEditing } from '../../actions/stepsUser';
import { resetAll } from '../../actions/global';
import { logOutWP } from '../../actions/auth';

// Components
import SnackbarTutorialHome from '../Banners/SnackbarTutorialHome/SnackbarTutorialHome';
import LogOutModal from '../Modals/LogOutModal/LogOutModal';
import NavbarMobile from './NavbarMobile';

// Configuration
import { APP_ID, MAIN_API_URL } from '../../configuration';

// Icons
import AdvancedIcon from '../../assets/icons/Navbar/AdvancedIcon';
import BillingsIcon from '../../assets/icons/Navbar/BillingsIcon';
import ReviewIcon from '../../assets/icons/Navbar/ReviewIcon';
import LogoutIcon from '../../assets/icons/Navbar/LogoutIcon';
import Potenciate from '../../assets/icons/Navbar/Potenciate';
import HomeIcon from '../../assets/icons/Navbar/HomeIcon';
import UserIcon from '../../assets/icons/Navbar/UserIcon';
import ChatIcon from '../../assets/icons/Navbar/ChatIcon';

// Images
import whatsproicon from '../../assets/images/Navbar/whatspro.png';
import RocketIcon from '../../assets/icons/Navbar/RocketIcon';

// import SynchronizationIcon from '../../assets/icons/Navbar/SynchronizationIcon';
// import MyCountIcon from '../../assets/icons/Navbar/MyCountIcon';
// import HelpIcon from '../../assets/icons/Navbar/HelpIcon';

// Material Ui
import { AppBar, Box, Collapse, IconButton, SwipeableDrawer, Toolbar } from '@mui/material';
import MenuIcon from '@mui/icons-material/Menu';

// CSS
import Styles from './Navbar.module.css';

const OFFStyles = {
    backgroundColor: '#2ED588',
    padding: '.1rem .3rem',
    height: 'max-content',
    borderRadius: '10px',
    marginLeft: '.5rem',
    fontSize: '.6rem',
    fontWeight: '700',
    color: '#393939',
}

const Navbar = (props) => {

    // Actions
    const { logOutWP, setUserEditing, resetAll } = props;

    // States
    const { endTutorial, logOut } = props;

    // External props
    const { handleCloseReview } = props;

    const [state, setState] = useState({
        top: false,
    });

    const [openDrawerSynchronization, setOpenDrawerSynchronization] = useState(false);
    const [openDrawerMyAccount, setOpenDrawerMyAccount] = useState(false);
    const [onBoardingComplete, setOnBoardingComplete] = useState(false);
    const [openDrawerEnhance, setOpenDrawerEnhance] = useState(false);
    const [openDrawerUsers, setOpenDrawerUsers] = useState(false);
    const [showSnackbar, setShowSnackbar] = useState(false);
    const [storeData, setStoreData] = useState('');
    const [store, setStore] = useState([]);

    const { t } = useTranslation();

    const { error, get, response } = useFetch(`${MAIN_API_URL}/stores`, {
        headers: {
            "x-token": `${localStorage.getItem("token")}`
        }
    });

    useEffect(() => { getStoreData(); }, []);

    const reload = () => { window.location.href('/chat') }

    const toggleDrawer = (anchor, open, drawerItem) => (event) => {
        if (
            event &&
            event.type === 'keydown' &&
            (event.key === 'Tab' || event.key === 'Shift')
        ) {
            return;
        }

        setState({ ...state, [anchor]: open });
        if (drawerItem === 'item') {
            setOpenDrawerUsers(false);
            setOpenDrawerMyAccount(false);
            setOpenDrawerEnhance(false);
        } else if (drawerItem === 'drawer') {
            setOpenDrawerUsers(false);
            setOpenDrawerMyAccount(false);
            setOpenDrawerEnhance(false);
        } else {
            return;
        }
    };

    const getStoreData = async () => {
        const resp = await get("");
        if (response.ok) {
            setOnBoardingComplete(resp.onBoardingFinished);
            setStoreData(resp.storeName);
            setStore(resp);
        } else {
            console.log(error);
        }
    }

    const handleClickDrawer = (type) => {
        switch (type) {
            case 'Users':
                setOpenDrawerUsers(!openDrawerUsers);
                break;
            case 'MyAccount':
                setOpenDrawerMyAccount(!openDrawerMyAccount);
                break;
            case 'Enhance':
                setOpenDrawerEnhance(!openDrawerEnhance);
                break;
            case 'Synchronization':
                setOpenDrawerSynchronization(!openDrawerSynchronization);
                break;
            default:
                break;
        }
    };

    const handleClickDrawerWeb = () => {
        setOpenDrawerSynchronization(false);
        setOpenDrawerMyAccount(false);
        setOpenDrawerEnhance(false);
        setOpenDrawerUsers(false);
    }

    const handleLogout = () => {
        logOutWP(!logOut);
    }

    const handleCreateUser = () => {
        setUserEditing({
            userEditing: false,
            userUid: ''
        });

        resetAll();
    }

    const handleClickChat = () => {
        reload();
    }

    const list = (anchor) => (
        <NavbarMobile
            openDrawerEnhance={openDrawerEnhance}
            openDrawerMyAccount={openDrawerMyAccount}
            onBoardingComplete={onBoardingComplete}
            showAlertSnackbar={showAlertSnackbar}
            handleClickDrawer={handleClickDrawer}
            handleCloseReview={handleCloseReview}
            handleCreateUser={handleCreateUser}
            openDrawerUsers={openDrawerUsers}
            handleClickChat={handleClickChat}
            toggleDrawer={toggleDrawer}
            handleLogout={handleLogout}
            storeData={storeData}
            anchor={anchor}
            store={store}
        />
    );

    const showAlertSnackbar = () => {
        setShowSnackbar(!showSnackbar);
    };

    return (
        <nav className={Styles.NavContainer} style={{ zIndex: '2' }}>
            <div id='NavWeb' className={Styles.NavWeb}>
                <NavLink
                    to="/home"
                    onClick={handleClickDrawerWeb}
                >
                    <img src={whatsproicon} alt="Img Top" className={Styles.ImgTop} />
                </NavLink>
                <p className={Styles.StoreName}>
                    {storeData}
                </p>
                <ul>
                    <li>
                        <NavLink
                            className={Styles.Navlink}
                            end="true"
                            style={
                                ({ isActive }) => ({
                                    color: isActive ? '#2ED588' : 'white',
                                })
                            }
                            to="/home"
                            onClick={handleClickDrawerWeb}
                        >
                            <HomeIcon />
                            {t('Home')}
                        </NavLink>
                    </li>
                    <Box
                        onClick={showAlertSnackbar}
                        sx={{
                            opacity: (!onBoardingComplete && store.suscriptionData?.status !== "authorized") ? '.5' : '1'
                        }}
                    >
                        <div
                            style={{
                                pointerEvents: (!onBoardingComplete && store.suscriptionData?.status !== "authorized") ? 'none' : 'auto'
                            }}
                        >
                            <div
                                onClick={() => handleClickDrawer('Users')}
                                className={Styles.DrawerButtonContainer}
                            >
                                <div
                                    className={Styles.Navlink}
                                    style={{
                                        cursor: 'pointer',
                                        color: 'white',
                                    }}
                                >
                                    <UserIcon />
                                    {t('Users')}
                                </div>
                            </div>
                            <Collapse in={openDrawerUsers} timeout="auto" unmountOnExit>
                                <li
                                    className={Styles.DrawerContainer}
                                >
                                    <NavLink
                                        className={Styles.NavlinkDrawer}
                                        end="true"
                                        style={
                                            ({ isActive }) => ({
                                                color: isActive ? '#2ED588' : 'white',
                                            })
                                        }
                                        to="/myUsers"
                                        onClick={handleCreateUser}
                                    >
                                        {t('User1')}
                                    </NavLink>
                                </li>
                                <li
                                    className={Styles.DrawerContainer}
                                >
                                    <NavLink
                                        className={Styles.NavlinkDrawer}
                                        end="true"
                                        style={
                                            ({ isActive }) => ({
                                                color: isActive ? '#2ED588' : 'white',
                                            })
                                        }
                                        to="/createUser"
                                        onClick={handleCreateUser}
                                    >
                                        {t('User2')}
                                    </NavLink>
                                </li>
                            </Collapse>
                        </div>
                        <li
                            style={{
                                pointerEvents: (!onBoardingComplete && store.suscriptionData?.status !== "authorized") ? 'none' : 'auto'
                            }}
                        >
                            <NavLink
                                className={Styles.Navlink}
                                end="true"
                                style={
                                    ({ isActive }) => ({
                                        color: isActive ? '#2ED588' : 'white',
                                    })
                                }
                                to="/chat"
                                onClick={handleClickChat}
                            >
                                <ChatIcon />
                                {t('Chat')}
                            </NavLink>
                        </li>
                        <li
                            style={{
                                pointerEvents: (!onBoardingComplete && store.suscriptionData?.status !== "authorized") ? 'none' : 'auto'
                            }}
                        >
                            <NavLink
                                className={Styles.Navlink}
                                end="true"
                                style={
                                    ({ isActive }) => ({
                                        color: isActive ? '#2ED588' : 'white',
                                    })
                                }
                                to="/advanced"
                                onClick={handleClickDrawerWeb}
                            >
                                <AdvancedIcon />
                                {t('Advanced')}
                            </NavLink>
                        </li>
                        {store?.origin === 'Particular' && <li
                                style={{
                                    pointerEvents: (!onBoardingComplete && store.suscriptionData?.status !== "authorized") ? 'none' : 'auto'
                                }}
                            >
                                <NavLink
                                    className={Styles.Navlink}
                                    end="true"
                                    style={
                                        ({ isActive }) => ({
                                            color: isActive ? '#2ED588' : 'white',
                                        })
                                    }
                                    to="/integrations"
                                    onClick={handleClickDrawerWeb}
                                >
                                    <Potenciate />
                                    {t('Integraciones')}
                                </NavLink>
                        </li>}
                        <div
                            style={{
                                pointerEvents: (!onBoardingComplete && store.suscriptionData?.status !== "authorized") ? 'none' : 'auto'
                            }}
                            className={Styles.DividerLine}/>
                            <li
                                style={{
                                    pointerEvents: (!onBoardingComplete && store.suscriptionData?.status !== "authorized") ? 'none' : 'auto'
                                }}
                            >
                                <NavLink
                                    className={Styles.Navlink}
                                    end="true"
                                    style={
                                        ({ isActive }) => ({
                                            color: isActive ? '#2ED588' : 'white',
                                        })
                                    }
                                    to="/billings"
                                    onClick={handleClickDrawerWeb}
                                >
                                    <BillingsIcon />
                                    {t('Billings')}
                                </NavLink>
                            </li>
                            <div
                                style={{
                                    pointerEvents: (!onBoardingComplete && store.suscriptionData?.status !== "authorized") ? 'none' : 'auto'
                                }}
                            >
                                <div
                                    onClick={() => handleClickDrawer('Enhance')}
                                    className={Styles.DrawerButtonContainer}
                                >
                                    <div
                                        className={Styles.Navlink}
                                        style={{
                                            cursor: 'pointer',
                                            color: 'white',
                                        }}
                                    >
                                        <RocketIcon />
                                        {t('Potenciate')}
                                    </div>
                                </div>
                                <Collapse in={openDrawerEnhance} timeout="auto" unmountOnExit>
                                    <li
                                        className={Styles.DrawerContainer}
                                    >
                                        <a
                                            href="https://flowypartners.com/crossup"
                                            target="_blank"
                                            rel="noopener noreferrer"
                                            className={Styles.ComplementLinks}
                                        >
                                            CrossUp
                                        </a>
                                    </li>
                                    <li
                                        className={Styles.DrawerContainer}
                                    >
                                        <a
                                            href="https://flowypartners.com/gifty"
                                            target="_blank"
                                            rel="noopener noreferrer"
                                            className={Styles.ComplementLinks}
                                        >
                                            Gifty
                                        </a>
                                    </li>
                                </Collapse>
                            </div>
                            {
                                (onBoardingComplete && store.origin === 'Tiendanube') ?
                                    <div
                                        onClick={() => handleCloseReview()}
                                        className={Styles.ReviewDrawerButtonContainer}
                                        style={{ pointerEvents: false ? 'none' : 'auto' }}
                                    >
                                        <div
                                            className={Styles.NavlinkReview}
                                            style={{
                                                cursor: 'pointer',
                                                color: 'white',
                                            }}
                                        >
                                            <Box position={'relative'} left={-3}>
                                                <ReviewIcon />
                                            </Box>
                                            {t('Reviews')}
                                            <Box sx={OFFStyles}>50% OFF 🔥</Box>
                                        </div>
                                        {/* <Collapse in={openDrawerSynchronization} timeout="auto" unmountOnExit>
                                            <li className={Styles.DrawerContainer}>
                                                <a
                                                    href={`https://www.tiendanube.com/tienda-aplicaciones-nube/flowy-whatspro/rating`}
                                                    rel="noopener noreferrer"
                                                style={{
                                                    textDecoration: 'none',
                                                    textAlign: 'left',
                                                    fontWeight: '600',
                                                    cursor: 'pointer',
                                                    color: 'white',
                                                    width: '100%',
                                                }}
                                                >
                                                Tiendanube
                                            </a>
                                        </li>
                                        </Collapse> */}
                                    </div>
                                    :
                                    null
                            }
                    </Box>
                </ul>
                <ul style={{ position: 'absolute', bottom: '5%' }}>
                    <li style={{ cursor: "pointer", userSelect: 'none', paddingBottom: '1rem' }}>
                        <div
                            className={Styles.Navlink}
                            end="true"
                            style={{
                                justifyContent: 'center',
                                color: '#EB5757',
                                display: 'flex',
                            }}
                            onClick={handleLogout}
                        >
                            <LogoutIcon />
                            {t('Logout')}
                        </div>
                    </li>
                </ul>
            </div>
            <AppBar
                position="static"
                className={Styles.NavMobile}
                sx={{
                    "& .MuiToolbar-root": {
                        minHeight: '4rem !important',
                        boxShadow: 'none'
                    }
                }}
            >
                <Toolbar>
                    <IconButton
                        size="large"
                        edge="start"
                        color="inherit"
                        aria-label="menu"
                        sx={{ mr: 2 }}
                        onClick={toggleDrawer('left', true, '')}
                    >
                        <MenuIcon />
                    </IconButton>
                    <Box sx={{ width: '100%', display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
                        <img src={whatsproicon} alt="Img Top" className={Styles.ImgTopMobile1} />
                    </Box>
                </Toolbar>
            </AppBar>
            <SwipeableDrawer
                anchor={'left'}
                open={state['left'] ? state['left'] : false}
                onClose={toggleDrawer('left', false)}
                onOpen={toggleDrawer('left', true)}
            >
                {
                    list('left')
                }
            </SwipeableDrawer>
            {onBoardingComplete ? null : <SnackbarTutorialHome open={showSnackbar} handleClose={showAlertSnackbar} />}
            {logOut && <LogOutModal showModalLogout={logOut ? logOut : false} handleClose={handleLogout} />}
        </nav>
    )
}

const mapStateToProps = state => {
    return {
        endTutorial: state.auth.endTutorial,
        logOut: state.auth.logOut,
    }
}

const mapDispatchToProps = dispatch => {
    return {
        setUserEditing: ({ userEditing, userUid }) => dispatch(setUserEditing({ userEditing, userUid })),
        logOutWP: (value) => dispatch(logOutWP(value)),
        resetAll: () => dispatch(resetAll()),
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(Navbar);