import { Box, Typography } from '@mui/material'
import React from 'react'

export const PlatformLogin = ({commercialName = '', logo = '', redirectHref = ''}) => {
    const handleClick = () => {
        localStorage.setItem('hasStartLoginWithPlatform', JSON.stringify(true));
        localStorage.removeItem('syncronizeId');
        window.location.href = redirectHref;
    }
    return (
        <Box
            display={'flex'}
            flexDirection={'row'}
            alignContent={'center'}
            justifyContent={'flex-start'}
            alignItems={'center'}
            sx={{
                width: '94%',
                gap: '10px',
                borderRadius: '6px',
                backgroundColor: '#fff',
                // boxShadow: '0 0 10px 0 rgba(0, 0, 0, 0.1)',
                padding: '12px 10px',
                cursor: 'pointer',
                border: '1px solid #c4c4c4',
                '&:hover': {
                    //backgroundColor black with 0.1 opacity
                    backgroundColor: 'rgba(0, 0, 0, 0.1)',
                },
            }}
            onClick={handleClick}
        >
            <Box
                component={'img'}
                src={logo}
                sx={{
                    width: '30px',
                    borderRadius: '6px',
                }}
            />
            <Typography variant={'subtitle1'} sx={{ fontSize: '0.9rem', fontWeight: 400 }}>
                Continuar con {commercialName} 
                {/* <span style={{color: primaryColor, background: '#61ebac', padding: '5px', borderRadius: '6px'}}>RECOMENDADO</span> */}
            </Typography>
        </Box>
    )
}
