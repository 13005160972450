import React from 'react';

// Material Ui
import { IconButton, Snackbar } from '@mui/material';

// Icon
import CloseIcon from '@mui/icons-material/Close';

const BannerErrorHour = ({ handleClose, open }) => {

    const snackbarStyles = {
        backgroundColor: '#FF7474',
        borderRadius: '8px',
        color: 'white',
        '.MuiPaper-root': {
            backgroundColor: '#FF7474',
            fontFamily: 'Poppins',
            borderRadius: '8px',
            fontSize: '.55rem',
            fontWeight: '500',
            '@media(min-width: 350px)': {
                fontSize: '.7rem'
            },
            '@media(min-width: 500px)': {
                fontSize: '.9rem'
            }
        },
        '.MuiSvgIcon-root': {
            fontSize: '.7rem',
            '@media(min-width: 500px)': {
                fontSize: '.9rem'
            }
        }
    }

    const action = (
        <React.Fragment>
            <IconButton
                size="small"
                aria-label="close"
                color="inherit"
                onClick={ handleClose }
                sx={{ 
                    backgroundColor: '#EB5757'
                }}
            >
                <CloseIcon fontSize="small" />
            </IconButton>
        </React.Fragment>
    );

    return (
        <Snackbar
            open={ open }
            autoHideDuration={ 3000 }
            anchorOrigin={{ 
                vertical: "bottom", 
                horizontal: "right" 
            }}
            onClose={ handleClose }
            message={ 
                'El horario "Hasta" debe ser mayor que "Desde"' 
            }
            action={ action }
            sx={ snackbarStyles }
        />
    )
}

export default BannerErrorHour;