import React from 'react';

// CSS
import Styles from './Step3Chat.module.css';

const TextStep2Chat = () => {
    return (
        <div className={ Styles.TextContainer }>
            <h2>Paso 2: Preguntas frecuentes</h2>
            <h3>¿Qué tengo que hacer?</h3>
            <p>Acá vas a poder seleccionar plantillas con preguntas frecuentes y sus respectivas respuestas predeterminadas o crear tus propias preguntas y respuestas personalizadas.</p>
        </div>
    )
}

export default TextStep2Chat;