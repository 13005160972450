import React from 'react';

// CSS
import Styles from './Step3.module.css';

const TextsStep3 = () => {
    return (
        <div className={ Styles.TextContainer }>
            <h2>Paso 3: Mensajes  automáticos</h2>
            <h3>¿Qué tengo que hacer?</h3>
            <p>En el paso 3 deberás completar los mensajes que enviará de forma automática tu asesor a tus clientes.</p>
            <h3>¿Qué es el mensaje de bienvenida?</h3>
            <p>El mensaje de bienvenida será enviado automáticamente a tus clientes cuando quieran hacerle una consulta a tu asesor dentro del horario de atención.</p>
            <h3>¿Permito mensajes fuera del horario de atención?</h3>
            <p>Esto es una elección que dependerá de tu negocio. En caso de que se permita enviarle un mensaje al asesor fuera de horario el mismo recibirá el mensaje y lo responderá cuando esté disponible. En caso de que no se habiliten los clientes no podrán enviar mensajes fuera de horario de atención.</p>
            <h3>Ejemplos de mensajes de bienvenida</h3>
            <ul className={ Styles.ListText }>
                <li>
                    <p>"¡Hola! Me alegra mucho que me hayas contactado. ¿Cómo estás hoy? ¿En qué puedo ayudarte?"</p>
                </li>
                <li>
                    <p>"Buenos días. Soy [nombre del asesor] y estoy aquí para ayudarte en lo que necesites. ¿Cómo puedo hacerlo hoy?"</p>
                </li>
                <li>
                    <p>"¡Bienvenido/a! Estoy emocionado/a de poder ayudarte hoy. ¿En qué puedo ser de ayuda para resolver tu consulta?"</p>
                </li>
            </ul>
        </div>
    )
}

export default TextsStep3