import React from 'react';

const PlusIcon = (props) => {
  return React.createElement(
    'svg',
    Object.assign(
      {
        xmlns: 'http://www.w3.org/2000/svg',
        fill: 'none',
        viewBox: '0 0 20 20',
        'aria-hidden': 'true',
      },
      props,
    ),
    React.createElement('path', {
      d: 'M9.76309 0.160156C8.79751 0.160156 8.01487 0.942798 8.01487 1.90838V7.85227H2.07098C1.1054 7.85227 0.322754 8.63491 0.322754 9.60049C0.322754 10.5661 1.1054 11.3487 2.07098 11.3487H8.01487V17.2926C8.01487 18.2582 8.79751 19.0408 9.76309 19.0408C10.7287 19.0408 11.5113 18.2582 11.5113 17.2926V11.3487H17.4552C18.4208 11.3487 19.2034 10.5661 19.2034 9.60049C19.2034 8.63491 18.4208 7.85227 17.4552 7.85227H11.5113V1.90838C11.5113 0.942798 10.7287 0.160156 9.76309 0.160156Z" fill="#2A2742',
      fill: 'currentColor',
      fillRule: 'evenodd',
      clipRule: 'evenodd',
    }),
  );
};

export { PlusIcon };
