// Material Ui
import { Box, Typography } from '@mui/material';

const ContainerStyles = {
    border: '1px solid #E4E4E4',
    backgroundColor: 'white',
    padding: '.2rem .6rem',
    borderRadius: '10px',
    width: 'max-content',
    fontSize: '.7rem',
    fontWeight: '500',
    display: 'flex',
}

const StepsCounter = {
    fontSize: '.7rem',
    fontWeight: '700',
}


const StepsComponent = ({ step }) => {
    return (
        <Box sx={ ContainerStyles }><Typography sx={ StepsCounter }>{ step - 1 }/4</Typography>&nbsp;completadas</Box>
    )
}

export default StepsComponent;