import { Visibility, VisibilityOff } from '@mui/icons-material'
import { LoadingButton } from '@mui/lab'
import { Box, FormControl, FormHelperText, IconButton, InputAdornment, InputLabel, OutlinedInput, TextField, Typography } from '@mui/material'
import React, { useEffect, useState } from 'react'
import { primaryColor } from '../../../constants'
import { useNavigate } from 'react-router-dom'
import { useFormik } from 'formik'
import * as Yup from 'yup';
import { useFetch } from 'use-http'
import { MAIN_API_URL } from '../../../configuration'
import { MuiTelInput } from 'mui-tel-input'

const validationSchema = Yup.object({
    mail: Yup.string().email('Ingrese un correo electrónico válido').required('El correo electrónico es requerido'),
    password: Yup.string().required('La contraseña es requerida').min(8, 'La contraseña debe tener al menos 8 caracteres'),
    phone: Yup.string()
        .min(10, 'El teléfono debe tener al menos 10 dígitos')
        .required('El teléfono es requerido'),
    domain: Yup.string().required('El dominio es requerido'),
    country: Yup.string().required('El país es requerido'),
    name: Yup.string().required('El nombre es requerido'),
    storeName: Yup.string().required('El nombre de la tienda es requerido'),
});

export const UserPasswordRegister = () => {
    const navigate = useNavigate();
    const { post, loading, response } = useFetch(MAIN_API_URL);
    const [showPassword, setShowPassword] = useState(false);
    const handleClickShowPassword = () => setShowPassword((show) => !show);
    const handleMouseDownPassword = (event) => {
        event.preventDefault();
    };
    const handleGoToLogin = () => {
        navigate('/')
    }
    const [errorMsg, setErrorMsg] = useState('');

    const formik = useFormik({
        initialValues: {
            name: '',
            phone: '',
            domain: '',
            country: 'AR',
            storeName: '',
            mail: '',
            password: '',
        },
        validationSchema: validationSchema,
        onSubmit: async (values) => {
            values.mail = values.mail.toLowerCase().trim();
            values.domain = values.domain.toLowerCase().trim();
            values.name = values.name.trim().toLowerCase().split(' ').map((s) => s.charAt(0).toUpperCase() + s.substring(1)).join(' ');
            values.phone = values.phone.toLowerCase().trim().split(' ').join('');
            values.storeName = values.storeName.trim().toLowerCase().split(' ').map((s) => s.charAt(0).toUpperCase() + s.substring(1)).join(' ');
            const data = await post('/auth/register', values)
            if (response.ok) {
                localStorage.setItem('token', data.user.token);
                localStorage.setItem('refreshToken', data.user.refreshToken);
                localStorage.setItem('store', data.user._id);
                localStorage.setItem('origin', data.user.origin);
                window.location.href = '/home'
            } else {
                setErrorMsg(data.msg)
            }
        }
    })

    useEffect(() => {
        if (errorMsg.length > 0) setErrorMsg('')
    }, [formik.values]);

    const handlePhoneChange = (newPhone, {countryCode}) => {
        formik.setFieldValue('phone', newPhone);
        formik.setFieldValue('country', countryCode);
    }


    return (
        <>
            <TextField
                variant="outlined"
                margin="normal"
                required
                fullWidth
                id="mail"
                label="Correo electrónico"
                name="mail"
                autoComplete="mail"
                autoFocus
                sx={{ margin: 0, fontFamily: 'Poppins' }}
                value={formik.values.mail}
                onChange={formik.handleChange}
                error={formik.touched.mail && Boolean(formik.errors.mail)}
                helperText={formik.touched.mail && formik.errors.mail}
            />
            <FormControl variant="outlined" fullWidth required>
                <InputLabel htmlFor="outlined-adornment-password">Contraseña</InputLabel>
                <OutlinedInput
                    id="password"
                    type={showPassword ? 'text' : 'password'}
                    endAdornment={
                        <InputAdornment position="end">
                            <IconButton
                                aria-label="toggle password visibility"
                                onClick={handleClickShowPassword}
                                onMouseDown={handleMouseDownPassword}
                                edge="end"
                            >
                                {showPassword ? <VisibilityOff /> : <Visibility />}
                            </IconButton>
                        </InputAdornment>
                    }
                    label="Contraseña"
                    sx={{ margin: 0 }}
                    value={formik.values.password}
                    onChange={formik.handleChange}
                    error={formik.touched.password && Boolean(formik.errors.password)}
                />
                <FormHelperText id="password-helper-text" sx={{ color: 'red' }}>{formik.touched.password && formik.errors.password}</FormHelperText>
            </FormControl>
            <TextField
                variant="outlined"
                margin="normal"
                required
                fullWidth
                id="name"
                label="Nombre"
                name="name"
                autoComplete="name"
                sx={{ margin: 0, fontFamily: 'Poppins' }}
                value={formik.values.name}
                onChange={formik.handleChange}
                error={formik.touched.name && Boolean(formik.errors.name)}
                helperText={formik.touched.name && formik.errors.name}
            />
            <TextField
                variant="outlined"
                margin="normal"
                required
                fullWidth
                id="storeName"
                label="Nombre de tu tienda"
                name="storeName"
                autoComplete="name"
                sx={{ margin: 0, fontFamily: 'Poppins' }}
                value={formik.values.storeName}
                onChange={formik.handleChange}
                error={formik.touched.storeName && Boolean(formik.errors.storeName)}
                helperText={formik.touched.storeName && formik.errors.storeName}
            />
            <MuiTelInput
                label="Teléfono"
                name='phone'
                id='phone'
                fullWidth
                defaultCountry="AR"
                value={formik.values.phone}
                onChange={handlePhoneChange}
                error={formik.touched.phone && Boolean(formik.errors.phone)}
                helperText={formik.touched.phone && formik.errors.phone}
                preferredCountries={['AR', 'UY', 'BR', 'CL', 'CO', 'MX', 'PE', 'US']}
            />
            <TextField
                variant="outlined"
                margin="normal"
                required
                fullWidth
                id="domain"
                label="Link de tu tienda"
                name="domain"
                autoComplete="domain"
                sx={{ margin: 0, fontFamily: 'Poppins' }}
                value={formik.values.domain}
                onChange={formik.handleChange}
                error={formik.touched.domain && Boolean(formik.errors.domain)}
                helperText={formik.touched.domain && formik.errors.domain}
            />
            {
                errorMsg && <Typography variant={'subtitle1'} sx={{ fontSize: '0.9rem', fontWeight: 400, color: 'red' }}>
                    {errorMsg}
                </Typography>
            }
            <LoadingButton
                variant="contained"
                loadingPosition="center"
                loading={loading}
                disabled={loading}
                loadingIndicator="Cargando..."
                sx={{
                    width: '100%',
                    height: '54px',
                    borderRadius: '6px',
                    color: primaryColor,
                    background: '#fff',
                    boxShadow: 'none',
                    border: '1px solid #c4c4c4',
                    '&:hover': {
                        backgroundColor: primaryColor,
                        color: '#fff',
                    },
                }}
                onClick={formik.handleSubmit}
            >
                <Typography variant={'subtitle1'} sx={{ fontSize: '1rem', fontWeight: 500 }}>
                    Registrarse
                </Typography>
            </LoadingButton>
            <Typography variant={'subtitle1'} sx={{ fontSize: '0.9rem', fontWeight: 400, cursor: 'pointer' }}>
                ¿Ya tiene una cuenta? <span style={{ color: primaryColor, fontWeight: 500 }} onClick={handleGoToLogin}>Iniciar sesión</span>
            </Typography>
        </>
    )
}
