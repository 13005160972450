import { typesChatSteps } from "../types/types";
import { MAIN_API_URL } from '../configuration';

export const setActiveStepChat = (activeStepChat) => ({
    type: typesChatSteps.activeStepChat,
    payload: {
        activeStepChat
    }
});

export const setCompleteStepChat = (completeStepChat) => ({
    type: typesChatSteps.completeStepChat,
    payload: {
        completeStepChat
    }
});

export const setTitleChat = (title) => ({
    type: typesChatSteps.titleChat,
    payload: title
});

export const setWelcomeTextChat = (data) => ({
    type: typesChatSteps.welcomeTextChat,
    payload: data
});

export const setEditingChat = (data) => ({
    type: typesChatSteps.setEditingChat,
    payload: data
});

export const activeTemplateChat = (data) => ({
    type: typesChatSteps.setActiveTemplate,
    payload: data
});

export const setColorsChat = (data) => ({
    type: typesChatSteps.setStylesChat,
    payload: data
});

export const setAdvancedOptions = (data) => ({
    type: typesChatSteps.setAdvancedOptions,
    payload: data
});

export const setEditAdvancedOptions = (data) => ({
    type: typesChatSteps.editAdvancedOptions,
    payload: data
});

export const setShowModalChat = (data) => ({
    type: typesChatSteps.showSaveChangesModal,
    payload: data
});

export const setShowErrorChat = (data) => ({
    type: typesChatSteps.showErrorSaveChanges,
    payload: data
});

export const setOnBoardingChatValue = (data) => ({
    type: typesChatSteps.setOnboardingChat,
    payload: data
});

export const onSetLeaveSaveChangesChat = (data) => ({
    type: typesChatSteps.setLeaveSaveChangesChat,
    payload: data
});

export const setQuestions = (data) => ({
    type: typesChatSteps.setQuestions,
    payload: data
});

export const updateQuestion = (data) => ({
    type: typesChatSteps.updateQuestion,
    payload: data
});

export const reorderQuestions = (data) => ({
    type: typesChatSteps.reorderQuestions,
    payload: data
});

export const setTemplates = (data) => ({
    type: typesChatSteps.setTemplates,
    payload: data
});

export const setActiveTemplate = (data) => ({
    type: typesChatSteps.setActiveTemplate,
    payload: data
});

export const createNewQuestion = (data) => ({
    type: typesChatSteps.createNewQuestion,
    payload: data
});

export const getQuestions = () => {
    return async (dispatch, getState) => {
        const { auth } = getState();
        const { token } = auth;
        try {
            let response = await fetch(`${MAIN_API_URL}/answers`, {
                method: 'GET',
                headers: {
                    'x-token': `${token}`
                }
            })

            if (response.status === 200) {
                let res = await response.json();
                dispatch(setQuestions(res.answers));
                return;
            }

            throw new Error('Expired token');
        } catch (error) {
            console.log("Error obteniendo preguntas: ", error);
        }
        return false;
    }
};

export const getTemplates = () => {
    return async (dispatch, getState) => {
        const { auth } = getState();
        const { token } = auth;
        try {
            const [templatesRes, storeRes] = await Promise.all([
                fetch(`${MAIN_API_URL}/answers_template`, {
                    method: 'GET',
                    headers: {
                        'x-token': `${token}`
                    }
                }),
                fetch(`${MAIN_API_URL}/stores`, {
                    method: 'GET',
                    headers: {
                        'x-token': `${token}`
                    }
                })
            ]);
            if (templatesRes.status === 200 && storeRes.status === 200) {
                const templates = await templatesRes.json();
                const store = await storeRes.json();
                const templatesInFormat = templates.map((t) => {
                    return {
                        ...t,
                        name: t.name[store.mainLanguage] || t.name['es'],
                    }
                });
                dispatch(setTemplates(templatesInFormat));
                return;
            }
            throw new Error('Expired token');
        } catch (error) {
            console.log("Error obteniendo preguntas: ", error);
        }
        return false;
    }
};

