import React from 'react';
import useFetch from "use-http";
import { connect } from "react-redux";

// Actions
import { deleteHour, deleteUser, setLoading } from '../../../actions/stepsUser';

// Configurations
import { MAIN_API_URL } from '../../../configuration';

// Material Ui
import DeleteOutlineOutlinedIcon from '@mui/icons-material/DeleteOutlineOutlined';
import { Button, Fade, Modal } from '@mui/material';
import Backdrop from '@mui/material/Backdrop';
import { Box } from '@mui/system';

// CSS
import Styles from './DeleteModal.module.css';

const DeleteModal = ( props ) => {

    // Actions
    const { deleteHour, deleteUser, setLoading } = props;

    // States
    const { idHourDelete, idUserDelete } = props;

    // Externals props
    const { open, handleClose, type } = props;

    const style = {
        transform: 'translate(-50%, -50%)',
        border: '1px solid #BFBBBD',
        position: 'absolute',
        borderRadius: '10px',
        textAlign: 'center',
        bgcolor: 'white',
        p: '1rem 1.5rem',
        width: '75%',
        left: '50%',
        top: '50%',
        "@media (min-width: 500px)": {
            width: '335px'
        },
    };

    const buttonCancel = {
        backgroundColor: '#ECEBEC',
        padding: '.5rem 1.5rem',
        textTransform: 'none',
        fontFamily: 'Poppins',
        borderRadius: '8px',
        fontWeight: '500',
        fontSize: '.9rem',
        color: '#BFBBBD',
        '&:hover': {
            backgroundColor: '#ECEBEC'
        }
    }

    const buttonDelete = {
        backgroundColor: '#FF7474',
        padding: '.5rem 1.5rem',
        fontFamily: 'Poppins',
        textTransform: 'none',
        borderRadius: '8px',
        fontWeight: '500',
        boxShadow: 'none',
        fontSize: '.9rem',
        color: 'white',
        '&:hover': {
            backgroundColor: '#FF7474'
        }
    }

    const { error, del, response } = useFetch(`${MAIN_API_URL}/employees`, {
        headers: {
            "x-token": `${localStorage.getItem("token")}`
        }
    });

    const handleDelete = async() => {
        if (type === 'users') {

            const resp = await del(`/${idHourDelete}`);
            setLoading( true );
            if(response.ok){
                //TODO quitar del front el empleado
                // console.log("RESPUESTA: ", resp);
                window.location.href = '/myUsers';
            } else {
                console.log(error);
            }
            
            await deleteUser({ id: idUserDelete });
            await handleClose();
            await setLoading( false );
        } else {
            await deleteHour({ id: idHourDelete });
            await handleClose();
            await setLoading( false );
        }
    }

    return (
        <Modal
            aria-labelledby="transition-modal-title"
            aria-describedby="transition-modal-description"
            open={open}
            onClose={handleClose}
            closeAfterTransition
            BackdropComponent={Backdrop}
            BackdropProps={{
                timeout: 500,
            }}
        >
            <Fade in={open}>
                <Box sx={style}>
                    <DeleteOutlineOutlinedIcon 
                        sx={{ 
                            color: '#FF7474', 
                            fontSize: '4rem' 
                        }} 
                    />
                    <div className={Styles.DeleteModalContainer}>
                        { type === 'users' ? <h1>Eliminar asesor</h1> : <h1>Eliminar horario</h1> }
                        { 
                            type === 'users' ?
                                <p>¿Estás seguro que querés eliminar este asesor?</p>
                            :
                                <p>¿Estás seguro que querés eliminar este horario?</p>
                        }
                        <div className={Styles.ButtonsContainer}>
                            <Button
                                sx={ buttonCancel }
                                onClick={ handleClose }
                            >
                                Cancelar
                            </Button>
                            <Button
                                sx={ buttonDelete }
                                onClick={ () => handleDelete() }
                            >
                                Eliminar
                            </Button>
                        </div>
                    </div>
                </Box>
            </Fade>
        </Modal>
    )
}

const mapStateToProps = state => {
    return {
        idHourDelete: state.stepsUser.idHourDelete,
        idUserDelete: state.stepsChat.idUserDelete,
    }
}

const mapDispatchToProps = dispatch => {
    return {
        deleteUser: ( value ) => dispatch(deleteUser( value )),
        deleteHour: ({ id }) => dispatch(deleteHour({ id })),
        setLoading: ( data ) => dispatch(setLoading( data )),
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(DeleteModal);