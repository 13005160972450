import React from 'react';

const RedAlert = ({ color }) => {
    return (
        <div>
            <svg width="72" height="72" viewBox="0 0 82 82" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path d="M41.0002 6.8335C34.2426 6.8335 27.6369 8.83734 22.0182 12.5916C16.3995 16.3459 12.0203 21.682 9.43429 27.9251C6.8483 34.1683 6.17169 41.0381 7.49001 47.6657C8.80834 54.2934 12.0624 60.3813 16.8407 65.1596C21.619 69.9379 27.7069 73.192 34.3346 74.5103C40.9623 75.8287 47.8321 75.152 54.0752 72.566C60.3183 69.9801 65.6544 65.6008 69.4087 59.9821C73.163 54.3635 75.1668 47.7577 75.1668 41.0002C75.1668 36.5133 74.2831 32.0704 72.5661 27.9251C70.849 23.7799 68.3323 20.0133 65.1597 16.8407C61.987 13.668 58.2205 11.1513 54.0752 9.43428C49.9299 7.71724 45.487 6.8335 41.0002 6.8335ZM41.0002 68.3335C35.5942 68.3335 30.3095 66.7304 25.8146 63.727C21.3196 60.7236 17.8163 56.4547 15.7475 51.4602C13.6787 46.4657 13.1374 40.9698 14.192 35.6677C15.2467 30.3655 17.85 25.4952 21.6726 21.6726C25.4952 17.8499 30.3656 15.2467 35.6677 14.192C40.9699 13.1374 46.4657 13.6787 51.4602 15.7475C56.4547 17.8163 60.7236 21.3196 63.727 25.8146C66.7304 30.3095 68.3335 35.5941 68.3335 41.0002C68.3335 48.2494 65.4538 55.2018 60.3278 60.3277C55.2018 65.4537 48.2494 68.3335 41.0002 68.3335Z" fill={ color === 'green' ? '#2ED588' : "#EB5757"}/>
                <path d="M41.0002 58.0833C42.8871 58.0833 44.4168 56.5536 44.4168 54.6667C44.4168 52.7797 42.8871 51.25 41.0002 51.25C39.1132 51.25 37.5835 52.7797 37.5835 54.6667C37.5835 56.5536 39.1132 58.0833 41.0002 58.0833Z" fill={ color === 'green' ? '#2ED588' : "#EB5757"}/>
                <path d="M41.0002 23.9165C40.094 23.9165 39.225 24.2765 38.5842 24.9172C37.9435 25.558 37.5835 26.427 37.5835 27.3332V44.4165C37.5835 45.3227 37.9435 46.1917 38.5842 46.8325C39.225 47.4732 40.094 47.8332 41.0002 47.8332C41.9063 47.8332 42.7754 47.4732 43.4161 46.8325C44.0569 46.1917 44.4168 45.3227 44.4168 44.4165V27.3332C44.4168 26.427 44.0569 25.558 43.4161 24.9172C42.7754 24.2765 41.9063 23.9165 41.0002 23.9165Z" fill={ color === 'green' ? '#2ED588' : "#EB5757"}/>
            </svg>
        </div>
    )
}

export default RedAlert;