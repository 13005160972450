import { useMediaQuery } from '@mui/material';
import React from 'react';

const Step3Icon = ({ color }) => {

    const matches = useMediaQuery('(min-width:600px)');

    return (
        <svg width={ matches ? "32" : "26" } height={ matches ? "32" : "26" } viewBox="0 0 32 28" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M13.7342 3.22008L23.9415 13.4295L12.276 25.095L2.06662 14.8876L13.7342 3.22008ZM12.276 1.76189L0.606375 13.4295C-0.202125 14.2359 -0.202125 15.5394 0.606375 16.3458L10.8158 26.5531C11.6222 27.3616 12.9236 27.3616 13.7321 26.5531L25.3976 14.8876C26.2041 14.0812 26.2041 12.7777 25.3976 11.9713L15.1924 1.76189C14.3859 0.955453 13.0804 0.955453 12.276 1.76189ZM0.606375 16.3458L10.8158 26.5531C11.6222 27.3616 12.9236 27.3616 13.7321 26.5531L25.3976 14.8876C25.7998 14.4855 26.004 13.9575 26.0019 13.4295H0.606375C-0.200063 14.2338 -0.200063 15.5394 0.606375 16.3458ZM3.52275 0.301641C3.12056 0.705891 3.12056 1.35764 3.52275 1.75983L13.0041 11.2411C13.4062 11.6433 14.0601 11.6433 14.4622 11.2411C14.8644 10.839 14.8644 10.1851 14.4622 9.78295L4.98094 0.301641C4.57875 -0.100547 3.927 -0.100547 3.52275 0.301641ZM26.8641 14.867C26.8641 14.867 23.0711 20.7225 22.7391 23.117C22.1719 27.2028 24.8016 27.6834 26.8641 27.6834C28.9266 27.6834 31.3912 27.2235 30.9891 23.117C30.7725 20.9163 26.8641 14.867 26.8641 14.867Z" fill={ color }/>
        </svg>
    )
}

export default Step3Icon;