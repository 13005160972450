const mxJSON = {
    // NAVBAR
    "Home":"Inicio",
    "Users":"Asesores",
    "User1":"Mis asesores",
    "User2":"Crear asesor",
    "Chat":"Chat",
    "Help":"Ayuda",
    "Advanced": "Avanzado",
    "MyCount": "Mi cuenta",
    "Integrations": "Integraciones",
    "Potenciate": "Potenciate",
    "Logout": "Cerrar sesión",
    "Synchronization": "Sincronización",
    "Billings": "Facturación",
    "Reviews": "Reseñas",
};

export default mxJSON;