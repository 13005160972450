import React from 'react';

// Material Ui
import { useMediaQuery } from '@mui/material';

const Step1Icon = ({ color }) => {

    const matches = useMediaQuery('(min-width:600px)');

    return (
        <svg width={ matches ? "37" : "31" } height={ matches ? "37" : "31" } viewBox="0 0 37 37" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M4.625 10.7918V7.7085H24.6667V10.7918M15.4167 7.7085V29.2918M18.5 29.2918H12.3333M23.125 20.0418V18.5002H32.375V20.0418M27.75 18.5002V29.2918M26.2083 29.2918H29.2917" stroke={ color } strokeWidth="3.08333" strokeLinecap="round" strokeLinejoin="round"/>
        </svg>
    )
}

export default Step1Icon;