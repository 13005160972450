import { Box, Typography } from '@mui/material'
import React from 'react'
import { useParams } from 'react-router-dom'
import { UserPasswordResendEmail } from '../components/UserPasswordResendEmail';

export const PasswordRecoverySentComponent = () => {
    const params = useParams();
    return (
        <>
            <Typography variant={'h4'} sx={{ fontSize: '1.5rem', fontWeight: 400 }}>
                ¡Revise su email!
            </Typography>
            <Typography variant={'subtitle1'} sx={{ fontSize: '0.9rem', fontWeight: 400 }}>
                Por favor revise la dirección de correo electrónico {params.email} y busque un email conteniendo instrucciones acerca de como restablecer su contraseña.
            </Typography>
            <Box
                display={'flex'}
                flexDirection={'column'}
                justifyContent={'center'}
                alignItems={'flex-start'}
                gap={1.5}
                sx={{
                    width: '100%',
                    padding: '5px 20px',
                }}
            >
                <UserPasswordResendEmail
                    email={params.email}
                />
            </Box>
        </>
    )
}
