import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { connect } from 'react-redux';
import { useFetch } from 'use-http';
import axios from 'axios';

// Actions
import { 
    setOnBoardingChatValue, 
    setCompleteStepChat, 
    setActiveStepChat, 
    setShowErrorChat, 
    setShowModalChat, 
    setEditingChat,
    onSetLeaveSaveChangesChat, 
} from '../../actions/stepsChat';
import { onSetOnBoardingChatComplete, setTaskState } from '../../actions/tasks';

// Configuration
import { MAIN_API_URL } from '../../configuration';

// Icons
import ArrowBackRoundedIcon from '@mui/icons-material/ArrowBackRounded';

// Material Ui
import { Box, Button, Stack } from '@mui/material';

const buttonsContainer = {
    justifyContent: 'space-between',
    padding: '1rem 0rem',
    maxWidth: '630px',
    display: 'flex',
    margin: 'auto',
    width: '100%',
};

const nextButtonStyles = {
    backgroundColor: '#2ED588',
    textTransform: 'none',
    fontFamily: 'Poppins',
    borderRadius: '8px',
    boxShadow: 'none',
    // display: 'none',
    color: 'white',
    ':hover': {
        backgroundColor: '#2ED588',
        boxShadow: 'none',
        color: 'white',
        '@media(min-width: 800px)': {
            backgroundColor: '#B2F1D4',
            color: '#2ED588',
        },
    },
    '@media(min-width: 400px)': {
        display: 'flex',
    },
};

const editButtonStyles = {
    border: '1px solid #2ED588',
    backgroundColor: '#FFFFFF',
    textTransform: 'none',
    fontFamily: 'Poppins',
    borderRadius: '8px',
    boxShadow: 'none',
    color: '#2ED588',
    ':hover': {
        backgroundColor: '#B2F1D4',
        boxShadow: 'none',
        color: '#2ED588',
    },
};

const ButtonsContainerChat = (props) => {

    // Actions
    const { 
        onSetLeaveSaveChangesChat,
        setOnBoardingChatValue, 
        setOnboardingChatCompleted,
        activeStepChatAction, 
        setShowErrorChat, 
        setShowModalChat, 
        setEditingChat, 
        setTaskState,
    } = props;

    // States
    const {
        onBoardingFinished,
        completedStepChat,
        onBoardingChat,
        activeStepChat, 
        backImgChat,
        activeTask,
        questions,
        titleChat, 
        description,
        backColor,
        colorFont,
        urlImg,
    } = props;

    const [disabledNextChat, setDisabledNextChat] = useState(false);
    const [disabledBack, setDisabledBack] = useState(false);
    const [objectPOST, setObjectPOST] = useState({});
    const navigate = useNavigate();

    const backButtonStyles = {
        backgroundColor: 'transparent',
        textTransform: 'none',
        fontFamily: 'Poppins',
        alignItems: 'center',
        borderRadius: '8px',
        color: '#BDBDBD',
        display: 'flex',
        opacity: activeStepChat === 0 ? 0 : 1,
        ':hover': {
            backgroundColor: '#B2F1D4',
            boxShadow: 'none',
            color: '#2ED588',
        },
    };

    const { error, put, response } = useFetch(MAIN_API_URL, {
        headers: {
            "x-token": `${localStorage.getItem("token")}`
        }
    });

    const { error: errorGet, response: responseGet, get , put: putStore} = useFetch(`${MAIN_API_URL}/stores`, {
        headers: {
            "x-token": `${localStorage.getItem("token")}`
        }
    });

    useEffect(() => { getStore(); }, []);
    
    const getStore = async() => {
        const resp = await get("/");
        if (responseGet.ok) {
            setOnBoardingChatValue({
                onBoardingFinished: resp.onBoardingFinished,
                onBoardingChat: resp.onBoardingChat,
            });
        } else {
            console.log(errorGet);
        }
    }

    // Chat Validation Form
    useEffect(() => {
        switch (activeStepChat) {
            case 0:
                setShowModalChat(false);
                if (titleChat === '') {
                    setDisabledNextChat(true);
                } else {
                    setDisabledNextChat(false);
                }
                break;
            case 3:
                setShowModalChat(true);
                break;
        
            default:
                setShowModalChat(false);
                break;
        }
    }, [activeStepChat, titleChat, setShowModalChat]);

    const handleEditChat = async () => {

        let data = {
            "title": titleChat,
            "backgroundImg": backImgChat,
            "description": description,
            "headerColour": backColor,
            "chatColour": backImgChat,
            "botonColour": backColor,
            "fontColour": colorFont,
            "iconColour": colorFont,
            "urlImg": urlImg,
        }

        const resp = await put("/chat/", data);

        if(response.ok){
            setObjectPOST(data);
            
            if (!onBoardingChat) {
                setShowModalChat(true);
                
                setTaskState({ number: (activeTask + 1), data: 'active' });
                setTaskState({ number: (activeTask), data: 'completed' });
                onSetLeaveSaveChangesChat(true);
                putStore({ onBoardingChat: true });
                setOnboardingChatCompleted(true);
            } 
            else {
                navigate("/home");
            }

        } else {
            setShowErrorChat(true);
            console.log(error);
        }
        const { data: respAnswers } = await axios.put(`${MAIN_API_URL}/answers/`, questions, {
            headers: {
                "x-token": `${localStorage.getItem("token")}`
                }
        });

        if(!respAnswers.msg){
            console.log(error);
        }
    }

    const handleChangeStep = async (type) => {
        setEditingChat( true );
        if (activeStepChat >= 0) {
            if (type === 'next') {
                if (activeStepChat <= 3) {
                    if ( activeStepChat === 2 ) {
                        setEditingChat( false );
                        // let arrayQuestions = parseQuestionsAndAnswers(questionsActiveArray, idsQuestionsMongo, questionActiveValue);

                        handleEditChat();
                    } else {
                        let step = activeStepChat + 1;
                        activeStepChatAction(step);
                    }
                } else {
                    return;
                }
            } else {
                let step = activeStepChat - 1;
                let reduxC = completedStepChat;
                reduxC[step] = false;
                activeStepChatAction(step);
            }
        } else {
            return;
        }
    }

    useEffect(() => {
        if (activeStepChat > 0) {
            setDisabledBack(false);
        } else {
            setDisabledBack(true);
        }
    }, [ activeStepChat ]);

    const handleComplete = (e) => {
        e.preventDefault();

        let reduxChat = completedStepChat;
        reduxChat[activeStepChat] = true;

        handleChangeStep('next');

    };
    
    return (
        <Box sx={buttonsContainer}>
            <Button 
                sx={backButtonStyles} 
                onClick={() => handleChangeStep('back')}
                disabled={ disabledBack }
            >
                <ArrowBackRoundedIcon fontSize="small" />
                &nbsp; Volver
            </Button>
            <Stack direction="row" alignItems="center" spacing={3}>
                {
                    ( onBoardingFinished && onBoardingChat ) ? 
                        <Button 
                            onClick={ handleEditChat }
                            disabled={ disabledNextChat } 
                            sx={editButtonStyles} 
                            variant="contained"
                        >
                            Guardar
                        </Button>
                    :
                        null
                }
                <Button 
                    onClick={(e) => handleComplete(e) }
                    disabled={ disabledNextChat } 
                    sx={nextButtonStyles} 
                    variant="contained"
                >
                    { ( activeStepChat === 2 ) ? 'Finalizar' : 'Siguiente' }
                </Button>
            </Stack>
        </Box>
    )
}

const mapStateToProps = state => {
    return {
        // States Chat
        onBoardingFinished: state.stepsChat.onBoardingFinished,
        onBoardingChat: state.tasks.onBoardingChat,
        backImgChat: state.stepsChat.backImgChat,
        titleChat: state.stepsChat.titleChat,
        backColor: state.stepsChat.backColor,
        colorFont: state.stepsChat.colorFont,
        urlImg: state.stepsChat.urlImg,

        typeWelcomeMessage: state.stepsChat.typeWelcomeMessage,
        showModalComplete: state.stepsChat.showModalComplete,
        completedStepChat: state.stepsChat.completeStepChat,
        activeStepChat: state.stepsChat.activeStepChat,
        description: state.stepsChat.description,
        questionsActiveArray: state.stepsChat.questionsActiveArray,
        questionActiveValue: state.stepsChat.questionActiveValue,
        idsQuestionsMongo: state.stepsChat.idsQuestionsMongo,
        questions: state.stepsChat.activeTemplate.answers,
        
        activeTask: state.tasks.activeTask,
        
    }
}

const mapDispatchToProps = dispatch => {
    return {
        onSetLeaveSaveChangesChat: (data) => dispatch(onSetLeaveSaveChangesChat(data)),
        setOnBoardingChatValue: (data) => dispatch(setOnBoardingChatValue(data)),
        activeStepChatAction: (value) => dispatch(setActiveStepChat(value)),
        completeStepChat: (value) => dispatch(setCompleteStepChat(value)),
        setShowModalChat: (data) => dispatch(setShowModalChat(data)),
        setShowErrorChat: (data) => dispatch(setShowErrorChat(data)),
        setEditingChat: (data) => dispatch(setEditingChat(data)),

        setTaskState: ({ number, data}) => dispatch(setTaskState({ number, data})),
        setOnboardingChatCompleted: (data) => dispatch(onSetOnBoardingChatComplete(data)),
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(ButtonsContainerChat);

// function parseQuestionsAndAnswers(questions, ids, values) {
//     let newQuestion = {};
//     let finalArray = [];

//     for (let i = 0; i < questions.length; i++) {
//         // console.log( 'UIDs --> ', ids[i].uid );
//         newQuestion = questions[i];
//         newQuestion.state = values[i];
//         newQuestion.uid = ids[i].uid;
        
//         finalArray.push(newQuestion);
//     }

//     return finalArray;
// }
