import React from 'react';

const ChatIcon = () => {
    return (
        <svg width="43" height="25" viewBox="0 0 53 29" fill="none" xmlns="http://www.w3.org/2000/svg" style={{ marginBottom: '-.2rem' }}>
            <path d="M0.772374 14.4326C0.772374 6.88828 6.88827 0.772374 14.4326 0.772374H36.3811C44.8707 0.772374 51.7529 7.65456 51.7529 16.1442V28.0929H14.4326C6.88828 28.0929 0.772374 21.977 0.772374 14.4326Z" fill="white" stroke="#2ED588" strokeWidth="1.54475"/>
            <circle cx="2.83533" cy="2.83533" r="2.83533" transform="matrix(-1 0 0 1 38.7969 11.3564)" fill="#2ED588"/>
            <circle cx="2.83533" cy="2.83533" r="2.83533" transform="matrix(-1 0 0 1 29.3418 11.3564)" fill="#D3D3D3"/>
            <circle cx="2.83533" cy="2.83533" r="2.83533" transform="matrix(-1 0 0 1 19.8867 11.3564)" fill="#D3D3D3"/>
        </svg>
    )
}

export default ChatIcon;