import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { useFetch } from 'use-http';

// Configurations
import { MAIN_API_URL } from '../../../../configuration';

// Icons
import ArrowForwardIcon from '@mui/icons-material/ArrowForward';
import LockOutlinedIcon from '@mui/icons-material/LockOutlined';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';

// Material Ui
import { Box, Button } from '@mui/material';

const CardButton = ({ state, task, handleShowCongratsModal }) => {

    const [origin, setOrigin] = useState('');

    const navigate = useNavigate();

    const ButtonStyles = {
        backgroundColor: state === 'completed' ? 'transparent' : state === 'active' ? '#2ED588' : '#E4E4E4',
        animation: state === 'active' ? 'pulseCardAnimation 1.5s infinite' : 'none',
        color: state === 'completed' ? '#2ED588' : 'white',
        fontFamily: 'Poppins',
        textTransform: 'none',
        alignItems: 'center',
        borderRadius: '6px',
        fontSize: '0.8rem',
        fontWeight: '500',
        display: 'flex',
        width: '100%',
        gap: '5px',
        '@keyframes pulseCardAnimation': {
            '0%': {
                transform: 'scale(1, 1)',
                color: 'white',
            },
            '25%': {
                transform: 'scale(1, 1)',
                color: 'white',
            },
            '50%': {
                transform: 'scale(1.03, 1.03)',
                color: 'white',
                width: '100%',
            },
            '75%': {
                transform: 'scale(1, 1)',
                color: 'white',
            }
        },
        ':hover': {
            backgroundColor: state === 'completed' ? 'transparent' : state === 'active' ? '#2ED588' : '#E4E4E4',
            color: state === 'completed' ? '#2ED588' : 'white',
        },
        ':disabled': {
            backgroundColor: state === 'completed' ? 'transparent' : state === 'active' ? '#2ED588' : '#E4E4E4',
            color: state === 'completed' ? '#2ED588' : 'white',
        },
        '@media(min-width: 800px)': {
            gap: '10px',
        }
    }

    const { error, get, response } = useFetch(`${MAIN_API_URL}`, {
        headers: {
            "x-token": `${localStorage.getItem("token")}`
        }
    });

    useEffect(() => { getStore(); }, []);

    const getStore = async () => {
        const resp = await get("/stores");
        if( response.ok ) {
            // setOrigin(resp.origin ?? 'Particular');
            setOrigin(localStorage.getItem('origin') ?? 'Particular');
        } else {
            console.log(error);
        }
    }

    // const ButtonLinkStyles = {
    //     backgroundColor: state === 'completed' ? 'transparent' : state === 'active' ? '#2ED588' : '#E4E4E4',
    //     color: state === 'completed' ? '#2ED588' : 'white',
    //     pointerEvents: state === 'active' ? '' : 'none',
    //     justifyContent: 'center',
    //     textDecoration: 'none',
    //     fontFamily: 'Poppins',
    //     textTransform: 'none',
    //     fontSize: '0.875rem',
    //     alignItems: 'center',
    //     borderRadius: '6px',
    //     padding: '6px 0px',
    //     fontWeight: '500',
    //     display: 'flex',
    //     width: '100%',
    //     gap: '10px'
    // }

    const handleRedirectButton = () => {
        switch (task) {
            case 2:
                navigate('/chat');
                break;
            case 3:
                navigate('/createUser');
                break;
            case 4:
                if ( origin === "Particular" ) {
                    navigate('/integrations');
                } else {
                    handleShowCongratsModal();
                }
                break;
            default:
                break;
        }
    }
    
    return (
        <Box sx={{ width: '100%' }}>
            <Button sx={ ButtonStyles } disabled={ state === 'active' ? false : true } onClick={ handleRedirectButton }>
                {
                    state === 'completed' ? 'Completada' : 'Comenzar'
                }
                {
                    state === 'completed' ?
                        <CheckCircleIcon 
                            sx={{ 
                                fontSize: '1.2rem',
                                color: '#2ED588',
                                '@media(min-width: 800px)': {
                                    fontSize: '1.5rem',
                                } 
                            }}
                        />
                    : state === 'active' ?
                        <ArrowForwardIcon 
                            sx={{
                                fontSize: '1.2rem', 
                                color: 'white',
                                '@media(min-width: 800px)': {
                                    fontSize: '1.5rem',
                                }  
                            }}
                        />
                    :   
                        <LockOutlinedIcon 
                            sx={{
                                fontSize: '1.2rem', 
                                color: 'white',
                                '@media(min-width: 800px)': {
                                    fontSize: '1.5rem',
                                }  
                            }}
                        />
                }
            </Button>
        </Box>
    )
}

export default CardButton;