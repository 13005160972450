import React from 'react';

// Material Ui
import { IconButton, Snackbar } from '@mui/material';

// Icon
import CloseIcon from '@mui/icons-material/Close';

const BannerCreateUser = ({ handleClose, open, success, type, error }) => {

    const snackbarStyles = {
        backgroundColor: success ? '#2ED588' : error ? '#FF7474' : '#2ED588',
        borderRadius: '8px',
        color: 'white',
        '.MuiPaper-root': {
            backgroundColor: success ? '#2ED588' : error ? '#FF7474' : '#2ED588',
            fontFamily: 'Poppins',
            borderRadius: '8px',
            fontWeight: '500'
        }
    }

    const action = (
        <React.Fragment>
            <IconButton
                size="small"
                aria-label="close"
                color="inherit"
                onClick={ handleClose }
                sx={{ 
                    backgroundColor: success ? '#14C774' : error ? '#EB5757' : '#2ED588' }}
            >
                <CloseIcon fontSize="small" />
            </IconButton>
        </React.Fragment>
    );

    return (
        <Snackbar
            open={ open }
            autoHideDuration={ 3000 }
            anchorOrigin={{ 
                vertical: "bottom", 
                horizontal: "right" 
            }}
            onClose={ handleClose }
            message={ 
                success ? 
                    type === 'congrats' ? 
                        'El asesor fue creado con éxito' 
                    : 
                        'Se guardaron tus cambios' 
                : 
                    error ? 
                        "Error" 
                    : 
                        type === 'congrats' ? 
                            'El asesor fue creado con éxito' 
                        : 
                            'Se guardaron tus cambios' 
            }
            action={ action }
            sx={ snackbarStyles }
        />
    )
}

export default BannerCreateUser;