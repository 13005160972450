import { createStore, combineReducers, applyMiddleware, compose } from 'redux';

import { integrationsReducer } from '../reducers/integrationsReducer';
import { stepsUserReducer } from '../reducers/stepsUsersReducer';
import { stepsChatReducer } from '../reducers/stepsChatReducer';
import { usersReducer } from '../reducers/usersReducer';
import { tasksReducer } from '../reducers/taskReducer';
import { authReducer } from '../reducers/authReducer';

import thunk from 'redux-thunk';

const reducers = combineReducers({
    integrations: integrationsReducer,
    stepsChat: stepsChatReducer,
    stepsUser: stepsUserReducer,
    employees: usersReducer,
    tasks: tasksReducer,
    auth: authReducer,
});

const composeEnhancers = window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ || compose;

export const store = createStore(
    reducers, composeEnhancers(applyMiddleware(thunk))
);