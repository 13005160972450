import Card from '@mui/material/Card';
import CardMedia from '@mui/material/CardMedia';
import CardContent from '@mui/material/CardContent';
import CardActions from '@mui/material/CardActions';
import Collapse from '@mui/material/Collapse';
import Avatar from '@mui/material/Avatar';
import IconButton from '@mui/material/IconButton';
import Typography from '@mui/material/Typography';
import { red } from '@mui/material/colors';
import MoreVertIcon from '@mui/icons-material/MoreVert';
import { GeneralHeader } from './Header/General';
import './styles.css'
import { useEffect, useRef, useState } from 'react';
import { PartnerGenericMessage, ClientGenericMessage, ClientSelectedMessage } from './Messages/General';
import { Box } from '@mui/material';
import { ClientOptions } from './Messages/ClientOptions';
import { PartnerEmployees } from './Messages/PartnerEmployees';
import { EmployeeHeader } from './Header/Employee';
import useFetch from 'use-http';
import { getEmployeeRigthMessage, isAnyWorkingEmployee, isWorkingEmployee, trueAnswers, trueEmployees } from '../../helpers/chat';
import { Loading } from './Messages/Loading';
import { SendMessageInput } from './Inputs/SendMessageInput';
import { EmployeesChannels } from './Messages/EmployeesChannels';
import { MAIN_API_URL } from '../../configuration';

const CHAT_MESSAGES_OPTIONS = {
    PARTNER_MESSAGE: 'partner',
    USER_MESSAGE: 'user',
    USER_SELECTED_MESSAGE: 'user-focus',
    OPTIONS_MESSAGE: 'options',
    EMPLOYEE_OPTIONS_MESSAGE: 'employeeOptions',
    LOADING: 'loading',
    EMPLOYEE_CHANNELS_OPTIONS: 'employeeChannelsOptions',
}

const ChatComponentSelector = ({ type, data, chat, handleAnswer, handleTalkToEmployees, handleTalkEmployee, setEmployeePage, employeePage }) => {
    switch (type) {
        case CHAT_MESSAGES_OPTIONS.PARTNER_MESSAGE:
            return <PartnerGenericMessage message={data} {...chat} />
        case CHAT_MESSAGES_OPTIONS.USER_MESSAGE:
            return <ClientGenericMessage message={data} {...chat} />
        case CHAT_MESSAGES_OPTIONS.USER_SELECTED_MESSAGE:
            return <ClientSelectedMessage message={data} {...chat} />
        case CHAT_MESSAGES_OPTIONS.OPTIONS_MESSAGE:
            return <ClientOptions
                answers={data.answers}
                hasEmployees={data.hasEmployees}
                chat={chat}
                handleAnswer={handleAnswer}
                handleTalkToEmployees={handleTalkToEmployees}
            />
        case CHAT_MESSAGES_OPTIONS.EMPLOYEE_OPTIONS_MESSAGE:
            return <PartnerEmployees
                employees={data}
                chat={chat}
                handleTalkEmployee={handleTalkEmployee}
            />
        case CHAT_MESSAGES_OPTIONS.LOADING:
            return <Loading {...chat} />
        case CHAT_MESSAGES_OPTIONS.EMPLOYEE_CHANNELS_OPTIONS:
            return <EmployeesChannels
                employee={data}
                {...chat}
                setEmployeePage={setEmployeePage}
                employeePage={employeePage}
            />
        default:
            return <PartnerGenericMessage message={data} {...chat} />
    }
}


export default function Chat() {

    const { get, loading, response } = useFetch(MAIN_API_URL);

    //Inicializo los estados
    const [chat, setChat] = useState([]);
    const [answers, setAnswers] = useState([]);
    const [employees, setEmployees] = useState([]);
    const [talkToEmplopoyees, setTalkToEmployees] = useState(false);
    const [employeePage, setEmployeePage] = useState({
        active: false,
        employee: null,
        chat: [],
        inputChat: {
            show: false,
            channel: 'whatsapp',
        },
    });
    const bottomRef = useRef(null);

    const [chatHistory, setChatHistory] = useState([]);

    /**
     * Query params para obtener el chat
     * @param {string} storeId: Es el id de la tienda
     * @param {string} origin: Es el origen de la tienda para saber que chat mostrar
     */
    const urlParams = new URLSearchParams(window.location.search);
    const chatId = urlParams.get('storeId')?? '5123321232325';
    const origin = urlParams.get('origin') ?? 'Tiendanube';

    useEffect(() => {
        async function getChat() {
            const data = await get('/fullchat/' + chatId + '?origin=' + origin);
            if (response.ok) {
                setAnswers(trueAnswers(data.answers));
                setEmployees(trueEmployees(data.employees));
                setChat(data.chat);
                setChatHistory((chatHistory) =>
                    [...chatHistory,
                    {
                        type: CHAT_MESSAGES_OPTIONS.PARTNER_MESSAGE,
                        data: data.chat.description,
                    }
                    ]);
            }
        }
        getChat();
    }, []);

    const handleAnswer = (answer) => {
        chatHistory.pop();
        setChatHistory((chatHistory) =>
            [...chatHistory,
            {
                type: CHAT_MESSAGES_OPTIONS.USER_MESSAGE,
                data: answer.question,
            }
            ]);
        bottomRef.current?.scrollIntoView({ behavior: 'smooth' });
        setTimeout(() => {
            setChatHistory((chatHistory) =>
                [...chatHistory,
                {
                    type: CHAT_MESSAGES_OPTIONS.LOADING,
                    data: null,
                }
                ]);
            bottomRef.current?.scrollIntoView({ behavior: 'smooth' });
        }, 500);
        setTimeout(() => {
            setChatHistory((chatHistory) =>
                [...chatHistory,
                {
                    type: CHAT_MESSAGES_OPTIONS.PARTNER_MESSAGE,
                    data: answer.reply,
                },
                ]);
            bottomRef.current?.scrollIntoView({ behavior: 'smooth' });
            window.parent.postMessage(`stat${answer.uid}`, '*');
            setAnswers(answers.filter((a) => a.uid !== answer.uid));
        }, 3000);
    }

    const handleTalkToEmployees = () => {
        chatHistory.pop();
        setChatHistory((chatHistory) =>
            [...chatHistory,
            {
                type: CHAT_MESSAGES_OPTIONS.USER_SELECTED_MESSAGE,
                data: 'Hablar con un asesor',
            }
            ]);
        bottomRef.current?.scrollIntoView({ behavior: 'smooth' });
        setTimeout(() => {
            setChatHistory((chatHistory) =>
                [...chatHistory,
                    {
                        type: CHAT_MESSAGES_OPTIONS.EMPLOYEE_OPTIONS_MESSAGE,
                        data: employees,
                    }
                ]);
            bottomRef.current?.scrollIntoView({ behavior: 'smooth' });
        }, 500);
        setTimeout(() => {
            setTalkToEmployees(!talkToEmplopoyees);
        }, 3000);
    }

    const handleTalkEmployee = (employee) => {
        setEmployeePage({
            active: true,
            employee: employee,
            chat: [{
                type: CHAT_MESSAGES_OPTIONS.PARTNER_MESSAGE,
                data: getEmployeeRigthMessage(employee),
            },
            {
                type: CHAT_MESSAGES_OPTIONS.EMPLOYEE_CHANNELS_OPTIONS,
                data: employee,
            }
            ],
            inputChat: {
                show: false,
                channel: 'whatsapp',
            },
        });
        window.parent.postMessage(`empl${employee.uid}`, '*');
    }

    const goBackToMainChat = () => {
        setEmployeePage({
            active: false,
            employee: null,
            chat: [],
            inputChat: {
                show: false,
                channel: 'whatsapp',
            },
        });
    }

    useEffect(() => {
        const chat2 = chatHistory.filter((c) => c.type !== CHAT_MESSAGES_OPTIONS.LOADING);
        setChatHistory(chat2);
        setTimeout(() => {
            setChatHistory((chatHistory) =>
                [...chatHistory,
                {
                    type: CHAT_MESSAGES_OPTIONS.OPTIONS_MESSAGE,
                    data: {
                        answers: answers,
                        hasEmployees: isAnyWorkingEmployee(employees)
                    },
                }]);
        }, 4000);
    }, [answers, talkToEmplopoyees]);

    useEffect(() => {
        bottomRef.current?.scrollIntoView({ behavior: 'auto' });
    }, [employeePage.active]);



    return (
        <Box
            sx={{
                borderRadius: '14px',
                boxShadow: 2,
                height: '100vh',
                width: '100vw',
                background: '#FFF',
                backgroundImage: chat?.backgroundImg ? `url(${chat.backgroundImg})` : 'none',
            }}
        >
            {employeePage.active ?
                <EmployeeHeader
                    employee={employeePage.employee}
                    {...chat}
                    goBackToMainChat={goBackToMainChat}
                />
                : <GeneralHeader {...chat} employees={employees} />}
            <Box
                sx={{
                    padding: '6px 20px',
                    display: 'flex',
                    flexDirection: 'column',
                    gap: '5px',
                }}
            >
                <Typography
                    variant="body2"
                    sx={{ color: '#393939' }}
                    textAlign={'right'}
                    fontWeight={400}
                    fontSize={14}
                >
                    Chat desarrollado por <span style={{ fontWeight: 600 }}>WhatsPRO</span>
                </Typography>
                <Box
                    display={'flex'}
                    flexDirection={'column'}
                    gap={'17px'}
                    className="chat-frame"
                    sx={{
                        overflowY: 'scroll',
                        height: 'calc(100vh - 160px)',
                    }}
                >
                    {

                        !employeePage.active
                            ? chatHistory.map((message, index) => (
                                <ChatComponentSelector
                                    key={index}
                                    type={message.type}
                                    data={message.data}
                                    chat={chat}
                                    handleAnswer={handleAnswer}
                                    handleTalkToEmployees={handleTalkToEmployees}
                                    handleTalkEmployee={handleTalkEmployee}
                                />
                            ))
                            : employeePage.chat.map((message, index) => (
                                <ChatComponentSelector
                                    key={index}
                                    type={message.type}
                                    data={message.data}
                                    chat={chat}
                                    setEmployeePage={setEmployeePage}
                                    employeePage={employeePage}
                                />
                            ))
                    }
                    <Box marginTop={30}></Box>
                    <Box marginTop={2} ref={bottomRef}></Box>
                    {employeePage.inputChat.show &&
                        <SendMessageInput
                        employeePage={employeePage}
                            {...chat}
                        />
                    }
                </Box>
            </Box>
        </Box>
    );
}