import React from 'react';

const GiftStepperTask = ({ backColor, giftColor, active }) => {
    return (
        <svg style={{ border: active ? '1px solid #2ED588' : 'none', borderRadius: '13px' }} width="46" height="46" viewBox="0 0 46 46" fill="none" xmlns="http://www.w3.org/2000/svg">
            <rect width="46" height="46" rx="11.1892" fill={ backColor }/>
            <path d="M24.4746 26.0299V38.7866H34.9692C36.333 38.7866 37.4394 37.6811 37.4394 36.3162L37.4396 26.0298L24.4746 26.0299Z" fill={ giftColor }/>
            <path d="M20.7627 26.03H7.80273V36.3172C7.80273 37.682 8.90914 38.7876 10.2732 38.7876H20.7628L20.7627 26.03ZM11.1702 36.1948C10.658 36.1948 10.2427 35.7793 10.2427 35.2673V30.1201C10.2427 29.6082 10.6583 29.1926 11.1702 29.1926C11.6822 29.1926 12.0978 29.6081 12.0978 30.1201V35.2673C12.0978 35.7792 11.6822 36.1948 11.1702 36.1948Z" fill={ giftColor }/>
            <path d="M35.7718 16.3559H31.1321C31.7726 16.0485 32.3097 15.731 32.6345 15.4065C34.5454 13.4958 34.5454 10.3963 32.6345 8.48507C31.6776 7.52962 30.425 7.052 29.1727 7.052C27.9202 7.052 26.6673 7.5296 25.7118 8.48507C24.9184 9.27876 24.1549 11.238 23.5314 13.2569C23.2405 13.177 22.9407 13.1211 22.6257 13.1211C22.3116 13.1211 22.012 13.177 21.7222 13.2563C21.0965 11.219 20.326 9.23349 19.5251 8.43307C18.5696 7.47762 17.3181 7 16.0642 7C14.8116 7 13.559 7.4776 12.6033 8.43307C10.6923 10.3449 10.6923 13.4439 12.6033 15.3545C12.9451 15.698 13.521 16.0336 14.2121 16.3559H9.46908C8.10533 16.3559 7 17.4614 7 18.8252V20.9265C7 22.2902 8.10498 23.3956 9.46908 23.3956H20.7635V16.454H24.4742V23.3953H35.7723C37.136 23.3953 38.2413 22.2903 38.2413 20.9262V18.8249C38.2411 17.4614 37.1358 16.3559 35.772 16.3559L35.7718 16.3559ZM27.4614 10.2343C27.918 9.77737 28.5267 9.52557 29.1728 9.52557C29.82 9.52557 30.4279 9.77737 30.8856 10.2343C31.3422 10.6912 31.5935 11.2991 31.5935 11.9457C31.5935 12.5924 31.3422 13.2002 30.8881 13.6544C30.377 14.1365 28.3757 14.8876 25.9017 15.5872C25.8318 15.3474 25.7373 15.1202 25.6177 14.9053C26.2918 12.5771 26.9996 10.7222 27.4614 10.2343ZM14.3526 10.1823C14.8092 9.72567 15.4179 9.47387 16.0643 9.47387C16.7116 9.47387 17.3194 9.72511 17.7746 10.1809C18.2399 10.673 18.9559 12.5522 19.6347 14.9044C19.5224 15.1061 19.4332 15.3188 19.3631 15.5427C16.8773 14.8421 14.8634 14.0872 14.3526 13.6054C13.4094 12.6617 13.4094 11.1264 14.3526 10.1823Z" fill={ giftColor }/>
        </svg>
    )
}

export default GiftStepperTask;