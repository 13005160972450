import React from 'react';

const PhoneIcon = () => {
    return (
        <svg width="27" height="27" viewBox="0 0 27 27" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M18.5625 1.6875C19.0101 1.6875 19.4393 1.86529 19.7557 2.18176C20.0722 2.49822 20.25 2.92745 20.25 3.375V23.625C20.25 24.0726 20.0722 24.5018 19.7557 24.8182C19.4393 25.1347 19.0101 25.3125 18.5625 25.3125H8.4375C7.98995 25.3125 7.56073 25.1347 7.24426 24.8182C6.92779 24.5018 6.75 24.0726 6.75 23.625V3.375C6.75 2.92745 6.92779 2.49822 7.24426 2.18176C7.56073 1.86529 7.98995 1.6875 8.4375 1.6875H18.5625ZM8.4375 0C7.54239 0 6.68395 0.355579 6.05101 0.988515C5.41808 1.62145 5.0625 2.47989 5.0625 3.375V23.625C5.0625 24.5201 5.41808 25.3785 6.05101 26.0115C6.68395 26.6444 7.54239 27 8.4375 27H18.5625C19.4576 27 20.316 26.6444 20.949 26.0115C21.5819 25.3785 21.9375 24.5201 21.9375 23.625V3.375C21.9375 2.47989 21.5819 1.62145 20.949 0.988515C20.316 0.355579 19.4576 0 18.5625 0L8.4375 0Z" fill="#393939"/>
            <path d="M13.5 23.625C13.9476 23.625 14.3768 23.4472 14.6932 23.1307C15.0097 22.8143 15.1875 22.3851 15.1875 21.9375C15.1875 21.4899 15.0097 21.0607 14.6932 20.7443C14.3768 20.4278 13.9476 20.25 13.5 20.25C13.0524 20.25 12.6232 20.4278 12.3068 20.7443C11.9903 21.0607 11.8125 21.4899 11.8125 21.9375C11.8125 22.3851 11.9903 22.8143 12.3068 23.1307C12.6232 23.4472 13.0524 23.625 13.5 23.625Z" fill="#393939"/>
        </svg>
    )
}

export default PhoneIcon;