import { typesIntegrations } from "../types/types";

const initialState = {
    site: 'Tiendanube',
    SelectedQuestion1: '',
    SelectedQuestion2: ''
}

export const integrationsReducer = ( state = initialState, action ) => {
    switch (action.type) {
        case typesIntegrations.activeSite: 
            return {
                ...state,
                site: action.payload
            }
        case typesIntegrations.setQuestionSelected: 
            return {
                ...state,
                SelectedQuestion1: action.payload.SelectedQuestion1 ?? state.SelectedQuestion1,
                SelectedQuestion2: action.payload.SelectedQuestion2 ?? state.SelectedQuestion2,
            }
        default:
            return state
    }
}