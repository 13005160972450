import React from 'react';
import { connect } from 'react-redux';
import CreatableSelect from 'react-select/creatable';

// Actions
import { setUserData } from '../../actions/stepsUser';

const SelectPersonalized = (props) => {
    
    // Actions
    const { setUserData } = props;

    // States
    const { position } = props;

    // External props
    const { options, canCreate, createString } = props;

    const handleChange = ( newValue ) => {
        if ( newValue.label !== null ) {
            setUserData({
                position: newValue
            })
        }
    };

    return (
        <CreatableSelect
            theme={(theme) => ({
                ...theme,
                borderRadius: 6,
                colors: {
                    ...theme.colors,
                    primary25: "#E3FFF2",
                    primary: "#2ED588"
                }
            })}
            placeholder='Seleccionar...'
            isClearable
            onChange={ handleChange }
            value={ position }
            options={ options }
            isValidNewOption={(e)=>{
                if ( !canCreate ) return false
                if (e === "" || e === " ") return false
                if (typeof e === 'string') {
                    return true
                } else {
                    return false
                }
            }}
            formatCreateLabel={(e)=>{
                return `${createString}: "${e}"`
            }}
            className={'CreatableSelect'}
        />
    );

}

const mapStateToProps = state => {
    return {
        position: state.stepsUser.userData.position,
    }
}

const mapDispatchToProps = dispatch => {
    return {
        setUserData: (value) => dispatch(setUserData(value)),
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(SelectPersonalized);