import React, { useState, useEffect } from 'react';
import { connect } from 'react-redux';

// Actions
import { changeProfilePhoto } from '../../../actions/stepsUser';

// Components
import SetPersonalizeImg from '../SetPersonalizeImg/SetPersonalizeImg';
import SaveButton from '../../SaveButton/SaveButton';

// Images
import SelectImage from '../../../assets/images/CreateUser/SelectImageIcon.png';
import Cross from '../../../assets/images/CreateUser/CrossModal.svg';
import Tick from '../../../assets/images/CreateUser/Tick.png';

// UsersImages
import { DefaulUsersImages } from './ImagesArray';

// Material Ui
import Backdrop from '@mui/material/Backdrop';
import { Fade, Modal } from '@mui/material';
import { Box } from '@mui/system';

// CSS
import Styles from './SelectPhotoModal.module.css';

const SelectPhotoModal = (props) => {

    // Actions
    const { changeProfileImg } = props;

    // States
    const { imgProfileId, imgsPersonalize, userEditing } = props;

    // External props
    const { open, handleClose } = props; 

    const [openModal, setOpenModal] = useState( false );
    const [totalLength, setTotalLength] = useState('');

    const style = {
        transform: 'translate(-50%, -50%)',
        border: '1px solid #BFBBBD',
        position: 'absolute',
        borderRadius: '10px',
        bgcolor: 'white',
        p: '1rem 1.5rem',
        height: '85vh',
        boxShadow: 24,
        width: '75%',
        left: '50%',
        top: '50%',
        "@media (min-width: 700px)": {
            height: 'auto',
        },
        "@media (min-width: 900px)": {
            width: 'auto',
        }
    };

    useEffect(() => {
        if ( open ) {
            setTimeout(() => {
                let tick = document.querySelector(`#tick-${imgProfileId}`);
                if (tick !== null) {
                    if (imgProfileId !== 11) {
                        for (let i = 0; i < DefaulUsersImages.length; i++) {
                            if (i !== imgProfileId) {
                                tick.style.display = "block";
                            } else {
                                tick.style.display = "none";
                            }
                        }
                    } else {
                        let tick = document.querySelector(`#tick-11`);
                        if (imgProfileId === 11) {
                            tick.style.display = "block";
                        } else {
                            tick.style.display = "none";
                        }
                    }
                } else {
                    return;
                }
            }, 100);
        }
    }, [open, imgProfileId]);
    
    const handleShowTick = (e) => {
        changeProfileImg({
            imgProfileId: e.target.id
        });

        for (let i = 0; i < totalLength; i++) {
            let icon = document.getElementById(`tick-${i}`);
            if ( icon !== null ) {
                icon.style.display = "none";
            } else {
                return;
            }
        }

        let tick = document.querySelector(`#tick-${e.target.id}`);
        tick.style.display = "block";
    }

    const handleChangeImg = (e) => {
        changeProfileImg({
            srcImgProfile: e,
        });
    }

    const handleChangeImgModal = () => {
        setOpenModal( !openModal );
    }

    useEffect(() => {
        setTotalLength( DefaulUsersImages.length + imgsPersonalize.length );
    }, [ imgsPersonalize ]);

    useEffect(() => {
        setTimeout(() => {
            if ( totalLength > 11 ) {
                for (let i = 0; i < ( totalLength - 1 ); i++) {
                    let icon = document.getElementById(`tick-${i}`);
                    if (icon !== null) {
                        icon.style.display = "none";
                    } else {
                        return;
                    }
                }
        
                let tick = document.querySelector(`#tick-${totalLength - 1}`);
                tick.style.display = "block";
            } else {
                return;
            }
        }, 500 );
    }, [ totalLength ]);

    useEffect(() => {
        setTimeout(() => {
            if ( totalLength > 11 && !userEditing ) {
                for (let i = 0; i < ( totalLength - 1 ); i++) {
                    let icon = document.getElementById(`tick-${i}`);
                    if ( icon !== null ) {
                        icon.style.display = "none";
                    } else {
                        return;
                    }
                }
            } else if (userEditing) {
                for (let i = 0; i < ( totalLength - 1 ); i++) {
                    let icon = document.getElementById(`tick-${i}`);
                    if ( icon !== null ) {
                        icon.style.display = "none";
                    } else {
                        return;
                    }
                }
            } else {
                return;
            }
        }, 1000 );
    }, []);
    
    return (
        <Modal
            aria-labelledby="transition-modal-title"
            aria-describedby="transition-modal-description"
            open={ open }
            onClose={ handleClose }
            closeAfterTransition
            BackdropComponent={ Backdrop }
            BackdropProps={{
                timeout: 500,
            }}
        >
            <Fade in={ open }>
                <Box sx={ style }>
                    <div className={ Styles.CrossContainer } onClick={ handleClose }>
                        <img src={ Cross } alt="Cross" />
                    </div>
                    <h1 className={ Styles.TitleModal }>Foto de perfil</h1>
                    <p className={ Styles.SubtitleModal} >Seleccioná la foto de perfil del asesor o importala desde tu computadora</p>
                    <div className={ Styles.ImagesContainer }>
                        <div 
                            id={`img-profile-0-container`} 
                            className={ Styles.PhotosContainer }
                            onClick={ handleChangeImgModal }
                        >
                            <div className={ Styles.ProfileLogoContainer }>
                                <img 
                                    id={`img-11`} 
                                    src={ SelectImage } 
                                    alt="Logo" 
                                />
                            </div>
                        </div>
                        {
                            DefaulUsersImages.map((item) => 
                                <div 
                                    id={`img-profile-${item.id}-container`} 
                                    key={ item.id } 
                                    className={ Styles.PhotosContainer }
                                >
                                    <div 
                                        className={ Styles.ProfileLogoContainer }
                                        style={{ borderRadius: '50%' }} 
                                        onClick={() => handleChangeImg(item.src)}
                                    >
                                        <img  
                                            onClick={ handleShowTick }
                                            src={ item.src } 
                                            alt="Logo" 
                                            id={ item.id }
                                            style={{
                                                border: '1px solid #2ED588',
                                                borderRadius: '50%',
                                            }}
                                        />
                                        <img id={`tick-${item.id}`} src={Tick} alt="Tick" className={ Styles.TickImages }/>
                                    </div>
                                </div>
                            )
                        }
                        {
                            imgsPersonalize.map((item) => 
                                <div 
                                    id={`img-profile-${item.id}-container`} 
                                    key={ item.id } 
                                    className={ Styles.PhotosContainer }
                                >
                                    <div 
                                        className={ Styles.ProfileLogoContainer }
                                        style={{ borderRadius: '50%' }} 
                                        onClick={() => handleChangeImg(item.src)}
                                    >
                                        <img  
                                            onClick={ handleShowTick }
                                            src={ item.src } 
                                            alt="Logo" 
                                            id={ item.id }
                                            style={{
                                                border: '1px solid #2ED588',
                                                borderRadius: '50%',
                                                width: '100px',
                                                height: '100%'
                                            }}
                                        />
                                        <img id={`tick-${item.id}`} src={Tick} alt="Tick" className={ Styles.TickImages }/>
                                    </div>
                                </div>
                            )
                        }
                    </div>
                    <div className={ Styles.ButtonContainer }>
                        <SaveButton
                            onClick={ handleClose }
                        />
                    </div>
                    <SetPersonalizeImg open={ openModal } handleClose={ handleChangeImgModal } />
                </Box>
            </Fade>
        </Modal>
    )
}

const mapStateToProps = state => {
    return {
        imgsPersonalize: state.stepsUser.imgsPersonalize,
        srcImgProfile: state.stepsUser.srcImgProfile,
        imgProfileId: state.stepsUser.imgProfileId,

        userEditing: state.stepsUser.userEditing,
    }
}

const mapDispatchToProps = dispatch => {
    return {
        changeProfileImg: (value) => dispatch(changeProfilePhoto(value)),
    }
}

export default connect( mapStateToProps, mapDispatchToProps )( SelectPhotoModal );
