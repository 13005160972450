import React, { useEffect } from 'react'
import { Box, Typography } from '@mui/material'
import { UserPasswordLogin } from '../components/UserPasswordLogin';
import { OrComponent } from '../components/OrComponent';
// import { SocialLogin } from './SocialLogin';
import { PlatformList } from '../../../helpers/platformList';
import { PlatformLogin } from '../components/PlatformLogin';
import SocialLogin from '../components/SocialLogin';

const platformLoginOrder = PlatformList.filter(p => p.hasLoginIntegration).map((platform) => {
  return {
    name: platform.name,
    type: 'platform',
    component: <PlatformLogin
      {...platform}
    />,
  }
})

const regularLoginOrder = [
  ...platformLoginOrder,
  {
    name: 'OrComponent',
    component: <OrComponent />,
  },
  {
    name: 'UserPasswordLogin',
    component: <UserPasswordLogin />,
    type: 'userpassword'
  },
  {
    name: 'SocialLogin',
    component: <SocialLogin />,
  },
]

export const LoginComponent = () => {
  const query = new URLSearchParams(window.location.search);
  const [loginOrder, setLoginOrder] = React.useState([]);

  useEffect(() => {
    const platform = query.get('platform');
    if (platform) {
      const platformLogin = PlatformList.find(p => p.name === platform && p.hasLoginIntegration);
      if (platformLogin) {
        return setLoginOrder([
          {
            name: platformLogin.name,
            component: <PlatformLogin
              {...platformLogin}
            />,
          },
          {
            name: 'OrComponent',
            component: <OrComponent />,
          },
          {
            name: 'UserPasswordLogin',
            component: <UserPasswordLogin />,
          },
          {
            name: 'SocialLogin',
            component: <SocialLogin />,
          }
        ])
      } else {
        if (!regularLoginOrder.find(l => l.type === 'platform')) {
          return setLoginOrder([
            {
              name: 'UserPasswordLogin',
              component: <UserPasswordLogin />,
            },
            {
              name: 'OrComponent',
              component: <OrComponent />,
            },
            {
              name: 'SocialLogin',
              component: <SocialLogin />,
            }
          ])
        } else {
          return setLoginOrder(regularLoginOrder);
        }
      }
    } else {
      if (!regularLoginOrder.find(l => l.type === 'platform')) {
        return setLoginOrder([
          {
            name: 'UserPasswordLogin',
            component: <UserPasswordLogin />,
          },
        ])
      } else {
        return setLoginOrder(regularLoginOrder);
      }
    }
  }, []);

  return (
      <>
      <Typography variant={'h4'} sx={{ fontSize: '1.5rem', fontWeight: 400 }}>
        Bienvenido
      </Typography>
      <Typography variant={'subtitle1'} sx={{ fontSize: '0.9rem', fontWeight: 400 }}>
        Iniciar sesión para continuar hacia WhatsPRO
      </Typography>
        {
          loginOrder.map((component, index) => {
            return (
              <Box
                key={ index }
                display={'flex'}
                flexDirection={'column'}
                justifyContent={'center'}
                alignItems={'flex-start'}
                gap={1.5}
                sx={{
                  width: '100%',
                  padding: '5px 20px',
                }}
              >
                <>
                  {component.component}
                </>
              </Box>
            )
          })
        }
      </>
  )
}
