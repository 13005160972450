import { typesChatSteps } from "../types/types";

const initialState = {
    activeStepChat: 0,
    completeStepChat: [
        false,
        false,
        false,
    ],
    onBoardingFinished: false,
    onBoardingChat: false,
    titleChat: 'Hola! 👋🏻',
    description: '',
    backColor: '#2ED588',
    colorFont: '#2ED588',
    backImgChat: '',
    urlImg: '',
    questions: [],
    templates: [],
    activeTemplate: {
        uid: 0,
        name: 'Personalizadas',
        answers: []
    },
    editAdvancedOptions: false,
    advancedOptions: {
        whatsAppIcon: false,
        alignButton: 'right',
        delayButton: 5,
        enabledTextButton: true,
        textButton: '',
        bulletPointDelay: 5,
        buttonVisibility: true
    },
    showModalComplete: false,
    errorSaveChat: false,
    editingChat: false,
    leaveSaveChanges: false,
}

export const stepsChatReducer = (state = initialState, action) => {
    switch (action.type) {
        case typesChatSteps.activeStepChat:
            return {
                ...state,
                activeStepChat: action.payload.activeStepChat
            }
        case typesChatSteps.completeStepChat:
            return {
                ...state,
                completeStepChat: action.payload.completeStepChat ?? state.completeStepChat,
            }
        case typesChatSteps.editAdvancedOptions:
            return {
                ...state,
                editAdvancedOptions: action.payload.editAdvancedOptions ?? state.editAdvancedOptions
            }
        case typesChatSteps.titleChat:
            return {
                ...state,
                titleChat: action.payload
            }
        case typesChatSteps.welcomeTextChat:
            return {
                ...state,
                description: action.payload.description ?? state.description,
            }
        case typesChatSteps.setStylesChat:
            return {
                ...state,
                backColor: action.payload.backColor ?? state.backColor,
                colorFont: action.payload.colorFont ?? state.colorFont,
                backImgChat: action.payload.backImgChat ?? state.backImgChat,
                urlImg: action.payload.urlImg ?? state.urlImg,
            }
        case typesChatSteps.setEditingChat:
            return {
                ...state,
                editingChat: action.payload
            }
        case typesChatSteps.setAdvancedOptions:
            return {
                ...state,
                advancedOptions: {
                    ...state.advancedOptions,
                    enabledTextButton: action.payload.enabledTextButton ?? state.advancedOptions.enabledTextButton,
                    bulletPointDelay: action.payload.bulletPointDelay ?? state.advancedOptions.bulletPointDelay,
                    buttonVisibility: action.payload.buttonVisibility ?? state.advancedOptions.buttonVisibility,
                    whatsAppIcon: action.payload.whatsAppIcon ?? state.advancedOptions.whatsAppIcon,
                    alignButton: action.payload.alignButton ?? state.advancedOptions.alignButton,
                    delayButton: action.payload.delayButton ?? state.advancedOptions.delayButton,
                    textButton: action.payload.textButton ?? state.advancedOptions.textButton,
                }
            }
        case typesChatSteps.showSaveChangesModal:
            return {
                ...state,
                showModalComplete: action.payload
            }
        case typesChatSteps.showErrorSaveChanges:
            return {
                ...state,
                errorSaveChat: action.payload
            }
        case typesChatSteps.setOnboardingChat:
            return {
                ...state,
                onBoardingFinished: action.payload.onBoardingFinished,
                onBoardingChat: action.payload.onBoardingChat,
            }
        case typesChatSteps.setLeaveSaveChangesChat:
            return {
                ...state,
                leaveSaveChanges: action.payload,
            }
        case typesChatSteps.setQuestions:
            state.activeTemplate.answers = action.payload ?? state.activeTemplate.answers
            return {
                ...state,
                questions: action.payload ?? state.questions,
                activeTemplate: state.activeTemplate
            }
        case typesChatSteps.setTemplates:
            const templatesInFormat = [{
                uid: 0,
                name: 'Personalizadas',
                answers: state.questions
            }]
            action.payload.forEach((t) => {
                templatesInFormat.push({
                    uid: t.uid,
                    name: t.name,
                    answers: t.answers.map((q, idx) => {
                        return {
                            ...state.questions[idx],
                            ...q,
                            state: true,
                        }
                    })
                })
            })
            return {
                ...state,
                templates: templatesInFormat,
            }
        case typesChatSteps.setActiveTemplate:
            return {
                ...state,
                activeTemplate: action.payload ?? state.activeTemplate,
                questions: action.payload.answers ?? state.questions,
            }
        case typesChatSteps.updateQuestion:
            const newTemplate = {
                ...state.activeTemplate,
                answers: state.questions.map((q) => {
                    if (q.uid === action.payload.uid) {
                        return {
                            ...q,
                            ...action.payload.newData
                        }
                    }
                    return q;
                })
            }
            const newTemplates = state.templates.map((t) => {
                if (t.uid === newTemplate.uid) {
                    return newTemplate;
                }
                return t;
            })
            return {
                ...state,
                questions: state.questions.map((question) => {
                    if (question.uid === action.payload.uid) {
                        return {
                            ...question,
                            ...action.payload.newData
                        }
                    }
                    return question;
                }),
                templates: newTemplates,
                activeTemplate: newTemplate
            }
        case typesChatSteps.reorderQuestions:
            const newQuestionsWithOrderInFormat = action.payload.map((q, idx) => {
                return {
                    ...q,
                    order: idx
                }
            })
            const newTemplateWithOrderInFormat = {
                ...state.activeTemplate,
                answers: newQuestionsWithOrderInFormat
            }
            const newTemplatesWithOrderInFormat = state.templates.map((t) => {
                if (t.uid === newTemplateWithOrderInFormat.uid) {
                    return newTemplateWithOrderInFormat;
                }
                return t;
            })
            return {
                ...state,
                questions: newQuestionsWithOrderInFormat,
                templates: newTemplatesWithOrderInFormat,
                activeTemplate: newTemplateWithOrderInFormat
            }
        case typesChatSteps.createNewQuestion:
            const newQuestion = {
                uid: state.questions.length.toString(),
                order: state.questions.length + 1,
                question: 'Pregunta ' + (state.questions.length + 1),
                reply: 'Respuesta ' + (state.questions.length + 1),
                state: true,
            }
            const newTemplateWithNewQuestion = {
                ...state.activeTemplate,
                answers: [...state.questions, newQuestion]
            }
            const newTemplatesWithNewQuestion = state.templates.map((t) => {
                if (t.uid === newTemplateWithNewQuestion.uid) {
                    return newTemplateWithNewQuestion;
                }
                return t;
            })
            return {
                ...state,
                questions: [...state.questions, newQuestion],
                templates: newTemplatesWithNewQuestion,
                activeTemplate: newTemplateWithNewQuestion
            }

        default:
            return state;
    }
}



