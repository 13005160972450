import React, { useEffect, useState } from 'react';

// CSS
import Styles from './OutOfScheduleQuestions.module.css';
import Sleep from '../../../assets/icons/Sleep';
import { useFetch } from 'use-http';
import { MAIN_API_URL } from '../../../configuration';
import { connect } from 'react-redux';

const OutOfScheduleQuestions = (props) => {
    const { startDate, endDate } = props;
    const [total, setTotal] = useState(0);
    const { get, response } = useFetch(MAIN_API_URL + '/stats/not_working', {
        headers: {
            'x-token': localStorage.getItem('token')
        }
    });

    const getStatsOutOfScheduleQuestions = async () => {
        const resp = await get("?startDate=" + startDate + "&endDate=" + endDate);
        if (response.ok) {
            setTotal(resp.total);
        }
    }

    useEffect(() => { getStatsOutOfScheduleQuestions(); }, [startDate, endDate]);

    return (
        <div className={ Styles.RankingItemContainerQuestion }>
            <div className={ Styles.DataSection }>
                <div className={ Styles.AvatarContainer }>
                  <Sleep/>
                </div>
                <div className={ Styles.NameContainer }>
                    <h2>Preguntas fuera de horario</h2>
                </div>
            </div>
            <div className={ Styles.BottomSection }>
                <div className={ Styles.MessagesContainer }>
                    <p>{total}</p>
                </div>
            </div>
        </div>
    )
}

const mapStateToProps = state => {
    return {
        startDate: state.employees.filterDates.startDate,
        endDate: state.employees.filterDates.endDate,
    }
}

export default connect(mapStateToProps, null)(OutOfScheduleQuestions);