import React from 'react'

// Material Ui
import { Button } from '@mui/material';

const SaveButton = ({ onClick }) => {
    return (
        <Button
            onClick={onClick}
            sx={{
                boxShadow: '0px 2.91309px 2.91309px rgba(0, 0, 0, 0.25)',
                backgroundColor: '#2ED588',
                fontFamily: 'Poppins',
                textTransform: 'none',
                padding: '6px 10px',
                borderRadius: '8px',
                color: 'white',
                '&:hover': {
                    backgroundColor: '#2ED588',
                }
            }}
        >
            Guardar
        </Button>
    )
}

export default SaveButton