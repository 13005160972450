import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import useFetch from 'use-http';

// Actions
import { onSetLeaveSaveChangesChat, setShowModalChat } from '../../actions/stepsChat';
import { logOutWP, updateTutorialState } from '../../actions/auth';
import { onSetVideoComplete, setActiveTask, setTaskState } from '../../actions/tasks';
import { getEmployees } from '../../actions/users';

// Components
import SuccessPaymentUpdated from '../../components/Modals/SuccessPaymentUpdated/SuccessPaymentUpdated';
import SnackbarTutorialHome from '../../components/Banners/SnackbarTutorialHome/SnackbarTutorialHome';
import PerformanceComponent from '../../components/Home/PerformanceComponent/PerformanceComponent';
import Task4SubCongratsModal from '../../components/Modals/CongratsModals/Task4SubCongratsModal';
import DateFilterComponent from '../../components/Home/DateFilterComponent/DateFilterComponent';
import EndTrialPeriodModal from '../../components/Modals/EndTrialPeriod/EndTrialPeriodModal';
import Task1CongratsModal from '../../components/Modals/CongratsModals/Task1CongratsModal';
import RankingComponent from '../../components/Home/RankingComponents/RankingComponent';
import DevicesComponents from '../../components/Home/DevicesComponent/DevicesComponent';
import ClicksComponent from '../../components/Home/ClicksComponent/ClicksComponent';
import BannerChatError from '../../components/Banners/BannerChat/BannerChatError';
import TasksComponent from '../../components/Home/TasksComponent/TasksComponent';
import WelcomeBanner from '../../components/Home/WelcomeBanner/WelcomeBanner';
import LoaderFlowy from '../../components/Loader/LoaderFlowy/LoaderFlowy';
import BannerChat from '../../components/Banners/BannerChat/BannerChat';

// import LastStepTutorial from '../../components/Modals/LastStepTutorial/LastStepTutorial';
// import BannerInstall from '../../components/Banners/BannerInstall/BannerInstall';
// import ErrorsModals from '../../components/Modals/ErrorsModal/ErrorsModals';
// import LogOutModal from '../../components/Modals/LogOutModal/LogOutModal';
// import SelectHome from '../../components/Home/SelectHome/SelectHome';

// Configurations
import { MAIN_API_URL } from '../../configuration';

// Layout
import LayoutPages from '../../layout/LayoutPages';

// Material Ui
import { Box, Grid } from '@mui/material';

// CSS
import Styles from './Home.module.css';
import SubCongratsModal from '../../components/Modals/CongratsModals/SubCongratsModal';
import OutOfScheduleQuestions from '../../components/Home/OutOfScheduleQuestions/OutOfScheduleQuestions';

const HomeScreenContainer = {
    '@media(min-width: 1350px)': {
        gridTemplateColumns: '60% 40%',
        display: 'grid'
    }
}

const DatePickerContainer = {
    justifyContent: 'flex-end',
    display: 'flex',
    width: '100%',
}

const HomeScreen = (props) => {

    // Actions
    const {
        onSetLeaveSaveChangesChat,
        updateTutorialState,
        onSetVideoComplete,
        setShowModalChat,
        getEmployeesA,
        setActiveTask,
        setTaskState,
    } = props;

    // States,
    const {
        endTrialPeriod,
        activeTask,
        employees,
        videoComplete,
        startDate,
        endDate,
    } = props;

    // Query params
    const query = new URLSearchParams(window.location.search);
    const payment = query.get('payment');
    const paymentProcesador = query.get("payment_procesador");
    const paymentType = query.get("payment_type");

    const { error, get, put, response, cache } = useFetch(`${MAIN_API_URL}`, {
        headers: {
            "x-token": `${localStorage.getItem("token")}`
        }
    });

    const [showSnackbarTutorial, setShowSnackbarTutorial] = useState(false);
    const [onBoardingComplete, setOnBoardingComplete] = useState(false);
    const [showCongratsModal, setShowCongratsModal] = useState(false);
    const [openFirstModal, setOpenFirstModal] = useState(true);
    const [onBoardingChat, setOnBoardingChat] = useState(false);
    const [questionsArray, setQuestionsArray] = useState([]);
    const [showProgress, setShowProgress] = useState(true);
    const [advisersArray, setAdvisersArray] = useState([]);
    const [clicks, setClicks] = useState([]);
    const [userName, setUserName] = useState('');
    const [origin, setOrigin] = useState('');
    const [paymentModal, setPaymentModal] = useState(true);

    useEffect(() => { getClicks(); }, [startDate, endDate]);
    useEffect(() => { updateTutorialState() }, []);
    useEffect(() => { getStatsAdvisers(); }, [startDate, endDate]);
    useEffect(() => { getStatsAnswers(); }, [startDate, endDate]);
    useEffect(() => { getStore(); }, []);

    useEffect(() => {
        onSetLeaveSaveChangesChat(false);
        getEmployeesA();
    }, []);

    useEffect(() => {
        if (onBoardingChat && employees.length === 0) {
            setTaskState({ number: 2, data: 'completed' });
            setTaskState({ number: 3, data: 'active' });
            setActiveTask(3);
        } else if (onBoardingChat && employees.length > 0) {
            setTaskState({ number: 2, data: 'completed' });
            setTaskState({ number: 3, data: 'completed' });
            setTaskState({ number: 4, data: 'active' });
            setActiveTask(4);
            return;
        } else {
            return;
        }
    }, [onBoardingChat, employees]);

    const getStatsAnswers = async () => {
        const resp = await get("/stats/answers?startDate=" + startDate + "&endDate=" + endDate);
        if (response.ok) {
            setQuestionsArray(resp.stats);
        } else {
            console.log(error);
        }
    }

    const getStatsAdvisers = async () => {
        const resp = await get("/stats/employees?startDate=" + startDate + "&endDate=" + endDate);
        if (response.ok) {
            setAdvisersArray(resp.stats);
        } else {
            console.log(error);
        }
    }

    const getClicks = async () => {
        const resp = await get("/stats/clicks?startDate=" + startDate + "&endDate=" + endDate);
        if (response.ok) {
            setClicks(resp.data.values);
        } else {
            console.log(error);
        }
    }

    const getStore = async () => {
        setShowProgress(true);
        cache.clear();
        const resp = await get("/stores");
        if (response.ok) {
            setOnBoardingComplete(resp.onBoardingFinished);
            setOnBoardingChat(resp.onBoardingChat);
            setUserName(resp.name);
            setOrigin(resp.origin);
            onSetVideoComplete(resp.onBoardingVideo);
        } else {
            console.log(error);
        }

        setShowProgress(false);
    }

    const handleCloseModal = () => {
        setShowModalChat(false);
    }

    const handleCloseErrorSnack = () => {
        setShowModalChat(false);
    }

    const handleShowFirstCongratModal = () => {
        setOpenFirstModal(!openFirstModal);
        put('/stores', { onBoardingVideo: true });
        console.log("Llamando a onSetVideoComplete con el valor: ", true, "");
        onSetVideoComplete(true);
    }

    const handleShowCongratsModal = () => {
        setShowCongratsModal(!showCongratsModal);
    }

    const handleShowSnackbarTutorial = () => {
        setShowSnackbarTutorial(!showSnackbarTutorial);
    }

    const handleModalPayment = () => {
        setPaymentModal(!paymentModal);
    }

    return (
        <LayoutPages>
            <WelcomeBanner name={userName} onBoardingFinished={onBoardingComplete} />
            {showProgress ? <LoaderFlowy /> : null}
            {!showProgress ? onBoardingComplete ? null : <TasksComponent handleShowCongratsModal={handleShowCongratsModal} /> : null}
            <Box sx={DatePickerContainer}>
                <DateFilterComponent />
            </Box>
            <Grid
                container
                spacing={3}
                paddingTop={2}
            >
                <Grid item xs={12} md={8}>
                    <Grid
                        container
                        spacing={3}
                    >
                        <Grid item xs={12}>
                            <PerformanceComponent />
                        </Grid>
                        <Grid item xs={12}>
                            <Grid
                                container
                                spacing={3}
                            >
                                <Grid item xs={12} md={6}>
                                    <Grid
                                        container
                                        spacing={3}
                                    >
                                        <Grid item xs={12}>
                                            <DevicesComponents />
                                        </Grid>
                                        <Grid item xs={12}>
                                            <OutOfScheduleQuestions />
                                        </Grid>
                                    </Grid>
                                </Grid>
                                <Grid item xs={12} md={6}>
                                    <ClicksComponent
                                        clicks={clicks}
                                    />
                                </Grid>
                            </Grid>
                        </Grid>
                    </Grid>
                </Grid>
                <Grid item sm={12} md={4}>
                    <Grid
                        container
                        spacing={3}
                    >
                        <Grid item xs={12}>
                            <RankingComponent
                                type={'advisers'}
                                array={advisersArray}
                                onBoardingComplete={onBoardingComplete}
                            />
                        </Grid>
                        <Grid item xs={12}>
                            <RankingComponent
                                type={'questions'}
                                array={questionsArray}
                                onBoardingComplete={onBoardingComplete}
                            />
                        </Grid>
                    </Grid>
                </Grid>
                {
                    payment === 'success' && paymentProcesador === 'mercadopago'
                        ?
                        (paymentType === 'update' ? <SuccessPaymentUpdated open={paymentModal} handleClose={handleModalPayment} /> : <SubCongratsModal open={paymentModal} handleClose={handleModalPayment} />)
                        :
                        null
                }
                {endTrialPeriod && <EndTrialPeriodModal open={true} />}
                <BannerChat handleClose={handleCloseModal} />
                <BannerChatError handleClose={handleCloseErrorSnack} />
                {
                    onBoardingComplete ?
                        null
                        :
                        <Task4SubCongratsModal
                            open={(activeTask === 4 || activeTask === 'sub') ? showCongratsModal : false}
                            handleClose={handleShowCongratsModal}
                            task={activeTask}
                            origin={origin}
                        />
                }
                {
                    !videoComplete && <Task1CongratsModal
                        open={openFirstModal}
                        handleClose={handleShowFirstCongratModal}
                    />
                }
                {
                    onBoardingComplete ?
                        null
                        :
                        <SnackbarTutorialHome
                            open={showSnackbarTutorial}
                            handleClose={handleShowSnackbarTutorial}
                        />
                }
            </Grid>
        </LayoutPages >
    );
}

const mapStateToProps = state => {
    return {
        endTrialPeriod: state.auth.endTrialPeriod,
        logOut: state.auth.logOut,
        videoComplete: state.tasks.videoComplete,
        activeTask: state.tasks.activeTask,
        employees: state.employees.employees,
        startDate: state.employees.filterDates.startDate,
        endDate: state.employees.filterDates.endDate,
    }
}

const mapDispatchToProps = dispatch => {
    return {
        onSetLeaveSaveChangesChat: (data) => dispatch(onSetLeaveSaveChangesChat(data)),
        onSetVideoComplete: (data) => dispatch(onSetVideoComplete(data)),
        setTaskState: ({ number, data }) => dispatch(setTaskState({ number, data })),
        updateTutorialState: () => dispatch(updateTutorialState()),
        setShowModalChat: (data) => dispatch(setShowModalChat(data)),
        setActiveTask: (data) => dispatch(setActiveTask(data)),
        getEmployeesA: () => dispatch(getEmployees()),
        logOutWP: (value) => dispatch(logOutWP(value)),
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(HomeScreen);
