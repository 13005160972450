import { Avatar, Box, IconButton, Tooltip, Typography } from '@mui/material'
import React from 'react'
import ArrowBackIosIcon from '@mui/icons-material/ArrowBackIos';
import ClearIcon from '@mui/icons-material/Clear';


export const EmployeeHeader = ({employee, headerColour = '#2ED588', goBackToMainChat}) => {
    const {name = 'Juan Perez', role = 'Atención', avatar = 'https://material-ui.com/static/images/avatar/1.jpg'} = employee;
    return (
        <Box
            sx={{
                background: headerColour,
                borderRadius: '14px 14px 0px 0px',
                padding: '20px 20px',
                display: 'flex',
                alignItems: 'center',
                position: 'sticky',
                top: 0,
            }}
        >
            <ArrowBackIosIcon 
                sx={{ color: '#FFF', fontSize: 25 }} 
                onClick={goBackToMainChat}
                />
            <Avatar alt={name} src={avatar} sx={{ width: 60, height: 60, border: `2px solid ${headerColour} !important` }} />
            <Box
                display={'flex'}
                flexDirection={'column'}
                gap={'2px'}
            >
                <Typography variant='h2' fontWeight={600} fontSize={25} sx={{ color: 'white', marginLeft: 2 }}>{name}</Typography>
                <Typography variant='body2' fontWeight={400} fontSize={16} sx={{ color: 'white', marginLeft: 2 }}>{role}</Typography>
            </Box>
            <Box
                position={'absolute'}
                right={0}
                top={0}
                onClick={() => window.parent.postMessage('Cerrar', '*')}
            >
                <Tooltip placement="top" arrow title={'Cerrar'}>
                    <IconButton
                        sx={{
                            alignSelf: 'center'
                        }}
                        color="primary"
                    >
                        <ClearIcon />
                    </IconButton>
                </Tooltip>
            </Box>
        </Box>
    )
}