import React, { useEffect, useState } from 'react';
import useFetch from 'use-http';

// Recharts
import { PieChart, Pie, Cell, ResponsiveContainer } from 'recharts';

// Images
import PhoneIcon from '../../../assets/images/Home/PhoneIcon';
import WebIcon from '../../../assets/images/Home/WebIcon';

// CSS
import Styles from './DevicesComponents.module.css';
import { MAIN_API_URL } from '../../../configuration';
import { connect } from 'react-redux';

const DevicesComponent = (props) => {

    const { startDate, endDate } = props;

    const { get, response } = useFetch(`${MAIN_API_URL}/stats`, {
        headers: {
            "x-token": `${localStorage.getItem("token")}`
        }
    });

    const COLORS = ['#393939', '#2ED588'];

    const [data, setData] = useState([]);

    useEffect(() => { getStatsDevices(); }, [startDate, endDate]);

    const getStatsDevices = async () => {
        const resp = await get("/mobile_desktop?startDate=" + startDate + "&endDate=" + endDate);
        if (response.ok) {
            setData([
                { name: 'Mobile', value: ( resp.statsFormat.mobile === 0 && resp.statsFormat.desktop === 0 ) ? 50 : resp.statsFormat.mobile },
                { name: 'Web', value: ( resp.statsFormat.mobile === 0 && resp.statsFormat.desktop === 0 ) ? 50 : resp.statsFormat.desktop },
            ]);
        }
    }

    return (
        <section className={Styles.DevicesGeneralContainer}>
            <h1>Ingresos desde dispositivos</h1>
            <div className={Styles.DataSection}>
                <div className={Styles.LeftSection}>
                    <div className={Styles.ItemSection}>
                        <div className={Styles.IconSection}>
                            <PhoneIcon />
                            <p>Celular</p>
                        </div>
                        <h1>{ ( data[0]?.value === 0 && data[1]?.value === 0 ) ? 50 : data[0]?.value }%</h1>
                    </div>
                    <div className={Styles.ItemSection}>
                        <div className={Styles.IconSection}>
                            <WebIcon />
                            <p>Web</p>
                        </div>
                        <h1>{ ( data[0]?.value === 0 && data[1]?.value === 0 ) ? 50 : data[1]?.value }%</h1>
                    </div>
                </div>
                <div className={Styles.RightSection}>
                    <ResponsiveContainer>
                        <PieChart>
                            <Pie
                                data={ data }
                                cy="50%"
                                innerRadius={ 40 }
                                outerRadius={ 55 }
                                fill="#8884d8"
                                paddingAngle={ 5 }
                                dataKey="value"
                            >
                                {
                                    data?.map((entry, index) => (
                                        <Cell key={`cell-${index}`} fill={COLORS[index % COLORS.length]} />
                                    ))
                                }
                            </Pie>
                        </PieChart>
                    </ResponsiveContainer>
                </div>
            </div>
        </section>
    )
}

const mapStateToProps = state => {
    return {
        startDate: state.employees.filterDates.startDate,
        endDate: state.employees.filterDates.endDate,
    }
}

export default connect(mapStateToProps, null)(DevicesComponent);