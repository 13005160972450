import React from 'react';
import { connect } from 'react-redux';

// Components
import ArrowBottom from '../../../assets/images/Advanced/ArrowBottom';
import ArrowTop from '../../../assets/images/Advanced/ArrowTop';

// CSS
import Styles from './BulletPointDelayComponent.module.css';

const BulletPointDelayComponent = (props) => {

    // States
    const { enabledTextButton } = props;

    // External props
    const { delay, onChange } = props;

    const handleMoreSeconds = () => {
        if ( delay >= 60 ) {
            return;
        } else {
            onChange( delay + 1 );
        }
    }

    const handleLessSeconds = () => {
        if ( delay <= 0 ) {
            return;
        } else {
            onChange( delay - 1 );
        }
    }

    return (
        <div 
            className={ Styles.DelayBulletPointContainer }
            style={{
                border: enabledTextButton ? '1px solid #2ED588' : '1px solid #BFBBBD'
            }}
        >
            <div className={ Styles.SecondsSection }>
                <p>{ delay } segundos</p> 
            </div>
            <div 
                className={ Styles.ButtonsSection }
            >
                <div 
                    className={ Styles.ButtonTop } 
                    onClick={ () => handleMoreSeconds() }
                    style={{
                        borderBottom: enabledTextButton ? '.2px solid #2ED588' : '.2px solid #BFBBBD',
                        borderLeft: enabledTextButton ? '.2px solid #2ED588' : '.2px solid #BFBBBD',
                        backgroundColor: enabledTextButton ?  '#E3FFF2' : '#EDEDED',                       
                        pointerEvents: enabledTextButton ?  'auto' : 'none',
                    }} 
                >
                    <ArrowTop color={ enabledTextButton ? '#2ED588' : '#BFBBBD' } />
                </div>
                <div 
                    className={ Styles.ButtonBottom } 
                    onClick={ () => handleLessSeconds() }
                    style={{
                        borderLeft: enabledTextButton ? '.2px solid #2ED588' : '.2px solid #BFBBBD',
                        backgroundColor: enabledTextButton ?  '#E3FFF2' : '#EDEDED',                       
                        pointerEvents: enabledTextButton ?  'auto' : 'none',
                    }} 
                >
                    <ArrowBottom color={ enabledTextButton ? '#2ED588' : '#BFBBBD' } />
                </div>
            </div>
        </div>
    )
}

const mapStateToProps = state => {
    return {
        enabledTextButton: state.stepsChat.advancedOptions.enabledTextButton,
    }
}

export default connect(mapStateToProps, null)( BulletPointDelayComponent );


