import React from 'react';

// Material Ui
import { Box } from '@mui/material';

const StepsContainer = {
    padding: '0rem',
    margin: 'auto',
    width: '95%',
}

const LayoutStepsChat = ({ children }) => {
    return (
        <Box sx={ StepsContainer } >{ children }</Box>
    )
}

export default LayoutStepsChat;