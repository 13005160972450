import React from 'react';

const JCBIcon = () => {
    return (
        <svg width="62" height="39" viewBox="0 0 62 39" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M3.29478 0.5H58.4823C60.0258 0.5 61.2771 1.75126 61.2771 3.29478V35.5012C61.2771 37.0447 60.0258 38.296 58.4823 38.296H3.29478C1.75126 38.296 0.5 37.0447 0.5 35.5012V3.29478C0.5 1.75126 1.75126 0.5 3.29478 0.5Z" fill="white" stroke="#EEEEEE"/>
            <path d="M41.0156 22.5522H43.7954C43.8748 22.5522 44.0601 22.5258 44.1395 22.5258C44.669 22.4199 45.1191 21.9433 45.1191 21.2815C45.1191 20.6461 44.669 20.1696 44.1395 20.0372C44.0601 20.0107 43.9013 20.0107 43.7954 20.0107H41.0156V22.5522Z" fill="url(#paint0_linear_348_506)"/>
            <path d="M43.4775 5C40.8301 5 38.6593 7.14439 38.6593 9.81826V14.8218H45.4631C45.6219 14.8218 45.8072 14.8218 45.9396 14.8483C47.4751 14.9277 48.6135 15.7219 48.6135 17.0986C48.6135 18.184 47.8457 19.1106 46.4161 19.2959V19.3489C47.9781 19.4548 49.1694 20.3284 49.1694 21.6786C49.1694 23.1346 47.8457 24.0877 46.0985 24.0877H38.6328V33.8831H45.7014C48.3487 33.8831 50.5196 31.7387 50.5196 29.0648V5H43.4775Z" fill="url(#paint1_linear_348_506)"/>
            <path d="M44.7749 17.4158C44.7749 16.7805 44.3249 16.3569 43.7954 16.2775C43.7424 16.2775 43.6101 16.251 43.5306 16.251H41.0156V18.5807H43.5306C43.6101 18.5807 43.7689 18.5807 43.7954 18.5542C44.3249 18.4748 44.7749 18.0512 44.7749 17.4158Z" fill="url(#paint2_linear_348_506)"/>
            <path d="M16.8447 5C14.1973 5 12.0265 7.14439 12.0265 9.81826V21.7051C13.3766 22.3669 14.7798 22.7905 16.1829 22.7905C17.8507 22.7905 18.7509 21.7845 18.7509 20.4078V14.7954H22.8808V20.3814C22.8808 22.5522 21.5306 24.326 16.9506 24.326C14.1709 24.326 12 23.7171 12 23.7171V33.8566H19.0685C21.7159 33.8566 23.8868 31.7122 23.8868 29.0383V5H16.8447Z" fill="url(#paint3_linear_348_506)"/>
            <path d="M30.1615 5C27.5141 5 25.3433 7.14439 25.3433 9.81826V16.1191C26.5611 15.0866 28.679 14.4247 32.0941 14.5836C33.9208 14.663 35.8799 15.166 35.8799 15.166V17.2045C34.9003 16.7015 33.7355 16.2514 32.2265 16.1455C29.632 15.9602 28.0701 17.231 28.0701 19.4548C28.0701 21.7051 29.632 22.9758 32.2265 22.764C33.7355 22.6581 34.9003 22.1816 35.8799 21.7051V23.7435C35.8799 23.7435 33.9473 24.2465 32.0941 24.326C28.679 24.4848 26.5611 23.823 25.3433 22.7905V33.9095H32.4118C35.0592 33.9095 37.2301 31.7651 37.2301 29.0913V5H30.1615Z" fill="url(#paint4_linear_348_506)"/>
            <defs>
                <linearGradient id="paint0_linear_348_506" x1="38.655" y1="21.2846" x2="50.5517" y2="21.2846" gradientUnits="userSpaceOnUse">
                    <stop stopColor="#007940"/>
                    <stop offset="0.2285" stopColor="#00873F"/>
                    <stop offset="0.7433" stopColor="#40A737"/>
                    <stop offset="1" stopColor="#5CB531"/>
                </linearGradient>
                <linearGradient id="paint1_linear_348_506" x1="38.6545" y1="19.43" x2="50.5521" y2="19.43" gradientUnits="userSpaceOnUse">
                    <stop stopColor="#007940"/>
                    <stop offset="0.2285" stopColor="#00873F"/>
                    <stop offset="0.7433" stopColor="#40A737"/>
                    <stop offset="1" stopColor="#5CB531"/>
                </linearGradient>
                <linearGradient id="paint2_linear_348_506" x1="38.6547" y1="17.4125" x2="50.5519" y2="17.4125" gradientUnits="userSpaceOnUse">
                    <stop stopColor="#007940"/>
                    <stop offset="0.2285" stopColor="#00873F"/>
                    <stop offset="0.7433" stopColor="#40A737"/>
                    <stop offset="1" stopColor="#5CB531"/>
                </linearGradient>
                <linearGradient id="paint3_linear_348_506" x1="12.0209" y1="19.43" x2="24.1017" y2="19.43" gradientUnits="userSpaceOnUse">
                    <stop stopColor="#1F286F"/>
                    <stop offset="0.4751" stopColor="#004E94"/>
                    <stop offset="0.8261" stopColor="#0066B1"/>
                    <stop offset="1" stopColor="#006FBC"/>
                </linearGradient>
                <linearGradient id="paint4_linear_348_506" x1="25.2748" y1="19.43" x2="37.0081" y2="19.43" gradientUnits="userSpaceOnUse">
                    <stop stopColor="#6C2C2F"/>
                    <stop offset="0.1735" stopColor="#882730"/>
                    <stop offset="0.5731" stopColor="#BE1833"/>
                    <stop offset="0.8585" stopColor="#DC0436"/>
                    <stop offset="1" stopColor="#E60039"/>
                </linearGradient>
            </defs>
        </svg>
    )
}

export default JCBIcon;