import React from 'react';

// CSS
import Styles from './Step2Chat.module.css';

const TextStep3Chat = () => {
    return (
        <div className={ Styles.TextContainer }>
            <h2>Paso 3: Estética</h2>
            <h3>¿Qué tengo que hacer?</h3>
            <p>En el paso 3 podrás elegir la estética de tu chat. Es recomendable que sea similar a la estética de tu tienda. En caso de que quieras personalizar los colores clickea en el lápiz y sinó elige una de las paletas predeterminadas.</p>
        </div>
    )
}

export default TextStep3Chat;