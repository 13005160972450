import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { connect } from 'react-redux';
import useFetch from 'use-http';

// Actions
import { 
    changeProfilePhoto, 
    setIdUserDeleteA, 
    setComunication, 
    activeDaysUser, 
    setUserEditing, 
    setResetHours, 
    setRangeHours, 
    setMessages, 
    setUserData, 
    setHours, 
} from '../../../actions/stepsUser';

// Components
import UserComponentMobile from '../UserComponentMobile/UserComponentMobile';
import GreenSwitch from '../../GreenSwitch/GreenSwitch';

// Configuration
import { MAIN_API_URL } from '../../../configuration';

// Material Ui
import { IconButton } from '@mui/material';

// Icons
import DeleteOutlineOutlinedIcon from '@mui/icons-material/DeleteOutlineOutlined';
import ModeEditOutlinedIcon from '@mui/icons-material/ModeEditOutlined';

// UsersImages
import { DefaulUsersImages } from '../../Modals/SelectPhotoModal/ImagesArray';

// CSS
import Styles from './UserComponent.module.css';

const UserComponent = ( props ) => {

    // Actions
    const { 
        setIdUserDeleteA, 
        changeProfileImg, 
        setUserEditing, 
        setRangeHours, 
        setResetHours, 
        setUserData, 
        setMessages,
    } = props;

    // States
    const { imgsPersonalize } = props;

    // External props
    const { 
        allowOutOfScheduleMessage, 
        setComunication,
        welcomeMessage,
        phoneNumber,
        outMessage, 
        onDelete, 
        schedule, 
        channels, 
        avatar,
        state, 
        name, 
        role, 
        uid, 
    } = props;

    const [showDots, setShowDots] = useState( false );
    const [checked, setChecked] = useState( state );
    const navigate = useNavigate();

    const { error, put, response } = useFetch(`${MAIN_API_URL}/employees`, {
        headers: {
            "x-token": `${localStorage.getItem("token")}`
        }
    });

    const handleClickSwitch = async () => {
        const resp = await put(`/${uid}`, {
            "state": !checked
        });

        if(response.ok){
            setChecked( !checked );
        } else {
            console.log( error );
        }
    }
    
    useEffect(() => {
        setChecked( state );
    }, [state]);

    const handleDeleteUser = () => {
        setIdUserDeleteA( uid );
        onDelete();
    }

    const handleEditUser = () => {

        setUserEditing({
            userEditing: true,
            userUid: uid
        });

        // Step 1
        setUserData({
            userName: name,
            position: {
                value: role,
                label: role
            },
            countryCode: phoneNumber.prefix,
            phoneNumber: phoneNumber.phoneNumber,
            dialCode: phoneNumber.country
        });

        changeProfileImg({
            srcImgProfile: avatar
        });

        if ( imgsPersonalize.length > 0 ) {
            for (let j = 0; j < imgsPersonalize.length; j++) {
                if ( imgsPersonalize[j].src === avatar  ) {
                    changeProfileImg({
                        imgProfileId: imgsPersonalize[j].id
                    });
                } else {
                    for (let k = 0; k < DefaulUsersImages.length; k++) {
                        if ( DefaulUsersImages[k].src === avatar  ) {
                            changeProfileImg({
                                imgProfileId: DefaulUsersImages[k].id
                            });
                        } 
                    }
                } 
            }
        } else {
            for (let k = 0; k < DefaulUsersImages.length; k++) {
                if ( DefaulUsersImages[k].src === avatar  ) {
                    changeProfileImg({
                        imgProfileId: DefaulUsersImages[k].id
                    });
                } 
            }
        }

        // Step 2
        console.log( 'Edit hours --> ', schedule );
        if (schedule.length === 1 && schedule[0].days.length === 7 && schedule[0].start === 0 && schedule[0].end === 1440) {
            setRangeHours( false );
        } else {
            setRangeHours( true );
        }

        let array = [];
        for (let i = 0; i < schedule.length; i++) {

            let activeDays = [false, false, false, false, false, false, false];

            schedule[i].days.forEach(element => {
                activeDays[element] = true;
            });

            const data = {
                id: i,
                index: i,
                name: `Horario ${i + 1}`,
                days: ["D","L","M","M","J","V","S"],
                start: schedule[i].start,
                end: schedule[i].end,
                state: schedule[i].state,
                activeHour: schedule[i].state,
                activeDays: activeDays
            }

            array.push({...data});
        }

        setResetHours({ newHours: array });

        // Step 3
        setMessages({
            welcomeMessage: welcomeMessage,
            allowOutHourMessage: allowOutOfScheduleMessage,
            outHourMessage: outMessage
        });

        // Advanced
        switch (channels.length) {
            case 3:
                setComunication({
                    whatsApp: true,
                    sms: true,
                    call: true,
                });
                break;
            case 2:
                switch (true) {
                    case (channels[0] === 'whatsapp' && channels[1] === 'sms'):
                        setComunication({
                            whatsApp: true,
                            sms: true,
                            call: false,
                        });
                        break;
                    case (channels[0] === 'sms' && channels[1] === 'cellphone'):
                        setComunication({
                            whatsApp: false,
                            sms: true,
                            call: true,
                        });
                        break;
                    case (channels[0] === 'whatsapp' && channels[1] === 'cellphone'):
                        setComunication({
                            whatsApp: true,
                            sms: false,
                            call: true,
                        });
                        break;
                    case (channels[0] === 'cellphone' && channels[1] === 'sms'):
                        setComunication({
                            whatsApp: false,
                            sms: true,
                            call: true,
                        });
                        break;
                    default:
                        break;
                }
                break;
            case 1:
                switch (channels[0]) {
                    case 'whatsapp':
                        setComunication({
                            whatsApp: true,
                            sms: false,
                            call: false,
                        });
                        break;
                    case 'sms':
                        setComunication({
                            whatsApp: false,
                            sms: true,
                            call: false,
                        });
                        break;
                    case 'cellphone':
                        setComunication({
                            whatsApp: false,
                            sms: false,
                            call: true,
                        });
                        break;
                    default:
                        break;
                }  
                break;
            default:
                setComunication({
                    whatsApp: false,
                    sms: false,
                    call: false,
                });
                break;
        }

        navigate('/createUser');
    }

    useEffect(() => {
        if ( name.length > 20 ) {
            setShowDots( true );
        } else {
            setShowDots( false );
        }
    }, [name]);

    return (
        <>
            <div className={Styles.UserContainer} style={{ border: checked ? '1px solid #2ED588' : '1px solid #BFBBBD'}}>
                <div className={Styles.SwitchContainer}>
                    <GreenSwitch 
                        onClick={ handleClickSwitch }
                        checked={ checked }
                    />
                </div>
                <div className={Styles.UserDataContainer}>
                    <div className={Styles.AvatarSection}>
                        <img src={avatar} alt="Logo" style={{ opacity: checked ?  '1' : '.7' }} />
                    </div>
                    <div className={Styles.NameSection}>
                        { showDots ?  
                                <p className={Styles.ShortUserName} style={{ color:  checked ? '#473C41' : '#BFBBBD' }}>
                                    { name.substring(0, 20) }...
                                </p>
                            :
                                <p className={Styles.UserName} style={{ color:  checked ? '#473C41' : '#BFBBBD' }}>{ name }</p>
                        }
                        <p className={Styles.UserPosition}>
                            { role }
                        </p>
                    </div>
                </div>
                <div className={Styles.PhoneContainer}>
                        <p className={Styles.UserName} style={{ color:  checked ? '#473C41' : '#BFBBBD' }}>
                            Teléfono
                        </p>
                        <p className={Styles.UserPhone}>
                            { phoneNumber.phoneNumber }
                        </p>
                </div>
                <div 
                    className={Styles.IconsContainer}
                >
                    <IconButton aria-label="delete" onClick={ handleEditUser }>
                        <ModeEditOutlinedIcon
                            sx={{ color: '#BFBBBD'}}
                        />
                    </IconButton>
                    <IconButton aria-label="delete" onClick={ handleDeleteUser }>
                        <DeleteOutlineOutlinedIcon
                            sx={{ 
                                color: '#BFBBBD !important', 
                                height: '1.5rem !important', 
                                width: '1.5rem !important' 
                            }}
                        />
                    </IconButton>
                </div>
            </div>
            <UserComponentMobile
                onClickDisabled={ handleClickSwitch }
                onClickDelete={ handleDeleteUser }
                phone={ phoneNumber.phoneNumber }
                onClickEdit={ handleEditUser }
                srcAvatar={ avatar }
                checked={ checked }
                userName={ name }
            />
        </>
    )
}

const mapStateToProps = state => {
    return {
        imgsPersonalize: state.stepsUser.imgsPersonalize,
        users: state.stepsUser.users,
    }
}

const mapDispatchToProps = dispatch => {
    return {
        setUserEditing: ({ userEditing, userUid }) => dispatch(setUserEditing({ userEditing, userUid })),
        activeDaysUser: ({ activeDays, index }) => dispatch(activeDaysUser({ activeDays, index })),
        setResetHours: ({ newHours }) => dispatch(setResetHours({ newHours })),
        changeProfileImg: (value) => dispatch(changeProfilePhoto(value)),
        setIdUserDeleteA: (value) => dispatch(setIdUserDeleteA(value)),
        setComunication: (value) => dispatch(setComunication(value)),
        setRangeHours: (value) => dispatch(setRangeHours(value)),
        setUserData: (value) => dispatch(setUserData(value)),
        setMessages: (value) => dispatch(setMessages(value)),
        setHours: (value) => dispatch(setHours(value)),
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(UserComponent);

