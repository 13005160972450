import React from 'react'

const UserIcon = () => {
    return (
        <div style={{marginRight: '10px', display: 'flex', alignItems: 'center'}}>
            <svg width="23" height="23" viewBox="0 0 23 23" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path d="M19.2828 17.1512C18.8591 16.1476 18.2442 15.2359 17.4724 14.4671C16.703 13.696 15.7915 13.0813 14.7883 12.6567C14.7794 12.6522 14.7704 12.65 14.7614 12.6455C16.1607 11.6348 17.0704 9.98838 17.0704 8.13086C17.0704 5.05371 14.5772 2.56055 11.5001 2.56055C8.42291 2.56055 5.92974 5.05371 5.92974 8.13086C5.92974 9.98838 6.83941 11.6348 8.23873 12.6478C8.22974 12.6522 8.22076 12.6545 8.21178 12.659C7.20552 13.0835 6.3026 13.6922 5.52769 14.4693C4.75663 15.2388 4.14186 16.1502 3.71734 17.1534C3.30029 18.1355 3.07537 19.1885 3.05474 20.2553C3.05414 20.2792 3.05835 20.3031 3.06711 20.3254C3.07587 20.3477 3.08901 20.3681 3.10576 20.3852C3.1225 20.4024 3.14252 20.416 3.16461 20.4254C3.18671 20.4347 3.21045 20.4395 3.23443 20.4395H4.58209C4.68092 20.4395 4.75953 20.3608 4.76178 20.2643C4.8067 18.5303 5.50299 16.9063 6.73384 15.6755C8.00738 14.402 9.69869 13.7012 11.5001 13.7012C13.3014 13.7012 14.9927 14.402 16.2663 15.6755C17.4971 16.9063 18.1934 18.5303 18.2383 20.2643C18.2406 20.3631 18.3192 20.4395 18.418 20.4395H19.7657C19.7897 20.4395 19.8134 20.4347 19.8355 20.4254C19.8576 20.416 19.8776 20.4024 19.8944 20.3852C19.9111 20.3681 19.9242 20.3477 19.933 20.3254C19.9418 20.3031 19.946 20.2792 19.9454 20.2553C19.9229 19.1816 19.7005 18.1372 19.2828 17.1512ZM11.5001 11.9941C10.4691 11.9941 9.49879 11.5921 8.76881 10.8621C8.03883 10.1321 7.63678 9.16182 7.63678 8.13086C7.63678 7.0999 8.03883 6.12959 8.76881 5.39961C9.49879 4.66963 10.4691 4.26758 11.5001 4.26758C12.531 4.26758 13.5013 4.66963 14.2313 5.39961C14.9613 6.12959 15.3633 7.0999 15.3633 8.13086C15.3633 9.16182 14.9613 10.1321 14.2313 10.8621C13.5013 11.5921 12.531 11.9941 11.5001 11.9941Z" fill="#2ED588"/>
            </svg>
        </div>
    )
}

export default UserIcon