import React from 'react';
import { connect } from "react-redux";

// Actions
import { changeEndRangeHours, changeStartRangeHours } from '../../../actions/stepsUser';

// Components
import { hoursArray } from './ArrayHours';

// Material Ui
import { FormControl, InputLabel, MenuItem, Select } from '@mui/material';
import { createTheme } from '@mui/material/styles';
import { ThemeProvider } from '@mui/system';

const theme = createTheme({
    palette: {
        primary: {
            main: '#2ED588',
        },
        error: {
            main: '#FF0000'
        }
    },
});

const SelectHours = (props) => {

    // Actions
    const { changeStartRangeHours, changeEndRangeHours } = props;

    // Externals props
    const { id, startHour, endHour, index, active } = props;

    const selectStyles = {
        // padding: '0rem .2rem',
        borderRadius: '8px',
        color: '#473C41',
        '.MuiSvgIcon-root': {
            color: '#473C41'
        },
        '.MuiSelect-select': {
            padding: '.5rem 0rem .5rem 1rem',
        },
        '.MuiSelect-select:focus': {
            backgroundColor: 'transparent',
        },
        '.MuiSelect-selectMenu': {
            padding: '.3rem 0rem .3rem 1rem'
        },
        '.MuiOutlinedInput-notchedOutline': {
            border: '2px solid #473C41',
            ":hover fieldset": {
                borderColor: "yellow"
            },
        },
        '.MuiOutlinedInput-notchedOutline:active': {
            border: '2px solid #2ED588',
        },
        ':hover .MuiOutlinedInput-notchedOutline': {
            borderColor: '#2ED588',
            borderWidth: '2px',
        },
    }

    const menuItemStyles = {
        justifyContent: 'center',
        fontFamily: 'Poppins',
        alignItems: 'center',
        fontWeight: '500',
        display: 'flex',
    }

    const LabelStyles = {
        color: '#473C41 !important',
        fontFamily: 'Poppins',
        fontSize: '15px',
        fontWeight: '500'
    }
    
    const handleChange = (newValue) => {
        // console.log( 'VALUE --> ', newValue.target.value );
        if (id === 'endHour') {
            changeEndRangeHours({
                index,
                endHour: newValue.target.value
            });
        } else {
            changeStartRangeHours({
                index,
                startHour: newValue.target.value,
            });
        }
    }

    return (
        <ThemeProvider theme={ theme }>
            <FormControl 
                size="small" 
                sx={{ 
                    m: 'auto 0', 
                    width: 100,
                    '@media(min-width: 1000px)': {
                        m: 'auto .5rem',
                    } 
                }}
            >
                <InputLabel sx={ LabelStyles }>{ id === 'endHour' ? 'Hasta' : 'Desde' }</InputLabel>
                <Select
                    value={ id === 'endHour' ? endHour : startHour }
                    onChange={ ( e ) => handleChange( e ) }
                    disabled={ !active }
                    label={ id === 'endHour' ? 'Hasta' : 'Desde' }
                    sx={ selectStyles }
                    MenuProps={{ 
                        PaperProps: { 
                            sx: { 
                                overflowY: 'auto', 
                                maxHeight: 130, 
                                "@media (min-width: 500px)": {
                                    maxHeight: 200,
                                }, 
                            } 
                        } 
                    }}
                >
                    {
                        hoursArray.map((hour) => 
                            <MenuItem value={ hour.value } sx={ menuItemStyles } key={ hour.value }>
                                { hour.label }
                            </MenuItem>
                        )
                    }
                </Select>
            </FormControl>
        </ThemeProvider>
    )
}

const mapStateToProps = state => {
    return {
        countryCodePhone: state.stepsUser.countryCodePhone,
    }
}

const mapDispatchToProps = dispatch => {
    return {
        changeStartRangeHours: ({ startHour, index }) => dispatch(changeStartRangeHours({ startHour, index })),
        changeEndRangeHours: ({ endHour, index }) => dispatch(changeEndRangeHours({ endHour, index })),
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(SelectHours);