import React from 'react';

const WebIcon = () => {
    return (
        <svg width="26" height="26" viewBox="0 0 26 26" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M4.33325 19.5C3.73742 19.5 3.22753 19.288 2.80359 18.8641C2.37892 18.4394 2.16659 17.9292 2.16659 17.3333V5.41667C2.16659 4.82083 2.37892 4.31058 2.80359 3.88592C3.22753 3.46197 3.73742 3.25 4.33325 3.25H21.6666C22.2624 3.25 22.7727 3.46197 23.1973 3.88592C23.6213 4.31058 23.8333 4.82083 23.8333 5.41667V17.3333C23.8333 17.9292 23.6213 18.4394 23.1973 18.8641C22.7727 19.288 22.2624 19.5 21.6666 19.5H4.33325ZM4.33325 17.3333H21.6666V5.41667H4.33325V17.3333ZM2.16659 22.75C1.85964 22.75 1.60253 22.646 1.39525 22.438C1.18725 22.2307 1.08325 21.9736 1.08325 21.6667C1.08325 21.3597 1.18725 21.1026 1.39525 20.8953C1.60253 20.6873 1.85964 20.5833 2.16659 20.5833H23.8333C24.1402 20.5833 24.3973 20.6873 24.6046 20.8953C24.8126 21.1026 24.9166 21.3597 24.9166 21.6667C24.9166 21.9736 24.8126 22.2307 24.6046 22.438C24.3973 22.646 24.1402 22.75 23.8333 22.75H2.16659ZM4.33325 17.3333V5.41667V17.3333Z" fill="#2ED588"/>
        </svg>
    )
}

export default WebIcon;