import React from 'react';

// Components
import SelectImgChat from '../SelectBackImgChat/SelectImgChat';
import SelectColors from '../SelectColorsChat/SelectColors';

// Layout
import LayoutStepsChat from '../../../layout/LayoutStepsChat';

// Material Ui
import { Box } from '@mui/material';

// CSS
import Styles from './Step2Chat.module.css';

const TitleContainerStyles = {
    maxWidth: '680px',
    margin: 'auto',
    '@media(min-width: 1600px)': {
        margin: '0'
    }
}

const Step2Chat = () => {
    return (
        <LayoutStepsChat>
            <Box sx={ TitleContainerStyles }>
                <h1 className={Styles.Title}>Personalizar colores</h1>
                <p>Elegí el o los colores que va a tener la ventana de tu chat</p>
            </Box>
            <SelectColors/>
            <SelectImgChat/>
        </LayoutStepsChat>
    )
}

export default Step2Chat;