export const errorCodeCreateSuscription = (errors) => {
    const erroresEnFormato = [];
    if (errors.length > 0) {
        errors.forEach(error => {
            switch (error.code) {
                case '106':
                    erroresEnFormato.push('No puedes realizar pagos a otros países.');
                    break;
                case '109':
                    erroresEnFormato.push('Tu proveedor de tarjeta no procesa pagos con estas cuotas. Elige otra tarjeta u otro medio de pago.');
                    break;
                case '126':
                    erroresEnFormato.push('No pudimos procesar tu pago. Inténtalo de nuevo.');
                    break;
                case '129': 
                    erroresEnFormato.push('Tu proveedor de tarjeta no procesa pagos del monto seleccionado. Elige otra tarjeta u otro medio de pago.');
                    break; 
                case '145':
                    erroresEnFormato.push('Una de las partes con la que intentas hacer el pago es de prueba y la otra es usuario real.');
                    break; 
                case '150':
                    erroresEnFormato.push('No puedes realizar pagos');
                    break; 
                case '151':
                    erroresEnFormato.push('No puedes realizar pagos');
                    break;
                case '160':
                    erroresEnFormato.push('No pudimos procesar tu pago');
                    break;
                case '204':
                    erroresEnFormato.push('Tu proveedor de tarjeta no está disponible en este momento. Elige otra tarjeta u otro medio de pago.')
                    break;
                case '801':
                    erroresEnFormato.push('Realizaste un pago similar hace instantes. Inténtalo de nuevo.');
                    break;
                default:
                    erroresEnFormato.push('No pudimos procesar tu pago. Inténtalo de nuevo.');
                break;
            }
        })
    }
    return erroresEnFormato;
}

export const errorCodeValidation = (errors) => {
    const erroresEnFormato = {
        cardNumber: '',
        cardExpirationMonth: '',
        cardExpirationYear: '',
        securityCode: '',
        cardholderName: '',
        documentNumber: '',
        documentType: '',
        cardIssuerId: '',
    };
    if(typeof errors === 'undefined') return erroresEnFormato;
    if (errors.length > 0) {
        errors.forEach(error => {
            switch (error.code) { 
                case '205':
                    erroresEnFormato.cardNumber = 'Ingresa el número de la tarjeta.';
                break;
                case '208':
                    erroresEnFormato.cardExpirationMonth = 'Elige un mes.';
                break;
                case '209':
                    erroresEnFormato.cardExpirationYear = 'Elige un año.';
                break;
                case '212':
                    erroresEnFormato.documentType = 'Ingresa un tipo de documento.';
                break;
                case '213':
                    erroresEnFormato.documentNumber = 'Ingresa tu documento.';
                break;
                case '214':
                    erroresEnFormato.documentNumber = 'Ingresa tu documento.';
                break;
                case '220':
                    erroresEnFormato.cardIssuerId = 'Ingresa un banco.';
                break;
                case '221':
                    erroresEnFormato.cardholderName = 'Ingresa el nombre y apellido.';
                break;
                case '224':
                    erroresEnFormato.securityCode = 'Ingresa el código de seguridad.';
                break;
                case 'E203':
                    erroresEnFormato.securityCode = 'Revisa el código de seguridad.';
                break;
                case 'E301':
                    erroresEnFormato.cardNumber = 'Revisa el número de la tarjeta.';
                break;
                case 'E302':
                    erroresEnFormato.securityCode = 'CVV incorrecto.';
                break;
                case '316':
                    erroresEnFormato.cardholderName = 'Ingresá un nombre válido.';
                break;
                case '322':
                    erroresEnFormato.documentType = 'Ingresá un tipo de documento válido.';
                break;
                case '323':
                    erroresEnFormato.documentNumber = 'El documento es inválido.';
                break;
                case '324':
                    erroresEnFormato.documentNumber = 'El documento es inválido.';
                break;
                case '325':
                    erroresEnFormato.cardExpirationMonth = 'El mes es inválido.';
                break;
                case '326':
                    erroresEnFormato.cardExpirationYear = 'El año es inválido.';
                break;
                default :
                    erroresEnFormato.default= 'Hay errores en los campos ingresados. Por favor revisalos y vuelve a intentarlo.';
                break;
            }
        })
    }
    // if(typeof errors[0].code === 'undefined' && typeof errors[0].message !== 'undefined'){
    //     erroresEnFormato.generic = errors[0].message;
    // }
    return erroresEnFormato;
}

export const messagesToSpanish = (messages) => {
    const mensajesEnFormato = {
        cardNumber: '',
        cardExpirationMonth: '',
        cardExpirationYear: '',
        securityCode: '',
        cardholderName: '',
        documentNumber: '',
        documentType: '',
        cardIssuerId: '',
    };
    if(typeof messages === 'undefined') return mensajesEnFormato;
    if (messages.length > 0) {
        messages.forEach(message => {
            switch (message.message) { 
                case 'parameter cardNumber can not be null/empty':
                    mensajesEnFormato.cardNumber = 'Ingresa el número de la tarjeta.';
                break;
                case 'parameter expirationMonth can not be null/empty':
                    mensajesEnFormato.cardExpirationMonth = 'Elige un mes.';
                break;
                case 'parameter expirationYear can not be null/empty':
                    mensajesEnFormato.cardExpirationYear = 'Elige un año.';
                break;
                case 'parameter securityCode can not be null/empty':
                    mensajesEnFormato.securityCode = 'Ingresa el código de seguridad.';
                break;
                case 'parameter cardholderName can not be null/empty':
                    mensajesEnFormato.cardholderName = 'Ingresa el nombre y apellido.';
                break;
                case 'parameter docNumber can not be null/empty':
                    mensajesEnFormato.documentNumber = 'Ingresa tu documento.';
                break;
                case 'parameter docType can not be null/empty':
                    mensajesEnFormato.documentType = 'Ingresa un tipo de documento.';
                break;
                case 'parameter cardIssuerId can not be null/empty':
                    mensajesEnFormato.cardIssuerId = 'Ingresa un banco.';
                break;
                case 'The parameter cardholder.document.subtype can not be null or empty':
                    mensajesEnFormato.documentType = 'Ingresa un tipo de documento.';
                break;
                case 'invalid parameter cardNumber':
                    mensajesEnFormato.cardNumber = 'El número de la tarjeta es inválido.';
                break;
                case 'invalid parameter expirationMonth':
                    mensajesEnFormato.cardExpirationMonth = 'El mes es inválido.';
                break;
                case 'invalid parameter expirationYear':
                    mensajesEnFormato.cardExpirationYear = 'El año es inválido.';
                break;
                case 'invalid parameter securityCode':
                    mensajesEnFormato.securityCode = 'El código de seguridad es inválido.';
                break;
                case 'invalid parameter cardholderName':
                    mensajesEnFormato.cardholderName = 'El nombre y apellido es inválido.';
                break;
                case 'invalid parameter docNumber':
                    mensajesEnFormato.documentNumber = 'El documento es inválido.';
                break;
                case 'invalid parameter docType':
                    mensajesEnFormato.documentType = 'El tipo de documento es inválido.';
                break;
                case 'invalid parameter cardIssuerId':
                    mensajesEnFormato.cardIssuerId = 'El banco es inválido.';
                break;
                case 'invalid parameter cardholder.document.subtype':
                    mensajesEnFormato.documentType = 'El tipo de documento es inválido.';
                break;
                default:
                    mensajesEnFormato.default = 'Hay errores en los campos ingresados. Por favor revisalos y vuelve a intentarlo.';
                break;
            }
        })
    }
    return mensajesEnFormato;
}

export const suscriptionErrorsToSpanish = (message) => {
    switch (message) {
        case 'cc_rejected_bad_filled_card_number':
            return 'El número de la tarjeta es inválido.';
        case 'cc_rejected_bad_filled_date':
            return 'El mes o el año son inválidos.';
        case 'cc_rejected_bad_filled_security_code':
            return 'El código de seguridad es inválido.';
        case 'cc_rejected_bad_filled_other':
            return 'Hay errores en los campos ingresados. Por favor revisalos y vuelve a intentarlo.';
        case 'cc_rejected_blacklist':
            return 'No pudimos procesar la suscripción. Por favor intenta con otra tarjeta.';
        case 'cc_rejected_call_for_authorize':
            return 'Llama a tu proveedor para activar tu tarjeta o usa otro medio de pago. El teléfono está al dorso de tu tarjeta.';
        case 'cc_rejected_card_error':
            return 'No pudimos procesar la suscripción. Por favor intenta con otra tarjeta.';
        case 'cc_rejected_duplicated_payment':
            return 'Ya realizaste un pago por este valor. Si necesitas volver a pagar usa otra tarjeta u otro medio de pago.';
        case 'cc_rejected_high_risk':
            return 'Tu suscripcion fue rechazada. Te recomendamos usar otros medios.';
        case 'cc_rejected_insufficient_amount':
            return 'Tu tarjeta no tiene fondos suficientes para el pago de verificación';
        case 'cc_rejected_invalid_installments':
            return 'Tu tarjeta no procesa suscripciones en estas cuotas';
        case 'cc_rejected_max_attempts':
            return 'Llegaste al límite de intentos permitidos.';
        case 'cc_rejected_other_reason':
            return 'No pudimos procesar tu suscripción. Por favor intenta con otra tarjeta.';
        case 'Card token was used, please generate new':
            return 'Para intentar nuevamente recargue la página.';
        default:
            return 'No pudimos procesar tu suscripción. Por favor intenta nuevamente';
    }
}