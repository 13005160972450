import { Button } from '@mui/material';
import React from 'react';

const SaveButtonAdvanced = ({ onClick }) => {
    return (
        <Button
            sx={{
                boxShadow: '0px 2.25103px 2.25103px rgba(0, 0, 0, 0.25)',
                backgroundColor: '#2ED588',
                padding: '.3rem 1.2rem',
                textTransform: 'none',
                fontFamily: 'Poppins',
                position: 'fixed',
                borderRadius: '8px',
                fontWeight: '600',
                fontSize: '.9rem',
                display: 'flex',
                color: 'white',
                bottom: '5%',
                left: '40%',
                '&:hover': {
                    backgroundColor: '#2ED588',
                    color: 'white',
                },
                '@media (min-width: 350px)': {
                    bottom: '10%',
                },
                '@media (min-width: 1550px)': {
                    bottom: '20%',
                },
                '@media (min-width: 800px)': {
                    left: '52%',
                },
            }}
            onClick={ onClick }
        >
            Guardar
        </Button>
    )
}

export default SaveButtonAdvanced;