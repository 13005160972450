import React from 'react';

// Material Ui
import { Button, Fade, Modal } from '@mui/material';
import Backdrop from '@mui/material/Backdrop';
import { Box } from '@mui/system';

// CSS
import Styles from './LogOutModal.module.css';
import LogoutIcon from '../../../assets/images/Modal/LogoutIcon';

const LogOutModal = ({ showModalLogout, handleClose }) => {

    const style = {
        transform: 'translate(-50%, -50%)',
        border: '1px solid #BFBBBD',
        position: 'absolute',
        borderRadius: '10px',
        textAlign: 'center',
        bgcolor: 'white',
        width: '75%',
        left: '50%',
        top: '50%',
        p: '2rem',
        "@media (min-width: 500px)": {
            width: '320px'
        },
        "@media (min-width: 900px)": {
            width: '340px'
        },
        ':focus-visible': {
            outlined: 'none'
        }
    };

    const buttonCancel = {
        backgroundColor: '#ECEBEC',
        padding: '.5rem 2rem',
        fontFamily: 'Poppins',
        textTransform: 'none',
        borderRadius: '8px',
        fontWeight: '500',
        fontSize: '.9rem',
        color: '#BFBBBD',
        '&:hover': {
            backgroundColor: '#ECEBEC'
        }
    };

    const buttonOK = {
        backgroundColor: '#2ED588',
        padding: '.5rem 2rem',
        fontFamily: 'Poppins',
        textTransform: 'none',
        borderRadius: '8px',
        fontWeight: '500',
        fontSize: '.9rem',
        color: 'white',
        '&:hover': {
            backgroundColor: '#2ED588'
        }
    };

    const handleCloseModal = () => {
        handleClose();
    }

    const handleLogOut = () => {
        localStorage.clear();
        sessionStorage.clear();
        window.location.href = window.location.origin;
    }
    return (
        <Modal
            aria-labelledby="transition-modal-title"
            aria-describedby="transition-modal-description"
            open={ showModalLogout }
            onClose={ handleClose }
            closeAfterTransition
            BackdropComponent={ Backdrop }
            BackdropProps={{
                timeout: 500,
            }}
        >
            <Fade in={ showModalLogout }>
                <Box sx={ style }>
                    <LogoutIcon/>
                    <div className={Styles.LogOutModalContainer}>
                        <h1>Cerrar sesión</h1>
                        <p>¿Estás seguro que querés salir de WhatsPRO?</p>
                        <div className={Styles.ButtonsContainer}>
                            <Button
                                sx={ buttonOK }
                                onClick={ handleLogOut }
                            >
                                Si
                            </Button>
                            <Button
                                sx={ buttonCancel }
                                onClick={ handleCloseModal }
                            >
                                Cancelar
                            </Button>
                        </div>
                    </div>
                </Box>
            </Fade>
        </Modal>
    )
}

export default LogOutModal;