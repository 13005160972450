import React, { useEffect, useState } from 'react';

import { useNavigate } from 'react-router-dom';
import { connect } from 'react-redux';
import useFetch from 'use-http';

// Actions
import {  
    setShowErrorUserModal, 
    setShowSaveUserModal, 
    setCompleteStep, 
    setUserEditing, 
    setActiveStep, 
    setUser,
    setShowUserModalComplete, 
} from '../../actions/stepsUser';
import { setChangeNewEmployee, setEditEmployee } from '../../actions/users';
import { onSetLeaveSaveChangesChat } from '../../actions/stepsChat';
import { setActiveTask, setTaskState } from '../../actions/tasks';
import { resetAll } from '../../actions/global';

// Configuration
import { MAIN_API_URL } from '../../configuration';

// Icons
import ArrowBackRoundedIcon from '@mui/icons-material/ArrowBackRounded';

// Material Ui
import { Box, Button, Stack } from '@mui/material';

const buttonsContainer = {
    justifyContent: 'space-between',
    padding: '1rem 0rem',
    maxWidth: '630px',
    display: 'flex',
    margin: 'auto',
    width: '100%',
};

const editButtonStyles = {
    border: '1px solid #2ED588',
    backgroundColor: '#FFFFFF',
    textTransform: 'none',
    fontFamily: 'Poppins',
    borderRadius: '8px',
    boxShadow: 'none',
    color: '#2ED588',
    ':hover': {
        backgroundColor: '#B2F1D4',
        boxShadow: 'none',
        color: '#2ED588',
    },
};

const ButtonsContainerStepper = (props) => {

    // Actions
    const { 
        onSetLeaveSaveChangesChat,
        setShowUserModalComplete, 
        setShowErrorUserModal,
        setChangeNewEmployee, 
        activeStepRedux, 
        setEditEmployee,
        setActiveTask,
        setTaskState, 
        resetAll, 
        setUser, 
    } = props;

    // States
    const { 
        allowOutHourMessage,
        enabledRangeHours,
        welcomeMessage,
        outHourMessage,
        completedStep, 
        srcImgProfile,
        comunication,
        errorSetHour, 
        phoneNumber,
        userEditing,
        activeStep,
        activeTask,
        userData, 
        userName,
        position,
        userUid,
        hours,
    } = props;

    const DaysArray = ['D','L','M', 'M', 'J', 'V', 'S'];

    const [disabledNextUser, setDisabledNextUser] = useState( true );
    const [employeesArray, setEmployeesArray] = useState([]);
    const [disabledBack, setDisabledBack] = useState(false);
    const [channelsArray, setChannelsArray] = useState([]);
    const [disabledCount, setDisabledCount] = useState(0);
    const [schedules, setSchedules] = useState([]);
    const [store, setStore] = useState([]);
    const navigate = useNavigate();

    const nextButtonStyles = {
        display: userEditing ? 'none' : 'flex',
        marginLeft: '18px !important',
        backgroundColor: '#2ED588',
        textTransform: 'none',
        fontFamily: 'Poppins',
        borderRadius: '8px',
        boxShadow: 'none',
        color: 'white',
        ':hover': {
            backgroundColor: '#2ED588',
            boxShadow: 'none',
            color: 'white',
            '@media(min-width: 800px)': {
                backgroundColor: '#B2F1D4',
                color: '#2ED588',
            },
        },
        '@media(min-width: 400px)': {
            display: 'flex',
        },
    };

    const backButtonStyles = {
        backgroundColor: 'transparent',
        textTransform: 'none',
        fontFamily: 'Poppins',
        alignItems: 'center',
        borderRadius: '8px',
        color: '#BDBDBD',
        display: 'flex',
        opacity: activeStep === 0 ? 0 : 1,
        ':hover': {
            backgroundColor: 'transparent',
            boxShadow: 'none',
            color: '#2ED588',
            '@media(min-width: 800px)': {
                backgroundColor: '#B2F1D4',
            },
        },
    };

    const { error, post, response, put } = useFetch(`${MAIN_API_URL}/employees`, {
        headers: {
            "x-token": `${localStorage.getItem("token")}`
        }
    });

    const { error: errorG, get, response: responseG } = useFetch(`${MAIN_API_URL}/stores`, {
        headers: {
            "x-token": `${localStorage.getItem("token")}`
        }
    });

    useEffect(() => { getStore(); }, []);
    
    const getStore = async() => {
        const resp = await get("/");
        if (responseG.ok) {
            setStore(resp);
        } else {
            console.log(errorG);
        }
    }

    // User Validation Form
    useEffect(() => {
        switch (activeStep) {
            case 0:
                    setShowSaveUserModal( false );
                    if ( userName === '' || position === '' || phoneNumber === '' ) {
                        setDisabledNextUser( true );
                    } else {
                        setDisabledNextUser( false );
                    }
                break;
            case 1:
                for (let i = 0; i < hours.length; i++) {
                    if ( hours[i].disabled ) {
                        setDisabledCount( disabledCount + 1 );
                    } else {
                        return;
                    }
                }
                break;
            case 2:
                    if ( welcomeMessage === '' || ( allowOutHourMessage === true && outHourMessage === '' ) ) {
                        setDisabledNextUser( true );
                    } else {
                        setDisabledNextUser( false );
                    }
                break;
            case 4:
                    setShowSaveUserModal( true );
                break;
        
            default:
                break;
        }
    }, [ activeStep, userName, position, phoneNumber, welcomeMessage, allowOutHourMessage, outHourMessage, setShowSaveUserModal, errorSetHour, hours ]);

    useEffect(() => {
        switch (true) {
            case ( comunication.whatsApp && comunication.sms && comunication.call ):
                setChannelsArray(['whatsapp', 'cellphone', 'sms']);
                break;
            case ( comunication.whatsApp && comunication.sms ):
                setChannelsArray(['whatsapp', 'sms']);
                break;
            case ( comunication.whatsApp && comunication.call ):
                setChannelsArray(['whatsapp', 'cellphone']);
                break;
            case ( comunication.sms && comunication.call ):
                setChannelsArray(['cellphone', 'sms']);
                break;
            case ( comunication.whatsApp ):
                setChannelsArray(['whatsapp']);
                break;
            case ( comunication.sms ):
                setChannelsArray(['sms']);
                break;
            case ( comunication.call ):
                setChannelsArray(['cellphone']);
                break;
            default:
                setChannelsArray([]);
                break;
        }
    }, [comunication]);

    useEffect(() => {
        if (!enabledRangeHours) {
            setSchedules([{
                id: 0,
                index: 0,
                activeDays: DaysArray,
                name: 'Horario 1',
                days: DaysArray,
                start: 0,
                end: 1440,
                state: true
            }]);
        } else {
            setSchedules(hours);
        }
    }, [hours, enabledRangeHours, userEditing]);

    const addUser = async () => {
        // console.log(userData)
        const newUser = await post('/', {
            "name": userData.userName,
            "phoneNumber": {
                "phoneNumber": userData.phoneNumber,
                "prefix": userData.prefix,
                "country": userData.dialCode,
                "countryCode": `${userData.countryCode}`
            },
            "avatar": srcImgProfile !== '' ? srcImgProfile : 'https://firebasestorage.googleapis.com/v0/b/whatspro-e3124.appspot.com/o/Avatares%2FProfile1Logo.png?alt=media&token=67519ce8-6f32-44f6-a9d3-5e101010abb8',
            "role": userData.position.label,
            "schedule": schedules.map((hour) => ({ 
                id: hour.id,
                index: hour.index,
                activeDays: hour.activeDays,
                name: hour.name,
                days: hour.days,
                start: hour.start,
                end: hour.end,
                state: hour.activeHour
            })),
            "allowOutOfScheduleMessage": allowOutHourMessage,
            "state": true,
            "welcomeMessage": welcomeMessage,
            "outMessage": outHourMessage,
            "channels": channelsArray,
            "enabledRangeHours": enabledRangeHours,
        });
        
        if (response.ok) {
            setEmployeesArray([...employeesArray, newUser]);
            setChangeNewEmployee({ 
                newUser: true
            });
            if ( store.onBoardingFinished ) {
                navigate('/myUsers');
            } else {
                setTaskState({ number: (activeTask + 1), data: 'active' });
                setTaskState({ number: (activeTask), data: 'completed' });
                onSetLeaveSaveChangesChat(true);
                setShowUserModalComplete(true);
            }
            
        } else {
            setShowErrorUserModal(true);
            navigate('/myUsers');
            console.log(error);
        };
    }

    const editUser = async () => {
        // console.log(userData)
        const newUser = await put(`/${userUid}`, {
            "name": userData.userName,
            "phoneNumber": {
                "phoneNumber": userData.phoneNumber,
                "prefix": userData.prefix,
                "country": userData.dialCode,
                "countryCode": `${userData.countryCode}`
            },
            "avatar": srcImgProfile,
            "role": userData.position.label,
            "schedule": schedules.map((hour) => ({ 
                id: hour.id,
                index: hour.index,
                activeDays: hour.activeDays,
                name: hour.name,
                days: hour.days,
                start: hour.start,
                end: hour.end,
                state: hour.activeHour
            })),
            "allowOutOfScheduleMessage": allowOutHourMessage,
            "state": true,
            "welcomeMessage": welcomeMessage,
            "outMessage": outHourMessage,
            "channels": channelsArray,
            "enabledRangeHours": enabledRangeHours,
        });

        if (response.ok) {
            setEmployeesArray([...employeesArray, newUser]);
            setEditEmployee({
                editUser: true
            });
            resetAll();
            if ( !store.onBoardingChat ) {
                navigate('/chat');
            } else {
                navigate('/myUsers');
            }
        } else {
            setShowErrorUserModal(true);
            navigate('/myUsers');
            console.log(error);
        };
    }

    const handleChangeStep = (type) => {
        if (activeStep >= 0) {
            if (type === 'next') {
                if (activeStep <= 4) {
                    if (activeStep === 3) {
                        if ( userEditing ) {
                            editUser();
                        } else {
                            addUser();
                        }
                        setUser({
                            "name": userData.userName,
                            "phoneNumber": {
                                "phoneNumber": userData.phoneNumber,
                                "prefix": userData.prefix,
                                "country": userData.dialCode,
                                "countryCode": `+${userData.countryCode}`
                            },
                            "avatar": srcImgProfile,
                            "role": userData.position.label,
                            "schedule": hours,
                            "allowOutOfScheduleMessage": allowOutHourMessage,
                            "state": true,
                            "welcomeMessage": welcomeMessage,
                            "outMessage": outHourMessage,
                            "channels": channelsArray
                        });
                    } else {
                        let step = activeStep + 1;
                        activeStepRedux(step);
                    }
                } else {
                    return;
                }
            } else {
                let step = activeStep - 1;
                let reduxC = completedStep;
                reduxC[step] = false;
                activeStepRedux(step);
            }
        } else {
            return;
        }
    }

    useEffect(() => {
        if ( activeStep > 0 ) {
            setDisabledBack(false);
        } else {
            setDisabledBack(true);
        }
}, [ activeStep ]);

    const handleComplete = () => {

        let reduxC = completedStep;
        reduxC[activeStep] = true;

        handleChangeStep('next');

    };
    
    return (
        <Box>
            <Stack sx={buttonsContainer}  direction="row" alignItems="center" justifyContent="space-between" spacing={3}>
                <Stack sx={{ width: '100%' }} direction="row" alignItems="center" justifyContent="space-between">
                    <Button 
                        sx={backButtonStyles} 
                        onClick={() => handleChangeStep('back')}
                        disabled={ disabledBack }
                    >
                        <ArrowBackRoundedIcon fontSize="small" />
                        &nbsp; Volver
                    </Button>
                    <Stack direction="row" alignItems="center" spacing={3}>
                        {
                            userEditing ? 
                                <Button 
                                    onClick={ editUser }
                                    disabled={ disabledNextUser } 
                                    sx={editButtonStyles} 
                                    variant="contained"
                                >
                                    Guardar
                                </Button>
                            :
                                null
                        }
                        <Button 
                            onClick={ handleComplete }
                            disabled={ disabledNextUser } 
                            sx={nextButtonStyles} 
                            variant="contained"
                        >
                            { activeStep === 3 ? 'Finalizar' : 'Siguiente'}
                        </Button>
                    </Stack>
                </Stack>
            </Stack>
        </Box>
    )
}

const mapStateToProps = state => {
    return {
        
        // Step 1 User
        phoneNumber: state.stepsUser.userData.phoneNumber,
        srcImgProfile: state.stepsUser.srcImgProfile,
        userName: state.stepsUser.userData.userName,
        position: state.stepsUser.userData.position,
        userData: state.stepsUser.userData,
        
        // Step 2 User
        allowOutHourMessage: state.stepsUser.allowOutHourMessage,
        enabledRangeHours: state.stepsUser.enabledRangeHours,
        welcomeMessage: state.stepsUser.welcomeMessage,
        outHourMessage: state.stepsUser.outHourMessage,
        errorSetHour: state.stepsUser.errorSetHour,
        hours: state.stepsUser.hours,

        // Advanced
        showUserModalComplete: state.stepsUser.showUserModalComplete,
        showErrorModal: state.stepsUser.showErrorModal,
        completedStep: state.stepsUser.completeStep,
        comunication: state.stepsUser.comunication,
        activeStep: state.stepsUser.activeStep,
        
        userEditing: state.stepsUser.userEditing,
        userUid: state.stepsUser.userUid,

        activeTask: state.tasks.activeTask,
    }
}

const mapDispatchToProps = dispatch => {
    return {
        setUserEditing: ({ userEditing, userUid }) => dispatch(setUserEditing({ userEditing, userUid })),
        onSetLeaveSaveChangesChat: (data) => dispatch(onSetLeaveSaveChangesChat(data)),
        setShowUserModalComplete: (data) => dispatch(setShowUserModalComplete(data)),
        setShowErrorUserModal: (value) => dispatch(setShowErrorUserModal(value)),
        setChangeNewEmployee: (value) => dispatch(setChangeNewEmployee(value)),
        setEditEmployee: (value) => dispatch(setEditEmployee(value)),
        activeStepRedux: (value) => dispatch(setActiveStep(value)),
        completeStep: (value) => dispatch(setCompleteStep(value)),
        setUser: (data) => dispatch(setUser(data)),
        resetAll: () => dispatch(resetAll()),

        setTaskState: ({ number, data}) => dispatch(setTaskState({ number, data})),
        setActiveTask: (data) => dispatch(setActiveTask(data)),
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(ButtonsContainerStepper);
