import React, { useEffect, useState } from 'react';
import { connect } from "react-redux";
import useFetch from 'use-http';

// Actions
import { setColorsChat } from '../../../actions/stepsChat';

// Configuration
import { MAIN_API_URL } from '../../../configuration';

// Icon
import CloseOutlinedIcon from '@mui/icons-material/CloseOutlined';

// Images
import BackImg1 from '../../../assets/images/Chat/BackChat/backchat1.png';
import BackImg2 from '../../../assets/images/Chat/BackChat/backchat2.png';
import BackImg3 from '../../../assets/images/Chat/BackChat/backchat3.png';
import BackImg4 from '../../../assets/images/Chat/BackChat/backchat4.png';
import BackImg5 from '../../../assets/images/Chat/BackChat/backchat5.png';
import ChatAvatars from '../../../assets/images/Chat/ChatAvatars';

// CSS
import Styles from './ChatWindow.module.css';

const ChatWindow = (props) => {

    // External props
    const { activeStep, open, handleClose } = props;

    // States
    const {
        description,
        backImgChat,
        questions,
        titleChat,
        backColor,
        colorFont,
    } = props;

    const [welcomeMessage, setWelcomeMessage] = useState(description);

    const [activeQuestions, setActiveQuestions] = useState([]);
    const [backImage, setBackImage] = useState(BackImg1);
    const [chatArray, setChatArray] = useState([]);

    const { error, response, get } = useFetch(`${MAIN_API_URL}/chat`, {
        headers: {
            "x-token": `${localStorage.getItem("token")}`
        }
    });

    useEffect(() => {
        setColorsChat({
            backColor: chatArray?.headerColour,
            colorFont: chatArray?.fontColour
        });
    }, [chatArray]);

    useEffect(() => {
        if (questions) {
            console.log(questions.filter(question => question.state));
            setActiveQuestions(questions.filter(question => question.state));
        }
    }, [questions])

    useEffect(() => {
        switch (backImgChat) {
            case 'srcImg0':
                setBackImage(BackImg1);
                break;
            case 'srcImg1':
                setBackImage(BackImg2);
                break;
            case 'srcImg2':
                setBackImage(BackImg3);
                break;
            case 'srcImg3':
                setBackImage(BackImg4);
                break;
            case 'srcImg4':
                setBackImage(BackImg5);
                break;
            default:
                setBackImage('');
                break;
        }
    }, [backImgChat]);

    useEffect(() => {
        setWelcomeMessage(description);
    }, [description]);

    return (
        <div
            className={Styles.ChatContainer}
            style={{
                backgroundImage: backImage !== '' ? `url(${backImage})` : '',
                display: open ? 'grid' : 'none',
                backgroundColor: 'white',
            }}
        >
            <div className={Styles.CrossContainer} onClick={() => handleClose()}>
                <CloseOutlinedIcon
                    sx={{
                        fontSize: '1.8rem',
                        color: 'white !important',
                        height: '2rem !important',
                        marginRight: '.5rem'
                    }}
                />
            </div>
            <div className={Styles.TitleChat} style={{ backgroundColor: backColor }}>
                <p>{titleChat}</p>
                <div className={Styles.AvatarContainer}>
                    <ChatAvatars
                        borderColor={backColor}
                    />
                </div>
            </div>
            <div className={Styles.MessagesChatContainer}>
                <p className={Styles.ComercialText}>Chat desarrollado por <span>WhatsPRO</span></p>
                <div className={Styles.WelcomeText}>
                    <p>{welcomeMessage}</p>
                </div>
                <div className={Styles.QuestionsContainer}>
                    {
                        questions.length === 0 ?
                            <div
                                style={{
                                    flexDirection: 'column',
                                    alignItems: 'end',
                                    display: 'flex',
                                }}
                            >
                                <div
                                    className={Styles.FirstQuestion}
                                >
                                    <div className={Styles.OpacityElement}></div>
                                </div>
                                <div
                                    className={Styles.FirstQuestion}
                                >
                                    <div className={Styles.OpacityElement}></div>
                                </div>
                                <div
                                    className={Styles.FirstQuestion}
                                >
                                    <div className={Styles.OpacityElement}></div>
                                </div>
                                <div
                                    className={Styles.FirstQuestion}
                                >
                                    <div className={Styles.OpacityElement}></div>
                                </div>
                            </div>
                            :
                            null
                    }
                    {
                        activeQuestions.map((item, index) => (
                            <div
                                style={{
                                    display: 'flex',
                                    justifyContent: 'right',
                                }}
                                key={index}
                            >
                                {
                                    activeStep !== 0 ?
                                        <p
                                            style={{
                                                color: colorFont !== '#2ED588' ? colorFont : backColor,
                                                backgroundColor: '#EEEEEE',
                                                width: 'max-content',
                                            }}
                                            className={Styles.Question}
                                            key={index}
                                        >
                                            {item.question}
                                        </p>
                                        :
                                        <div
                                            className={Styles.FirstQuestion}
                                        >
                                            <div className={Styles.OpacityElement}></div>
                                        </div>
                                }
                            </div>
                        ))
                    }
                    <div
                        style={{
                            justifyContent: 'right',
                            display: 'flex',
                        }}
                    >
                        {
                            activeStep !== 0 ?
                                <p
                                    style={{
                                        backgroundColor: colorFont !== '#2ED588' ? colorFont : backColor,
                                        justifyContent: 'right',
                                        display: 'flex',
                                        color: 'white',
                                    }}
                                    className={Styles.Question}
                                >Hablar con un asesor</p>
                                :
                                <div
                                    style={{
                                        backgroundColor: colorFont !== '#2ED588' ? colorFont : backColor,
                                    }}
                                    className={Styles.LastQuestion}
                                >
                                    <div className={Styles.OpacityElement}></div>
                                </div>
                        }
                    </div>
                </div>
            </div>
        </div>
    )
}

const mapStateToProps = state => {
    return {
        backImgChat: state.stepsChat.backImgChat,
        description: state.stepsChat.description,
        colorFont: state.stepsChat.colorFont,
        titleChat: state.stepsChat.titleChat,
        backColor: state.stepsChat.backColor,
        questions: state.stepsChat.activeTemplate.answers,
    }
}

const mapDispatchToProps = dispatch => {
    return {
        setColorsChat: (value) => dispatch(setColorsChat(value)),
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(ChatWindow);
