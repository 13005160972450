import React, { useEffect, useState } from "react";
import { Outlet, useNavigate } from "react-router-dom";
import { connect } from 'react-redux';

// Actions
import { refreshLogin, checkValidToken, checkIsLogIn, getStore, changeIsLogIn, changeStore } from '../../actions/auth';

// Components
import LoaderFlowy from "../Loader/LoaderFlowy/LoaderFlowy";

const GuardRoute = (props) => {

    // Actions
    const { checkIsLogIn, redirectTo, getStore, changeIsLogIn, checkValidToken, refreshLogin } = props;

    // States
    const { store, isLogIn, type } = props;

    const [returnChild, setReturnChild] = useState(false);
    // const location = useLocation();
    const navigate = useNavigate();

    useEffect(() => {

        const exitToken = async () => {
            const existToken = await checkIsLogIn();
            if (existToken) {
                let validToken = await checkValidToken();
                if (!validToken) {
                    let isRefreshed = await refreshLogin();
                    if (!isRefreshed) {
                        localStorage.removeItem("token");
                        localStorage.removeItem("refreshToken");
                        window.location.href = '/';
                    }
                }
            }

            if (!existToken && type === "private") {
                window.location.href = redirectTo || "/";
            }
            else if (existToken && type === "public") {
                if (store === undefined) await getStore();
                window.location.href = redirectTo || "/home";
            }
            else if (type === "public") {
                setReturnChild(true);
            }
            else {
                if (!existToken) {
                    navigate('/');
                }
            }

            changeIsLogIn(existToken);
        }
        exitToken();
    }, []);

    return (isLogIn === true || returnChild === true ? <Outlet /> : <LoaderFlowy />);
}

const mapStateToProps = state => {
    return {
        isLogIn: state.auth.isLogIn,
        loading: state.auth.loading,
        store: state.auth.store,
    }
}

const mapDispatchToProps = dispatch => {
    return {
        changeIsLogIn: (value) => dispatch(changeIsLogIn(value)),
        changeStore: (value) => dispatch(changeStore(value)),
        checkValidToken: () => dispatch(checkValidToken()),
        refreshLogin: () => dispatch(refreshLogin()),
        checkIsLogIn: () => dispatch(checkIsLogIn()),
        getStore: () => dispatch(getStore()),
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(GuardRoute);