import React, { useState, useEffect } from 'react';
import { connect } from 'react-redux';

// Actions
import { getStore } from '../../actions/auth';

// Components
import Task4SubCongratsModal from '../../components/Modals/CongratsModals/Task4SubCongratsModal';
import MercadoPagoForm from '../../components/MercadoPago/MercadoPagoForm';

// CSS
import Styles from './PaymentScreen.module.css';

const PaymentScreen = (props) => {

    // Actions
    const { getStore, store } = props;

    const urlParams = new URLSearchParams(window.location.search);

    const [showCongratsModal, setShowCongratsModal] = useState(false);
    const [subError, setSubError] = useState(null);

    const [formCondition, setFormCondition] = useState({
        paymentProcessador: 'MERCADO_PAGO',
        paymentType: 'subscription',
        buttonText: 'Suscribirme',
    });

    useEffect(() => {

        let token = localStorage.getItem('token');
        getStore(token);

    },[getStore]);

    useEffect(() => {
        if(localStorage.getItem('subError')){
            setSubError(JSON.parse(localStorage.getItem('subError')));
            localStorage.removeItem('subError');
        }
    },[]);

    useEffect(() => {
        if(store){
            const isChangePaymentMethod = urlParams.get('changePaymentMethod');

            //Casos donde no se puede ingresar a la pantalla de pago
            if(isChangePaymentMethod && (!store.suscriptionData || (store.suscriptionData && store.suscriptionData.status !== 'authorized'))){
                window.location.href = '/card';
            }
            if(!isChangePaymentMethod && store.suscriptionData && store.suscriptionData.status === 'authorized'){
                // window.location.href = '/home';
                setShowCongratsModal( true );
            }

            //Casos donde si se puede ingresar a la pantalla de pago, ahora elegimos el procesador de pago
            if(store.mainLanguage !== 'es'){
                if(isChangePaymentMethod){
                    setFormCondition({
                        paymentProcessador: 'STRIPE',
                        paymentType: 'changePaymentMethod',
                        buttonText: 'Cambiar método de pago',
                    });
                } else{
                    setFormCondition({
                        paymentProcessador: 'STRIPE',
                        paymentType: 'subscription',
                        buttonText: 'Suscribirme',
                    });
                }
            } else{
                if(isChangePaymentMethod){
                    setFormCondition({
                        paymentProcessador: 'MERCADO_PAGO',
                        paymentType: 'changePaymentMethod',
                        buttonText: 'Cambiar método de pago',
                    });
                } else{
                    setFormCondition({
                        paymentProcessador: 'MERCADO_PAGO',
                        paymentType: 'subscription',
                        buttonText: 'Suscribirme',
                    });
                }
            }
        }
    },[store]);

    useEffect(() => {
        console.log('Store --> ', store);
    }, [store]);

    const handleShowCongratsModal = () => {
        setShowCongratsModal( !showCongratsModal );
    }
    
    return (
        <div className={Styles.PaymentFormContainer}>
            <MercadoPagoForm
                subError={subError}
                {...formCondition}
                store={store}
            />
            <Task4SubCongratsModal 
                open={ showCongratsModal } 
                handleClose={ handleShowCongratsModal } 
                origin={ origin }
                task={ 'sub' }
            />
        </div>
    )
}

const mapStateToProps = state => {
    return {
        isLogIn: state.auth.isLogIn,
        loading: state.auth.loading,
        store: state.auth.store,
    }
}

const mapDispatchToProps = dispatch => {
    return {
        getStore: (value) => dispatch(getStore(value))
    }
}
export default connect(mapStateToProps, mapDispatchToProps)(PaymentScreen);