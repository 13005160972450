export const DefaulUsersImages = [
    {
        src: 'https://firebasestorage.googleapis.com/v0/b/whatspro-e3124.appspot.com/o/Avatares%2FProfile1Logo.png?alt=media&token=67519ce8-6f32-44f6-a9d3-5e101010abb8',
        id: 0
    },
    {
        src: 'https://firebasestorage.googleapis.com/v0/b/whatspro-e3124.appspot.com/o/Avatares%2FProfile2Logo.png?alt=media&token=8969fa54-4c44-405c-860e-93bdbcf6cb6a',
        id: 1
    },
    {
        src: 'https://firebasestorage.googleapis.com/v0/b/whatspro-e3124.appspot.com/o/Avatares%2FProfile3Logo.png?alt=media&token=ecbcaea0-86a5-4655-b6c9-3cc41f95f3aa',
        id: 2
    },
    {
        src: 'https://firebasestorage.googleapis.com/v0/b/whatspro-e3124.appspot.com/o/Avatares%2FProfile4Logo.png?alt=media&token=ad12a178-a8c7-4896-8ef6-ec88686b858c',
        id: 3
    },
    {
        src: 'https://firebasestorage.googleapis.com/v0/b/whatspro-e3124.appspot.com/o/Avatares%2FProfile5Logo.png?alt=media&token=dfea257c-613d-4d7a-8f0a-c3bd5ecc3c3c',
        id: 4
    },
    {
        src: 'https://firebasestorage.googleapis.com/v0/b/whatspro-e3124.appspot.com/o/Avatares%2FProfile6Logo.png?alt=media&token=078e7bbc-813c-4e41-8da3-834273adbd84',
        id: 5
    },
    {
        src: 'https://firebasestorage.googleapis.com/v0/b/whatspro-e3124.appspot.com/o/Avatares%2FProfile7Logo.png?alt=media&token=f0dd7bc8-f1af-44d5-a138-4ed432a07406',
        id: 6
    },
    {
        src: 'https://firebasestorage.googleapis.com/v0/b/whatspro-e3124.appspot.com/o/Avatares%2FProfile8Logo.png?alt=media&token=22fd5935-0051-4578-98bc-5801e3f4e90e',
        id: 7
    },
    {
        src: 'https://firebasestorage.googleapis.com/v0/b/whatspro-e3124.appspot.com/o/Avatares%2FProfile9Logo.png?alt=media&token=c07817b3-0454-4323-9df0-ad5a2f061840',
        id: 8
    },
    {
        src: 'https://firebasestorage.googleapis.com/v0/b/whatspro-e3124.appspot.com/o/Avatares%2FProfile10Logo.png?alt=media&token=0589277d-ccd1-48ff-ba13-665d9cdcb995',
        id: 9
    },
    {
        src: 'https://firebasestorage.googleapis.com/v0/b/whatspro-e3124.appspot.com/o/Avatares%2FProfile11Logo.png?alt=media&token=d7195628-6676-43a2-b1c7-46db819945cd',
        id: 10
    },
]