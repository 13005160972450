import React from 'react';

// Material Ui
import { Box } from '@mui/material';

const StepsContainer = {
    padding: '1rem 0 0rem 0rem',
    width: '100%',
}

const LayoutStepsCreateUser = ({ children }) => {
    return (
        <Box sx={ StepsContainer } >{ children }</Box>
    )
}

export default LayoutStepsCreateUser;