import React from 'react'
import { LayoutAuth } from '../../layout/LayoutAuth'
import { NewPasswordComponent } from '../../components/Auth/views/NewPasswordComponent'

export const NewPassword = () => {
  return (
    <LayoutAuth>
      <NewPasswordComponent />
    </LayoutAuth>
  )
}
