import { Avatar, AvatarGroup, Box, IconButton, Tooltip, Typography } from '@mui/material'
import React from 'react'
import ClearIcon from '@mui/icons-material/Clear';

export const GeneralHeader = ({ title = 'Hola! 👋🏻', headerColour = '#2ED588', employees = [] }) => {
    return (
        <Box
            sx={{
                background: headerColour,
                borderRadius: '14px 14px 0px 0px',
                padding: '30px 20px',
                display: 'flex',
                justifyContent: 'space-between',
                alignItems: 'center',
                position: 'sticky',
                top: 0,
            }}
        >
            <Typography
                variant="h2"
                fontWeight={600}
                fontSize={28}
                sx={{ color: 'white' }}
            >
                {title}
            </Typography>
            <AvatarGroup max={3} spacing='small'>
                {
                    employees.map((employee, index) => {
                        return (
                            <Avatar key={index} alt={employee.name} src={employee.avatar} sx={{ width: 45, height: 45, border: `2px solid ${headerColour ?? '#01C66B'} !important` }} />
                        )
                    }
                    )
                }
            </AvatarGroup>
            <Box
                position={'absolute'}
                right={0}
                top={0}
                onClick={() => window.parent.postMessage('Cerrar', '*')}
            >
                <Tooltip placement="top" arrow title={'Cerrar'}>
                    <IconButton
                        sx={{
                            alignSelf: 'center',
                            color: 'white'
                        }}
                        color="primary"
                    >
                        <ClearIcon />
                    </IconButton>
                </Tooltip>
            </Box>
        </Box >
    )
}
