import React from 'react';

// Image
import BannerHomeMobile from '../../../assets/images/Home/BannerHomeMobile.png';
import BannerHome from '../../../assets/images/Home/BannerHome.png';

// Material Ui
import { Box, Typography, useMediaQuery } from '@mui/material';

const GeneralContainerStyles = {
    boxSizing: 'border-box',
    position: 'relative', 
    marginBottom: '1rem', 
    paddingTop: '1rem',
    paddingRight: 0,
    width: '100%',
}

const TextContainer = {
    transform: 'translateY(40%)',
    position: 'absolute',
    textAlign: 'left',
    bottom: '50%',
    left: '1rem',
    width: '55%',
    '@media(min-width: 650px)': {
        transform: 'translateY(60%)',
        left: '2rem',
    },
    '@media(min-width: 950px)': {
        width: 'max-content',
    }
}

const TitleStyle = {
    marginBottom: '.2rem',
    fontWeight: '600',
    fontSize: '1rem',
    '@media(min-width: 800px)': {
        fontSize: '1.2rem',
    },
    '@media(min-width: 900px)': {
        marginBottom: '.7rem',
    },
    '@media(min-width: 1100px)': {
        fontSize: '1.5rem',
    },
    '@media(min-width: 1500px)': {
        fontSize: '2rem',
    }
} 

const MobileTitleStyle = {
    marginBottom: '.2rem',
    fontWeight: '600',
    fontSize: '.7rem',
    '@media(min-width: 350px)': {
        fontSize: '.9rem',
    },
    '@media(min-width: 400px)': {
        fontSize: '1.2rem',
    },
    '@media(min-width: 500px)': {
        fontSize: '1.7rem',
    }
} 

const SubtitleStyle = {
    fontSize: '.6rem',
    fontWeight: '500',
    '@media(min-width: 350px)': {
        fontSize: '.7rem',
    },
    '@media(min-width: 500px)': {
        fontSize: '.9rem',
    },
    '@media(min-width: 1100px)': {
        fontSize: '1rem',
    },
    '@media(min-width: 1500px)': {
        fontSize: '1.4rem',
    }
}

const MobileSubtitleStyle = {
    fontSize: '.5rem',
    fontWeight: '500',
    '@media(min-width: 350px)': {
        fontSize: '.7rem',
    },
    '@media(min-width: 400px)': {
        fontSize: '.8rem',
    },
    '@media(min-width: 500px)': {
        fontSize: '.9rem',
    }
} 

const WelcomeBanner = ({ name, onBoardingFinished }) => {

    const matches = useMediaQuery('(min-width:650px)');

    return (
        <Box sx={ GeneralContainerStyles }>
            <img src={ matches ? BannerHome : BannerHomeMobile } alt="Banner" style={{ width: '100%', position: 'relative' }} />
            <Box sx={ TextContainer }>
                <Typography sx={ matches ? TitleStyle : MobileTitleStyle }>¡Hola { name }!</Typography>
                {
                    onBoardingFinished ?
                        <Typography sx={ SubtitleStyle }>Te damos la bienvenida a WhatsPRO</Typography>
                        :
                            matches ? 
                                <Typography sx={ SubtitleStyle }>Completá las tareas para hacer visible el chat en tu pagina web</Typography>
                            :
                                <Typography sx={ MobileSubtitleStyle }>Deslizá y completá las tareas para hacer visible el chat en tu pagina web</Typography>
                }
            </Box>
        </Box>
    )
}

export default WelcomeBanner;