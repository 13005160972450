import { FormControl, FormHelperText, IconButton, InputAdornment, InputLabel, OutlinedInput, Typography } from '@mui/material'
import React, { useEffect, useState } from 'react'
import { primaryColor } from '../../../constants'
import { Visibility, VisibilityOff } from '@mui/icons-material';
import { LoadingButton } from '@mui/lab';
import { MAIN_API_URL } from '../../../configuration';
import { useFetch } from 'use-http';
import { useFormik } from 'formik';
import * as Yup from 'yup';
import { useNavigate } from 'react-router-dom';

const validationSchema = Yup.object({
    password: Yup.string().required('La contraseña es requerida').min(8, 'La contraseña debe tener al menos 8 caracteres'),
    password2: Yup.string().required('La confirmación de la contraseña es requerida').oneOf([Yup.ref('password'), null], 'Las contraseñas no coinciden'),
    token: Yup.string().required('El token es requerido')
});

export const UserPasswrdNewPassword = () => {
    const { post, loading, response } = useFetch(MAIN_API_URL);
    const query = new URLSearchParams(window.location.search);
    const navigate = useNavigate();
    const [errorMsg, setErrorMsg] = useState('');
    const [showPassword, setShowPassword] = useState(false);
    const handleClickShowPassword = () => setShowPassword((show) => !show);
    const handleMouseDownPassword = (event) => {
        event.preventDefault();
    };

    const formik = useFormik({
        initialValues: {
            password: '',
            password2: '',
            token: query.get('token'),
        },
        validationSchema: validationSchema,
        onSubmit: async (values) => {
            const valuesInFormat = { password: values.password, token: values.token};
            const data = await post('/auth/reset-password', valuesInFormat);
            if (response.ok) {
                navigate('/password_reset/success_recovery')
            } else {
                if(data.msg === 'Token no encontrado') navigate('/password_reset/not_valid_token');
                setErrorMsg(data.msg)
            }
        }
    })

    useEffect(() => {
        const checkToken = async () => {
            await post('/auth/verify-token', { token: query.get('token') });
            if (!response.ok) {
                navigate('/password_reset/not_valid_token');
            }
        }
        checkToken();
    }, [])

    return (
        <>
            <FormControl variant="outlined" fullWidth required>
                <InputLabel htmlFor="outlined-adornment-password">Nueva contraseña</InputLabel>
                <OutlinedInput
                    id="password"
                    name='password'
                    type={showPassword ? 'text' : 'password'}
                    endAdornment={
                        <InputAdornment position="end">
                            <IconButton
                                aria-label="toggle password visibility"
                                onClick={handleClickShowPassword}
                                onMouseDown={handleMouseDownPassword}
                                edge="end"
                            >
                                {showPassword ? <VisibilityOff /> : <Visibility />}
                            </IconButton>
                        </InputAdornment>
                    }
                    label="Nueva contraseña"
                    sx={{ margin: 0 }}
                    value={formik.values.password}
                    onChange={formik.handleChange}
                    error={formik.touched.password && Boolean(formik.errors.password)}
                />
                <FormHelperText error={formik.touched.password && Boolean(formik.errors.password)}>
                    {formik.touched.password && formik.errors.password}
                </FormHelperText>
            </FormControl>
            <FormControl variant="outlined" fullWidth required>
                <InputLabel htmlFor="outlined-adornment-password">Reintroduzca contraseña</InputLabel>
                <OutlinedInput
                    id="password2"
                    name='password2'
                    type={showPassword ? 'text' : 'password'}
                    endAdornment={
                        <InputAdornment position="end">
                            <IconButton
                                aria-label="toggle password visibility"
                                onClick={handleClickShowPassword}
                                onMouseDown={handleMouseDownPassword}
                                edge="end"
                            >
                                {showPassword ? <VisibilityOff /> : <Visibility />}
                            </IconButton>
                        </InputAdornment>
                    }
                    label="Reintroduzca contraseña"
                    sx={{ margin: 0 }}
                    value={formik.values.password2}
                    onChange={formik.handleChange}
                    error={formik.touched.password2 && Boolean(formik.errors.password2)}
                />
                <FormHelperText error={formik.touched.password2 && Boolean(formik.errors.password2)}>
                    {formik.touched.password2 && formik.errors.password2}
                </FormHelperText>
            </FormControl>
            {
                errorMsg && (
                    <Typography variant={'subtitle1'} sx={{ fontSize: '1rem', fontWeight: 500, color: 'red' }}>
                        {errorMsg}
                    </Typography>
                )
            }
            <LoadingButton
                variant="contained"
                loadingPosition="center"
                loading={loading}
                disabled={loading}
                loadingIndicator="Cargando..."
                sx={{
                    width: '100%',
                    height: '54px',
                    borderRadius: '6px',
                    color: primaryColor,
                    background: '#fff',
                    boxShadow: 'none',
                    border: '1px solid #c4c4c4',
                    '&:hover': {
                        backgroundColor: primaryColor,
                        color: '#fff',
                    },
                }}
                onClick={formik.handleSubmit}
            >
                <Typography variant={'subtitle1'} sx={{ fontSize: '1rem', fontWeight: 500 }}>
                    Reestablecer contraseña
                </Typography>
            </LoadingButton>
        </>

    )
}
