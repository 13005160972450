import React, { useEffect, useState, useRef, useMemo } from 'react';
import { connect } from "react-redux";

import { DragDropContext, Droppable } from 'react-beautiful-dnd';

// Actions
import {
    createNewQuestion,
    reorderQuestions,
    setActiveTemplate,
    setQuestions,
    setTemplates,
} from '../../../actions/stepsChat';

// Components
import LoaderQuestions from '../../Loader/LoaderQuestions/LoaderQuestions';
import QuestionSetting from '../QuestionSetting/QuestionSetting';
import Chip from '../../Chip/Chip';

// Icons
import ArrowForwardIosIcon from '@mui/icons-material/ArrowForwardIos';
import ArrowBackIosNewIcon from '@mui/icons-material/ArrowBackIosNew';

import ControlPointOutlinedIcon from '@mui/icons-material/ControlPointOutlined';

// Material Ui
import { Button, IconButton, useMediaQuery } from '@mui/material';

// CSS
import Styles from './QuestionsComponent.module.css';

const AddButtonStyles = {
    padding: '.5rem .9rem !important',
    borderRadius: '8px !important',
    fontFamily: 'Poppins',
    marginLeft: '2rem',
    fontSize: '.9rem',
    boxShadow: 'none',
}

const reorder = (list, startIndex, endIndex) => {
    const result = [...list];
    const [removed] = result.splice(startIndex, 1);
    result.splice(endIndex, 0, removed);
    return result;
}

const AnswerContainer = (props) => {

    //Actions
    const {
        setActiveTemplate,
        reorderQuestions,
        createNewQuestion,
    } = props;

    //States
    const { templates = [], activeTemplate } = props;

    const [showArrow, setShowArrow] = useState(false);
    const [loading, setLoading] = useState(true);
    // const [activeTemplate, setActiveTemplate] = useState(

    const matches600 = useMediaQuery('(min-width: 600px)');
    const matches = useMediaQuery('(min-width: 550px)');
    const chipsContainer = useRef(null);
    const scrollWidth = (templates.length * 125 + 20 * (templates.length - 1));

    const questions = useMemo(() => activeTemplate.answers, [activeTemplate]);

    useEffect(() => {
        if (templates.length > 0) {
            setLoading(false);
        }
    }, [templates]);
    useEffect(() => {
        if (!matches) {
            chipsContainer.current.scrollLeft += 20;
        }
    }, [chipsContainer, loading]);
    const handleClickScroll = () => {
        chipsContainer.current.scrollLeft += 100;
    }
    const handleClickLeftScroll = () => {
        chipsContainer.current.scrollLeft -= 100;
    }
    useEffect(() => {
        if (chipsContainer.current.offsetWidth > scrollWidth) {
            setShowArrow(false);
        } else {
            setShowArrow(true);
        }

    }, [chipsContainer, scrollWidth]);


    const handleChipClick = (uid) => {
        setActiveTemplate(templates.find(template => template.uid === uid));
    };

    const setDragEnd = (result) => {
        const { source, destination } = result;
        if (!destination) return;
        if (source.index === destination.index && source.droppableId === destination.droppableId) return;
        reorderQuestions(reorder(questions, source.index, destination.index));
    }

    const handleCreateNewQuestion = () => {
        createNewQuestion();
    }

    return (
        <section className={Styles.AnswerContainer}>
            <DragDropContext onDragEnd={(result) => setDragEnd(result)}>
                <div
                    style={{ display: matches600 ? showArrow ? 'flex' : 'none' : 'none' }}
                    className={Styles.ArrowLeftContainer}
                    onClick={handleClickLeftScroll}
                >
                    <IconButton sx={{ marginRight: '.5rem' }}>
                        <ArrowBackIosNewIcon sx={{ color: '#BFBBBD' }} />
                    </IconButton>
                </div>
                <div
                    style={{ display: matches600 ? showArrow ? 'flex' : 'none' : 'none' }}
                    className={Styles.ArrowContainer}
                    onClick={handleClickScroll}
                >
                    <IconButton sx={{ marginRight: '.5rem' }}>
                        <ArrowForwardIosIcon sx={{ color: '#BFBBBD' }} />
                    </IconButton>
                </div>
                <div className={Styles.LinearGradientContainer}></div>
                <div className={Styles.ChipContainer} ref={chipsContainer}>
                    <div style={{ display: 'flex', gap: '10px' }}>
                        {
                            loading ? 'Cargando...' :
                                templates.map(({ name, uid }) => (
                                    <Chip
                                        handleClick={() => handleChipClick(uid)}
                                        active={activeTemplate.uid === uid}
                                        text={name}
                                        key={uid}
                                    />
                                ))
                        }
                    </div>
                </div>
                <Droppable droppableId="questions" >
                    {
                        (droppableProvided) => (
                            <div
                                className={Styles.QuestionsGeneralComponents}
                                {...droppableProvided.droppableProps}
                                ref={droppableProvided.innerRef}
                            >
                                {loading && <LoaderQuestions />}
                                {
                                    questions.map((question, index) => (
                                        <QuestionSetting
                                            key={question.uid}
                                            {...question}
                                            index={index}
                                            id = {question.uid.toString()}
                                        />
                                    ))
                                }
                                {droppableProvided.placeholder}
                            </div>
                        )}
                </Droppable>
                <Button
                    className={'AddHourButton'}
                    onClick={handleCreateNewQuestion}
                    sx={AddButtonStyles}
                >
                    <ControlPointOutlinedIcon
                        sx={{
                            height: '1.5rem !important',
                            color: 'white !important',
                            width: '1.5rem !important'
                        }}
                    />
                    Agregar pregunta
                </Button>
            </DragDropContext>
        </section>
    )
}


const mapStateToProps = state => {
    return {
        questions: state.stepsChat.questions,
        templates: state.stepsChat.templates,
        activeTemplate: state.stepsChat.activeTemplate,
    }
}

const mapDispatchToProps = dispatch => {
    return {
        setQuestions: (data) => dispatch(setQuestions(data)),
        setTemplates: (data) => dispatch(setTemplates(data)),
        setActiveTemplate: (data) => dispatch(setActiveTemplate(data)),
        reorderQuestions: (data) => dispatch(reorderQuestions(data)),
        createNewQuestion: (data) => dispatch(createNewQuestion(data)),
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(AnswerContainer);