import React from 'react';

// CSS
import Styles from './RankingItem.module.css';

const RankingItem = ({ avatar, name, role, type, question, totalClicks }) => {
    return (
        <div className={ type === 'advisers' ? Styles.RankingItemContainer : Styles.RankingItemContainerQuestion }>
            <div className={ Styles.DataSection }>
                <img 
                    src={ avatar } 
                    alt="Avatar" 
                    style={{ 
                        display: type === 'advisers' ? 'block' : 'none',
                        border: '2px solid #2ED588', 
                        borderRadius: '50%',
                    }}
                />
                <div className={ Styles.NameContainer }>
                    <h2>{ type === 'advisers' ? name : question }</h2>
                    <p 
                        style={{ 
                            display: type === 'advisers' ? 'block' : 'none' 
                        }}
                    >
                        { role }
                    </p>
                </div>
            </div>
            <div className={ Styles.BottomSection }>
                <p className={ Styles.TitleMobile } >
                    { type === 'advisers' ? 'Respuestas' : 'Total de Clicks' }
                </p>
                <div className={ Styles.MessagesContainer }>
                    <p>{ totalClicks }</p>
                </div>
            </div>
        </div>
    )
}

export default RankingItem;