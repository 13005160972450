import { Typography } from '@mui/material'
import { Box } from '@mui/system'
import React from 'react'
import { UserPasswordRecover } from '../components/UserPasswordRecover'


export const PasswordResetComponent = () => {
    return (
        <>
            <Typography variant={'h4'} sx={{ fontSize: '1.5rem', fontWeight: 400 }}>
                ¿Olvidó su contraseña?
            </Typography>
            <Typography variant={'subtitle1'} sx={{ fontSize: '0.9rem', fontWeight: 400 }}>
                Introduzca su usuario y le enviaremos un correo electrónico con instrucciones para restablecer su contraseña.
            </Typography>
            <Box
                display={'flex'}
                flexDirection={'column'}
                justifyContent={'center'}
                alignItems={'flex-start'}
                gap={1.5}
                sx={{
                    width: '100%',
                    padding: '5px 20px',
                }}
            >
                <UserPasswordRecover />
            </Box>
        </>
    )
}
