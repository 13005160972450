import React, { useEffect, useState } from 'react';
import useFetch from 'use-http';

// Configuration
import { MAIN_API_URL } from '../../../configuration';

// Recharts
import { AreaChart, Area, ResponsiveContainer } from 'recharts';

// CSS
import Styles from './ClicksComponent.module.css';
import { connect } from 'react-redux';

const ClicksComponent = (props) => {

    const { clicks : clicksArray  } = props;

    const { startDate, endDate } = props;

    const { error, get, response } = useFetch(`${MAIN_API_URL}/stats`, {
        headers: {
            "x-token": `${localStorage.getItem("token")}`
        }
    });

    const [data, setData] = useState([]);
    const [percentage, setPercentage] = useState(100);
    const [totalClicks, setTotalClicks] = useState('');
    // This month
    const [thisClick, setThisClick] = useState(0);
    // Last month
    const [lastClick, setLastClick] = useState(0);

    useEffect(() => { getStatsTotalClicks(); }, [startDate, endDate]);

    const getStatsTotalClicks = async () => {
        const resp = await get("/total_clicks?startDate=" + startDate + "&endDate=" + endDate);
        if (response.ok) {
                setThisClick(resp.totalClicksThisMonth);
                setLastClick(resp.totalClicksLastMonth);
                setTotalClicks(resp.totalClicks);
        } else {
            console.log(error);
        }
    }

    useEffect(() => {

        if (lastClick > 0) {
            const per = ((thisClick - lastClick) / lastClick) * 100;
            setPercentage(per.toFixed(0));
        } else {
            setLastClick(100);
        }

    }, [thisClick, lastClick])


    useEffect(() => {
        // if (data.length <= clicksArray.length) {
            const d = [];
            for (let i = 0; i < clicksArray.length; i++) {

                const newData = {
                    Name: clicksArray[i].x,
                    Clicks: clicksArray[i].y
                };

                d.push(newData);
            }

            setData([...d]);
        // } else {
        //     return;
        // }

    }, [clicksArray]);


    return (
        <section className={Styles.ClicksContainer}>
            <div className={Styles.GraphicSection} style={{ paddingBottom: totalClicks === 0 ? '2rem' : '0rem' }}>
                <ResponsiveContainer>
                    <AreaChart
                        data={data}
                        margin={{
                            top: 0,
                            right: 0,
                            left: 0,
                            bottom: 0,
                        }}
                    >
                        <defs>
                            <linearGradient id="colorUv" x1="0" y1="0" x2="0" y2="1">
                                <stop offset="5%" stopColor="#2ED588" stopOpacity={0.8} />
                                <stop offset="95%" stopColor="#2ED588" stopOpacity={0} />
                            </linearGradient>
                        </defs>
                        <Area type="monotone" dataKey="Clicks" stroke="#2ED588" fillOpacity={1} fill="url(#colorUv)" />
                    </AreaChart>
                </ResponsiveContainer>
            </div>
            <div className={Styles.DataSection}>
                <h1>Clicks</h1>
                <div className={Styles.Data}>
                    <h2>{ thisClick }</h2>
                    <p style={{ color: percentage > 0 ? '#2ED588' : 'red' }}>
                        { percentage > 0 ? '+' : '' }{percentage} %
                    </p>
                    <p
                        style={{
                            margin: '1rem 0 0 .3rem',
                            color: percentage > 0 ? '#2ED588' : 'red'
                        }}
                    >{percentage > 0 ? '↑' : '↓'}</p>
                </div>
            </div>
        </section>
    )
}

const mapStateToProps = state => {
    return {
        startDate: state.employees.filterDates.startDate,
        endDate: state.employees.filterDates.endDate,
    }
}

export default connect(mapStateToProps, null)(ClicksComponent);