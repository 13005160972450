import { MAIN_API_URL } from '../configuration';
import { typesUsers, globalsTypes } from '../types/types';

export const saveEmployees = (data) => ({
    type: typesUsers.getUser,
    payload: data
});

export const setChangeNewEmployee = (data) => ({
    type: typesUsers.changeNewUserState,
    payload: data
});

export const setEditEmployee = (data) => ({
    type: typesUsers.getEditUser,
    payload: data
});

export const setDateRangeData = (data) => ({
    type: globalsTypes.changeDateRangeState,
    payload: data
})

export const getEmployees = () => {
    return async ( dispatch, getState ) => {
        const { auth } = getState();
        const { token } = auth;
        try {
            let response = await fetch(`${MAIN_API_URL}/employees`, {
                method: "GET",
                headers: {
                    'x-token': `${token}`
                }
            });
    
            if ( response.status === 200 ) {
                let employees = await response.json();
                dispatch( saveEmployees( employees.employees ) );
                return;
            }
            
            throw new Error('Expired token');
            
        } catch (error) {
            console.log("Error asistentes: ", error);
        }
    }
}