import React from 'react'

const RocketIcon = () => {
    return (
        <div style={{ marginRight: '8px', display: 'flex', alignItems: 'center' }}>
            <svg width="26" height="26" viewBox="0 0 26 26" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path d="M22.6407 2.57655C21.4257 2.30445 20.1846 2.16654 18.9395 2.1653C17.7636 2.09064 16.5846 2.24918 15.4702 2.63178C14.3558 3.01438 13.3281 3.61351 12.4461 4.39471C11.2845 5.55631 10.1337 6.7143 8.99373 7.86869C7.68422 7.728 4.57821 7.65224 2.50031 9.75178C2.29875 9.95455 2.18561 10.2288 2.18561 10.5148C2.18561 10.8007 2.29875 11.075 2.50031 11.2777L14.7296 23.5286C14.9323 23.7302 15.2066 23.8433 15.4926 23.8433C15.7785 23.8433 16.0528 23.7302 16.2555 23.5286C18.3659 21.3642 18.301 18.3123 18.1711 17.0352L21.6451 13.5721C25.0974 10.1197 23.5282 3.63714 23.4632 3.36659C23.4133 3.17139 23.3098 2.99401 23.1645 2.85444C23.0192 2.71486 22.8378 2.61862 22.6407 2.57655ZM20.0866 12.0461L16.2339 15.8772C16.1091 16.0032 16.0172 16.1579 15.9663 16.3278C15.9153 16.4976 15.9069 16.6774 15.9417 16.8512C16.1755 18.2991 15.972 19.7837 15.3573 21.1153L4.89206 10.6284C6.24469 10.0059 7.75674 9.81684 9.221 10.0873C9.39424 10.1098 9.57036 10.0901 9.73432 10.0298C9.89828 9.96949 10.0452 9.87039 10.1625 9.74095C10.1625 9.74095 11.6777 8.17171 13.9612 5.8882C15.3653 4.76063 17.143 4.20412 18.9395 4.32978C19.7845 4.33396 20.6277 4.40635 21.4611 4.54622C21.7208 6.09382 22.1321 10.0007 20.0866 12.0461Z" fill="#2ED588"/>
                <path d="M17.0241 11.1477C18.2195 11.1477 19.1886 10.1786 19.1886 8.9832C19.1886 7.78779 18.2195 6.81873 17.0241 6.81873C15.8287 6.81873 14.8596 7.78779 14.8596 8.9832C14.8596 10.1786 15.8287 11.1477 17.0241 11.1477Z" fill="#2ED588"/>
                <path d="M5.41166 17.3159C3.24719 18.3982 3.24719 22.7271 3.24719 22.7271C5.25049 22.6699 7.16829 21.9028 8.65837 20.5626L5.41166 17.3159Z" fill="#2ED588"/>
            </svg>
        </div>
    )
}

export default RocketIcon