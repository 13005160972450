import React from 'react';

// Icon
import CloseOutlinedIcon from '@mui/icons-material/CloseOutlined';

// Material Ui
import { Box, Drawer, Typography } from '@mui/material';

// CSS
import Styles from './DrawerAdvanced.module.css';

const DrawerStyles = {
    '.MuiModal-root.MuiDrawer-root': {
        zIndex: '0',
    },
    '.MuiDrawer-root': {
        zIndex: '0',
    },
    '.MuiPaper-root': {
        zIndex: '1',
    },
    '.MuiDrawer-paper': {
        boxSizing: 'border-box',
        flexDirection: 'column',
        alignItems: 'center',
        maxWidth: '360px',
        display: 'flex',
        padding: '1rem',
        width: '100%',
        '@media(min-width: 800px)': {
            width: '550px',
        }
    },
}

const CrossContainer = {
    position: 'absolute',
    cursor: 'pointer',
    top: '.5rem',
    left: '3%',
}

const HelpTextContainer = {
    margin: '2rem auto auto auto',
    flexDirection: 'column',
    alignItms: 'center',
    padding: '1rem',
    display: 'flex',
}

const TitleChatDrawer = {
    marginBottom: '1rem',
    fontSize: '1.1rem',
    fontWeight: '600',
    marginTop: '0',
}

const DrawerAdvanced = ({ open, toggleDrawer }) => {
    return (
        <Drawer
            onClose={toggleDrawer(false)}
            sx={ DrawerStyles }
            anchor={'right'}
            open={open}
            hideBackdrop={true}
            ModalProps = {{
                disableEnforceFocus: true
            }}
        >
            <Box sx={ CrossContainer } onClick={ toggleDrawer(false) }>
                <CloseOutlinedIcon
                    sx={{
                        color: '#393939 !important',
                        height: '2rem !important',
                        marginRight: '.5rem',
                        fontSize: '1.2rem',
                    }}
                />
            </Box>
            <Box sx={ HelpTextContainer }>
                <Typography sx={ TitleChatDrawer }>Configuración avanzada</Typography>
                <div className={ Styles.TextContainer }>
                    <h3>¿Qué tengo que hacer?</h3>
                    <p>La configuración avanzada es opcional, esto quiere decir que solo debés hacer cambios en caso de que lo creas necesario.</p>
                    <h3>¿Qué cosas puedo modificar?</h3>
                    <p>En la configuración avanzada podrás configurar las siguientes cosas:</p>
                    <ul className={ Styles.ListText }>
                        <li>
                            Si WhatsPRO está o no visible en tu tienda
                        </li>
                        <li>
                            El diseño del icono de WhatsPRO en tu tienda
                        </li>
                        <li>
                            La posición del Botón en tu tienda
                        </li>
                        <li>
                            El delay con el que aparece el mismo
                        </li>
                        <li>
                            Una viñeta adicional con un mensaje para tus clientes
                        </li>
                        <li>
                            El delay de la viñeta
                        </li>
                    </ul>
                    <h3>¿Es conveniente agregar una viñeta?</h3>
                    <p>La viñeta es una ventana emergente que aparece por encima del botón de WhatsPRO en tu tienda. Es muy útil para comunicarle a tus clientes que pueden hacerte cualquier consulta clickeando sobre el botón por si no se dieron cuenta automáticamente. El delay de esta viñeta sirve para que no aparezca inmediatamente sinó algunos segundos después. </p>
                    <h3>¿Cuándo debo cambiar la posición del botón?</h3>
                    <p>La única razón por la que es conveniente cambiar el botón de posición es por si entra en conflicto con algún componente de tu página.</p>
                </div>
            </Box>
        </Drawer>
    )
}

export default DrawerAdvanced;