import { Box, CircularProgress } from '@mui/material';
import { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';

const GeneralContainer = {
    backgroundColor: '#F9F8FF',
    justifyContent: 'center',
    alignItems: 'center',
    display: 'block',
    height: '100vh',
    width: '100vw',
};

const ContainerStyles = {
    transform: 'translate(-50%, -50%)',
    justifyContent: 'space-between',
    flexDirection: 'column',
    alignItems: 'center',
    position: 'absolute',
    textAlign: 'center',
    display: 'flex',
    left: '50%',
    top: '50%',
};

export const AuthCheckPlatform = () => {

    const [state, setState] = useState('Cargando...');
    const navigate = useNavigate();
    const query = new URLSearchParams(window.location.search);
    // useEffect(() => {
    //     if(query.get('error') === 'access_denied') {
    //         navigate('/auth/syncronize_failure/particular?error=Ya tienes una cuenta vinculada a este correo. Por favor, inicia sesión con una cuenta con difente correo');
    //     } else{
    //         setTimeout(() => {
    //             setState('Redirigiendo...');
    //         loginWithRedirect(
    //             {
    //                 prompt: 'none',
    //                 redirectUri: window.location.origin + '/auth/check',
    //             }
    //         );
    //         }, 500);
    //     }
    // }, []);

    return (
        <Box sx={GeneralContainer}>
            <Box sx={ContainerStyles}>
                <CircularProgress
                    size={'5rem'}
                    color='success'
                />
                <h1>{state}</h1>
            </Box>
        </Box>
    );
};
