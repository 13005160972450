import { LoadingButton } from '@mui/lab'
import {TextField, Typography } from '@mui/material'
import React, { useEffect, useState } from 'react'
import { primaryColor } from '../../../constants'
import { useNavigate } from 'react-router-dom'
import { useFormik } from 'formik'
import * as Yup from 'yup';

import { useFetch } from 'use-http'
import { MAIN_API_URL } from '../../../configuration'

const validationSchema = Yup.object({
    mail: Yup.string().email('Ingrese un correo electrónico válido').required('El correo electrónico es requerido'),
});

export const UserPasswordRecover = () => {
    const { post, loading, response } = useFetch(MAIN_API_URL);
    const query = new URLSearchParams(window.location.search);
    const navigate = useNavigate();
    const handleBackClick = () => {
        navigate('/');
    }

    const [errorMsg, setErrorMsg] = useState('');

    const formik = useFormik({
        initialValues: {
            mail: query.get('mail') || '',
        },
        validationSchema: validationSchema,
        onSubmit: async (values) => {
            values.mail = values.mail.toLowerCase().trim();
            const data = await post('/auth/request-password-reset', values)
            if (response.ok) {
                navigate('/password_reset/email_sent/' + values.mail)
            } else {
                setErrorMsg(data.msg)
                setTimeout(() => {
                    window.location.reload();
                }, 3000);
            }
        }
    })

    useEffect(() => {
        if(errorMsg.length > 0) setErrorMsg('')
    }, [formik.values])


    return (
        <>
            <TextField
                variant="outlined"
                margin="normal"
                required
                fullWidth
                id="mail"
                label="Correo electrónico"
                name="mail"
                autoComplete="mail"
                autoFocus
                sx={{ margin: 0, fontFamily: 'Poppins' }}
                value={formik.values.mail}
                onChange={formik.handleChange}
                error={formik.touched.mail && Boolean(formik.errors.mail)}
                helperText={formik.touched.mail && formik.errors.mail}
            />
            {
                errorMsg.length > 0 &&
                <Typography variant={'subtitle1'} sx={{ fontSize: '0.7rem', fontWeight: 400, color: 'red' }}>
                    {errorMsg}, por favor intente de nuevo.
                </Typography>
            }
            <LoadingButton
                variant="contained"
                loadingPosition="center"
                loading={loading}
                disabled={loading}
                loadingIndicator="Cargando..."
                sx={{
                    width: '100%',
                    height: '54px',
                    borderRadius: '6px',
                    color: primaryColor,
                    background: '#fff',
                    boxShadow: 'none',
                    border: '1px solid #c4c4c4',
                    '&:hover': {
                        backgroundColor: primaryColor,
                        color: '#fff',
                    },
                }}
                onClick={formik.handleSubmit}
            >
                <Typography variant={'subtitle1'} sx={{ fontSize: '1rem', fontWeight: 500 }}>
                    Enviar correo electrónico
                </Typography>
            </LoadingButton>
            <Typography variant={'subtitle1'} sx={{ fontSize: '0.9rem', fontWeight: 400, cursor: 'pointer', alignSelf: 'center' }}>
                <span style={{ color: primaryColor, fontWeight: 500 }} onClick={handleBackClick}>Volver</span>
            </Typography>
        </>
    )
}