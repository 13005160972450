const Sleep = (props) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={34}
    height={34}
    fill="none"
    {...props}
  >
    <path
      stroke="#393939"
      strokeLinejoin="round"
      strokeWidth={2.833}
      d="M11.947 5.292a12.741 12.741 0 0 0-.614 3.917c0 7.041 5.709 12.75 12.75 12.75a12.708 12.708 0 0 0 5.054-1.042C27.484 26.044 22.675 29.75 17 29.75 9.958 29.75 4.25 24.042 4.25 17c0-5.246 3.168-9.752 7.697-11.709Z"
    />
    <path
      stroke="#393939"
      strokeLinecap="round"
      strokeLinejoin="round"
      strokeWidth={2.833}
      d="M22.426 7.083h6.616l-7.084 5.667h7.084"
    />
  </svg>
)
export default Sleep