import React from 'react'
import { LayoutAuth } from '../../layout/LayoutAuth'
import { RegisterComponent } from '../../components/Auth/views/RegisterComponent'

export const Register = () => {
  return (
    <LayoutAuth>
        <RegisterComponent/>
    </LayoutAuth>
  )
}
