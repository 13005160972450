import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import useFetch from 'use-http';

// Actions
import { getBillingData, getStore, setBillingsData } from '../../actions/auth';

// Components
import BillingsButton from '../../components/Billings/BillingsButton/BillingsButton';
import BillingsTable from '../../components/Billings/BillingsTable/BillingsTable';
import ChangeCUIL from '../../components/Modals/ChangeCUIL/ChangeCUIL';

// Configurations
import { MAIN_API_URL } from '../../configuration';

// Layout
import LayoutPages from '../../layout/LayoutPages';

// Material Ui
import { Box, Tooltip } from '@mui/material';

// PaymentProvider
import { ProviderPaymentList } from '../../components/Billings/BillingsTable/ProviderPaymentList';

// CSS
import Styles from './BillingScreen.module.css';

const BillingScreenContainer = {
    userSelect: 'none',
    textAlign: 'left'
}

const BillingScreen = (props) => {

    // Actions
    const { setBillingsData, getBillingData, getStore, store } = props;

    // States
    const { direccion, emailBilling, documentNro } = props;

    const [showCuilModal, setShowCuilModal] = useState(false);
    const [paymentMethod, setPaymentMethod] = useState('');
    const [fourNumber, setFourNumber] = useState('');
    const [userData, setUserData] = useState([]);
    const [paymentData, setPaymentData] = useState({
        paymentMethod: '',
        subscriptionLink: '',
    });
    const [icon, setIcon] = useState('');
    const [row, setRow] = useState([]);

    const { error, response, get } = useFetch(`${MAIN_API_URL}`, {
        headers: {
            "x-token": `${localStorage.getItem("token")}`
        }
    });

    useEffect(() => { getTableData(); }, []);
    useEffect(() => { getUserData(); }, []);

    const getTableData = async () => {
        const resp = await get("/invoices");
        if (response.ok) {
            setRow(resp);
        } else {
            console.log(error);
        }
    }

    const getUserData = async () => {
        const resp = await get("/stores");
        if (response.ok) {
            setUserData(resp);
        } else {
            console.log(error);
        }
    }

    useEffect(() => {
        async function getSuscriptionData() {
            if (store && store.suscriptionData) {
                if (store.mainLanguage === 'es') {
                    const resp = await get(`/subscriptions/mercadopago`);
                    if (response.ok) {
                        const { data } = resp;
                        setPaymentData({
                            paymentMethod: data.payment_method_id,
                            subscriptionLink: `https://www.mercadopago.com.ar/subscriptions/v0/${data.id}/admin`
                        });
                        for (let j = 0; j < ProviderPaymentList.length; j++) {
                            if (data.payment_method_id === ProviderPaymentList[j].code) {
                                setPaymentMethod(ProviderPaymentList[j].name);
                                setIcon(ProviderPaymentList[j].icon);
                            }
                        }

                    }
                } else {
                    const resp = await get(`/subscriptions/stripe`);
                    if (response.ok) {
                        console.log(resp);
                    }
                }
            }
        }
        getSuscriptionData();
    }, [store]);

    const handleChangeData = () => {
        setShowCuilModal(!showCuilModal);
    }

    const handleChangeCardData = () => {
        console.log('Change card data');
    }

    useEffect(() => {
        getBillingData();
        const token = localStorage.getItem('token');
        getStore(token);
    }, []);

    return (
        <LayoutPages>
            <Box sx={BillingScreenContainer} >
                <h1 className={Styles.Title}>Facturación</h1>
                <section className={Styles.DataSection} >
                    <div className={Styles.DataSectionContainer} >
                        <div className={Styles.DataSectionItems1} >
                            <h2 className={Styles.DataTitle} >Datos de facturación</h2>
                            <p className={Styles.Data} >CUIT/CUIL: <span>{documentNro}</span></p>
                            <p className={Styles.Data} >Dirección de facturación: <span>{direccion}</span></p>
                            <p className={Styles.Data} >Mail de facturación: <span>{emailBilling}</span></p>
                            <div className={Styles.ButtonContainer}>
                                <BillingsButton
                                    handleClick={handleChangeData}
                                    text={'Cambiar datos'}
                                    type={'userData'}
                                />
                            </div>
                        </div>
                        <div className={Styles.DataSectionItems}>
                            <Tooltip title='En caso de haberlo modificado en el último mes, podrás visualizarlo directamente desde el procesador de pagos' arrow placement='left'>
                                <h2 className={Styles.DataTitle} >Medio de pago actual</h2>
                            </Tooltip>
                            {store && store.suscriptionData
                                ? <div className={Styles.CardData} >
                                    {icon}
                                    <p>{paymentMethod}</p>
                                    {paymentData.subscriptionLink && <a href={paymentData.subscriptionLink} target='_blank' rel='noreferrer' >Ver suscripción</a>}
                                </div>
                                : <p className={Styles.Data} >No hay información de medios de pago</p>
                            }
                            <div className={Styles.ButtonContainer}>
                                <BillingsButton
                                    handleClick={handleChangeCardData}
                                    text={(store && store.suscriptionData) ? 'Cambiar medio de pago' : 'Suscribirme'}
                                    type={'Card'}
                                />
                            </div>
                        </div>
                    </div>
                </section>
                <section className={Styles.TableSection} >
                    <div className={Styles.TableContainer}>
                        <BillingsTable rowsBack={row} />
                    </div>
                </section>
                <ChangeCUIL
                    open={showCuilModal}
                    handleClose={handleChangeData}
                    cuilt={userData.billingData?.document_nro}
                    email={userData.billingData?.email}
                />
            </Box>
        </LayoutPages>
    )
}

const mapStateToProps = state => {
    return {
        documentNro: state.auth.billingData.documentNro,
        direccion: state.auth.billingData.direccion,
        emailBilling: state.auth.billingData.email,
        store: state.auth.store,
    }
}

const mapDispatchToProps = dispatch => {
    return {
        setBillingsData: (value) => dispatch(setBillingsData(value)),
        getBillingData: () => dispatch(getBillingData()),
        getStore: () => dispatch(getStore()),
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(BillingScreen);