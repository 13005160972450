import React, { useMemo } from 'react';
import { connect } from 'react-redux';

// actions
import { setActiveStepChat } from '../../actions/stepsChat';
import { setActiveStep } from '../../actions/stepsUser';

//Icons
import Step1IconChat from '../../assets/icons/NewStepper/Chat/Step1Icon';
import Step2IconChat from '../../assets/icons/NewStepper/Chat/Step2Icon';
import Step3IconChat from '../../assets/icons/NewStepper/Chat/Step3Icon';

import Step1IconUser from '../../assets/icons/NewStepper/CreateUser/Step1Icon';
import Step2IconUser from '../../assets/icons/NewStepper/CreateUser/Step2Icon';
import Step3IconUser from '../../assets/icons/NewStepper/CreateUser/Step3Icon';
import Step4IconUser from '../../assets/icons/NewStepper/CreateUser/Step4Icon';

// Material Ui
import { Box, Collapse, Stack, Typography } from '@mui/material';

const stepCounterStyles = {
    fontSize: '.75rem',
    fontWeight: '600',
    color: '#2ED588',
    margin: 0,
};

const stepNameStyles = {
    fontWeight: '600',
    fontSize: '1rem',
    margin: 0,
};

const CollapseStyles = {
    display: 'none',
    '@media(min-width: 600px)': {
        display: 'block'
    }
}

const StepsButton = ( props ) => {

    const { step, activeStep, screen } = props;

    // State
    const { userEditing, onBoardingFinished, onBoardingChat } = props;

    // Actions
    const { activeStepRedux, activeStepChat } = props;

    const stepsNameUsers = [
        { id: 1, name: 'Datos' },
        { id: 2, name: 'Horarios' },
        { id: 3, name: 'Mensajes' },
        { id: 4, name: 'Avanzado' },
    ];

    const stepsNameChat = [
        { id: 1, name: 'Textos' },
        { id: 2, name: 'Preguntas' },
        { id: 3, name: 'Estética' },
    ];

    const [backgroundStep, stepColor] = useMemo(() => {
        if (activeStep === step) {
            return ['#2ED588', 'white'];
        }
        if (activeStep > step) {
            return ['#F0F0F0', '#2ED588'];
        }
        return ['transparent', '#BDBDBD'];
    }, [activeStep, step]);

    const showStep = () => {
        activeStepRedux(step-1);
        activeStepChat(step-1);
    }

    return (
        <Stack 
            direction="row" 
            alignItems="center" 
            justifyContent="space-between" 
            sx={{ 
                marginLeft: '0px !important' 
            }}
        >
            <Box
                onClick={ ( userEditing || ( onBoardingFinished && onBoardingChat ) ) ? showStep : null }
                sx={{
                    boxShadow:
                        activeStep === step
                        ? '0px 37.9091px 15.7184px rgba(42, 39, 66, 0.01), 0px 21.2661px 12.9446px rgba(42, 39, 66, 0.05)'
                        : 'none',
                    backgroundColor: backgroundStep,
                    justifyContent: 'center',
                    alignItems: 'center',
                    borderRadius: '50%',
                    cursor: 'pointer',
                    padding: '.7rem',
                    display: 'flex',
                    ':hover': {
                        backgroundColor: activeStep < step ? '#F0F0F0' : '',
                        transition: 'background-color 0.3s'
                    }
                }}
            >
                {
                    screen === 'Chat' ? {
                        1: <Step1IconChat color={stepColor} />,
                        2: <Step2IconChat color={stepColor} />,
                        3: <Step3IconChat color={stepColor} />,
                    }[step]
                    :
                    {
                        1: <Step1IconUser color={stepColor} />,
                        2: <Step2IconUser color={stepColor} />,
                        3: <Step3IconUser color={stepColor} />,
                        4: <Step4IconUser color={stepColor} />,
                    }[step]
                }
            </Box>
            <Collapse orientation="horizontal" in={activeStep === step} sx={ CollapseStyles }>
                <Box
                    sx={{
                        display: activeStep === step ? 'block' : 'none',
                        paddingLeft: '1rem',
                        textAlign: 'left',
                    }}
                >
                    <Typography variant="body1" sx={stepCounterStyles}>
                        Paso {step}
                        /{ screen === 'Chat' ? '3' : '4' }
                    </Typography>
                    {
                        screen === 'Chat' ? 
                            stepsNameChat.map((item) =>
                                activeStep === item.id ? (
                                    <Typography variant="body1" key={item.id} sx={stepNameStyles}>
                                        { item.name }
                                    </Typography>
                            ) : null )
                        :
                            stepsNameUsers.map((item) =>
                                activeStep === item.id ? (
                                    <Typography variant="body1" key={item.id} sx={stepNameStyles}>
                                        { item.name }
                                    </Typography>
                            ) : null )
                    }
                </Box>
            </Collapse>
        </Stack>
    )
}

const mapStateToProps = state => {
    return {
        onBoardingFinished: state.stepsChat.onBoardingFinished,
        onBoardingChat: state.stepsChat.onBoardingChat,

        userEditing: state.stepsUser.userEditing,
    }
}

const mapDispatchToProps = dispatch => {
    return {
        activeStepChat: (value) => dispatch(setActiveStepChat(value)),
        activeStepRedux: (value) => dispatch(setActiveStep(value)),
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(StepsButton);