import React, { useEffect } from 'react';

import { connect } from 'react-redux';
import { useFormik } from 'formik';
import useFetch from 'use-http';
import * as yup from 'yup';

// Actions
import { setBillingsData } from '../../../actions/auth';

// Configurations
import { MAIN_API_URL } from '../../../configuration';

// Material Ui
import { Fade, IconButton, Modal, TextField } from '@mui/material';
import { createTheme } from '@mui/material/styles';
import { Box, ThemeProvider } from '@mui/system';
import Backdrop from '@mui/material/Backdrop';
import { LoadingButton } from "@mui/lab";

// Icons
import CloseOutlinedIcon from '@mui/icons-material/CloseOutlined';

// CSS
import Styles from './ChangeCUIL.module.css';

const style = {
    transform: 'translate(-50%, -50%)',
    border: '1px solid #BFBBBD',
    position: 'absolute',
    borderRadius: '10px',
    textAlign: 'center',
    bgcolor: 'white',
    p: '1rem 1.5rem',
    width: '75%',
    left: '50%',
    top: '50%',
    "@media (min-width: 500px)": {
        width: '320px'
    },
    "@media (min-width: 900px)": {
        width: '340px'
    }
};

const buttonSubmit = {
    boxShadow: '0px 2.91309px 2.91309px rgba(0, 0, 0, 0.25)',
    backgroundColor: '#2ED588',
    margin: '1rem 0 .5rem 0',
    padding: '.3rem 2.5rem',
    fontFamily: 'Poppins',
    textTransform: 'none',
    borderRadius: '8px',
    fontWeight: '500',
    fontSize: '.9rem',
    color: 'white',
    '&:hover': {
        backgroundColor: '#2ED588'
    }
};

const textFieldStyles = {
    backgroundColor: 'white',
    margin: '.5rem auto',
    borderRadius: '8px',
    width: '95%',
    '&:hover fieldset': {
        borderColor: '#2ED588 !important'
    },
    '.MuiOutlinedInput-root': {
        fontFamily: 'Poppins',
        borderRadius: '8px',
        fontSize: '.8rem' 
    },
    '.MuiInputLabel-root': {
        padding: '0 .4rem 0 .2rem',
        backgroundColor: 'white',
        fontFamily: 'Poppins',
        marginLeft: '-.2rem'
    },
    '.MuiFormHelperText-root': {
        backgroundColor: '#F0F2F5',
        fontFamily: 'Poppins',
    },
    "@media (min-width: 600px)": {
        width: '90%',
    },
    "@media (min-width: 1600px)": {
        width: '85%',
    } 
}

const textFieldCuiltStyles = {
    fontFamily: 'Poppins',
    margin: '1rem auto',
    width: '90%',
    '&:hover fieldset': {
        borderColor: '#2ED588 !important'
    },
    '.MuiOutlinedInput-root': {
        borderRadius: '10px',
        fontSize: '.8rem'
    },
    '.MuiFormHelperText-root': {
        fontFamily: 'Poppins',
        marginLeft: '.2rem',
        fontSize: '.55rem',
        color: '#7E7E7E',
    },
    '& input::placeholder': {
        fontWeight: '600',
    },
    "@media (min-width: 600px)": {
        width: '90%',
    },
    "@media (min-width: 1600px)": {
        width: '85%',
    }
}

const theme = createTheme({
    palette: {
        primary: {
            main: '#2ED588',
        },
        error: {
            main: '#FF0000'
        }
    },
});

const validationSchema = yup.object({
    cuilt: yup
        .string('Ingresar CUIT o CUIL')
        .min(10, 'El minimo es de 10 numeros')
        .max(12, 'El maximo es de 12 numeros')
        .required('Campo obligatorio'),
    email: yup.string()
        .email('Email no valido')
        .required('Campo obligatorio'),
});

const ChangeCUIL = ( props ) => {

    // Actions
    const { setBillingsData } = props;

    // States
    const { doc, email } = props;

    // Externals props
    const { open, handleClose } = props;

    const { error, loading, response, post } = useFetch(`${MAIN_API_URL}/stores/billing_data`, {
        headers: {
            "x-token": `${ localStorage.getItem("token") }`
        }
    });

    useEffect(() => {
        if ( open ) {
            formik.setFieldValue("email", email );
            formik.setFieldValue("cuilt", doc );
        }
    }, [ open ]);

    const formik = useFormik({

        initialValues: {
            email: email,
            cuilt: doc
        },

        validationSchema: validationSchema,
        onSubmit: async (values) => {
            Object.keys(values).forEach(key => {
                if (typeof values[key] === 'string') {
                    values[key] = values[key].trim();
                }
            });

            try {
                const newCuil = await post('/', {
                    billingData: {
                        "document_nro": values.cuilt,
                        "email": values.email,
                    }
                });
                if( response.ok ) {
                    setBillingsData({
                        documentNro: newCuil.billingData?.document_nro,
                        direccion: newCuil.billingData?.direccion,
                        email: newCuil.billingData?.email
                    });
                    handleClose();

                } else {
                    console.log( 'Error --> ', error );
                }
            } catch (error) {
                console.log('error -> ', error);
                alert('Error al enviar el CUIT');
            }
        },
    });

    return (
        <Modal
            aria-labelledby="transition-modal-title"
            aria-describedby="transition-modal-description"
            open={ open }
            onClose={ handleClose }
            closeAfterTransition
            BackdropComponent={ Backdrop }
            BackdropProps={{ timeout: 500 }}
        >
            <Fade in={ open } >
                <Box sx={ style }>
                    <IconButton 
                        sx={{ 
                            position: 'absolute', 
                            right: '1rem', 
                            top: '.5rem' 
                        }}
                        onClick={ handleClose } 
                    >
                        <CloseOutlinedIcon sx={{ color: '#393939' }} />
                    </IconButton>
                    <div className={ Styles.CreateUserModalContainer }>
                        <h1>Cambiar datos</h1>
                        <form className={ Styles.ButtonsContainer }>
                            <ThemeProvider theme={theme}>
                                <TextField
                                    error={ (formik.touched.cuilt && formik.errors.cuilt) ? true : false }
                                    helperText="Ingresá solo números, sin espacios, puntos o guiones."
                                    onChange={ formik.handleChange }
                                    value={ formik.values.cuilt }
                                    sx={ textFieldCuiltStyles }
                                    placeholder="CUIT/CUIL"
                                    variant="outlined"
                                    type="number"
                                    name='cuilt'
                                    id='cuilt'
                                    label=""
                                />
                                <TextField
                                    error={ formik.touched.email && Boolean(formik.errors.email) } 
                                    placeholder={ 'Ingresá tu email' }
                                    onChange={ formik.handleChange }
                                    value={ formik.values.email }
                                    sx={ textFieldStyles } 
                                    variant="outlined"
                                    name="email" 
                                    id="email"
                                    label="" 
                                />
                            </ThemeProvider>
                            <LoadingButton
                                onClick={ formik.handleSubmit }
                                disabled={ loading }
                                loading={ loading }
                                sx={ buttonSubmit }
                            >
                                Cambiar datos
                            </LoadingButton>
                        </form>
                    </div>
                </Box>
            </Fade>
        </Modal>
    )
}

const mapStateToProps = state => {
    return {
        doc: state.auth.billingData.documentNro,
        email: state.auth.billingData.email,
    }
}

const mapDispatchToProps = dispatch => {
    return {
        setBillingsData: ( value ) => dispatch( setBillingsData( value ) ),
    }
}

export default connect( mapStateToProps, mapDispatchToProps )(ChangeCUIL);