import React from 'react';
import { connect } from 'react-redux';

// Actions
import { setAdvancedOptions, setEditAdvancedOptions } from '../../../actions/stepsChat';

// Components
import GreenSwitch from '../../GreenSwitch/GreenSwitch';

// CSS
import Styles from './ButtonVisibility.module.css';

const ButtonVisibility = ( props ) => {

    // Actions
    const { setEditAdvancedOptions, advancedOptions } = props;

    // States
    const { buttonVisibility } = props;

    const handleChangeVisibility = () => {
        
        setEditAdvancedOptions({
            editAdvancedOptions: true
        });

        advancedOptions({
            buttonVisibility: !buttonVisibility 
        });
    }

    return (
        <div 
            className={ Styles.SwitchButtonContainer } 
            style={{ 
                border: buttonVisibility ? '1px solid #2ED588' : '1px solid #D7D5D6'
            }}
        >
            <div className={ Styles.TextsSection } >
                <h2
                    style={{ color: buttonVisibility ? '#393939' : '#CFCFCF' }}
                >Visibilidad del botón WhatsPRO</h2>
                <p
                    style={{ color: buttonVisibility ? '#878787' : '#CFCFCF' }}
                >Al deshabilitar el botón, se ocultará el chat en tu tienda. </p>
            </div>
            <div className={ Styles.SwitchSection } >
                <GreenSwitch
                    onClick={ () => handleChangeVisibility() } 
                    checked={ buttonVisibility }
                />
            </div>
        </div>
    )
}

const mapStateToProps = state => {
    return {
        buttonVisibility: state.stepsChat.advancedOptions.buttonVisibility,
    }
}

const mapDispatchToProps = dispatch => {
    return {
        setEditAdvancedOptions: (value) => dispatch(setEditAdvancedOptions(value)),
        advancedOptions: (value) => dispatch(setAdvancedOptions(value)),
    }
}

export default connect(mapStateToProps, mapDispatchToProps)( ButtonVisibility );