import React from 'react';

// Material Ui
import { useMediaQuery } from '@mui/material';

const Step3Icon = ({ color }) => {

    const matches = useMediaQuery('(min-width:600px)');

    return (
        <svg width={ matches ? "31" : "22" } height={ matches ? "29" : "20" } viewBox="0 0 31 29" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path fillRule="evenodd" clipRule="evenodd" d="M0 4.63944C0 3.40899 0.488797 2.22893 1.35886 1.35886C2.22893 0.488797 3.40899 0 4.63944 0H26.2902C27.5206 0 28.7007 0.488797 29.5708 1.35886C30.4408 2.22893 30.9296 3.40899 30.9296 4.63944V20.1043C30.9296 21.3347 30.4408 22.5148 29.5708 23.3848C28.7007 24.2549 27.5206 24.7437 26.2902 24.7437H14.4333L9.27889 28.6099C8.00459 29.5656 6.18593 28.6563 6.18593 27.0634V24.7437H4.63944C3.40899 24.7437 2.22893 24.2549 1.35886 23.3848C0.488797 22.5148 0 21.3347 0 20.1043V4.63944ZM4.63944 3.09296C4.22929 3.09296 3.83594 3.2559 3.54592 3.54592C3.2559 3.83594 3.09296 4.22929 3.09296 4.63944V20.1043C3.09296 20.5144 3.2559 20.9078 3.54592 21.1978C3.83594 21.4878 4.22929 21.6507 4.63944 21.6507H6.95917C7.5744 21.6507 8.16443 21.8951 8.59946 22.3302C9.03449 22.7652 9.27889 23.3552 9.27889 23.9705V24.7437L12.5775 22.2693C13.1129 21.8678 13.7641 21.6507 14.4333 21.6507H26.2902C26.7003 21.6507 27.0937 21.4878 27.3837 21.1978C27.6737 20.9078 27.8367 20.5144 27.8367 20.1043V4.63944C27.8367 4.22929 27.6737 3.83594 27.3837 3.54592C27.0937 3.2559 26.7003 3.09296 26.2902 3.09296H4.63944Z" fill={ color }/>
        </svg>
    )
}

export default Step3Icon;