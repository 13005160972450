import React from 'react'

const LogoutIcon = () => {
    return (
        <div style={{marginRight: '12px', display: 'flex', alignItems: 'center'}}>
            <svg width="19" height="19" viewBox="0 0 21 21" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path d="M7.375 19.875H3.20833C2.6558 19.875 2.12589 19.6555 1.73519 19.2648C1.34449 18.8741 1.125 18.3442 1.125 17.7917V3.20833C1.125 2.6558 1.34449 2.12589 1.73519 1.73519C2.12589 1.34449 2.6558 1.125 3.20833 1.125H7.375" stroke="#EB5757" strokeWidth="2.08333" strokeLinecap="round" strokeLinejoin="round"/>
                <path d="M14.6667 15.7083L19.875 10.5L14.6667 5.29163" stroke="#EB5757" strokeWidth="2.08333" strokeLinecap="round" strokeLinejoin="round"/>
                <path d="M19.875 10.5H7.375" stroke="#EB5757" strokeWidth="2.08333" strokeLinecap="round" strokeLinejoin="round"/>
            </svg>
        </div>
    )
}

export default LogoutIcon