import React from 'react';

const VisaDebitoIcon = () => {
    return (
        <svg width="61" height="39" viewBox="0 0 61 39" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M57.72 0H3.12C1.39687 0 0 1.39687 0 3.12V35.88C0 37.6031 1.39687 39 3.12 39H57.72C59.4431 39 60.84 37.6031 60.84 35.88V3.12C60.84 1.39687 59.4431 0 57.72 0Z" fill="#0E256B"/>
            <path d="M37.5732 11.0859C36.6856 10.7625 35.7473 10.6002 34.8026 10.6066C31.7474 10.6066 29.5897 12.1457 29.5745 14.3549C29.5554 15.978 31.1078 16.8926 32.2821 17.4349C33.487 17.9925 33.8899 18.3438 33.8861 18.8422C33.8784 19.6002 32.9237 19.944 32.0358 19.944C30.8156 19.944 30.1492 19.774 29.1238 19.3482L28.7438 19.1706L28.3104 21.714C29.0532 22.0234 30.3917 22.2888 31.7742 22.3117C35.0222 22.3117 37.1455 20.7822 37.1703 18.4316C37.1971 17.1351 36.3569 16.1556 34.5887 15.3459C33.5137 14.8208 32.8473 14.4695 32.8473 13.9368C32.8473 13.4651 33.4182 12.961 34.6117 12.961C35.4059 12.9418 36.1953 13.0908 36.9278 13.3983L37.2143 13.5262L37.6477 11.0687L37.5732 11.0859ZM45.5014 10.8129H43.1145C42.3718 10.8129 41.8123 11.0172 41.4877 11.7542L36.8973 22.1532H40.1453L40.7945 20.4481L44.7586 20.4519C44.8522 20.8509 45.1386 22.1532 45.1386 22.1532H48.0028L45.5014 10.8129ZM25.1808 10.7174H28.276L26.3398 22.0635H23.2484L25.1808 10.7136V10.7174ZM17.3233 16.9709L17.6441 18.5462L20.6687 10.8129H23.9473L19.0762 22.1379H15.8053L13.132 12.5486C13.0887 12.3904 12.987 12.2546 12.8475 12.1686C11.884 11.6709 10.8631 11.2931 9.80762 11.0439L9.84963 10.8052H14.8314C15.5074 10.8319 16.0516 11.0439 16.233 11.7657L17.3214 16.9766V16.9709H17.3233ZM41.6824 18.128L42.916 14.9545C42.9007 14.9889 43.1699 14.2996 43.3265 13.8737L43.5384 14.8533L44.2545 18.1261H41.6805V18.128H41.6824Z" fill="white"/>
            <path d="M25.6373 30.0448H23.8796V24.3491H25.7152C26.2677 24.3491 26.7405 24.4632 27.1335 24.6912C27.5266 24.9174 27.8279 25.2428 28.0374 25.6674C28.2469 26.0901 28.3517 26.5963 28.3517 27.1859C28.3517 27.7792 28.246 28.29 28.0346 28.7182C27.8233 29.1447 27.5155 29.4729 27.1113 29.7028C26.7071 29.9308 26.2158 30.0448 25.6373 30.0448ZM24.5694 29.433H25.5928C26.0637 29.433 26.454 29.3421 26.7636 29.1604C27.0733 28.9787 27.3041 28.7201 27.4561 28.3845C27.6082 28.0489 27.6842 27.6494 27.6842 27.1859C27.6842 26.726 27.6091 26.3302 27.4589 25.9983C27.3087 25.6646 27.0844 25.4087 26.7859 25.2307C26.4874 25.0509 26.1157 24.961 25.6707 24.961H24.5694V29.433Z" fill="white"/>
            <path d="M31.2106 30.1338C30.799 30.1338 30.444 30.043 30.1455 29.8613C29.8488 29.6777 29.6198 29.4219 29.4585 29.0937C29.2991 28.7637 29.2194 28.3799 29.2194 27.9423C29.2194 27.5048 29.2991 27.1191 29.4585 26.7854C29.6198 26.4498 29.8442 26.1884 30.1316 26.0011C30.4208 25.812 30.7582 25.7174 31.1439 25.7174C31.3664 25.7174 31.5861 25.7545 31.803 25.8287C32.0199 25.9028 32.2174 26.0234 32.3954 26.1902C32.5734 26.3552 32.7152 26.574 32.8209 26.8466C32.9266 27.1191 32.9794 27.4547 32.9794 27.8533V28.1314H29.6866V27.5641H32.312C32.312 27.3231 32.2638 27.108 32.1673 26.9189C32.0728 26.7298 31.9374 26.5805 31.7613 26.4711C31.587 26.3617 31.3812 26.307 31.1439 26.307C30.8825 26.307 30.6563 26.3719 30.4653 26.5017C30.2762 26.6296 30.1306 26.7965 30.0287 27.0023C29.9267 27.2081 29.8757 27.4287 29.8757 27.6642V28.0424C29.8757 28.365 29.9313 28.6385 30.0426 28.8629C30.1557 29.0854 30.3123 29.255 30.5126 29.3718C30.7128 29.4868 30.9455 29.5442 31.2106 29.5442C31.3831 29.5442 31.5388 29.5201 31.6779 29.4719C31.8188 29.4219 31.9402 29.3477 32.0422 29.2494C32.1442 29.1493 32.223 29.0251 32.2786 28.8768L32.9127 29.0548C32.8459 29.2698 32.7338 29.459 32.5762 29.6221C32.4186 29.7834 32.2239 29.9095 31.9921 30.0003C31.7604 30.0893 31.4999 30.1338 31.2106 30.1338ZM30.8102 25.1946L31.4888 23.9041H32.2563L31.3886 25.1946H30.8102Z" fill="white"/>
            <path d="M34.0668 30.0448V24.3491H34.7232V26.4516H34.7788C34.827 26.3775 34.8938 26.2829 34.979 26.168C35.0662 26.0512 35.1904 25.9473 35.3517 25.8565C35.5149 25.7638 35.7355 25.7174 36.0136 25.7174C36.3733 25.7174 36.6904 25.8074 36.9648 25.9872C37.2392 26.167 37.4533 26.422 37.6072 26.752C37.7611 27.082 37.838 27.4714 37.838 27.9201C37.838 28.3725 37.7611 28.7646 37.6072 29.0965C37.4533 29.4265 37.2401 29.6824 36.9675 29.8641C36.695 30.0439 36.3807 30.1338 36.0247 30.1338C35.7503 30.1338 35.5306 30.0884 35.3656 29.9976C35.2006 29.9049 35.0736 29.8001 34.9846 29.6833C34.8956 29.5646 34.827 29.4664 34.7788 29.3885H34.7009V30.0448H34.0668ZM34.7121 27.9089C34.7121 28.2316 34.7593 28.5162 34.8539 28.7627C34.9485 29.0075 35.0866 29.1994 35.2683 29.3384C35.45 29.4756 35.6725 29.5442 35.9357 29.5442C36.2101 29.5442 36.4391 29.4719 36.6227 29.3273C36.8081 29.1808 36.9471 28.9843 37.0398 28.7377C37.1344 28.4893 37.1817 28.213 37.1817 27.9089C37.1817 27.6086 37.1353 27.3379 37.0426 27.0969C36.9518 26.854 36.8137 26.6621 36.6282 26.5212C36.4447 26.3784 36.2139 26.307 35.9357 26.307C35.6688 26.307 35.4444 26.3747 35.2627 26.51C35.081 26.6435 34.9438 26.8308 34.8511 27.0718C34.7584 27.311 34.7121 27.59 34.7121 27.9089Z" fill="white"/>
            <path d="M38.842 30.0448V25.7731H39.4984V30.0448H38.842ZM39.1757 25.0611C39.0478 25.0611 38.9375 25.0175 38.8448 24.9304C38.7539 24.8432 38.7085 24.7385 38.7085 24.6161C38.7085 24.4937 38.7539 24.389 38.8448 24.3018C38.9375 24.2147 39.0478 24.1711 39.1757 24.1711C39.3037 24.1711 39.4131 24.2147 39.5039 24.3018C39.5966 24.389 39.643 24.4937 39.643 24.6161C39.643 24.7385 39.5966 24.8432 39.5039 24.9304C39.4131 25.0175 39.3037 25.0611 39.1757 25.0611Z" fill="white"/>
            <path d="M42.5583 25.7731V26.3293H40.3445V25.7731H42.5583ZM40.9897 24.7496H41.6461V28.8211C41.6461 29.0066 41.6729 29.1456 41.7267 29.2383C41.7823 29.3292 41.8528 29.3903 41.9381 29.4219C42.0252 29.4515 42.117 29.4664 42.2134 29.4664C42.2857 29.4664 42.345 29.4627 42.3914 29.4552C42.4377 29.446 42.4748 29.4386 42.5026 29.433L42.6361 30.0226C42.5916 30.0393 42.5295 30.056 42.4498 30.0726C42.3701 30.0912 42.269 30.1005 42.1467 30.1005C41.9613 30.1005 41.7796 30.0606 41.6016 29.9809C41.4254 29.9011 41.279 29.7797 41.1621 29.6165C41.0472 29.4534 40.9897 29.2476 40.9897 28.9991V24.7496Z" fill="white"/>
            <path d="M45.2351 30.1338C44.8494 30.1338 44.5111 30.0421 44.22 29.8585C43.9307 29.6749 43.7045 29.4182 43.5414 29.0881C43.3801 28.7581 43.2994 28.3725 43.2994 27.9312C43.2994 27.4862 43.3801 27.0978 43.5414 26.7659C43.7045 26.434 43.9307 26.1763 44.22 25.9928C44.5111 25.8092 44.8494 25.7174 45.2351 25.7174C45.6207 25.7174 45.9582 25.8092 46.2474 25.9928C46.5385 26.1763 46.7647 26.434 46.926 26.7659C47.0892 27.0978 47.1707 27.4862 47.1707 27.9312C47.1707 28.3725 47.0892 28.7581 46.926 29.0881C46.7647 29.4182 46.5385 29.6749 46.2474 29.8585C45.9582 30.0421 45.6207 30.1338 45.2351 30.1338ZM45.2351 29.5442C45.528 29.5442 45.7691 29.4691 45.9582 29.319C46.1473 29.1688 46.2873 28.9713 46.3781 28.7266C46.469 28.4819 46.5144 28.2167 46.5144 27.9312C46.5144 27.6457 46.469 27.3796 46.3781 27.133C46.2873 26.8864 46.1473 26.6871 45.9582 26.5351C45.7691 26.383 45.528 26.307 45.2351 26.307C44.9421 26.307 44.7011 26.383 44.512 26.5351C44.3229 26.6871 44.1829 26.8864 44.092 27.133C44.0012 27.3796 43.9558 27.6457 43.9558 27.9312C43.9558 28.2167 44.0012 28.4819 44.092 28.7266C44.1829 28.9713 44.3229 29.1688 44.512 29.319C44.7011 29.4691 44.9421 29.5442 45.2351 29.5442Z" fill="white"/>
        </svg>
    )
}

export default VisaDebitoIcon;