import React, { useMemo } from 'react';
import { NavLink } from 'react-router-dom';
import { useTranslation } from 'react-i18next';

// Icons
import CloseOutlinedIcon from '@mui/icons-material/CloseOutlined';
import AdvancedIcon from '../../assets/icons/Navbar/AdvancedIcon';
import BillingsIcon from '../../assets/icons/Navbar/BillingsIcon';
import Potenciate from '../../assets/icons/Navbar/Potenciate';
import LogoutIcon from '../../assets/icons/Navbar/LogoutIcon';
import RocketIcon from '../../assets/icons/Navbar/RocketIcon';
import ReviewIcon from '../../assets/icons/Navbar/ReviewIcon';
import HomeIcon from '../../assets/icons/Navbar/HomeIcon';
import UserIcon from '../../assets/icons/Navbar/UserIcon';
import ChatIcon from '../../assets/icons/Navbar/ChatIcon';

// Images
import whatsproicon from '../../assets/images/Navbar/whatspro.png';

// Material Ui
import { Box, Collapse, Typography } from '@mui/material';

// CSS
import Styles from './Navbar.module.css';

const styleBox = {
    backgroundColor: '#393939 !important',
    padding: '1.5rem 0rem 0rem 0rem',
    textAlign: 'center',
    listStyle: 'none',
    height: '100%',
    width: '100vw',
    '@media (min-width: 500px)': {
        width: 250,
    },
};

const OFFMobileStyles = {
    backgroundColor: '#2ED588',
    padding: '.1rem .3rem',
    height: 'max-content',
    borderRadius: '10px',
    marginLeft: '.5rem',
    fontSize: '.6rem',
    fontWeight: '700',
    color: '#393939',
    position: 'absolute',
    right: '15%',
    '@media(min-width: 300px)': {
        right: '20%',
    },
    '@media(min-width: 350px)': {
        position: 'inherit',
    }
}

const NavbarMobile = (props) => {

    // External props
    const {
        openDrawerMyAccount,
        onBoardingComplete,
        openDrawerEnhance,
        handleClickDrawer,
        handleCloseReview,
        showAlertSnackbar,
        handleCreateUser,
        openDrawerUsers,
        handleClickChat,
        handleLogout,
        toggleDrawer,
        storeData,
        anchor,
        store
    } = props;

    const { t } = useTranslation();

    const origin = useMemo(() => localStorage.getItem('origin') ?? 'Particular', []);


    return (
        <Box
            sx={styleBox}
            role="presentation"
            onKeyDown={toggleDrawer(anchor, false, 'container')}
            className={'NavbarMobileContainer'}
        >
            <div className={Styles.CrossContainer} onClick={toggleDrawer(anchor, false, 'cross')}>
                <CloseOutlinedIcon
                    sx={{
                        fontSize: '1.8rem',
                        color: 'white !important',
                        height: '2rem !important',
                        marginRight: '.5rem'
                    }}
                />
            </div>
            <NavLink to="/home" onClick={toggleDrawer(anchor, false, 'item')}>
                <img src={whatsproicon} alt="Img Top" className={Styles.ImgTopMobile} />
            </NavLink>
            <p className={Styles.StoreNameMobile}>
                {storeData}
            </p>
            <ul
                style={{
                    listStyle: 'none',
                    padding: '0'
                }}
            >
                <li style={{ cursor: "pointer", userSelect: 'none', textAlign: 'left' }}>
                    <NavLink
                        className={Styles.NavlinkMobile}
                        end="true"
                        style={
                            ({ isActive }) => ({
                                color: isActive ? '#2ED588' : 'white',
                            })
                        }
                        to="/home"
                        onClick={toggleDrawer(anchor, false, 'item')}
                    >
                        <HomeIcon />
                        {t('Home')}
                    </NavLink>
                </li>
                <Box onClick={showAlertSnackbar} sx={{ opacity: (!onBoardingComplete && store.suscriptionData?.status !== "authorized") ? '.5' : '1' }}>
                    <div
                        style={{
                            pointerEvents: (!onBoardingComplete && store.suscriptionData?.status !== "authorized") ? 'none' : 'auto'
                        }}
                    >
                        <div
                            onClick={() => handleClickDrawer('Users')}
                            className={Styles.DrawerButtonContainerMobile}
                        >
                            <div
                                className={Styles.NavlinkMobile}
                                style={{
                                    cursor: 'pointer',
                                    color: 'white',
                                }}
                            >
                                <UserIcon />
                                {t('Users')}
                            </div>
                        </div>
                        <Collapse in={openDrawerUsers} timeout="auto" unmountOnExit>
                            <li
                                className={Styles.DrawerContainerMobile}
                                onClick={handleCreateUser}
                            >
                                <NavLink
                                    className={Styles.NavlinkDrawerMobile}
                                    end="true"
                                    style={
                                        ({ isActive }) => ({
                                            color: isActive ? '#2ED588' : 'white',
                                        })
                                    }
                                    to="/myUsers"
                                    onClick={toggleDrawer(anchor, false, 'drawer')}
                                >
                                    {t('User1')}
                                </NavLink>
                            </li>
                            <li
                                className={Styles.DrawerContainerMobile}
                                onClick={handleCreateUser}
                            >
                                <NavLink
                                    className={Styles.NavlinkDrawerMobile1}
                                    end="true"
                                    style={
                                        ({ isActive }) => ({
                                            color: isActive ? '#2ED588' : 'white',
                                        })
                                    }
                                    to="/createUser"
                                    onClick={toggleDrawer(anchor, false, 'drawer')}
                                >
                                    {t('User2')}
                                </NavLink>
                            </li>
                        </Collapse>
                    </div>
                    <li
                        style={{
                            pointerEvents: (!onBoardingComplete && store.suscriptionData?.status !== "authorized") ? 'none' : 'auto',
                            cursor: "pointer",
                            userSelect: 'none'
                        }}
                    >
                        <NavLink
                            className={Styles.NavlinkMobile}
                            end="true"
                            style={
                                ({ isActive }) => ({
                                    color: isActive ? '#2ED588' : 'white',
                                })
                            }
                            to="/chat"
                            onClick={handleClickChat}
                        >
                            <ChatIcon />
                            {t('Chat')}
                        </NavLink>
                    </li>
                    <li
                        style={{
                            pointerEvents: (!onBoardingComplete && store.suscriptionData?.status !== "authorized") ? 'none' : 'auto',
                            cursor: "pointer",
                            userSelect: 'none'
                        }}
                    >
                        <NavLink
                            className={Styles.NavlinkMobile}
                            end="true"
                            style={
                                ({ isActive }) => ({
                                    color: isActive ? '#2ED588' : 'white'
                                })
                            }
                            to="/advanced"
                            onClick={toggleDrawer(anchor, false, 'item')}
                        >
                            <AdvancedIcon />
                            {t('Advanced')}
                        </NavLink>
                    </li>
                    {
                        store.origin === 'Particular' && (
                            <li
                                style={{
                                    pointerEvents: (!onBoardingComplete && store.suscriptionData?.status !== "authorized") ? 'none' : 'auto',
                                    cursor: "pointer",
                                    userSelect: 'none',
                                    textAlign: 'left'
                                }}
                            >
                                <NavLink
                                    className={Styles.NavlinkMobile}
                                    end="true"
                                    style={
                                        ({ isActive }) => ({
                                            color: isActive ? '#2ED588' : 'white'
                                        })
                                    }
                                    to="/integrations"
                                    onClick={toggleDrawer(anchor, false, 'item')}
                                >
                                    <Potenciate />
                                    {t('Integraciones')}
                                </NavLink>
                            </li>
                        )
                    }
                    <div className={Styles.DividerLineMobile} />
                    <div
                        style={{
                            pointerEvents: (!onBoardingComplete && store.suscriptionData?.status !== "authorized") ? 'none' : 'auto'
                        }}>
                        <li style={{ cursor: "pointer", userSelect: 'none' }}>
                            <NavLink
                                className={Styles.NavlinkMobile}
                                end="true"
                                style={
                                    ({ isActive }) => ({
                                        color: isActive ? '#2ED588' : 'white'
                                    })
                                }
                                to="/billings"
                                onClick={toggleDrawer(anchor, false, 'item')}
                            >
                                <BillingsIcon />
                                {t('Billings')}
                            </NavLink>
                        </li>
                        <Collapse in={openDrawerMyAccount} timeout="auto" unmountOnExit>
                            <li
                                className={Styles.DrawerContainerMobile}
                            >
                                <NavLink
                                    className={Styles.NavlinkDrawerMobile}
                                    end="true"
                                    style={{
                                        fontSize: '.9rem',
                                        color: 'red',
                                    }}
                                    to="/myUsers"
                                    onClick={toggleDrawer(anchor, false, 'drawer')}
                                >
                                    Eliminar suscripcion
                                </NavLink>
                            </li>
                        </Collapse>
                    </div>
                    <div
                        style={{
                            pointerEvents: (!onBoardingComplete && store.suscriptionData?.status !== "authorized") ? 'none' : 'auto'
                        }}
                    >
                        <div
                            onClick={() => handleClickDrawer('Enhance')}
                            className={Styles.DrawerButtonContainerMobile}
                        >
                            <div
                                className={Styles.NavlinkMobile}
                                style={{
                                    cursor: 'pointer',
                                    color: 'white',
                                }}
                            >
                                <RocketIcon />
                                {t('Potenciate')}
                            </div>
                        </div>
                        <Collapse in={openDrawerEnhance} timeout="auto" unmountOnExit>
                            <li
                                className={Styles.DrawerContainerMobile}
                            >
                                <a
                                    href="https://flowypartners.com/crossup"
                                    target="_blank"
                                    rel="noopener noreferrer"
                                    className={Styles.ComplementLinksMobile}
                                >
                                    CrossUp
                                </a>
                            </li>
                            <li
                                className={Styles.DrawerContainerMobile}
                            >
                                <a
                                    href="https://flowypartners.com/gifty"
                                    target="_blank"
                                    rel="noopener noreferrer"
                                    className={Styles.ComplementLinksMobile}
                                >
                                    Gifty
                                </a>
                            </li>
                        </Collapse>
                    </div>
                    {
                        (onBoardingComplete && store.origin === 'Tiendanube') ?
                            <div
                                onClick={() => handleCloseReview()}
                                className={Styles.ReviewDrawerButtonContainer}
                                style={{
                                    pointerEvents: (!onBoardingComplete && store.suscriptionData?.status !== "authorized") ? 'none' : 'auto'
                                }}
                            >
                                <div
                                    className={Styles.NavlinkReview}
                                    style={{
                                        cursor: 'pointer',
                                        color: 'white',
                                    }}
                                >
                                    <ReviewIcon />
                                    <Typography sx={{ position: 'relative' }}>{t('Reviews')}</Typography>
                                    <Box sx={OFFMobileStyles}>50% OFF 🔥</Box>
                                </div>
                            </div>
                            :
                            null
                    }
                </Box>
            </ul>
            <ul
                style={{
                    position: 'absolute',
                    listStyle: 'none',
                    paddingLeft: '0',
                    width: '100%',
                    bottom: '1%',
                }}
            >
                {/* <li style={{cursor:"pointer",userSelect:'none'}}>
                    <NavLink
                        className={Styles.NavlinkMobile}
                        end
                        style={
                            ({ isActive }) => ({
                                color: isActive ? '#2ED588' : 'white',
                                fontWeight: '400'
                            })
                        }
                        to="/help"
                        onClick={handleClickDrawerWeb}
                    >
                        <HelpIcon/>
                        {t('Help')}
                    </NavLink>
                </li> */}
                <li style={{ cursor: "pointer", userSelect: 'none' }}>
                    <div
                        className={Styles.Navlink}
                        end="true"
                        style={{
                            justifyContent: 'center',
                            fontWeight: '400',
                            color: '#EB5757',
                            display: 'flex',
                        }}
                        onClick={handleLogout}
                    >
                        <LogoutIcon />
                        {t('Logout')}
                    </div>
                </li>
            </ul>
        </Box>
    )
}

export default NavbarMobile;