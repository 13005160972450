import React from 'react';
import { connect } from "react-redux";

// Actions
import { setComunication } from '../../../actions/stepsUser';

// Components
import SwitchComponent from './SwitchComponent/SwitchComponent';

// CSS
import Styles from './Advanced.module.css';

const Advanced = (props) => {

    // Actions
    const { setComunication } = props;

    // States
    const { whatsAppState, callState, smsState } = props;

    return (
        <>
            <h1 className={Styles.Title}>Vías de comunicación </h1>
            <p>Habilita o deshabilita las vias de comunicacion que quieras darles a tus clientes para que se contacten contigo.</p>
            <section className={Styles.SwitchGeneralContainer}>
                <SwitchComponent
                    title={ 'WhatsApp' }
                    text1={ 'Habilitar esta función le permitirá a tus clientes comunicarse por Whatsapp con tu asesor.' }
                    text2={ '' }
                    onClick={ () => setComunication({ whatsApp: !whatsAppState }) }
                    value={ whatsAppState }
                />
                <SwitchComponent
                    title={ 'SMS' }
                    text1={ 'Habilitar esta función le permitirá a tus clientes comunicarse por SMS con tu asesor.' }
                    text2={ '' }
                    onClick={ () => setComunication({ sms: !smsState }) }
                    value={ smsState }
                />
                <SwitchComponent
                    title={ 'Llamada telefónica' }
                    text1={ 'Habilitar esta función le permitirá a tus clientes comunicarse por llamada telefónica con tu asesor.' }
                    text2={ '' }
                    onClick={ () => setComunication({ call: !callState }) }
                    value={ callState }
                />
            </section>
        </>
    )
}

const mapStateToProps = state => {
    return {
        whatsAppState: state.stepsUser.comunication.whatsApp,
        callState: state.stepsUser.comunication.call,
        smsState: state.stepsUser.comunication.sms,
        userEditing: state.stepsUser.userEditing,
        userCreate: state.stepsUser.userCreate,
    }
}

const mapDispatchToProps = dispatch => {
    return {
        setComunication: (value) => dispatch(setComunication(value)),
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(Advanced);
