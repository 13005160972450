import { useState, useEffect } from 'react';
import { connect } from 'react-redux';

// Actions
import { getStoreData } from '../../../../actions/auth';

// Icon
import AlarmClock from '../../../../assets/icons/Home/AlarmClock';

// Material Ui
import { Box, Typography } from '@mui/material';
import moment from 'moment';

const TextStyles = {
    marginBottom: '.5rem',
    fontSize: '.7rem',
    fontWeight: '600',
    '@media(min-width: 350px)': {
        marginBottom: 0,
    }
}

const TimeContainer = {
    alignItems: 'center',
    display: 'flex',
}

const TimeText = {
    fontSize: '.7rem',
    fontWeight: '600',
    color: '#EB5757',
}
// const getReturnValues = (countDown) => {
//     const interval = new Date(countDown).getTime() - new Date().getTime();

//     const days = Math.floor(interval / (1000 * 60 * 60 * 24));
//     const hours = Math.floor((interval / (1000 * 60 * 60)) % 24);
//     const minutes = Math.floor((interval / 1000 / 60) % 60);
//     const seconds = Math.floor((interval / 1000) % 60);

//     return (
//         <Typography sx={TimeText}>
//             {days} D: {hours}HS: {minutes}MIN {seconds}SEC
//         </Typography>
//     )
// }

const TextsContainer = {
    // display: (intervalDays > 3) ? 'none' : 'block',
    padding: '.5rem .6rem 0 .6rem',
    border: '1px solid #E4E4E4',
    backgroundColor: 'white',
    borderRadius: '10px',
    alignItems: 'center',
    width: 'max-content',
    '@media(min-width: 350px)': {
        padding: '0rem .6rem',
        display: 'flex',
        gap: '10px'
    }
}

function calculateRemainingTime(creationDate, maxDays = 3) {
    const remainingTime = moment.duration(maxDays, 'days').subtract(moment().diff(creationDate));
    const timeLeft = remainingTime.asMilliseconds() > 0 ?
        `${Math.floor(remainingTime.asDays())}D: ${remainingTime.hours()}HS: ${remainingTime.minutes()}MIN ${remainingTime.seconds()}SEC` :
        '0D: 0HS: 0MIN 0SEC';

    return timeLeft;
}

const OfferDurationComponent = (props) => {

    // Actions
    const { getStoreData } = props;

    const [timeLeft, setTimeLeft] = useState('0D: 0HS: 0MIN 0SEC');
    const [createdAt, setCreatedAt] = useState('');


    useEffect(() => {
        getStoreData().then((store) => {
            if (store?.createdAt) {
                setCreatedAt(store.createdAt);
            }
        });
    }, [getStoreData]);

    useEffect(() => {
        setTimeLeft(calculateRemainingTime(createdAt));

        const timerId = setInterval(() => {
            setTimeLeft(calculateRemainingTime(createdAt));
        }, 1000);

        return () => clearInterval(timerId);
    }, [createdAt]);

    return (
        timeLeft !== '0D: 0HS: 0MIN 0SEC' ?
        <Box sx={TextsContainer}>
            <Typography sx={TextStyles}>LA OFERTA TERMINA EN</Typography>
            <Box sx={TimeContainer}>
                <AlarmClock />
                &nbsp;
                <Typography sx={TimeText}>
                    {timeLeft}
                </Typography>
            </Box>
        </Box>
        : null
    )
}

const mapDispatchToProps = dispatch => {
    return {
        getStoreData: () => dispatch(getStoreData()),
    }
}

export default connect(null, mapDispatchToProps)(OfferDurationComponent);