/** ENVIRONMENTS */
// export const MAIN_API_URL = 'https://whatspro-yvkptqz7ga-rj.a.run.app/api';

//DEV 
// export const MAIN_API_URL = 'http://localhost:8080/api';
// export const APP_ID = 3908;

//TEST
// export const MAIN_API_URL = 'https://whatspro-test-kitbfcj7yq-rj.a.run.app/api';
// export const APP_ID = 3908;

//PROD
export const MAIN_API_URL = 'https://whatspro-kitbfcj7yq-rj.a.run.app/api';
export const APP_ID = 3905;