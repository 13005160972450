import React, { useEffect, useState } from 'react';

import { Box, Button, Grid, Typography } from '@mui/material';

import { useParams } from 'react-router-dom';
import { PlatformList } from '../../helpers/platformList';
import WhatsPROLogo from '../../assets/logos/WhatsPRO.svg';
import ErrorImg from '../../assets/images/Auth/ErrorImg.png';

const ContainerStyles = {
    transform: 'translate(-50%, -50%)',
    justifyContent: 'center',
    position: 'absolute',
    alignItems: 'center',
    display: 'flex',
    width: '90%',
    left: '50%',
    top: '50%',
    '@media(min-width:1000px)': {
        width: '80%',
    },
    '@media(min-width:1750px)': {
        width: '60%',
    },
    margin: '0',
};

const LogoContainer = {
    margin: 'auto auto 3rem auto',
    width: '100%',
    '@media(min-width:350px)': {
        width: '300px',
    },
    '@media(min-width:1200px)': {
        margin: '0 0 3rem 0',
        width: '350px',
    },
};

const LogoImgStyles = {
    width: '100%',
};

const TextSectionStyles = {
    justifyContent: 'center',
    flexDirection: 'column',
    display: 'flex',
    '@media(min-width: 1200px)': {
        justifyContent: 'flex-start',
    },
};

const TextStyles = {
    fontFamily: 'Poppins',
    textAlign: 'center',
    fontSize: '1.1rem',
    color: '#898989',
    '@media(min-width: 1200px)': {
        textAlign: 'left',
    },
};

const ButtonsStyles = {
    width: 'max-content',
    borderRadius: '8px',
    backgroundColor: '#2ED588',
    padding: '0.75rem 3.25rem 0.63rem 3.25rem',
    border: '1px solid #2ED588',
    margin: '3rem auto auto auto',
    fontSize: '1.1rem',
    fontWeight: '500',
    ':hover': {
        backgroundColor: 'white',
        color: '#2ED588',
        border: '1px solid #2ED588',
    },
    '@media(min-width: 1200px)': {
        margin: '3rem 0 0 0',
    },
};

const ErrorImgContainer = {
    display: 'none',
    '@media(min-width:1200px)': {
        display: 'flex',
    },
};

const ErrorImgBoxContainer = {
    width: '100%',
    '@media(min-width:1200px)': {
        height: '488px',
        width: '488px',
    },
};

const ErrorImgStyles = {
    width: '100%',
};

const FailureSynchronizeView = () => {

    const params = useParams();
    const query = new URLSearchParams(window.location.search);

    const error = query.get('error');

    // const [ shopPlatform ] = useState(PlatformList.find((platform) => platform.name === params.platform).name);
    const [shopPlatform, setShopPlatform] = useState(null);

    useEffect(() => {
        const platform = PlatformList.find((platform) => platform.name === params.platform);
        if (platform) {
            setShopPlatform(platform);
        } else {
        }
    }, [params.platform]);
    return (
        <Box>
            <Grid container spacing={2} sx={ContainerStyles}>
                <Grid item lg={6}>
                    <Box sx={TextSectionStyles}>
                        <Box sx={LogoContainer}>
                            <img src={WhatsPROLogo} alt="Logo" style={LogoImgStyles} height={70} />
                        </Box>
                        <Box>
                            <Typography sx={TextStyles}>
                                {error ?? 'Ocurrió un error inesperado. Por favor, intentalo de nuevo'}
                            </Typography>
                        </Box>
                        <Button key={shopPlatform?.name ?? 1} variant="contained" sx={ButtonsStyles}
                            onClick={() => {
                                window.location.href = shopPlatform?.redirectHref ?? window.location.origin + '/welcome';
                            }}
                        >
                            Reintentar
                        </Button>
                    </Box>
                </Grid>
                <Grid item xs={12} md={6} sx={ErrorImgContainer}>
                    <Box sx={ErrorImgBoxContainer}>
                        <img src={ErrorImg} alt="ErrorImg" style={ErrorImgStyles} />
                    </Box>
                </Grid>
            </Grid>
        </Box>
    );
};

export { FailureSynchronizeView };
