import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';

// Icons
import GiftStepperTask from '../../../../assets/icons/Home/GiftStepperTask';
import StartIcons from '../../../../assets/icons/Home/StartIcons';

// Material Ui
import StepConnector, { stepConnectorClasses } from '@mui/material/StepConnector';
import StepLabel from '@mui/material/StepLabel';
import { styled } from '@mui/material/styles';
import Stepper from '@mui/material/Stepper';
import Step from '@mui/material/Step';
import { Box } from '@mui/material';

const ColorlibConnector = styled(StepConnector)(({ theme, activeStep }) => ({
    [`&.${stepConnectorClasses.alternativeLabel}`]: {
        top: 20,
    },
    [`&.${stepConnectorClasses.active}`]: {
        [`& .${stepConnectorClasses.line}`]: {
            backgroundColor: 'rgb(46,213,136)',
            background: activeStep !== 4 ? 
                'linear-gradient(90deg, rgba(46,213,136,1) 0%, rgba(46,213,136,1) 50%, #EEEEEE 50%, #EEEEEE 100%)'
                :
                'linear-gradient(90deg, rgba(46,213,136,1) 0%, rgba(46,213,136,1) 52%, #EEEEEE 52%, #EEEEEE 100%)'
        },
    },
    [`&.${stepConnectorClasses.completed}`]: {
        [`& .${stepConnectorClasses.line}`]: {
            background: '#2ED588',
        },
    },
    [`& .${stepConnectorClasses.line}`]: {
        height: 9,
        border: 0,
        backgroundColor:
            theme.palette.mode === 'dark' ? theme.palette.grey[800] : '#EEEEEE',
        boxShadow: 'inset 0px 4px 4px rgba(0, 0, 0, 0.1)',
        borderRadius: 1,
    },
}));

const ColorlibStepIconRoot = styled('div')(({ theme, ownerState }) => ({
    backgroundColor: theme.palette.mode === 'dark' ? theme.palette.grey[700] : 'transparent',
    justifyContent: 'center',
    color: 'transparent',
    position: 'relative',
    alignItems: 'center',
    boxShadow: 'none',
    display: 'flex',
    // height: 50,
    zIndex: 1,
    // width: 50,
    ...(ownerState.active && {
        backgroundImage: '#2ED588',
        boxShadow: 'none',
    }),
    ...(ownerState.completed && {
        backgroundImage: '#2ED588',
        boxShadow: 'none',
    }),
}));

function ColorlibStepIcon(props) {
    const { active, completed, className } = props;

    const icons = {
        1: <GiftStepperTask 
                backColor={ active ? 'white' : completed ? '#2ED588' : '#E4E4E4' } 
                giftColor={ active ? '#2ED588' : 'white' }
                active={ active }
            />,
        2: <GiftStepperTask 
                backColor={ active ? 'white' : completed ? '#2ED588' : '#E4E4E4' } 
                giftColor={ active ? '#2ED588' : 'white' }
                active={ active }
            />,
        3: <GiftStepperTask 
                backColor={ active ? 'white' : completed ? '#2ED588' : '#E4E4E4' } 
                giftColor={ active ? '#2ED588' : 'white' }
                active={ active }
            />,
        4: <GiftStepperTask 
                backColor={ active ? 'white' : completed ? '#2ED588' : '#E4E4E4' } 
                giftColor={ active ? '#2ED588' : 'white' }
                active={ active }
            />,
    };

    return (
        <Box>
            <StartIcons top={ '-5px' } display={ completed ? 'flex' : 'none' } right={ '13.5rem' } />
            <StartIcons top={ '-15px' } display={ completed ? 'flex' : 'none' } right={ '8rem' } />
            <ColorlibStepIconRoot ownerState={{ completed, active }} className={className}>
                {icons[String(props.icon)]}
            </ColorlibStepIconRoot>
        </Box>
    );
}

ColorlibStepIcon.propTypes = {
    /**
     * Whether this step is active.
     * @default false
     */
    active: PropTypes.bool,
    className: PropTypes.string,
    /**
     * Mark the step as completed. Is passed to child components.
     * @default false
     */
    completed: PropTypes.bool,
    /**
     * The label displayed in the step icon.
     */
    icon: PropTypes.node,
};

const StepperComponent = (props) => {

    
    // States
    const { stepperTexts, activeTask } = props;

    // External props
    const { task } = props;

    const handleShowIconLabel = (index) => {
        switch (true) {
            case index < (task - 1):
                return '🔥';
            case index === (task - 1):
                return '🎁';
            case index > (task - 1):
                return '🔒';
            default:
                return;
        }
    };

    return (
        <Stepper alternativeLabel activeStep={ activeTask - 1 } connector={<ColorlibConnector />}>
            {stepperTexts.map((label, index) => (
                <Step key={index}>
                    <StepLabel StepIconComponent={ColorlibStepIcon}>
                        {label} { handleShowIconLabel(index) }
                    </StepLabel>
                </Step>
            ))}
        </Stepper>
    )
}

const mapStateToProps = state => {
    return {
        stepperTexts: state.tasks.stepperTexts,
        activeTask: state.tasks.activeTask,
    }
}

// const mapDispatchToProps = dispatch => {
//     return {
        
//     }
// }

export default connect(mapStateToProps, null)(StepperComponent);