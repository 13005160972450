import { Box, useMediaQuery, useTheme } from '@mui/material'
import React from 'react'
import Logo from '../assets/logos/whatspro.gif';

export const LayoutAuth = ({ children }) => {

    const theme = useTheme();
    const isMobile = useMediaQuery(theme.breakpoints.down('sm'));

    return (
        <Box
            display={'flex'}
            flexDirection={'column'}
            justifyContent={isMobile ? 'flex-start' : 'center'}
            alignItems={'center'}
            sx={{
                minHeight: '100vh',
                width: '100vw',
                backgroundColor: '#f9f9f9',
                padding: isMobile ? '0' : '40px 0px',
                boxSizing: 'border-box',
            }}
        >
            <Box
                display={'flex'}
                flexDirection={'column'}
                alignItems={'center'}
                sx={{
                    minHeight: isMobile ? '100vh' : '500px',
                    width: isMobile ? '100%' : '400px',
                    textAlign: 'center',
                    backgroundColor: '#fff',
                    borderRadius: '6px',
                    boxShadow: '0 0 10px 0 rgba(0, 0, 0, 0.1)',
                    padding: '40px 20px',
                }}
                gap={2}
            >
                <Box
                    component={'img'}
                    src={Logo}
                    sx={{
                        height: '90px',
                        width: '90px',
                        borderRadius: '6px',
                    }}
                />
                <Box
                    component={'form'}
                    display={'flex'}
                    flexDirection={'column'}
                    justifyContent={'center'}
                    alignItems={'center'}
                    gap={1}
                    width={'85%'}
                >
                    {children}
                </Box>
            </Box>
        </Box>
    )
}
