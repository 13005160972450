import React from 'react';

// Icons
import ArrowForwardIcon from '@mui/icons-material/ArrowForward';

// Images
import Step0MobileImg from '../../assets/images/WelcomeQuestions/Step0MobileImg.png'; 
import Step0Img from '../../assets/images/WelcomeQuestions/Step0Img.png'; 

// Material Ui
import { Box, Button, Typography, useMediaQuery } from '@mui/material';

const GeneralContainer = {
    height: 'max-content',
    paddingTop: '40px',
    width: '100%',
    '@media(min-width: 550px)': {
        paddingTop: 0,
    },
} 

const ImageContainer = {
    margin: '2rem auto auto auto',
    width: '75%',
}

const TextContainer = {
    textAlign: 'center',
    marginTop: '2rem',
    bottom: '35%',
}

const Title = {
    fontSize: '1.8rem',
    fontWeight: '500',
    color: 'white',
}

const Subtitle = {
    margin: '1rem auto auto auto',
    fontSize: '.8rem',
    fontWeight: '500',
    color: 'white',
    width: '100%',
    '@media(min-width: 400px)': {
        width: '320px',
    }
}

const ButtonStyles = {
    backgroundColor: 'white',
    textTransform: 'none',
    fontFamily: 'Poppins',
    alignItems: 'center',
    borderRadius: '6px',
    fontSize: '1rem',
    color: '#2ED588',
    display: 'flex',
    padding: '8px',
    margin: 'auto',
    bottom: '5%',
    width: '90%',
    gap: '5px',
    ':hover': {
        color: '#2ED588',
        backgroundColor: '#F1FFF9',
    },
    '@media(min-width: 400px)': {
        position: 'relative',
        width: '60%',
    },
    '@media(min-height: 400px)': {
        margin: '4.5rem auto auto auto',
    }
}

const Step0WelcomeQuestion = ({ handleChange }) => {

    const matches = useMediaQuery('(min-width: 900px)');

    return (
        <Box sx={ GeneralContainer }>
            <Box sx={ ImageContainer }>
                <img src={ matches ? Step0Img : Step0MobileImg } alt="Logo" style={{ width: '100%' }}/>
            </Box>
            <Box sx={ TextContainer }>
                <Typography sx={ Title }>Bienvenido a WhatsPRO</Typography>
                <Typography sx={ Subtitle }>Completá los siguientes pasos y comenzá a vender más y atender mejor</Typography>
            </Box>
            <Button sx={ ButtonStyles } onClick={ handleChange }>
                Comenzar
                <ArrowForwardIcon sx={{ height: '1.3rem' }}/>
            </Button>
        </Box>
    )
}

export default Step0WelcomeQuestion;