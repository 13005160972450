import React, { Suspense, useState } from 'react';

// Avatar
import AvatarTask4Mobile from '../../../assets/images/Home/AvatarTask4Mobile.png';
import AvatarTask4 from '../../../assets/images/Home/AvatarTask4.png';

import CongratsModalBackMobile from '../../../assets/images/Home/CongratsModalBackMobile.png';
import CongratsModalBack from '../../../assets/images/Home/CongratsModalBack.png';

// Icon
import CheckCircleOutlineIcon from '@mui/icons-material/CheckCircleOutline';

// Material ui
import { Box, Button, Fade, Modal, Typography, useMediaQuery } from '@mui/material';

// CSS
import Styles from './CongratsModal.module.css';
import { connect } from 'react-redux';
import { useFetch } from 'use-http';
import { MAIN_API_URL } from '../../../configuration';
import { LoadingButton } from '@mui/lab';

const TitleStyles = {
    fontSize: '1.2rem',
    fontWeight: '600',
    '@media(min-width: 300px)': {
        fontSize: '1.3rem',
    }
}

const SubtitleStyles = {
    margin: '.5rem 0 1rem 0',
    fontSize: '.9rem',
}

const GiftTextContainer = {
    border: '2px solid #E1C478',
    backgroundColor: '#FFFFFF',
    padding: '.6rem 1rem',
    borderRadius: '20px',
    width: 'max-content',
    alignItems: 'center',
    margin: '2rem auto',
    display: 'flex',
    gap: '5px',
}

const GiftText = {
    marginBottom: '-.1rem',
    fontSize: '.55rem',
    fontWeight: '600',
    '@media(min-width: 350px)': {
        fontSize: '.7rem',
    },
    '@media(min-width: 900px)': {
        fontSize: '.8rem',
    }
}

const HelperText = {
    textAlign: 'left',
    fontSize: '.6rem',
    color: '#BFBBBD',
    width: '100%',
    margin: 0,
    '@media(min-width: 400px)': {
        fontSize: '.7rem',
    }
}

const LoadingText = {
    textAlign: 'left',
    fontSize: '1rem',
    width: '100%',
    marginTop: '1rem',
    color: '#BFBBBD',
    '@media(min-width: 400px)': {
        fontSize: '1.1rem',
    }
}

const AvatarImg = {
    width: '100%'
}

const SubCongratsModal = (props) => {

    // External props
    const { open, handleClose, task, origin } = props;

    const { post, error, response, loading } = useFetch(MAIN_API_URL + '/stores/cuit', {
        headers: {
            'Content-Type': 'application/json',
            'x-token': localStorage.getItem('token')
        }
    });

    const [disableButton, setDisableButton] = useState(false);

        // States
        const { lastTask } = props;
                
        const ContainerStyles = {
            backgroundImage: `url(${CongratsModalBackMobile})`,
            transform: 'translate(-50%, -50%)',
            backgroundRepeat: 'no-repeat',
            border: '1px solid #BFBBBD',
            justifyContent: 'center',
            boxSizing: 'border-box',
            backgroundSize: 'cover',
            flexDirection: 'column',
            position: 'absolute',
            alignItems: 'center',
            textAlign: 'center',
            overflowY: 'auto',
            bgcolor: 'white',
            p: '1rem 1.5rem',
            display: 'flex',
            height: '100%',
            width: '100%',
            left: '50%',
            top: '50%',
            "@media (min-width: 400px)": {
                backgroundImage: `url(${CongratsModalBack})`,
                height: 'max-content',
                overflowY: 'inherit',
                borderRadius: '10px',
                width: '400px',
            },
            "@media (min-width: 900px)": {
                width: '550px',
            },
            '&:focus-visible': {
                outline: 'none'
            }
        };
    
        const DataContainer = {
            position: 'absolute',
            width: '98%',
            top: (task === 2) ? '4%' : '10%',
            '@media(min-height: 300px)': {
                width: task === 4 ? '98%' : 'auto',
            },
            '@media(min-height: 500px)': {
                top: '10%',
            },
            '@media(min-width: 350px)': {
                top: '20%',
            },
            '@media(min-width: 400px)': {
                position: 'relative'
            }
        };
    
        const AvatarContainer = {
            margin: '0 auto',
            width: '120px',
            '@media(min-width: 400px)': {
                margin: '-3.5rem auto auto auto',
                width: '320px',
            }
        };
    
        const ButtonStyles = {
            animation: ( task === 4 ) ? 'pulseCongratsModalAnimation 2s infinite' : 'none',
            marginBottom: ( task === 'sub' || task === 2 ) ? '1rem' : 0,
            backgroundColor: disableButton ? 'gray' : '#E1C478',
            padding: '.5rem 1rem',
            fontFamily: 'Poppins',
            textTransform: 'none',
            borderRadius: '6px',
            fontWeight: '500',
            fontSize: '1rem',
            marginTop: '1rem',
            color: 'white',
            width: '90%',
            "@media (min-width: 440px)": {
                width: ( task === 2 ) ? '60%' : '80%',
                marginTop: '2rem',
                marginBottom: 0,
            },
            "@media (min-width: 900px)": {
                marginTop: '2rem',
                width: '70%',
            },
            '&:hover': {
                backgroundColor: '#E1C478'
            },
            '&:disabled': {
                color: 'white'
            },
            '@keyframes pulseCongratsModalAnimation': {
                '0%': {
                    transform: 'scale(1, 1)',
                    color: 'white',
                },
                '25%': {
                    transform: 'scale(1, 1)',
                    color: 'white',
                },
                '50%': {
                    transform: 'scale(1.03, 1.03)',
                    color: 'white',
                },
                '75%': {
                    transform: 'scale(1, 1)',
                    color: 'white',
                }
            },
        };

    const LastTextStyles = {
        margin: '1rem auto 0 auto',
        fontSize: '.8rem',
        fontWeight: '400',
        width: '100%',
        '@media(min-height: 500px)': {
            margin: '1rem auto'
        },
        '@media(min-width: 900px)': {
            width: '300px'
        }
    }

    const matches = useMediaQuery('(min-width: 400px)');


    const [cuit, setCuit] = useState('');

    const handleChangeCUITCUIL = (e) => {
        if (e.target.value.length > 11) return;
        setCuit(e.target.value);
    }

    const handleCloseForm = async () => {
        setDisableButton(true);
        const cuitInFormat = cuit.trim().replace(/-/g, '');
        const payload = { cuit: cuitInFormat };
        try {
            await post('/', payload);
            if (response.ok) {
                setDisableButton(false);
                window.location.href = '/home';
            }
        }
        catch (error) {
            console.log('error -> ', error);
            alert('Error al enviar el CUIT');
        }
    }


    return (
        <Suspense>
            <Modal
                open={open}
                aria-labelledby="modal-modal-title"
                aria-describedby="modal-modal-description"
            >
                <Fade in={open}>
                    <Box sx={ContainerStyles}>
                        <Box sx={DataContainer}>
                            <Box sx={AvatarContainer} >
                                <img src={matches ? AvatarTask4 : AvatarTask4Mobile} alt="avatar" style={AvatarImg} />
                            </Box>
                            <Box>
                                <Typography sx={TitleStyles}>¡Suscripción exitosa!</Typography>
                                <Typography sx={SubtitleStyles}>'Gracias a tu suscripción ya tenés tus premios:</Typography>
                                <Box sx={GiftTextContainer}>
                                    <CheckCircleOutlineIcon sx={{ color: '#E1C478', height: '1.2rem' }} />
                                    <Typography sx={GiftText}>30 DÍAS <span style={{ fontWeight: '700' }}>GRATIS</span> PARA USAR EL COMPLEMENTO</Typography>
                                </Box>
                                <Typography sx={LastTextStyles}>Completá tus datos de facturación para recibir tu factura por email</Typography>
                                {
                                    (task !== 4) ?
                                        <Box sx={{ width: '90%', margin: 'auto', '@media(min-width: 400px)': { width: '100%' } }}>
                                            <input
                                                onChange={handleChangeCUITCUIL}
                                                className={Styles.InputStyles}
                                                placeholder="CUIT O CUIL"
                                                value={cuit}
                                                maxLength={30}
                                                type="number"
                                            />
                                            <Typography
                                                sx={HelperText}
                                            >Ingresá solo números, sin espacios, puntos o guiones.</Typography>
                                        </Box>
                                        :
                                        null
                                }
                            </Box>
                            <LoadingButton
                                onClick={handleCloseForm}
                                disabled={loading}
                                loading={loading}
                                loadingPosition='end'
                                sx={ButtonStyles}
                            >
                                { loading ? 'Consultando' : 'Finalizar'}
                            </LoadingButton>
                        </Box>
                    </Box>
                </Fade>
            </Modal>
        </Suspense>
    )
}

export default connect(null, null)(SubCongratsModal);
