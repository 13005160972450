import React from 'react';

// CSS
import Styles from './Chip.module.css';

const Chip = ({ active, text, handleClick }) => {
    
    return (
        <div
            className={Styles.Chip}
            style={{
                backgroundColor: active ? '#2ED588' : '#F6F6F6',
                color: active ? 'white' : '#473C41',
            }}
            onClick={ (index) => handleClick(index) }
        >{text}</div>
    )
}

export default Chip;
