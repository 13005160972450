import React, { useState, useEffect } from 'react';
import { connect } from "react-redux";

import { Draggable } from 'react-beautiful-dnd';

// Actions
import { updateQuestion } from '../../../actions/stepsChat';

// Components
import GreenSwitch from '../../GreenSwitch/GreenSwitch';
import MenuComponent from '../../Menu/MenuComponent';

// Icons
import DragIcon from '../../../assets/icons/Chat/DragIcon';

// Material Ui
// import DeleteOutlineOutlinedIcon from '@mui/icons-material/DeleteOutlineOutlined';
import ModeEditOutlinedIcon from '@mui/icons-material/ModeEditOutlined';
import { Button, IconButton } from '@mui/material';

// CSS
import Styles from './QuestionSetting.module.css';

const QuestionSetting = ( props ) => {

    // Actions
    const { updateQuestion } = props;

    // External props
    const { uid, question, reply, state, index} = props;
    
    const [edit, setEdit] = useState(false);

    const handlePenClick = () => {
        setEdit(!edit);
    };

    const handleClickSwitch = () => {
        updateQuestion({
            uid,
            newData: {
                state: !state,
            }
        });
    };

    const handleChangeQuestion = (e) => {

        updateQuestion({
            uid,
            newData: {
                question: e.target.value,
            }
        });
    };

    const handleChangeAnswer = (e) => {

        updateQuestion({
            uid,
            newData: {
                reply: e.target.value,
            }
        });
    };
    
    return (
        <Draggable draggableId={ uid } index={ index } style={{ display: 'flex' }}>
            {
                (draggableProvided) => (
                    <div 
                        className={Styles.DraggContainer} 
                        { ...draggableProvided.draggableProps }
                        ref={ draggableProvided.innerRef } 
                    >
                        <div
                            { ...draggableProvided.dragHandleProps }
                            className={Styles.DotsContainer}
                        >
                            <DragIcon/>
                        </div>
                        <div 
                            className={Styles.QuestionComponent}
                            style={{
                                border: state ? '1px solid #2ED588' : '1px solid #BFBBBD',
                                padding: edit ? '1rem' : '.5rem 1rem',
                            }}
                        >
                            <div className={Styles.TopContainer}>
                                <div className={Styles.SwitchContainer}>
                                    <div className={Styles.Switch}>
                                        <GreenSwitch
                                            onClick={ handleClickSwitch }
                                            checked={ state }
                                        />
                                    </div>
                                    {
                                        edit ?
                                            <textarea 
                                                className={Styles.Question} 
                                                style={{ 
                                                    color: state ? '#393939' : '#BFBBBD',
                                                    border: !edit && 'none',
                                                    resize: 'none' 
                                                }} 
                                                onChange={ handleChangeQuestion } 
                                                value={ question }
                                                disabled={ !edit }
                                                maxLength="150"
                                            />
                                        :
                                            <div className={ Styles.QuestionDiv } style={{ color: state ? '#393939' : '#BFBBBD' }}>{ question }</div>
                                    }
                                </div>
                                <div className={Styles.ButtonsContainer}>
                                    <div 
                                        style={{ 
                                            display: edit ? 'none' : 'flex' 
                                        }}
                                    >
                                        <IconButton aria-label="edit" 
                                        onClick={ handlePenClick }
                                        >
                                            <ModeEditOutlinedIcon
                                                sx={{ 
                                                    color: '#BFBBBD !important', 
                                                    height: '1.5rem !important', 
                                                    width: '1.5rem !important' 
                                                }}
                                            />
                                        </IconButton>
                                    </div>
                                </div>
                                { edit ? 
                                    null : 
                                    <div className={Styles.MobileButtonsContainer}>
                                        <MenuComponent
                                            onClickDisabled={ handleClickSwitch }
                                            onClickEdit={ handlePenClick }
                                            checked={ state }
                                            type={ 'question' }
                                        />
                                    </div>
                                }
                            </div>
                            <div className={Styles.BottomContainer} style={{ display: edit ? 'block' : 'none' }}>
                                <p style={{ display: edit ? 'block' : 'none', position: 'absolute', zIndex: '1' }}>Respuesta</p>
                                <textarea
                                    onChange={ handleChangeAnswer }
                                    className={ Styles.TextArea }
                                    value={ reply }
                                    style={{
                                        color: state ? '#393939' : '#BFBBBD', 
                                        display: edit ? 'block' : 'none',
                                        height: '10em',
                                    }} 
                                />
                                <div
                                    style={{ display: edit ? 'flex' : 'none' }} 
                                    className={Styles.SaveButtonContainer}
                                >
                                    <Button
                                        sx={{
                                            boxShadow: '0px 2.25103px 2.25103px rgba(0, 0, 0, 0.25)',
                                            backgroundColor: 'white',
                                            textTransform: 'none',
                                            fontFamily: 'Poppins',
                                            borderRadius: '8px',
                                            fontWeight: '600',
                                            color: '#2ED588',
                                        }}
                                        onClick={ handlePenClick }
                                    >
                                        Listo
                                    </Button>
                                </div>
                            </div>
                        </div>
                    </div>
                )}
        </Draggable>
    )
}

const mapDispatchToProps = dispatch => {
    return {
        updateQuestion: (question) => dispatch(updateQuestion(question)), 
    }
}

export default connect( null, mapDispatchToProps )(QuestionSetting);


