import React from 'react';

const WhatsAppIcon = ({ color }) => {
    return (
        <svg width="50" height="50" viewBox="0 0 139 139" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M118.15 20.2249C111.778 13.7887 104.188 8.68558 95.8238 5.21325C87.4592 1.74093 78.4869 -0.0311367 69.4303 0.000414008C31.4834 0.000414008 0.555999 30.9278 0.555999 68.8747C0.555999 81.0372 3.75299 92.8521 9.72997 103.277L0 139L36.4874 129.409C46.5649 134.9 57.8933 137.818 69.4303 137.818C107.377 137.818 138.305 106.891 138.305 68.9442C138.305 50.5268 131.146 33.2213 118.15 20.2249ZM69.4303 126.143C59.1443 126.143 49.0669 123.363 40.2404 118.15L38.1554 116.899L16.4715 122.598L22.2399 101.47L20.8499 99.3156C15.1353 90.1901 12.1009 79.6419 12.093 68.8747C12.093 37.3218 37.8079 11.6069 69.3608 11.6069C84.6507 11.6069 99.0372 17.5839 109.81 28.4258C115.144 33.7354 119.371 40.0508 122.246 47.0061C125.121 53.9614 126.587 61.4181 126.559 68.9442C126.698 100.497 100.983 126.143 69.4303 126.143ZM100.844 83.3307C99.1067 82.4967 90.6277 78.3267 89.0987 77.7012C87.5002 77.1452 86.3882 76.8672 85.2067 78.5352C84.0253 80.2727 80.7588 84.1647 79.7858 85.2767C78.8128 86.4582 77.7703 86.5972 76.0328 85.6937C74.2953 84.8597 68.7353 82.9832 62.2023 77.1452C57.0593 72.5582 53.6538 66.9287 52.6113 65.1912C51.6383 63.4537 52.4723 62.5502 53.3758 61.6467C54.1403 60.8822 55.1133 59.6312 55.9473 58.6582C56.7813 57.6852 57.1288 56.9207 57.6848 55.8087C58.2408 54.6272 57.9628 53.6543 57.5458 52.8203C57.1288 51.9863 53.6538 43.5073 52.2638 40.0323C50.8738 36.6963 49.4144 37.1133 48.3719 37.0438H45.0359C43.8544 37.0438 42.0474 37.4608 40.4489 39.1983C38.9199 40.9358 34.4719 45.1058 34.4719 53.5848C34.4719 62.0637 40.6574 70.2647 41.4914 71.3767C42.3254 72.5582 53.6538 89.9331 70.8898 97.3696C74.9903 99.1766 78.1873 100.219 80.6893 100.984C84.7897 102.304 88.5427 102.096 91.5312 101.679C94.8672 101.192 101.748 97.5086 103.138 93.4776C104.597 89.4466 104.597 86.0412 104.111 85.2767C103.624 84.5122 102.582 84.1647 100.844 83.3307Z" fill={ color }/>
        </svg>
    )
}

export default WhatsAppIcon;