import React, { useState, useEffect } from 'react';
import { Link } from 'react-router-dom';
import { connect } from 'react-redux';

// Actions
import { getEmployees, setChangeNewEmployee, setEditEmployee } from '../../actions/users';
import { getEmployeeImgs, setIdUserDeleteA, setUserEditing } from '../../actions/stepsUser';
import { resetAll } from '../../actions/global';

// Components
import BannerCreateUser from '../../components/Banners/BannerCreateUser/BannerCreateUser';
import CreateUser from '../../components/Modals/ModalsWithArrows/User/CreateUser';
import UserComponent from '../../components/Users/UserComponent/UserComponent';
import DeleteModal from '../../components/Modals/DeleteHourModal/DeleteModal';
import WelcomeModal from '../../components/Modals/WelcomeModal/WelcomeModal';

// Icons
import ControlPointIcon from '@mui/icons-material/ControlPoint';

// Material Ui
import { Box } from '@mui/material';

// CSS
import Styles from './MyUsersScreen.module.css';
import LayoutPages from '../../layout/LayoutPages';

const MyUsers = (props) => {

    // Actions
    const { setUserEditing, resetAll, getEmployeesA, setChangeNewEmployee, setEditEmployee, getEmployeeImgs } = props;

    // States
    const { endTutorial, employees, newUser, editUser } = props;

    const MyUsersScreenContainer = {
        textAlign: 'left',
        width: '100%',
        '@media(min-width: 1000px)': {
            width: '1000px'
        }
    }
    
    const [showWelcomeModal, setShowWelcomeModal] = useState(false);
    const [showModal, setShowModal] = useState(false);

    const handleShowDeleteModal = () => {
        setShowModal( !showModal );
    }

    const handleCloseWelcome = () => {
        setShowWelcomeModal( !showWelcomeModal );
    }

    const handleCreateUser = () => {
        setUserEditing({
            userEditing: false,
            userUid: ''
        });
        
        resetAll();
    }

    useEffect(() => {
        getEmployeeImgs();
        getEmployeesA();
    }, []);

    const handleCloseBannerNew = () => {
        setChangeNewEmployee({
            newUser: false
        });
    }

    const handleCloseBannerEdit = () => {
        setEditEmployee({
            editUser: false
        })
    }

    return (
        <LayoutPages>
            <Box component="div" sx={ MyUsersScreenContainer } >
                <h1 className={Styles.Title} >Mis asesores</h1>
                <div className={Styles.SubtitleSection}>
                    <div className={Styles.SubtitleText}>
                        <p>Creá y editá los asesores que van a estar disponibles en tu chat.</p>
                    </div>
                    <div className={Styles.ButtonContainer}>
                        <Link
                            style={{
                                backgroundColor: '#2ED588',
                                textDecoration: 'none',
                                fontFamily: 'Poppins',
                                padding: '.5rem 1rem',
                                textTransform: 'none',
                                position: 'relative',
                                borderRadius: '8px',
                                textAlign: 'center',
                                fontWeight: '600',
                                fontSize: '.9rem',
                                display: 'flex',
                                color: 'white',
                                gap: '5px',
                                '&:hover': {
                                    backgroundColor: '#2ED588',
                                }
                            }}
                            to='/createUser'
                            onClick={ handleCreateUser }
                        >
                            <ControlPointIcon 
                                sx={{ 
                                    color: 'white !important', 
                                    height: '1.7rem !important', 
                                    width: '1.7rem !important' 
                                }}
                            />
                            <p style={{ margin: 'auto 0', fontFamily: 'Poppins' }}>Agregar asesor</p> 
                        </Link>
                        { (endTutorial && employees.length === 0) && <CreateUser/> }
                    </div>
                </div>
                {
                    employees.map((employee, index) => (
                        <UserComponent
                            key={ index }
                            id={ index }
                            onDelete={ handleShowDeleteModal }
                            {...employee}
                        />
                    ))
                }
                <DeleteModal
                    open={ showModal }
                    handleClose={ handleShowDeleteModal }
                    type={ 'users' }
                />
                { endTutorial && <WelcomeModal open={showWelcomeModal} handleClose={ handleCloseWelcome }/> }
                {
                    editUser && 
                        <BannerCreateUser 
                            open={ editUser } 
                            success={ editUser } 
                            handleClose={ handleCloseBannerEdit }
                            type={ 'edit' }
                            error={ false } 
                        />
                }
                {
                    newUser && 
                        <BannerCreateUser 
                            open={ newUser } 
                            success={ newUser } 
                            handleClose={ handleCloseBannerNew }
                            type={ 'congrats' }
                            error={ false } 
                        />
                }
            </Box>
        </LayoutPages>
    )
}

const mapStateToProps = state => {
    return {
        employees: state.employees.employees,
        endTutorial: state.auth.endTutorial,
        editUser: state.employees.editUser,
        newUser: state.employees.newUser,
        users: state.stepsUser.users,
    }
}

const mapDispatchToProps = dispatch => {
    return {
        setUserEditing: ({ userEditing, userUid }) => dispatch(setUserEditing({ userEditing, userUid })),
        setChangeNewEmployee: (data) => dispatch( setChangeNewEmployee(data) ),
        setIdUserDeleteA: (value) => dispatch(setIdUserDeleteA(value)),
        setEditEmployee: (value) => dispatch(setEditEmployee(value)),
        getEmployeeImgs: () => dispatch(getEmployeeImgs()),
        getEmployeesA: () => dispatch( getEmployees() ),
        resetAll: () => dispatch(resetAll()),
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(MyUsers);