import React from 'react';

const ReviewIcon = () => {
    return (
        <div style={{marginRight: '8px', display: 'flex', alignItems: 'center'}}>
            <svg width="20" height="21" viewBox="0 0 20 21" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path d="M16.7387 7.379H12.8845L13.7554 3.45667V3.45683C13.8625 2.97266 13.8279 2.46779 13.6558 2.0028C13.4837 1.53765 13.1814 1.1321 12.7848 0.834269L12.1851 0.38449C11.7475 0.0548103 11.1894 -0.0710278 10.6527 0.0387998C10.116 0.14848 9.65211 0.483455 9.37896 0.958361C8.19362 3.11577 6.84289 5.17824 5.33906 7.1273C5.28126 7.00481 5.18984 6.90122 5.07567 6.82852C4.96135 6.75567 4.82893 6.71676 4.69348 6.71628H0.717318C0.527102 6.71628 0.344731 6.79186 0.210072 6.92635C0.0755767 7.06085 0 7.24338 0 7.4336V18.7053C0 18.8957 0.0755767 19.0781 0.210072 19.2126C0.344731 19.3471 0.527102 19.4227 0.717318 19.4227H4.69557C4.86737 19.4215 5.03261 19.358 5.161 19.244C5.28941 19.13 5.37203 18.9732 5.39349 18.803C6.14348 19.6172 7.19896 20.082 8.30585 20.0855H12.2439C13.5681 20.087 14.8048 19.4251 15.5379 18.3223L19.4531 12.4497H19.4529C19.8913 11.7916 20.0756 10.9967 19.9717 10.2126C19.8678 9.42867 19.4827 8.70926 18.888 8.18791C18.2932 7.66673 17.5294 7.37931 16.7386 7.37902L16.7387 7.379ZM3.97819 17.9879H1.43458V8.15002H3.97819V17.9879ZM18.2594 11.6546L14.3442 17.5264L14.3444 17.5266C13.8767 18.2293 13.0883 18.6514 12.244 18.6506H8.30596C7.51162 18.6488 6.76405 18.2749 6.28611 17.6405L5.41299 16.4771V9.11007C6.91153 7.84546 9.04475 4.42332 10.6228 1.6721H10.6229C10.69 1.55361 10.8055 1.47019 10.9392 1.44393C11.0743 1.41575 11.2149 1.44713 11.3252 1.53008L11.9242 1.97986H11.924C12.1 2.11227 12.2342 2.29256 12.3104 2.49911C12.3868 2.70566 12.402 2.92983 12.3544 3.1447L11.2914 7.94007C11.2442 8.15255 11.2959 8.37477 11.432 8.54451C11.5681 8.71423 11.774 8.81302 11.9914 8.81302H16.7409C17.1838 8.8135 17.6116 8.97458 17.9448 9.26678C18.2778 9.55883 18.4935 9.96184 18.5518 10.401C18.6101 10.8401 18.5069 11.2855 18.2615 11.6544L18.2594 11.6546Z" fill="#2ED588"/>
            </svg>
        </div>
    )
}

export default ReviewIcon;