import React from 'react';

// CSS
import Styles from './Step1Chat.module.css';

const TextStep1Chat = () => {
    return (
        <div className={ Styles.TextContainer }>
            <h2>Paso 1: Título y bienvenida</h2>
            <h3>¿Qué tengo que hacer?</h3>
            <p>En este paso podrás configurar lo primero que verán tus clientes, el título del chat y el texto de bienvenida. Podrás elegir una plantilla predeterminada para tu saludo de bienvenida o personalizar el tuyo. </p>
        </div>
    )
}

export default TextStep1Chat;