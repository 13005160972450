import React from 'react'

const GreenCheckColors = () => {
    return (
        <svg width="50" height="50" viewBox="0 0 57 57" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M11.875 26.125L26.125 40.375L49.875 16.625" stroke="#2ED588" strokeWidth="4.75" strokeLinecap="round" strokeLinejoin="round"/>
        </svg>
    )
}

export default GreenCheckColors