import React from 'react';
import { connect } from "react-redux";

// Actions
import { setMessages } from '../../../actions/stepsUser';

// Components
import BannerHours from '../../Banners/BannerHours/BannerHours';
import GreenSwitch from '../../GreenSwitch/GreenSwitch';

// CSS
import Styles from './Step3.module.css';

const Step3 = (props) => {

    // Actions
    const { setMessages } = props;

    // States
    const { welcomeMessage, allowOutHourMessage, outHourMessage, enabledRangeHours } = props;

    const handleWelcomeMessage = (e) => {
        setMessages({
            welcomeMessage: e.target.value
        });
    }

    const handleAllowOutHourMessage = () => {
        setMessages({
            allowOutHourMessage: !allowOutHourMessage
        });
    }

    const handleOutHourMessage = (e) => {
        setMessages({
            outHourMessage: e.target.value
        });
    }

    return (
        <>
            <h1 className={Styles.Title}>Mensajes de respuesta automáticos</h1>
            <p>Personalizá los mensajes automáticos que se van a mostrar en el chat de tu asesor</p>
            <section className={Styles.MessagesContainer}>
                <h2>Mensaje de bienvenida</h2>
                <textarea
                    id="message1"
                    rows="3"
                    placeholder='Mensaje'
                    className={Styles.InputMessage}
                    onChange={handleWelcomeMessage}
                    value={welcomeMessage}
                />
            </section>
            {enabledRangeHours && <BannerHours type={ '' } />}
            {enabledRangeHours &&
                <section style={{ marginBottom: '4rem' }}>
                    {/* <BannerHours type={'message'} /> */}
                    <div
                        className={Styles.MessageComponent}
                        style={{
                            border: allowOutHourMessage ? '1px solid #2ED588' : '1px solid #BFBBBD',
                            height: allowOutHourMessage ? 'auto' : '65px',
                        }}
                    >
                        <div className={Styles.TitleSection}>
                            <h2>Mensajes fuera del horario</h2>
                            <GreenSwitch
                                onClick={() => handleAllowOutHourMessage()}
                                checked={allowOutHourMessage}
                            />
                        </div>
                        <div
                            className={Styles.TextAreaContainer}
                            style={{
                                display: allowOutHourMessage ? 'block' : 'none'
                            }}
                        >
                            <textarea
                                id="message2"
                                rows="3"
                                placeholder='Mensaje fuera del horario de atención'
                                className={Styles.InputMessage2}
                                onChange={handleOutHourMessage}
                                value={outHourMessage}
                            />
                        </div>
                    </div>
                </section>}
        </>
    )
}


const mapStateToProps = state => {
    return {
        allowOutHourMessage: state.stepsUser.allowOutHourMessage,
        enabledRangeHours: state.stepsUser.enabledRangeHours,
        welcomeMessage: state.stepsUser.welcomeMessage,
        outHourMessage: state.stepsUser.outHourMessage,
        userEditing: state.stepsUser.userEditing,
        userCreate: state.stepsUser.userCreate,
    }
}

const mapDispatchToProps = dispatch => {
    return {
        setMessages: (value) => dispatch(setMessages(value)),
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(Step3);
