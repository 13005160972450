import React, { useEffect, useState } from 'react';
import { connect } from "react-redux";
import useFetch from 'use-http';

// // import { useTranslation } from 'react-i18next';
// // import i18next from "i18next";

// Actions
import {
    setCompleteStepChat,
    setAdvancedOptions,
    setWelcomeTextChat,
    setActiveStepChat,
    setEditingChat,
    setColorsChat,
    setTitleChat,
    getTemplates,
    getQuestions,
} from '../../actions/stepsChat';

// Components
import Task23CongratsModal from '../../components/Modals/CongratsModals/Task23CongratsModal';
import ButtonsContainerChat from '../../components/Stepper/ButtonsContainerChat';
import DrawerChat from '../../components/Drawers/DrawerChat/DrawerChat';
import ChatWindow from '../../components/Chat/ChatWindow/ChatWindow';
import Step1Chat from '../../components/Chat/Step1/Step1Chat';
import Step2Chat from '../../components/Chat/Step2/Step2Chat';
import Step3Chat from '../../components/Chat/Step3/Step3Chat';
import NewStepper from '../../components/Stepper/NewStepper';

// Configurations
import { MAIN_API_URL } from '../../configuration';

// Icon
import RemoveRedEyeOutlinedIcon from '@mui/icons-material/RemoveRedEyeOutlined';

// Layout
import LayoutPages from '../../layout/LayoutPages';
import LayoutChat from '../../layout/LayoutChat';

// Material Ui
import { Box, Button, useMediaQuery } from '@mui/material';

// CSS
import Styles from './ChatScreen.module.css';
import { onSetOnBoardingChatComplete } from '../../actions/tasks';

const FormContainer = {
    marginBottom: '0rem',
    position: 'relative',
    textAlign: 'left',
}

const ButtonShowChat = {
    animation: 'pulseChatAnimation 2s 1',
    transform: 'rotate(-90deg)',
    backgroundColor: '#2ED588',
    justifyContent: 'center',
    textTransform: 'none',
    animationDelay: '2s',
    alignItems: 'center',
    borderRadius: '8px',
    textAlign: 'center',
    position: 'fixed',
    fontSize: '1rem',
    right: '-3.5rem',
    display: 'flex',
    width: '150px',
    height: '40px',
    top: '165px',
    '@keyframes pulseChatAnimation': {
        '0%': {
            width: '150px',
            height: '40px',
            fontSize: '1rem',
        },
        '25%': {
            width: '150px',
            height: '40px',
            fontSize: '1rem',
        },
        '50%': {
            transform: 'rotate(-90deg)',
            fontSize: '1.05rem',
            width: '155px',
            height: '45px',
        },
        '75%': {
            width: '150px',
            height: '40px',
            fontSize: '1rem',
        }
    },
    ':hover': {
        backgroundColor: '#2ED588',
    },
    '@media(min-width: 1300px)': {
        top: '120px',
    }
}

const ChatScreen = (props) => {

    // Actions
    const {
        activeStepChatRedux,
        onSetOnBoardingChatComplete,
        completeStepChat,
        advancedOptions,
        getTemplates,
        getQuestions,
        setEditingChat,
        setColorsChat,
        setTitleChat,
    } = props;

    // States
    const { activeStepChat, showModalComplete, activeTask } = props;

    // const { t } = useTranslation();

    const [onBoardingComplete, setOnBoardingComplete] = useState(false);
    // const [showSaveChanges, setShowSaveChanges] = useState(false);
    const [showChat, setShowChat] = useState(false);
    const [chatArray, setChatArray] = useState([]);

    const matchesWidth = useMediaQuery('(min-width:1200px)');

    const NewStepperContainer = {
        width: (matchesWidth) ? '630px' : '100%',
        position: matchesWidth ? 'block' : 'sticky',
        backgroundColor: '#F9F8FF',
        paddingTop: '1rem',
        margin: '0 auto',
        top: '64px',
        zIndex: '2',
        '@media(min-width: 1300px)': {
            position: 'relative',
            top: '0px',
        }
    }

    const { error, response, get } = useFetch(`${MAIN_API_URL}/chat`, {
        headers: {
            "x-token": `${localStorage.getItem("token")}`
        }
    });

    const { error: errorS, get: getS, response: responseS } = useFetch(`${MAIN_API_URL}`, {
        headers: {
            "x-token": `${localStorage.getItem("token")}`
        }
    });

    useEffect(() => { getStore(); }, []);

    useEffect(() => { getChat(); }, []);

    useEffect(() => {
        getQuestions();
        getTemplates();
        setEditingChat(false);

        completeStepChat([false, false, false, false]);
        activeStepChatRedux(0);
    }, []);

    const getChat = async () => {
        const resp = await get("/");
        if (response.ok) {
            setChatArray(resp);
        } else {
            console.log(error);
        }
    }

    const getStore = async () => {
        const resp = await getS("/stores");
        if (responseS.ok) {
            setOnBoardingComplete(resp.onBoardingFinished);
            onSetOnBoardingChatComplete(resp.onBoardingChat);
        } else {
            console.log(errorS);
        }
    }

    useEffect(() => {
        if (chatArray.length === undefined) {

            setTitleChat(chatArray.title);

            setColorsChat({
                backImgChat: chatArray.chatColour,
                backColor: chatArray.headerColour,
                urlImg: chatArray.backgroundImg,
                colorFont: chatArray.fontColour,
            });

            advancedOptions({
                backImgChat: chatArray.feedbackMessage.state,
                urlImg: chatArray
            });
        }
    }, [chatArray]);

    const renderComponentsStepper = () => {
        switch (activeStepChat) {
            case 0:
                return (
                    <Step1Chat />
                );
            case 1:
                return (
                    <Step3Chat />
                );
            case 2:
                return (
                    <Step2Chat />
                );
            default:
                break;
        }
    }

    const handleShowChat = (open) => (event) => {
        if (event.type === 'keydown' && (event.key === 'Tab' || event.key === 'Shift')) {
            return;
        }

        setShowChat(open);
    };

    return (
        <LayoutPages>
            <Box sx={FormContainer}>
                <LayoutChat>
                    <Box sx={NewStepperContainer} >
                        <NewStepper steps={['TEXTOS', 'PREGUNTAS', 'COLORES']} screen={'Chat'} />
                        <ButtonsContainerChat welcomeTextBack={chatArray?.description} />
                    </Box>
                    <section className={Styles.ComponentsContainer}>
                        {
                            renderComponentsStepper()
                        }
                    </section>
                    {
                        (activeStepChat === 3) ? null : <ChatWindow />
                    }
                    <DrawerChat open={showChat} toggleDrawer={handleShowChat} />
                </LayoutChat>
                <Button
                    onClick={handleShowChat(true)}
                    sx={ButtonShowChat}
                    variant='contained'
                ><RemoveRedEyeOutlinedIcon />&nbsp;Vista previa</Button>
                {onBoardingComplete ? null : <Task23CongratsModal open={showModalComplete} task={activeTask} />}
            </Box>
        </LayoutPages>
    )
}

const mapStateToProps = state => {
    return {
        showModalComplete: state.stepsChat.showModalComplete,
        leaveSaveChanges: state.stepsChat.leaveSaveChanges,
        activeStepChat: state.stepsChat.activeStepChat,
        titleChat: state.stepsChat.titleChat,
        backColor: state.stepsChat.backColor,

        activeTask: state.tasks.activeTask,
    }
}

const mapDispatchToProps = dispatch => {
    return {
        setWelcomeTextChat: (value) => dispatch(setWelcomeTextChat(value)),
        activeStepChatRedux: (value) => dispatch(setActiveStepChat(value)),
        onSetOnBoardingChatComplete: (value) => dispatch(onSetOnBoardingChatComplete(value)),
        completeStepChat: (value) => dispatch(setCompleteStepChat(value)),
        advancedOptions: (value) => dispatch(setAdvancedOptions(value)),
        getTemplates: () => dispatch(getTemplates()),
        getQuestions: () => dispatch(getQuestions()),
        setEditingChat: (data) => dispatch(setEditingChat(data)),
        setColorsChat: (value) => dispatch(setColorsChat(value)),
        setTitleChat: (value) => dispatch(setTitleChat(value)),
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(ChatScreen);

