import React from 'react';

// Material Ui
import { IconButton, Snackbar } from '@mui/material';

// Icon
import CloseIcon from '@mui/icons-material/Close';
import { connect } from 'react-redux';

const BannerChat = ( props ) => {

    // External props
    const { handleClose } = props;

    // States
    const { showModalComplete } = props;    

    const snackbarStyles = {
        backgroundColor: '#2ED588',
        borderRadius: '8px',
        color: 'white',
        '.MuiPaper-root': {
            backgroundColor: '#2ED588',
            fontFamily: 'Poppins',
            borderRadius: '8px',
            fontWeight: '500'
        }
    }

    const action = (
        <React.Fragment>
            <IconButton
                size="small"
                aria-label="close"
                color="inherit"
                onClick={ handleClose }
                sx={{ backgroundColor: '#14C774' }}
            >
                <CloseIcon fontSize="small" />
            </IconButton>
        </React.Fragment>
    );

    return (
        <Snackbar
            open={ showModalComplete }
            autoHideDuration={ 3000 }
            anchorOrigin={{ 
                vertical: "bottom", 
                horizontal:"right" 
            }}
            onClose={ handleClose }
            message={ "Tu chat ha sido configurado con éxito" }
            action={ action }
            sx={ snackbarStyles }
        />
    )
}

const mapStateToProps = state => {
    return {
        showModalComplete: state.stepsChat.showModalComplete,
    }
}

export default connect(mapStateToProps, null)(BannerChat);
