import { useState, useEffect } from 'react';

const cursor = {
    both: 'nwse-resize',
    vertical: 'ns-resize',
    horizontal: 'ew-resize'
}

export const useResize = (ref, options) => {
    ref = ref || {};

    const { step = 1, axis = 'both' } = options || {};

    const [size, setSize] = useState({ width: Infinity, height: Infinity });
    const [dims, setDims] = useState({ width: Infinity, height: Infinity });
    const [coords, setCoords] = useState({ x: Infinity, y: Infinity });

    const initResize = (event) => {
        // console.log('foo');
        if (!ref.current.children[1]) return;

        setCoords({ x: event.clientX, y: event.clientY });

        const { width, height } = window.getComputedStyle(ref.current.children[1]);

        setDims({ width: parseInt(width, 10), height: parseInt(height, 10) });
    }

    useEffect(() => {
        // Round the size based to `props.step`.
        const getValue = (input) => Math.ceil(input / step) * step;

        const doDrag = (event) => {
            if (!ref.current) return;

            // Calculate the box size.
            const height = getValue(dims.height + event.clientY - coords.y);
            const width = getValue(dims.width - event.clientX + coords.x);

            // Set the box size.
            if (axis === 'both') {
                ref.current.children[1].style.height = height + 'px';
                ref.current.children[1].style.width = width + 'px';
            }

            if (axis === 'horizontal' && width < 800 && width > 50) ref.current.children[1].style.width = width + 'px';
            if (axis === 'vertical') ref.current.children[1].style.height = height + 'px';

            setSize({ width, height });
        };

        const stopDrag = () => {
            document.removeEventListener('mousemove', doDrag, false);
            document.removeEventListener('mouseup', stopDrag, false);
        };

        document.addEventListener('mousemove', doDrag, false);
        document.addEventListener('mouseup', stopDrag, false);
    }, [dims, coords, step, ref, axis]);

    return { initResize, size, cursor: cursor[axis] }
}