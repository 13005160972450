import React from 'react';

// Icon
import CloseIcon from '@mui/icons-material/Close';

// Images
import BackIntegrationsModal from '../../../assets/images/Integrations/BackIntegrationsModal.png'; 

// Material Ui
import { Box, Fade, Modal, Typography } from '@mui/material';

const style = {
    backgroundImage: `url(${ BackIntegrationsModal })`,
    transform: 'translate(-50%, -50%)',
    border: '1px solid #BFBBBD',
    position: 'absolute',
    borderRadius: '10px',
    textAlign: 'center',
    bgcolor: 'white',
    width: '95%',
    left: '50%',
    top: '50%',
    "@media (min-width: 500px)": {
        width: '500px'
    },
    "@media (min-width: 900px)": {
        width: '550px'
    },
    ':focus-visible': {
        outline: 'none'
    }
};

const CrossContainer = {
    p: '.5rem .5rem 0 .5rem',
    justifyContent: 'right',
    boxSizing: 'border-box',
    display: 'flex',
    width: '100%',
}

const CrossStyles = {
    cursor: 'pointer',
    color: '#393939', 
    height: '1.3rem',
}

const GeneralContainer = {
    p: '.5rem 1.5rem 1rem 1.5rem',
}

const TitleTutorial = {
    margin: '.5rem auto 1.5rem auto',
    fontSize: '1rem',
    fontWeight: '500',
    "@media (min-width: 500px)": {
        fontSize: '1.2rem',
    },
}

const SubtitleTutorial = {
    margin: '1rem auto', 
    fontSize: '.8rem',
    fontWeight: '400',
    width: '100%',
    "@media (min-width: 500px)": {
        width: '80%',
    },
}

const VideoContainer = {
    margin: '1.5rem auto',
    height: '250px',
    width: '90%',
}

const Video = {
    borderRadius: '8px',
    height: '100%',
    width: '100%',
}

const IntegrationsModal = ({ open, handleClose }) => {
    return (
        <Modal
            open={ open }
            aria-labelledby="modal-modal-title"
            aria-describedby="modal-modal-description"
        >
            <Fade in={ open }>
                <Box sx={ style }>
                    <Box sx={ CrossContainer }>
                        <CloseIcon 
                            onClick={ () => handleClose('Tutorial') }
                            sx={ CrossStyles } 
                        />
                    </Box>
                    <Box sx={ GeneralContainer }>
                        <Box>
                            <Typography sx={ TitleTutorial }>¿Cómo integrar WhatsPRO manualmente?</Typography>
                            <Typography sx={ SubtitleTutorial }>Mirá el video y aprendé como conectar WhatsPRO a tu página web de forma manual</Typography>
                            <Box sx={ VideoContainer }>
                                <iframe 
                                    src="https://www.youtube.com/embed/v3mI_fZ6Fao" 
                                    title="YouTube video player" 
                                    allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" 
                                    allowFullScreen
                                    style={ Video }
                                ></iframe>
                            </Box>
                        </Box>
                    </Box>
                </Box>
            </Fade>
        </Modal>
    )
}

export default IntegrationsModal;