import React, { useEffect, useState } from "react";
import { connect } from "react-redux";
import { useFetch } from "use-http";

// import { useTranslation } from 'react-i18next';
// import i18next from "i18next";

// Actions
import { getEmployeeImgs, setActiveStep, setCompleteStep } from "../../actions/stepsUser";

// Components
import AlertTaskTutorialModal from "../../components/Modals/AlertTaskTutorialModal/AlertTaskTutorialModal";
import Task23CongratsModal from "../../components/Modals/CongratsModals/Task23CongratsModal";
import DrawerCreateUser from "../../components/Drawers/DrawerCreateUser/DrawerCreateUser";
import ButtonsContainerStepper from "../../components/Stepper/ButtonsContainerStepper";
import Advanced from "../../components/CreateUser/Advanced/Advanced";
import NewStepper from "../../components/Stepper/NewStepper";
import Step1 from "../../components/CreateUser/Step1/Step1";
import Step2 from "../../components/CreateUser/Step2/Step2";
import Step3 from "../../components/CreateUser/Step3/Step3";

// import FooterCreate from "../../components/CreateUser/FooterCreate/FooterCreate";
// import Stepper from "../../components/Stepper/Stepper";

// Configurations
import { MAIN_API_URL } from '../../configuration';

// Helpers
import { useNavigatingAway } from "../../helpers/useNavigatingAway";

// Icons
import HelpOutlineOutlinedIcon from '@mui/icons-material/HelpOutlineOutlined';

// Layout
import LayoutStepsCreateUser from "../../layout/LayoutStepsCreateUser";
import LayoutCreateUser from "../../layout/LayoutCreateUser";
import LayoutPages from "../../layout/LayoutPages";

// Material Ui
import { Box, Button, useMediaQuery } from "@mui/material";

// CSS
import Styles from "./userCreate.module.css";

const FormContainer = {
    marginBottom: '0rem',
    position: 'relative',
    textAlign: 'left',
}

const ButtonTextsStyles = {
    animation: 'pulseAnimation 2s 1',
    transform: 'rotate(-90deg)',
    backgroundColor: '#2ED588',
    textTransform: 'none',
    animationDelay: '2s',
    borderRadius: '8px',
    position: 'fixed',
    fontSize: '1rem',
    right: '-2.5rem',
    display: 'flex',
    width: '110px',
    height: '40px',
    top: '140px',
    '@keyframes pulseAnimation': {
        '0%': {
            fontSize: '1rem',
            width: '110px',
            height: '40px',
        },
        '25%': {
            fontSize: '1rem',
            width: '110px',
            height: '40px',
        },
        '50%': {
            fontSize: '1.05rem',
            width: '115px',
            height: '45px',
        },
        '75%': {
            fontSize: '1rem',
            width: '110px',
            height: '40px',
        }
    },
    ':hover': {
        backgroundColor: '#2ED588',
    },
    '@media(min-width: 1300px)': {
        top: '100px',
    }
}

const UserCreate = (props) => {

    // Actions
    const { activeStepRedux, completeStep, getEmployeeImgs } = props;

    // States
    const { activeStep, userEditing, showUserModalComplete, activeTask, leaveSaveChanges } = props;

    const [showSaveChanges, setShowSaveChanges] = useState(false);
    const [showTexts, setShowTexts] = useState(false);

    // const { t } = useTranslation();

    const matchesWidth = useMediaQuery('(min-width:1300px)');

    const NewStepperContainer = { 
        position: matchesWidth ? 'block' : 'sticky',
        width: ( matchesWidth ) ? '630px' : '100%',
        backgroundColor: '#F9F8FF',
        paddingTop: '1rem',
        margin: '0 auto', 
        top: '64px',
        zIndex: '2',
        '@media(min-width: 1000px)': {
            zIndex: '0',
        },
        '@media(min-width: 1300px)': {
            position: 'relative',
            top: '0px',
        }
    }

    const [onBoardingComplete, setOnBoardingComplete] = useState(false);

    const { error, get, response } = useFetch(`${MAIN_API_URL}`, {
        headers: {
            "x-token": `${localStorage.getItem("token")}`
        }
    });

    useEffect(() => { getStore(); }, []);

    useEffect(() => {
        completeStep([false, false, false, false]);
        activeStepRedux(0);
    }, []);

    useEffect(() => {
        if ( userEditing ) {
            return;
        } else {
            getEmployeeImgs();
        }
    }, [ userEditing ]);

    const getStore = async () => {
        const resp = await get("/stores");
        if( response.ok ) {
            setOnBoardingComplete(resp.onBoardingFinished);
        } else {
            console.log(error);
        }
    }

    // useEffect(() => {
    //     if (!onBoardingComplete && !leaveSaveChanges) {
    //         setShowSaveChanges(true);
    //     } else {
    //         setShowSaveChanges(false);
    //     }
    // }, [onBoardingComplete, leaveSaveChanges]);

    const [showDialogLeavingPage, confirmNavigation, cancelNavigation] = useNavigatingAway(showSaveChanges);

    const renderComponentsStepper = () => {
        switch (activeStep) {
            case 0:
                return(
                    <Step1/>
                );
            case 1:
                return(
                    <Step2/>
                );
            case 2:
                return(
                    <Step3/>
                );
            case 3:
                return(
                    <Advanced/>
                );
            default:
                break;
        }
    }

    const handleShowHelpTexts = ( open ) => (event) => {
        if (event.type === 'keydown' && (event.key === 'Tab' || event.key === 'Shift')) {
            return;
        }

        setShowTexts( open );
    }

    return  (
        <LayoutPages>
            <Box sx={FormContainer}>
                {/* <Stepper steps={['DATOS','HORARIOS','MENSAJES','AVANZADO']}/> */}
                <LayoutCreateUser activeStep={ activeStep }>
                    <Box sx={ NewStepperContainer } >
                        <NewStepper steps={['DATOS','HORARIOS','MENSAJES','AVANZADO']}/>
                        <ButtonsContainerStepper/>
                    </Box>
                    <section className={Styles.ComponentsContainer}>
                        <LayoutStepsCreateUser>
                            {
                                renderComponentsStepper()
                            }
                        </LayoutStepsCreateUser>
                    </section>
                    <DrawerCreateUser open={ showTexts } toggleDrawer={ handleShowHelpTexts } />
                </LayoutCreateUser>
                <Button 
                    onClick={ handleShowHelpTexts(true) }
                    sx={ ButtonTextsStyles } 
                    variant='contained' 
                ><HelpOutlineOutlinedIcon/>&nbsp;Ayuda</Button>
                { onBoardingComplete ? null : <Task23CongratsModal open={ showUserModalComplete } task={ activeTask }/>}
                {/* <FooterCreate dots={4} /> */}
                {/* { onBoardingComplete ? 
                        null 
                    : 
                        <AlertTaskTutorialModal 
                            showAlertModal={ showDialogLeavingPage }
                            confirmNavigation={confirmNavigation}
                            cancelNavigation={cancelNavigation}
                            setShowModal={ setShowSaveChanges } 
                        />
                } */}
            </Box>
        </LayoutPages>
    )
}

const mapStateToProps = state => {
    return {
        showUserModalComplete: state.stepsUser.showUserModalComplete,
        closeBannerSus: state.auth.closeBannerSus,
        userEditing: state.stepsUser.userEditing,
        activeStep: state.stepsUser.activeStep,

        leaveSaveChanges: state.stepsChat.leaveSaveChanges,

        activeTask: state.tasks.activeTask,
    }
}

const mapDispatchToProps = dispatch => {
    return {
        activeStepRedux: (value) => dispatch(setActiveStep(value)),
        completeStep: (value) => dispatch(setCompleteStep(value)),
        getEmployeeImgs: () => dispatch(getEmployeeImgs()),
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(UserCreate);