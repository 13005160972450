import React from 'react';

const ArrowBottom = ({ color }) => {
    return (
        <svg width="15" height="10" viewBox="0 0 15 10" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M13.9 1L7.44995 8.52002L1 1" stroke={ color } strokeWidth="2" strokeLinecap="round" strokeLinejoin="round"/>
        </svg>
    )
}

export default ArrowBottom;