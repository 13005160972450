import React from 'react';
import { connect } from 'react-redux';
import { useNavigate } from 'react-router-dom';

// Actions
import { resetAll } from '../../../../actions/global';

// Components
import GreeUser from '../../../../assets/images/Modal/GreeUser';

// Material Ui
import { Button } from '@mui/material';

// CSS
import Styles from './CreateUser.module.css';

const CreateUser = (props) => {

    // Actions
    const { resetAll } = props;

    const buttonStyle = {
        backgroundColor: '#2ED588',
        padding: '.3rem 1.5rem',
        fontFamily: 'Poppins',
        textTransform: 'none',
        borderRadius: '8px',
        fontWeight: '500',
        fontSize: '.7rem',
        color: 'white',
        '&:hover': {
            backgroundColor: '#2ED588'
        }
    }

    const navigate = useNavigate();

    const handleNavigate = () => {
        navigate('/createUser');
        resetAll();
    }

    return (
        <div className={Styles.CreateUserModalArrowTop}>
            <div className={Styles.CreateUserModalWrapper}>
                <div className={Styles.CreateUserComponents}>
                    <div className={ Styles.LogoSection }>
                        <GreeUser/>
                    </div>
                    <div className={ Styles.TextSection }>
                        <h2>Creá un asesor</h2>
                        <p>Hace click acá para crear el primer asesor de tu Chat y configurarlo</p>
                    </div>
                </div>
                <div className={Styles.BottomSection}>
                    <p>Paso 1 de 2</p>
                    <Button
                        sx={ buttonStyle }
                        onClick={ handleNavigate }
                    >
                        Siguiente
                    </Button>
                </div>
            </div>
        </div>
    )
}

const mapDispatchToProps = dispatch => {
    return {
        resetAll: () => dispatch(resetAll()),
    }
}

export default connect(null, mapDispatchToProps)(CreateUser);
