import React, { Suspense } from 'react';

import { connect } from 'react-redux';
import { useNavigate } from 'react-router-dom';

// Actions
import { onSetFinalTasksTutorial, setActiveTask } from '../actions/tasks';

// Images
import CongratsModalBackMobile from '../assets/images/Home/CongratsModalBackMobile.png';
import CongratsModalBack from '../assets/images/Home/CongratsModalBack.png';

// Material Ui
import { Box, Button, Fade, Modal, useMediaQuery } from '@mui/material';

const AvatarImg = {
    width: '100%'
}

const LayoutCongratsModals = (props) => {

    // Actions
    const { setActiveTask, onSetFinalTasksTutorial } = props;

    // States
    const { lastTask } = props;

    // External props
    const { children, open, handleClose, srcAvatar, width1, width2, width3, task = 1, disabledButtonNext } = props;

    const matches = useMediaQuery('(min-width: 900px)');

    const navigate = useNavigate();

    const ContainerStyles = {
        backgroundImage: `url(${CongratsModalBackMobile})`,
        transform: 'translate(-50%, -50%)',
        backgroundRepeat: 'no-repeat',
        border: '1px solid #BFBBBD',
        justifyContent: 'center',
        boxSizing: 'border-box',
        backgroundSize: 'cover',
        flexDirection: 'column',
        position: 'absolute',
        alignItems: 'center',
        textAlign: 'center',
        overflowY: 'auto',
        bgcolor: 'white',
        p: '1rem 1.5rem',
        display: 'flex',
        height: '100%',
        width: width1,
        left: '50%',
        top: '50%',
        "@media (min-width: 400px)": {
            backgroundImage: `url(${CongratsModalBack})`,
            height: 'max-content',
            overflowY: 'inherit',
            borderRadius: '10px',
            width: width2
        },
        "@media (min-width: 900px)": {
            width: width3
        },
        '&:focus-visible': {
            outline: 'none'
        }
    };

    const DataContainer = {
        position: 'absolute',
        width: '98%',
        top: (task === 2) ? '4%' : '10%',
        '@media(min-height: 300px)': {
            width: task === 4 ? '98%' : 'auto',
        },
        '@media(min-height: 500px)': {
            top: '10%',
        },
        '@media(min-width: 350px)': {
            top: '20%',
        },
        '@media(min-width: 400px)': {
            position: 'relative'
        }
    };

    const AvatarContainer = {
        margin: '0 auto',
        width: ((task === 4 && lastTask) || task === 'sub') ? '100px' : '120px',
        '@media(min-width: 400px)': {
            margin: (task === 4 || task === 'sub') ? matches ? '-5.5rem auto auto auto' : '-4.3rem auto auto auto' : '-3.5rem auto auto auto',
            width: (task === 4 || task === 'sub') ? matches ? '320px' : '240px' : '80px' , 
        }
    };

    const ButtonStyles = {
        animation: ( task === 4 ) ? 'pulseCongratsModalAnimation 2s infinite' : 'none',
        marginBottom: ( task === 'sub' || task === 2 ) ? '1rem' : 0,
        backgroundColor: disabledButtonNext ? 'gray' : '#E1C478',
        padding: '.5rem 1rem',
        fontFamily: 'Poppins',
        textTransform: 'none',
        borderRadius: '6px',
        fontWeight: '500',
        fontSize: '1rem',
        marginTop: '1rem',
        color: 'white',
        width: '90%',
        "@media (min-width: 440px)": {
            width: ( task === 2 ) ? '60%' : '80%',
            marginTop: '2rem',
            marginBottom: 0,
        },
        "@media (min-width: 900px)": {
            marginTop: '2rem',
            width: '70%',
        },
        '&:hover': {
            backgroundColor: '#E1C478'
        },
        '&:disabled': {
            color: 'white'
        },
        '@keyframes pulseCongratsModalAnimation': {
            '0%': {
                transform: 'scale(1, 1)',
                color: 'white',
            },
            '25%': {
                transform: 'scale(1, 1)',
                color: 'white',
            },
            '50%': {
                transform: 'scale(1.03, 1.03)',
                color: 'white',
            },
            '75%': {
                transform: 'scale(1, 1)',
                color: 'white',
            }
        },
    };

    const handleRedirect = () => {
        if (task !== 'sub') {
            setActiveTask('sub');
            navigate('/card');
        } else {
            onSetFinalTasksTutorial(true);
            navigate('/integrations');
        }
    }

    return (
        <Suspense>
            <Modal
                open={open}
                aria-labelledby="modal-modal-title"
                aria-describedby="modal-modal-description"
            >
                <Fade in={ open }>
                    <Box sx={ ContainerStyles }>
                        <Box sx={ DataContainer }>
                            <Box sx={ AvatarContainer } >
                                <img src={ srcAvatar } alt="avatar" style={ AvatarImg } />
                            </Box>
                            <Box>
                                {
                                    children
                                }
                            </Box>
                            <Button
                                onClick={ () => (task === 4 || task === 'sub') ? handleRedirect() : handleClose() }
                                disabled={ disabledButtonNext }
                                sx={ ButtonStyles }
                            >
                                { !lastTask ? (task === 'sub' ? 'Finalizar' : 'Continuar') : 'Comenzar período gratuito' }
                            </Button>
                        </Box>
                    </Box>
                </Fade>
            </Modal>
        </Suspense>
    )
}

const mapStateToProps = state => {
    return {
        lastTask: state.tasks.lastTask,
    }
}

const mapDispatchToProps = dispatch => {
    return {
        onSetFinalTasksTutorial: (data) => dispatch(onSetFinalTasksTutorial(data)),
        setActiveTask: (data) => dispatch(setActiveTask(data)),
    }
}

export default connect(mapStateToProps, mapDispatchToProps)( LayoutCongratsModals );