import React from 'react';

// Material Ui
import { Backdrop,  CircularProgress } from '@mui/material';

const LoaderQuestions = () => {

    const boxStyles = {
        backgroundColor: 'rgba(0, 0, 0, 0.2)',
        justifyContent: 'center',
        position: 'absolute',
        alignItems: 'center',
        borderRadius: '12px',
        display: 'flex',
        top: '1.45rem',
        width: '98.1%',
        height: '86%',
        color: '#000',
        zIndex: '1',
    }

    return (
        <Backdrop
            sx={ boxStyles }
            open={ true }
        >
            <CircularProgress />
        </Backdrop>
    )
}

export default LoaderQuestions;