import React from 'react'

const ChatIcon = () => {
    return (
        <div style={{ marginRight: '12px', display: 'flex', alignItems: 'center' }}>
            <svg width="22" height="22" viewBox="0 0 22 22" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path d="M18.3334 1.83337H3.66677C2.65843 1.83337 1.83344 2.65837 1.83344 3.66671V20.1667L5.5001 16.5H18.3334C19.3418 16.5 20.1668 15.675 20.1668 14.6667V3.66671C20.1668 2.65837 19.3418 1.83337 18.3334 1.83337ZM18.3334 14.6667H5.5001L3.66677 16.5V3.66671H18.3334V14.6667Z" fill="#2ED588"/>
            </svg>
        </div>
    )
}

export default ChatIcon