import React from 'react';
import { connect } from "react-redux";
import 'react-phone-input-2/lib/style.css';
import PhoneInput from 'react-phone-input-2';

// Actions
import { setUserData } from '../../../actions/stepsUser';

// Components
import SelectPersonalized from '../../EditableSelect/editableSelect';
import PhotoSelect from '../PhotoSelect/PhotoSelect';

// CSS
import Styles from './Step1.module.css';

const Step1 = (props) => {

    // Actions
    const { setUserData } = props;

    // States
    const { userName, phoneNumber, countryCode } = props;
    
    const PositionsOption =  [
        { value: 'Mayorista', label: 'Mayorista'},
        { value: 'Minorista', label: 'Minorista'},
        { value: 'Atención al cliente', label: 'Atención al cliente'},
        { value: 'Asesor', label: 'Asesor'},
        { value: 'Pedidos', label: 'Pedidos'},
        { value: 'Devoluciones', label: 'Devoluciones'},
        { value: 'Reclamos', label: 'Reclamos'},
        { value: 'Especialista', label: 'Especialista'},
    ];

    const handleChangeName = (e) => {
        setUserData({
            userName: e.target.value,
        });
    }
    
    const handleChangeCodePhone = ( value, data ) => {
        // console.log(data);
        setUserData({
            countryCode: data.countryCode,
            dialCode: data.dialCode,
            prefix: data.dialCode,
        })
    }

    const handleChangePhone = (e) => {
        if ( e.target.value.length <= 15 ) {
            setUserData({
                phoneNumber: e.target.value
            });
        } else {
            return;
        }
    }

    const blockInvalidChar = e => ['e', 'E', '+', '-'].includes(e.key) && e.preventDefault();

    return (
        <div
            className={Styles.Step1GeneralContainer}
        >
            <h1 className={Styles.Title}>Datos personales</h1>
            <p>Ingresá los datos personales del asesor</p>
            <section className={Styles.Step1Container}>
                <div className={Styles.InputsContainer}>
                    <p className={Styles.TitleData}>
                        ¿Cómo es su nombre?
                    </p>
                    <div>
                        <input 
                            value={ userName }
                            onChange={ handleChangeName } 
                            className={Styles.InputPlain} 
                            type="text"
                            placeholder="Nombre..."
                            style={{
                                fontFamily: 'Poppins',
                            }}
                            maxLength={30}
                        />
                        <p
                            style={{
                                textAlign: 'right',
                                fontSize: '.7rem',
                                color: '#BFBBBD',
                                width: '100%',
                                margin: 0
                            }}
                        >Max 30 caracteres</p>
                    </div>
                    <p className={Styles.TitleData}>
                        ¿Cuál es su cargo?
                    </p>
                    <SelectPersonalized 
                        options={PositionsOption} 
                        canCreate={true} 
                        createString={'Crear nuevo cargo'}
                    />
                    <br />
                    <p className={Styles.TitleData}>
                        ¿Cual es su número de contacto?
                    </p>
                    <div className={Styles.InputsPhoneContainer}>
                        <div className={Styles.SelectCodePhone}>
                            <PhoneInput
                                country={ countryCode }
                                countryCodeEditable={ false }
                                value={ countryCode }
                                onChange={ handleChangeCodePhone }
                                inputStyle={{
                                    fontFamily: 'Poppins',
                                    height: '2.45rem',
                                    width: '100%',
                                }}
                                inputProps={{
                                    disabled: true
                                }}
                                placeholder=''
                                dropdownClass={'PhoneInput'}
                            />
                        </div>
                        <input 
                            value={ phoneNumber }
                            onChange={ handleChangePhone } 
                            className={Styles.InputPlainPhone}
                            onKeyDown={blockInvalidChar} 
                            type="number"
                            placeholder='Número de teléfono...'
                            style={{
                                fontFamily: 'Poppins'
                            }}
                            maxLength="10" 
                            min="1"
                        />
                    </div>
                </div>
                <div className={Styles.PhotoContainer}>
                    <PhotoSelect/>
                </div>
            </section>
        </div>
    )
}

const mapStateToProps = state => {
    return {
        countryCode: state.stepsUser.userData.countryCode,
        phoneNumber: state.stepsUser.userData.phoneNumber,
        userName: state.stepsUser.userData.userName,
        userCreate: state.stepsUser.userCreate,
        activeStep: state.stepsUser.activeStep,
    }
}

const mapDispatchToProps = dispatch => {
    return {
        setUserData: (value) => dispatch(setUserData(value)),
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(Step1);


