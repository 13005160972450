import { MAIN_API_URL } from '../configuration';
import { typesAuth } from '../types/types';
import CryptoJS from "crypto-js";
import i18next from "i18next";

export const initLoginBack = () => ({
    type: typesAuth.initLoginBack
});

export const finishLoginBack = () => ({
    type: typesAuth.finishLoginBack
});

export const initLoginBackMercadoShops = () => ({
    type: typesAuth.initLoginBackMercadoShops
});

export const initLogin = () => ({
    type: typesAuth.initLogin
});

export const initRegister = () => ({
    type: typesAuth.initRegister
});

export const successLogin = () => ({
    type: typesAuth.successLogin
});

export const failLogin = (data) => ({
    type: typesAuth.failLogin,
    payload: data,
});

export const failRegister = (data) => ({
    type: typesAuth.failRegister,
    payload: data,
});

export const failConnectionRegister = (data) => ({
    type: typesAuth.failConnectionRegister,
    payload: data,
});

export const changeStore = (data) => ({
    type: typesAuth.changeStore,
    payload: data,
});

export const changesTokens = (data) => ({
    type: typesAuth.changeTokens,
    payload: data,
});

export const saveResData = ({ id, isRegistered, storeId, storeName }) => ({
    type: typesAuth.saveResData,
    payload: {
        id,
        isRegistered,
        storeId,
        storeName
    },
});

export const changeIsLogIn = (data) => ({
    type: typesAuth.changeIsLogIn,
    payload: data,
});

export const changeEndTutorial = (data) => ({
    type: typesAuth.setEndTutorial,
    payload: data,
});

export const changeOnBoardingComplete = (onBoardingComplete) => ({
    type: typesAuth.changeOnBoardingComplete,
    payload: onBoardingComplete,
});

export const initUserLogin = (data) => ({
    type: typesAuth.login,
    payload: data,
});

export const initUserRegister = (data) => ({
    type: typesAuth.register,
    payload: data,
});

export const endTutorial = (data) => ({
    type: typesAuth.setEndTutorial,
    payload: data,
});

export const logOutWP = (data) => ({
    type: typesAuth.setLogout,
    payload: data,
});

export const showRecoverPass = ({ showRecoverPass1, showRecoverPass2 }) => ({
    type: typesAuth.showRecoverPass,
    payload: {
        showRecoverPass1,
        showRecoverPass2
    }
});

export const showConfirmPass = ({ pass, confirmPass }) => ({
    type: typesAuth.showConfirmPass,
    payload: {
        pass,
        confirmPass,
    }
});

export const setEndTrialPeriod = (data) => ({
    type: typesAuth.changeEndTrialPeriod,
    payload: {
        data
    }
});

export const setCloseBannerSus = (data) => ({
    type: typesAuth.changeCloseBannerSus,
    payload: data
});

export const setBillingsData = (data) => ({
    type: typesAuth.billings,
    payload: data,
});

export const setLoading = (data) => ({
    type: typesAuth.loading,
    payload: data,
});

export const register = ({ name, mail, password, storeName, phone, call, country, timeZone }) => {
    return async (dispatch, getState) => {
        dispatch(initRegister());

        let idMongo = localStorage.getItem("id") ? localStorage.getItem("id") : "";

        try {

            let headers = {
                "Content-Type": "application/json",
                "Access-Control-Allow-Origin": "*"
            }

            let data = {
                name: name,
                mail: mail,
                password: password,
                storeName: storeName,
                id: `${idMongo}`,
                phone: phone,
                call: call,
                country: country,
                timeZone: timeZone
            }
            
            dispatch( setLoading( true ) );
            let response = await fetch(`${MAIN_API_URL}/auth/register`, {
                // let response = await fetch('http://localhost:8080/api/auth/register', {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json'
                },
                body: JSON.stringify(data)
            })

            dispatch( setLoading( false ) );

            let res = await response.json();

            if (response.status >= 200 && response.status < 210) {
                localStorage.setItem("isRegistered", "true");
                window.location.href = '/login';
            } else if (response.status === 400) {
                dispatch(failRegister(true));
                window.location.href = '/register'
            } else {
                window.location.href = '/register'
            }

        } catch (error) {
            console.log("Error registrando: ", error);
            dispatch(failConnectionRegister(true));
            throw new Error("Fallo login");
        }
    }
}

export const loginBack = (code, origin = 'Tiendanube') => {
    return async (dispatch, getState) => {
        dispatch(initLoginBack())

        let mongoId = localStorage.getItem("store");

        try {
            let response = await fetch(`${MAIN_API_URL}/auth/tiendanube/redirect`, {
                // let response = await fetch(`http://localhost:8080/api/auth/tiendanube/redirect`, {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json'
                },
                body: JSON.stringify({ code: code, origin: origin, mongoId: mongoId })
            })

            let res = await response.json();

            dispatch(saveResData({
                id: res.id,
                isRegistered: res.isRegistered,
                storeId: res.storeId,
                storeName: res.storeName
            }));

            localStorage.setItem("isRegistered", res.isRegistered);
            localStorage.setItem("id", res.id);
            localStorage.setItem("storeId", res.storeId);
            localStorage.setItem("storeName", res.storeName);
            localStorage.setItem("origin", origin);

        } catch (error) {
            dispatch(failLogin(error));
            throw new Error("Fallo login");
        }
    }
}

export const loginBackMercadoShops = (code) => {
    return async (dispatch, getState) => {
        dispatch(initLoginBackMercadoShops())
        let mongoId = localStorage.getItem("store");
        try {
            let response = await fetch(`${MAIN_API_URL}/auth/mercadoshops/redirect`, {
                method: "POST",
                headers: {
                    "Content-Type": "application/json",
                },
                body: JSON.stringify({ code, mongoId })
            })

            const res = await response.json();
            dispatch(saveResData({
                id: res.id,
                isRegistered: res.isRegistered,
                storeId: res.storeId,
                storeName: res.storeName
            }));

            localStorage.clear();
            sessionStorage.clear();
            localStorage.setItem("isRegistered", res.isRegistered);
            localStorage.setItem("id", res.id);
            localStorage.setItem("storeId", res.storeId);
            localStorage.setItem("storeName", res.storeName);

        } catch (error) {
            dispatch(failLogin(error));
            throw new Error("Fallo login")
        }
    }
}

export const login = ({ mail, password }) => {
    return async (dispatch, getState) => {
        dispatch(initLogin())
        let passwordHash = CryptoJS.AES.encrypt(`${password}`, 'eST03SmYpUBLICk3Y2134321').toString();
        try {
            dispatch( setLoading( true ) );
            let response = await fetch(`${MAIN_API_URL}/auth/login`, {
                // let response = await fetch(`http://localhost:8080/api/auth/login`, {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json'
                },
                body: JSON.stringify({
                    "mail": `${mail}`,
                    "password": `${passwordHash}`
                })
            });

            dispatch( setLoading( false ) );

            let res = await response.json();

            if (response.status === 200) {
                localStorage.setItem("token", `${res.token}`);
                localStorage.setItem("refreshToken", `${res.refreshToken}`);
                localStorage.setItem("store", `${res.store._id}`);
                localStorage.setItem("origin", `${res.store.origin}`);

                dispatch(changesTokens({
                    token: res.token,
                    refreshToken: res.refreshToken
                }));

                dispatch(changeStore(res.store));
                dispatch(changeEndTutorial({ endTutorial: res.store.onBoardingFinished }));
                dispatch(successLogin());
            } else {
                throw new Error(`${res.msg}`)
            }


        } catch (error) {
            dispatch(failLogin(error.message))
            console.log(error.message);
        }
    }
}

export const refreshLogin = () => {
    return async (dispatch, getState) => {
        const { auth } = getState();
        const { refreshToken } = auth;
        dispatch(initLogin())
        try {
            let response = await fetch(`${MAIN_API_URL}/auth/refreshToken`, {
                method: 'GET',
                headers: {
                    'x-token': `${refreshToken}`
                }
            })

            if (response.status === 200) {
                let res = await response.json();
                dispatch(changesTokens({
                    token: res.token,
                    refreshToken: res.refreshToken
                }))
                dispatch(successLogin());
                return;
            }
            throw new Error("Token no valido");

        } catch (error) {
            dispatch(failLogin(error))
            console.log("Error autenticando: ", error);
        }
    }
}

export const checkIsLogIn = () => {
    return async (dispatch, getState) => {
        const token = localStorage.getItem('token');
        const refreshToken = localStorage.getItem('refreshToken');
        dispatch(changesTokens({ token: token, refreshToken: refreshToken }))
        return !(token === null || token === undefined)
    }
}

export const checkValidToken = () => {
    return async (dispatch, getState) => {
        const { auth } = getState();
        const { token } = auth;
        try {
            let response = await fetch(`${MAIN_API_URL}/auth`, {
                method: 'GET',
                headers: {
                    'x-token': `${token}`
                }
            })

            if (response.status === 200) {
                return true;
            }

            throw new Error('Expired token');
        } catch (error) {
            console.log("Error autenticando: ", error);
        }
        return false;
    }
}

export const getStore = () => {
    return async (dispatch, getState) => {
        const { auth } = getState();
        const { token } = auth;
        try {
            let response = await fetch(`${MAIN_API_URL}/stores`, {
                method: 'GET',
                headers: {
                    'x-token': `${token}`
                }
            })
            if (response.status === 200) {
                let store = await response.json();
                localStorage.setItem('origin', store.origin);
                dispatch(changeStore(store))
                await i18next.changeLanguage(store.country.toLowerCase() || 'ar');
                return store;
            }

            throw new Error('Error obteniendo tienda');
        } catch (error) {
            console.log("Error obteniendo tienda: ", error);
        }
        return null;
    }
}

export const getBillingData = () => {
    return async (dispatch, getState) => {
        const { auth } = getState();
        const { token } = auth;
        try {
            let response = await fetch(`${MAIN_API_URL}/stores`, {
                method: 'GET',
                headers: {
                    'x-token': `${token}`
                }
            });

            if (response.status === 200) {
                let store = await response.json();
                dispatch(setBillingsData({
                    documentNro: store.billingData.document_nro,
                    direccion: store.billingData.direccion,
                    email: store.billingData.email
                }));

                return;
            }

            throw new Error('Error obteniendo tienda');
        } catch (error) {
            console.log("Error obteniendo tienda: ", error);
        }
        return null;
    }
}

export const updateTutorialState = () => {
    return async (dispatch, getState) => {
        const { auth } = getState();
        const { token } = auth;
        try {
            let response = await fetch(`${MAIN_API_URL}/stores`, {
                method: 'GET',
                headers: {
                    'x-token': `${token}`
                }
            })
            if (response.status === 200) {
                let store = await response.json();
                dispatch( changeEndTutorial({ endTutorial: store.onBoardingFinished }));
                return;
            }

            throw new Error('Error obteniendo tienda');
        } catch (error) {
            console.log("Error obteniendo tienda: ", error);
        }
        return null;
    }
}

export const getStoreData = () => {
    return async (dispatch, getState) => {
        const { auth } = getState();
        const { token } = auth;
        try {
            let response = await fetch(`${MAIN_API_URL}/stores`, {
                method: 'GET',
                headers: {
                    'x-token': `${token}`
                }
            })
            
            if (response.status === 200) {
                let store = await response.json();

                return store;
            }

            throw new Error('Error obteniendo tienda');
        } catch (error) {
            console.log("Error obteniendo tienda: ", error);
        }
    }
}