import React from 'react';
import { connect } from 'react-redux';

// Actions
import { setAdvancedOptions, setEditAdvancedOptions } from '../../../actions/stepsChat';

// CSS
import Styles from './AlignIconComponent.module.css';

const AlignIconComponent = (props) => {

    // Actions
    const { advancedOptions, setEditAdvancedOptions } = props;

    // States
    const { alignButton } = props;

    const handleSelectOption = (option) => {
        setEditAdvancedOptions({
            editAdvancedOptions: true
        });
        advancedOptions({
            alignButton: option
        });
    }

    return (
        <div className={Styles.GroupButton}>
            <div 
                className={Styles.ButtonItem}
                onClick={() => handleSelectOption('left')}
                style={{ 
                    backgroundColor: alignButton === 'left' ? '#E3FFF2' : 'transparent' 
                }}
            >
                <p
                    style={{ 
                        color: alignButton === 'left' ? '#2ED588' : 'black',
                        fontWeight: alignButton === 'left' ? '500' : '400' 
                    }}
                >Alineado a la izquierda</p>
            </div>
            <div 
                className={Styles.ButtonItem}
                onClick={() => handleSelectOption('right')}
                style={{ 
                    backgroundColor: alignButton === 'right' ? '#E3FFF2' : 'transparent' 
                }}
            > 
                <p
                    style={{ 
                        color: alignButton === 'right' ? '#2ED588' : 'black',
                        fontWeight: alignButton === 'right' ? '500' : '400' 
                    }}
                >Alineado a la derecha</p>
            </div>
        </div>
    )
}

const mapStateToProps = state => {
    return {
        alignButton: state.stepsChat.advancedOptions.alignButton,
    }
}

const mapDispatchToProps = dispatch => {
    return {
        setEditAdvancedOptions: (value) => dispatch(setEditAdvancedOptions(value)),
        advancedOptions: (value) => dispatch(setAdvancedOptions(value)),
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(AlignIconComponent);