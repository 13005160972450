import i18n from 'i18next';
import { initReactI18next } from 'react-i18next';

import arJSON from './translations/ar';
import brJSON from './translations/br';
import mxJSON from './translations/mx';



const resources = {
    ar: { translation: arJSON },
    br: { translation: brJSON },
    mx: { translation: mxJSON },
};

i18n
    .use(initReactI18next)
    .init({
        resources,
        keySeparator: false,
        lng: 'ar',
        fallbackLng: 'ar',
        react: {
            useSuspense: true
        },
        interpolation: {
            escapeValue: false
        }
    });

export default i18n;