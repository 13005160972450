import React from 'react';

// Loader
import LoaderFlowyGif from '../../../assets/logos/whatspro-not-background.gif';

//CSS
import Styles from './LoaderFlowy.module.css';

const LoaderFlowy = () => {
    return (
        <section className={Styles.LoaderFlowyContainer}>
            <img src={LoaderFlowyGif} alt="Loader"/>
        </section>
    )
}

export default LoaderFlowy;