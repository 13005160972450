import React, { Suspense, useMemo } from 'react';

// Icons
import PlayArrowOutlinedIcon from '@mui/icons-material/PlayArrowOutlined';
import CheckIcon from '@mui/icons-material/Check';
import AddIcon from '@mui/icons-material/Add';

// Images
import MercadoshopsIcon from '../../../assets/images/Integrations/MercadoshopsIcon.png';
import HechoAMedidaIcon from '../../../assets/images/Integrations/HechoAMedidaIcon.png';
import WooCommerceIcon from '../../../assets/images/Integrations/WooCommerceIcon.png';
import TiendanubeIcon from '../../../assets/images/Integrations/TiendanubeIcon.png';

// Material Ui
import { Box, Button, Card, CardActions, CardContent, Typography } from '@mui/material';

const CardContainer = {
    border: '1px solid #BFBBBD',
    backgroundColor: 'white',
    borderRadius: '10px',
    boxShadow: 'none',
    maxWidth: '350px',
}

const Title = {
    fontSize: '1.2rem',
    fontWeight: '600',
}

const Description = {
    fontSize: '.8rem',
    '@media(min-width: 650px)': {
        fontSize: '.9rem',
    }
}

const ButtonsContainer = {
    justifyContent: 'space-between',
    padding: '0 .5rem .3rem .5rem',
    alignItems: 'center',
    display: 'block',
    width: '100%',
    '@media(min-width: 345px)': {
        display: 'flex',
    }
}

const TutorialButtonStyles = {
    padding: '.2rem 0',
    color: '#393939',
    height: '1.2rem',
    width: '1.2rem',
    '@media(min-width: 650px)': {
        width: '1.5rem'
    }
}

const CardIntegration = ({ title, origin, handleShowModal, handleShowVideo }) => {

    const TitleContainer = {
        color: title !== 'Woo Commerce' ? '#393939' : '#BFBBBD',
        marginBottom: '1rem',
        alignItems: 'center',
        display: 'flex', 
        gap: '10px',
    }

    const ButtonMUIStyles = {
        padding: (title !== 'Woo Commerce' && title !== 'Hecho a medida') ? '.2rem .5rem .2rem .2rem' : '.2rem .5rem',
        color: (origin === title) ? 'white' : (title !== 'Woo Commerce') ? '#393939' : '#BFBBBD',
        pointerEvents: (origin === title || title === 'Woo Commerce') ? 'none' : 'auto',
        border: (origin === title) ? '1px solid #2ED588' : '1px solid #BFBBBD',
        backgroundColor: (origin === title) ? '#2ED588' : 'white',
        fontFamily: 'Poppins',
        textTransform: 'none',
        borderRadius: '6px',
        marginTop: '.5rem',
        fontSize: '.8rem',
        fontWeight: '600',
        display: 'flex',
        gap: '5px',
        ':hover': {
            backgroundColor: (origin === title) ? '#2ED588' : 'white',
        },
        '@media(min-width: 450px)': {
            marginTop: 0,
        }
    }

    const ButtonTutorialStyles = {
        padding: '.2rem .5rem .2rem .2rem',
        border: '1px solid #BFBBBD',
        backgroundColor: 'white',
        fontFamily: 'Poppins',
        textTransform: 'none',
        borderRadius: '6px',
        marginTop: '.5rem',
        fontSize: '.6rem',
        fontWeight: '600',
        color: '#393939',
        display: 'flex',
        gap: '5px',
        ':hover': {
            backgroundColor: 'white',
        },
        '@media(min-width: 450px)': {
            marginTop: 0,
        },
        '@media(min-width: 1100px)': {
            fontSize: '.8rem',
        }
    }

    const handleShowIcons = () => {
        switch (title) {
            case 'Tiendanube':
                return TiendanubeIcon;
            case 'Mercado Shops':
                return MercadoshopsIcon;
            case 'Woo Commerce':
                return WooCommerceIcon;
            case 'Hecho a medida':
                return HechoAMedidaIcon;
            default:
                break;
        }
    }

    const originInFormat = useMemo(() => origin?.toLowerCase(), [origin]);

    return (
        <Suspense>
            <Card sx={ CardContainer }>
                <CardContent sx={{ paddingBottom: '0' }}>
                    <Box sx={ TitleContainer }>
                        <img width={ '45px' } src={ handleShowIcons() } alt="Icon" />
                        <Typography sx={ Title }>{ title }</Typography>
                    </Box>
                    {
                        title !== 'Hecho a medida' ?
                            <Typography sx={ Description } gutterBottom>
                                Conectá whatsPRO con tu E-commerce de { title } en segundos con nuestra integración automática.
                            </Typography>
                        :
                            <Typography sx={ Description } gutterBottom>
                                Conectá tu Pagina Web en pocos segundos siguiendo nuestro tutorial y luego apretando en + Conectar.
                            </Typography>
                    }
                </CardContent>
                <CardActions>
                    <Box sx={ ButtonsContainer }>
                        <Button 
                            sx={ ButtonMUIStyles } 
                            onClick={ () => handleShowModal(origin) }
                        >
                            {
                                (originInFormat === title.toLowerCase()) ? 
                                    <CheckIcon sx={{ color: 'white', fontSize: '1.2rem' }}/> 
                                :
                                    ( title !== 'Woo Commerce' && title !== 'Hecho a medida' ) ?
                                        <AddIcon sx={{ color: '#393939', fontSize: '1.2rem' }}/>
                                    :
                                        null
                            } 
                            {
                                (origin === title) ? 'Conectado' : (title !== 'Woo Commerce') ? 'Conectar' : 'Próximanente'
                            }
                        </Button>
                        {
                            title === 'Hecho a medida' && 
                                <Button sx={ ButtonTutorialStyles } onClick={handleShowVideo }>
                                    <PlayArrowOutlinedIcon sx={ TutorialButtonStyles }/> Ver Tutorial
                                </Button>
                        }
                    </Box>
                </CardActions>
            </Card>
        </Suspense>
    )
}

export default CardIntegration;