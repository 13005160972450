import React from 'react';


// Components
import ChatIcon from '../../assets/icons/WelcomeQuestions/ChatIcon';

// Images
import Step3MobileImg from '../../assets/images/WelcomeQuestions/Step3MobileImg.png'; 
import WhatsProLogo from '../../assets/images/WelcomeQuestions/WhatsProLogo.png'; 
import Step3Img from '../../assets/images/WelcomeQuestions/Step3Img.png'; 


// Material Ui
import { Box, Button, Typography, useMediaQuery } from '@mui/material';
import { useNavigate } from 'react-router-dom';

const GeneralContainer = {
    height: 'max-content',
    paddingTop: '40px',
    width: '100%',
    '@media(min-width: 550px)': {
        paddingTop: 0,
    },
}

const IconContainer = {
    justifyContent: 'center',
    margin: '2rem 0 1rem 0',
    display: 'flex',
    width: '100%',
}

const FirstTitle = {
    fontSize: '.9rem',
    margin: 'auto',
    width: '100%',
    '@media(min-width: 350px)': {
        fontSize: '1.1rem',
    },
    '@media(min-width: 400px)': {
        width: '350px',
    }
}

const ImageContainer = {
    margin: '2rem auto auto auto',
    justifyContent: 'center',
    display: 'flex',
    width: '75%',
}

const TextContainer = {
    textAlign: 'center',
    marginTop: '2rem',
    bottom: '35%',
}

const Title = {
    fontSize: '1.5rem',
    fontWeight: '500',
    color: '#393939',
}

const FreeDays = {
    margin: '1rem auto auto auto',
    border: '2px solid #2ED588',
    backgroundColor: 'white',
    padding: '.5rem',
    borderRadius: '12px',
    width: 'max-content',
    fontSize: '1.2rem',
    fontWeight: '500',
    color: '#2ED588',
}

const ButtonStyles = {
    backgroundColor: '#2ED588',
    textTransform: 'none',
    fontFamily: 'Poppins',
    alignItems: 'center',
    borderRadius: '6px',
    fontSize: '1rem',
    color: 'white',
    display: 'flex',
    padding: '8px',
    margin: 'auto',
    bottom: '8%',
    width: '90%',
    gap: '5px',
    border: '1px solid #2ED588',
    ':hover': {
        backgroundColor: 'white',
        color: '#2ED588',
        border: '1px solid #2ED588',
    },
    '@media(min-width: 400px)': {
        position: 'relative',
        width: '60%',
    },
    '@media(min-height: 400px)': {
        margin: '4.5rem auto auto auto',
    }
}

const Step3WelcomeQuestion = () => {

    const matches = useMediaQuery('(min-width: 900px)');
    const navigate = useNavigate();

    const handleChange = () => {
        const storeOnboarding = JSON.parse(localStorage.getItem('storeOnboarding') || null);
        if (storeOnboarding) {
            navigate('/register?platform=' + storeOnboarding.origin.toLowerCase());
        } else{
            navigate('/register');
        }
    }

    return (
        <Box sx={ GeneralContainer }>
            <Box sx={ IconContainer }>
                <img src={ WhatsProLogo } alt="Logo"/>
            </Box>
            <Typography sx={ FirstTitle }>
                <ChatIcon/> ¡Felicitaciones! Encontraste el chat perfecto para tu página web
            </Typography>
            <Box sx={ ImageContainer }>
                <img src={ matches ? Step3Img : Step3MobileImg } alt="Logo" style={{ width: '90%' }}/>
            </Box>
            <Box sx={ TextContainer }>
                <Typography sx={ Title }>Creá tu cuenta y obtené</Typography>
                <Box sx={ FreeDays }>
                    +15 DÍAS <span style={{ fontWeight: '600' }}>GRATUITOS</span>
                </Box>
            </Box>
            <Button sx={ ButtonStyles } onClick={ handleChange }>
                Crear mi cuenta
            </Button>
        </Box>
    )
}

export default Step3WelcomeQuestion;