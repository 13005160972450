import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import { useFetch } from 'use-http';

// Actions
import { onSetEcommerceSelected } from '../../actions/tasks';
import { setActiveSite } from '../../actions/integrations';

// Components
import FinalModalIntegration from '../../components/Modals/IntegrationsModal/FinalModalIntegration';
import Task4SubCongratsModal from '../../components/Modals/CongratsModals/Task4SubCongratsModal';
import DrawerIntegrations from '../../components/Drawers/DrawerIntegrations/DrawerIntegrations';
import CardIntegration from '../../components/Integrations/CardIntegration/CardIntegration';
import IntegrationsModal from '../../components/Modals/IntegrationsModal/IntegrationsModal';

// Configuration
import { MAIN_API_URL } from '../../configuration';

// import StepsIntegrations from '../../components/Integrations/StepsIntegrations/StepsIntegrations';
// import OptionsItem from '../../components/Integrations/OptionsItem/OptionsItem';

// Icons
import HelpOutlineOutlinedIcon from '@mui/icons-material/HelpOutlineOutlined';

// Images
// import mercadoshops from '../../assets/images/Integrations/mercadoshops.png';
// import tiendanube from '../../assets/images/Integrations/tiendanube.png';

// import woocommerce from '../../assets/images/Integrations/woocommerce.png';

// Layout
import LayoutCreateUser from '../../layout/LayoutCreateUser';
import LayoutPages from '../../layout/LayoutPages';

// Material Ui
import { Box, Button, Grid } from '@mui/material';

// CSS
import Styles from './IntegrationsScreen.module.css';

const IntegrationsScreenContainer = {
    userSelect: 'none',
    textAlign: 'left',
    marginTop: '2rem',
    width: '100%',
    '@media(min-width: 800px)': {
        marginTop: 0,
    },
    '@media(min-width: 1000px)': {
        marginTop: '2rem',
    },
    '@media(min-width: 1300px)': {
        marginTop: 0,
    },
    '@media(min-width: 1400px)': {
        width: '100%',
    }
}

const CardsContainer = {
    paddingTop: '2rem',
}

const ButtonTextsStyles = {
    animation: 'pulseIntegrationAnimation 2s 1',
    transform: 'rotate(-90deg)',
    backgroundColor: '#2ED588',
    textTransform: 'none',
    animationDelay: '2s',
    borderRadius: '8px',
    position: 'fixed',
    fontSize: '1rem',
    right: '-2.5rem',
    display: 'flex',
    width: '110px',
    height: '40px',
    top: '140px',
    '@keyframes pulseIntegrationAnimation': {
        '0%': {
            fontSize: '1rem',
            width: '110px',
            height: '40px',
        },
        '25%': {
            fontSize: '1rem',
            width: '110px',
            height: '40px',
        },
        '50%': {
            fontSize: '1.05rem',
            width: '115px',
            height: '45px',
        },
        '75%': {
            fontSize: '1rem',
            width: '110px',
            height: '40px',
        }
    },
    ':hover': {
        backgroundColor: '#2ED588',
    },
    '@media(min-width: 1300px)': {
        top: '100px',
    }
}

const IntegrationsScreen = (props) => {

    // Actions
    const { onSetEcommerceSelected } = props;

    // States
    const { ecommerceSelected } = props;

    const [showCongratsModal, setShowCongratsModal] = useState(false);
    const [showFinalModal, setShowFinalModal] = useState(false);
    const [showHelpText, setShowHelpText] = useState(false);
    const [showModal, setShowModal] = useState(false);
    const [storeData, setStoreData] = useState('');

    const { error, get, response } = useFetch(`${MAIN_API_URL}/stores`, {
        headers: {
            "x-token": `${localStorage.getItem("token")}`
        }
    });

    useEffect(() => { getStoreData(); }, []);

    const getStoreData = async () => {
        const resp = await get("");
        if (response.ok) {
            setStoreData(resp);
        } else {
            console.log(error);
        }
    }

    const SitesArray = ['Tiendanube', 'Mercado Shops', 'Woo Commerce', 'Hecho a medida'];

    const handleShowHelpTexts = (open) => (event) => {
        if (event.type === 'keydown' && (event.key === 'Tab' || event.key === 'Shift')) {
            return;
        }

        setShowHelpText(open);
    }

    const handleShowModal = (site) => {
        onSetEcommerceSelected(site);
        if(showFinalModal && site === 'Hecho a medida' && !storeData?.onBoardingFinished){
            setShowCongratsModal(true);
        }
        setShowFinalModal(!showFinalModal);
    }

    const handleShowVideo = () => {
        setShowModal(!showModal);
    };

    return (
        <LayoutPages>
            <LayoutCreateUser>
                <Box sx={IntegrationsScreenContainer}>
                    <h1 className={Styles.Title}>Instalá WhatsPRO en tu página web</h1>
                    <p className={Styles.Subtitle}>Buscá tu ecommerce y conectalo para poder integrar el complemento</p>
                    <Grid container spacing={2} sx={CardsContainer}>
                        {
                            SitesArray.map((site, index) => (
                                <Grid item xs={12} sm={6} md={4} key={index}>
                                    <CardIntegration
                                        handleShowModal={(type) => handleShowModal(site, type)}
                                        onBoardingComplete={storeData?.onBoardingFinished}
                                        handleShowVideo={handleShowVideo}
                                        origin={localStorage.getItem('origin') ?? 'Particular'}
                                        title={site}
                                    />
                                </Grid>
                            ))
                        }
                    </Grid>
                    {/* <section className={ Styles.OptionsContainer }>
                            {
                                optionsArray.map((option, index) => 
                                    <OptionsItem
                                        key={ index }
                                        src={ option.src }
                                        value={ option.value }
                                        onClick={ handleChangeEcommerce }
                                    />
                                )
                            }
                        </section> */}
                    {/* <section className={ Styles.StepsContainer }>
                            <h1>¿Cómo integrar WhatsPRO a mi <span style={{ fontWeight: '600' }}>{ siteActive }</span>?</h1>
                            <div className={ Styles.Steps }>
                                <StepsIntegrations array={ stepsArray } /> 
                            </div>
                        </section> */}
                </Box>
            </LayoutCreateUser>
            <IntegrationsModal open={showModal} handleClose={handleShowVideo} />
            <FinalModalIntegration
                handleShowModal={handleShowModal}
                ecommerce={ecommerceSelected}
                open={showFinalModal}
            />
            <Button
                onClick={handleShowHelpTexts(true)}
                sx={ButtonTextsStyles}
                variant='contained'
            >
                <HelpOutlineOutlinedIcon />&nbsp;Ayuda
            </Button>
            <DrawerIntegrations open={showHelpText} toggleDrawer={handleShowHelpTexts} />
            <Task4SubCongratsModal
                open={showCongratsModal}
                origin={origin}
                task={4}
            />
        </LayoutPages>
    )
}

const mapStateToProps = state => {
    return {
        ecommerceSelected: state.tasks.ecommerceSelected,
        closeBannerSus: state.auth.closeBannerSus,
        siteActive: state.integrations.site,
    }
}

const mapDispatchToProps = dispatch => {
    return {
        onSetEcommerceSelected: (data) => dispatch(onSetEcommerceSelected(data)),
        setActiveSite: (data) => dispatch(setActiveSite(data)),
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(IntegrationsScreen);
