import React, { useEffect, useMemo, useRef, useState } from 'react';
import { connect } from "react-redux";
import { useFetch } from 'use-http';

// Actions
import { setTitleChat, setWelcomeTextChat } from '../../../actions/stepsChat';

// Components
import Chip from '../../Chip/Chip';

// Configurations
import { MAIN_API_URL } from '../../../configuration';

// Icons
import ArrowForwardIosIcon from '@mui/icons-material/ArrowForwardIos';
import ArrowBackIosNewIcon from '@mui/icons-material/ArrowBackIosNew';

// Layout
import LayoutStepsChat from '../../../layout/LayoutStepsChat';

// Material Ui
import { Box, IconButton, useMediaQuery } from '@mui/material';

// CSS
import Styles from './Step1Chat.module.css';

const TitleContainerStyles = {
    maxWidth: '680px',
    margin: 'auto',
};


const TemplatesPlantillas = [
    {
        title: 'Personalizada',
        description: '',
    },
    {
        title: 'Plantilla 1',
        description: '🍃Bienvenido a nuestro sistema de respuestas rápidas 🍃 Seleccioná una de las opciones o bien contactá con una de nuestras asesoras para que podamos atender tu consulta con la mayor rapidez posible 🔥',
    },
    {
        title: 'Plantilla 2',
        description: '¡Hey! Es un gusto saludarte 👋🏻, estamos aquí para atender súper rápido tus consultas. Aquí abajo están las preguntas más frecuentes. Si no encuentras una respuesta acorde a lo que necesitas, puedes hablar con uno de nuestros asesores. 😆',
    },
    {
        title: 'Plantilla 3',
        description: '¡Hola! 👋🏻 Que bueno que estés visitando nuestra página. Te detallamos las preguntas más frecuentes aquí abajo, si tienes alguna otra pregunta, nuestros asesores estarán encantados de atenderte. 😀',
    },
    {
        title: 'Plantilla 4',
        description: 'Gracias por comunicarte con nosotros, ¿Cómo podemos ayudarte? 🤔',
    },
    {
        title: 'Plantilla 5',
        description: '👋🏻 ¡Hola! Bienvenid@ a [Nombre]! Estamos para ayudarte ❤️',
    }
]

const Step1Chat = (props) => {

    // Actions
    const { setTitleChat, setWelcomeTextChat } = props;

    // States
    const {
        titleChat,
    } = props;

    const { error, response, get } = useFetch(`${MAIN_API_URL}/chat`, {
        headers: {
            "x-token": `${localStorage.getItem("token")}`
        }
    });
    const [templates, setTemplates] = useState(TemplatesPlantillas);
    const [descriptionTemplate, setDescriptionTemplate] = useState({
        description: '',
        template: 0,
    });

    const [showArrow, setShowArrow] = useState(false);

    const matches600 = useMediaQuery('(min-width: 600px)');
    const matches = useMediaQuery('min-width: 800px');
    const chipsContainer = useRef(null);

    const scrollWidth = useMemo(() => (TemplatesPlantillas.length * 125 + 10 * (TemplatesPlantillas.length - 1)), []);

    useEffect(() => { getChat(); }, []);

    useEffect(() => {
        if (!matches) chipsContainer.current.scrollLeft += 20;
    }, [chipsContainer, matches]);

    const getChat = async () => {
        const resp = await get("/");
        if (response.ok) {
            setWelcomeTextChat({description: resp.description});
            setTemplates(template => template.map((item, idx) => {
                if (idx === 0) {
                    return {
                        ...item,
                        description: resp.description,
                    }
                }
                return item;
            }));
            setDescriptionTemplate({
                description: resp.description,
                template: 0,
            });
        } else {
            console.log(error);
        }
    };

    const handleChangeTitle = (e) => {
        setTitleChat(e.target.value);
    };

    const handleClickScroll = () => {
        chipsContainer.current.scrollLeft += 60;
    };

    const handleClickLeftScroll = () => {
        chipsContainer.current.scrollLeft -= 60;
    };

    useEffect(() => {
        if (chipsContainer.current.offsetWidth > scrollWidth) {
            setShowArrow(false);
        } else {
            setShowArrow(true);
        }
    }, [chipsContainer, scrollWidth]);

    const handleChangeTemplate = (idx) => {
        setDescriptionTemplate({
            description: templates[idx].description,
            template: idx,
        });
        setWelcomeTextChat({description: templates[idx].description});
    };

    const handleChangeMessage = (e) => {
        const newText = e.target.value;
        setDescriptionTemplate({
            ...descriptionTemplate,
            description: newText,
        });
        setTemplates(templates.map((item, idx) => {
            if (idx === descriptionTemplate.template) {
                return {
                    ...item,
                    description: newText,
                }
            }
            return item;
        }));
        setWelcomeTextChat({description: newText});
    };


    return (
        <LayoutStepsChat>
            <Box sx={TitleContainerStyles}>
                <h1 className={Styles.Title}>Textos</h1>
                <p>Ingresá el título de tu chat y un texto de bienvenida</p>
            </Box>
            <section className={Styles.DataSection}>
                <h2>Título de bienvenida</h2>
                <div className={Styles.TextAreaContainer}>
                    <textarea
                        className={Styles.InputMessage}
                        onChange={handleChangeTitle}
                        placeholder='Mensaje'
                        value={titleChat}
                        maxLength={12}
                        id="title1"
                        rows="1"
                    />
                    <p className={Styles.MaxCharacters}>Máx 12 caracteres</p>
                </div>
                <div>
                    <h2>Texto de bienvenida</h2>
                    <div className={Styles.ChipsContainer}>
                        <div
                            style={{ display: matches600 ? showArrow ? 'flex' : 'none' : 'none' }}
                            className={Styles.ArrowLeftContainer}
                            onClick={handleClickLeftScroll}
                        >
                            <IconButton sx={{ marginRight: '.5rem' }}>
                                <ArrowBackIosNewIcon sx={{ color: '#BFBBBD' }} />
                            </IconButton>
                        </div>
                        <div
                            style={{ display: matches600 ? showArrow ? 'flex' : 'none' : 'none' }}
                            className={Styles.ArrowContainer}
                            onClick={handleClickScroll}
                        >
                            <IconButton sx={{ marginLeft: '.5rem' }}>
                                <ArrowForwardIosIcon sx={{ color: '#BFBBBD' }} />
                            </IconButton>
                        </div>
                        <div className={Styles.LinearGradientContainer}></div>
                        <div className={Styles.ChipSection} ref={chipsContainer}>
                            {
                                templates.map((template, idx) => (
                                    <Chip
                                        key={idx}
                                        text={template.title}
                                        active={descriptionTemplate.template === idx}
                                        handleClick={() => handleChangeTemplate(idx)}
                                    />
                                ))
                            }
                        </div>

                    </div>
                    <div className={Styles.TextAreaContainer}>
                        <textarea
                            rows="4"
                            value={descriptionTemplate.description}
                            placeholder={`Mensaje de bienvenida`}
                            className={Styles.InputMessageType}
                            onChange={handleChangeMessage}
                            maxLength={350}
                        />
                        <p className={Styles.MaxCharactersWelcome}>Máx 350 caracteres</p>
                    </div>
                </div>
            </section>
        </LayoutStepsChat>
    )
}

const mapStateToProps = state => {
    return {
        titleChat: state.stepsChat.titleChat,
    }
}

const mapDispatchToProps = dispatch => {
    return {
        setWelcomeTextChat: (value) => dispatch(setWelcomeTextChat(value)),
        setTitleChat: (value) => dispatch(setTitleChat(value)),
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(Step1Chat);