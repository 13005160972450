import React from 'react';

// CSS
import Styles from './Advanced.module.css';

const AdvancedTexts = () => {
    return (
        <div className={ Styles.TextContainer }>
            <h2>Paso 4: Vías de Comunicación</h2>
            <h3>¿Qué tengo que hacer?</h3>
            <p>En este paso podrás habilitar o deshabilitar las vías de comunicación a través de las cuales tus clientes se pondrán en contacto con los asesores.</p>
            <h3>¿Cuál me conviene habilitar?</h3>
            <p>El 58% de los clientes prefiere whatsapp como vía de comunicación para hacer consultas por lo tanto esta opción no puede faltar.</p>
            <h3>¿Habilito la llamada telefónica?</h3>
            <p>Esto depende de tu modelo de negocios. En caso de que tu producto o servicio sea complejo de comprender o requiera de una atención personalizada la llamada telefónica puede ser una buena opción.</p>
            <h3>¿Habilito los SMS?</h3>
            <p>Esto depende de tu modelo de negocios. Los SMS son muy utilizados en Estados Unidos por lo que podría ser conveniente habilitar la opción en caso de que tus clientes sean de Estados Unidos.</p>
        </div>
    )
}

export default AdvancedTexts;