import React from 'react';
import { connect } from 'react-redux';
import { Link } from 'react-router-dom';

// Actions
import { setUserEditing } from '../../../actions/stepsUser';
import { resetAll } from '../../../actions/global';

// Components
import RankingItem from '../RankingItem/RankingItem';

// Icons
import ControlPointIcon from '@mui/icons-material/ControlPoint';

// Images
import UsersImg from '../../../assets/images/Home/UsersImg.png';
import ChatImg from '../../../assets/images/Home/ChatImg.png';

// CSS
import Styles from './RankingComponent.module.css';

const AdviserComponent = (props) => {

    // Actions
    const { setUserEditing, resetAll, activeTask } = props;

    // External props
    const { type, array, onBoardingComplete } = props;

    const linkStyles = {
        backgroundColor: '#2ED588',
        textDecoration: 'none',
        padding: '.4rem .7rem',
        fontFamily: 'Poppins',
        textTransform: 'none',
        position: 'relative',
        borderRadius: '8px',
        width: 'max-content',
        textAlign: 'center',
        fontWeight: '600',
        fontSize: '.7rem',
        display: 'flex',
        color: 'white',
        margin: 'auto',
        gap: '5px',
        '&:hover': {
            backgroundColor: '#2ED588',
        }
    }

    const handleCreateUser = () => {
        setUserEditing({
            userEditing: false,
            userUid: ''
        });

        resetAll();
    }

    return (
        <section className={Styles.RankingGeneralContainer}>
            <div className={Styles.TopContainer}>
                <h1>{type === 'advisers' ? 'Top Asesores' : 'Top Preguntas Frecuentes'}</h1>
                <p>{type === 'advisers' ? 'Preguntas respondidas' : 'Total de Clicks'}</p>
            </div>
            <div className={Styles.RankingContainer}>
                {
                    array.length === 0 ?
                        type === 'advisers' ?
                            <div className={Styles.BackContainer}>
                                <img src={UsersImg} alt="Img" className={Styles.ImgBackRanking} />
                                <p className={Styles.BackContainerText}>Creá o habilitá al menos un asesor para poder visualizar sus estadísticas.</p>
                                {(activeTask > 3 || onBoardingComplete)&& <Link
                                    style={linkStyles}
                                    to='/createUser'
                                    onClick={handleCreateUser}
                                >
                                    <ControlPointIcon
                                        sx={{
                                            height: '1.2rem !important',
                                            width: '1.2rem !important',
                                            color: 'white !important',
                                        }}
                                    />
                                    <p style={{ margin: 'auto 0' }}>Agregar asesor</p>
                                </Link>}
                            </div>
                            :
                            type === 'questions' ?
                                <div className={Styles.BackContainer}>
                                    <img src={ChatImg} alt="Img" className={Styles.ImgBackRanking} />
                                    <p className={Styles.BackContainerText}>Dirigite a la sección CHAT para poder configurar tus preguntas frecuentes</p>
                                    {(activeTask > 2 || onBoardingComplete) ?
                                        <Link
                                            style={linkStyles}
                                            to='/chat'
                                            onClick={handleCreateUser}
                                        >
                                            <p style={{ margin: 'auto 0' }}>Configurar chat</p>
                                        </Link>
                                        :
                                        null
                                    }
                                </div>
                                :
                                null
                        :
                        null
                }
                {
                    (activeTask > 2 || onBoardingComplete) &&
                        array.map((item, index) => (
                            <RankingItem
                                key={index}
                                type={type}
                                {...item}
                            />
                        ))
                }
                {
                    (type === 'advisers' && array.length <= 2 && array.length !== 0 && onBoardingComplete) ?
                        <Link
                            style={linkStyles}
                            to='/createUser'
                            onClick={handleCreateUser}
                        >
                            <ControlPointIcon
                                sx={{
                                    height: '1.2rem !important',
                                    width: '1.2rem !important',
                                    color: 'white !important',
                                }}
                            />
                            <p style={{ margin: 'auto 0' }}>Agregar asesor</p>
                        </Link>
                        :
                        null
                }
            </div>
        </section>
    )
}

const mapStateToProps = state => {
    return {
        endTutorial: state.auth.endTutorial,
        users: state.stepsUser.users,
        activeTask: state.tasks.activeTask
    }
}

const mapDispatchToProps = dispatch => {
    return {
        setUserEditing: ({ userEditing, userUid }) => dispatch(setUserEditing({ userEditing, userUid })),
        resetAll: () => dispatch(resetAll()),
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(AdviserComponent);