import React from 'react';

// CSS
import Styles from './Step2.module.css';

const TextsStep2 = () => {
    return (
        <div className={ Styles.TextContainer }>
            <h2>Paso 2: Horarios de atención</h2>
            <h3>¿Qué tengo que hacer?</h3>
            <p>En el paso dos deberás configurar los horarios de atención de tus asesores. Los horarios de atención son los  horarios donde tus asesores estarán disponibles para responder de forma rápida y eficaz.</p>
            <h3>¿Puedo recibir consultas fuera de horario?</h3>
            <p>Esto es configurable. En el paso 3 podrás configurar si tu asesor recibirá consultas fuera de horario. En caso de habilitar la opción los clientes podrán escribirle pero serán informados que el mismo no está  dentro de su horario de atención.</p>
            <h3>¿Puedo poner horarios cortados?</h3>
            <p>Por supuesto, para crear horarios cortados deberás agregar un horario y crear un nuevo rango horario de atención para el mismo dia o dias diferentes.</p>
            <h3>¿Puedo poner horarios de Lun a Vie?</h3>
            <p>Si, esto es muy simple, en caso de que tu asesor solo esté disponible los días de semana, será necesario clickear en verde los días que esté disponible y dejar en gris los que no.</p>
            <h3>¿Puedo deshabilitar un horario?</h3>
            <p>Si, con el switch de la derecha de cada horario podrás deshabilitarlo en caso de ser necesario. Cuando un horario está deshabilitado no se tendrá en cuenta para la disponibilidad.</p>
        </div>
    )
}

export default TextsStep2;