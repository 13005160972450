import React from 'react';

// Material Ui
import { createTheme, Grid, ThemeProvider } from '@mui/material';

const GeneralContainer = {
    margin: '1rem auto auto auto',
    padding: '0rem',
    width: '95%',
    '@media(min-width: 600px)': {
        maxWidth: '900px'
    },
    '@media(min-width: 1000px)': {
        marginTop: '3rem'
    },
    '@media(min-width: 1300px)': {
        marginTop: '0rem'
    },
    '@media(min-width: 1800px)': {
        width: '100%',
    }
}

const theme = createTheme({
    breakpoints: {
        values: {
            xs: 0,
            sm: 600,
            md: 900,
            lg: 1600,
            xl: 1800,
        },
    },
});

const LayoutCreateUser = ({ children }) => {
    
    return (
        <ThemeProvider theme={ theme } >
            <Grid container sx={ GeneralContainer }>
                <Grid item xs={12} md={12} lg={12} xl={12}>{ children }</Grid>
            </Grid>
        </ThemeProvider>
    )
}

export default LayoutCreateUser;