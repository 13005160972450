import React from 'react';

// Images
import AvatarsA from '../../images/Chat/AvatarsA.png';

const ChatAvatars = () => {

    return (
        <div style={{ display: 'flex', alignItems: 'center' }}>
            <img src={ AvatarsA } alt="Img" width={ 45 }/>
        </div>
    )
}

export default ChatAvatars;