const brJSON = {
    // NAVBAR
    "Home":"Início",
    "Users":"Consultores",
    "User1":"Meus Consultores",
    "User2":"Criar Consultor",
    "Chat":"Chat",
    "Help":"Ajuda",
    "Advanced": "Avançado",
    "MyCount": "Minha conta",
    "Integrations": "Integrações",
    "Potenciate": "Potenciate",
    "Logout": "Fechar sessão",
    "Synchronization": "Sincronização",
    "Billings": "Cobrança",
    "Reviews": "Análises",
};

export default brJSON;