import React from 'react';

// Material Ui
import { FormControl, MenuItem, Select } from '@mui/material';
import { createTheme } from '@mui/material/styles';
import { ThemeProvider } from '@mui/system';

const theme = createTheme({
    palette: {
        primary: {
            main: '#2ED588',
        },
        secundary: {
            main: '#BFBBBD'
        },
        error: {
            main: '#FF0000'
        }
    },
});

const SelectPayment = ({ value, items, handleChange, index, error }) => {

    const SelectStyles = {
        margin: '.2rem 0',
        width: '100%',
        '@media (min-width: 1200px)': {
            margin: '0',
        },
        '.MuiOutlinedInput-root': {
            borderRadius: '12px',
            width: '100%'
        },
        '.MuiOutlinedInput-input': {
            fontFamily: 'Poppins',
            fontSize: '.7rem', 
            padding: '.5rem',
            color: value === "" ? '#BFBBBD' : 'black', 
            '@media (min-width: 1200px)': {
                padding: '.95rem',
                fontSize: '1rem',
            },
        },
        '.MuiInputLabel-root': {
            fontFamily: 'Poppins',
        }

    }

    const menuItemStyles = {
        marginTop: '.2rem',
        fontSize: '.7rem',
        '@media (min-width: 1200px)': {
            fontSize: '1rem',
            margin: '0rem',
        },
    }

    return (
        <ThemeProvider theme={theme}>
            <FormControl sx={ SelectStyles }>
                <Select
                    id={ index }
                    name={ index }
                    value={ value }
                    onChange={ handleChange }
                    displayEmpty
                    inputProps={{ 'aria-label': 'Without label' }}
                    error={ error ? true : false }
                >
                    {items.map((option) => (
                        <MenuItem key={ option.value } value={ option.value } sx={ menuItemStyles }>
                            { option.label }
                        </MenuItem>
                    ))}
                </Select>
            </FormControl>
        </ThemeProvider>
    )
}

export default SelectPayment;