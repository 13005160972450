import React from 'react';
import { connect } from 'react-redux';

// Components
import ChatWindow from '../components/Chat/ChatWindow/ChatWindow';

// Material Ui
import { Box, createTheme, Grid, ThemeProvider, Typography, useMediaQuery } from '@mui/material';

const GeneralContainer = {
    margin: '1rem auto auto auto',
    padding: '0rem',
    width: '95%',
    '@media(min-width: 600px)': {
        maxWidth: '900px'
    },
    '@media(min-width: 1000px)': {
        marginTop: '3rem'
    },
    '@media(min-width: 1300px)': {
        marginTop: '0rem'
    },
    '@media(min-width: 1800px)': {
        width: '100%',
    }
}

const ChatContainer = {
    borderLeft: '1px solid #D1D1D199',
    padding: '1rem 0 0 2rem',
    position: 'relative',
    display: 'none',
    '@media(min-width: 1600px)': {
        flexDirection: 'column',
        display: 'flex',
    }
}

const TextContainer = {
    position: 'fixed',
    display: 'flex',
}

const Title = {
    marginBottom: '.6rem',
    fontWeight: '500',
    fontSize: '1rem',
}

const Subtitle = {
    fontSize: '.9rem',
    fontWeight: '500',
}

const theme = createTheme({
    breakpoints: {
        values: {
            xs: 0,
            sm: 600,
            md: 900,
            lg: 1600,
            xl: 1800,
        },
    },
});

const LayoutChat = ( props ) => {

    // External props
    const { children } = props;

    // States
    const { activeStepChat } = props;

    const matches = useMediaQuery('(min-width:1600px)');

    const TextSteps = () => {
        switch (activeStepChat) {
            case 0:
                return <Typography sx={ Subtitle }>Personalizá tu título y texto de bienvenida</Typography>;
            case 1:
                return <Typography sx={ Subtitle }>Personalizá tus preguntas frecuentes</Typography>;
            case 2:
                return <Typography sx={ Subtitle }>Personalizá la estética de tu chat</Typography>;
            default:
                break;
        }
    }

    return (
        <ThemeProvider theme={ theme } >
            <Grid container sx={ GeneralContainer }>
                <Grid item xs={12} md={12} lg={9} xl={9}>{ children }</Grid>
                {/* <Grid item xs={0} md={0} lg={3} xl={3} sx={ ChatContainer }>
                    <Box sx={ TextContainer }>
                        <Box>
                            <Typography sx={ Title }>Vista previa</Typography>
                            {
                                TextSteps()
                            }
                        </Box>
                    </Box>
                    {
                        activeStepChat === 3 ? null : <ChatWindow open={ matches ? true : false } activeStep={ activeStepChat } />
                    }
                </Grid> */}
            </Grid>
        </ThemeProvider>
    )
}

const mapStateToProps = state => {
    return {
        activeStepChat: state.stepsChat.activeStepChat,
    }
}

export default connect(mapStateToProps, null)(LayoutChat);
