import React, { useState } from 'react';

// Components
import Step0WelcomeQuestion from '../../components/WelcomeQuestions/Step0WelcomeQuestion';
import Step1WelcomeQuestion from '../../components/WelcomeQuestions/Step1WelcomeQuestion';
import Step2WelcomeQuestion from '../../components/WelcomeQuestions/Step2WelcomeQuestion';
import Step3WelcomeQuestion from '../../components/WelcomeQuestions/Step3WelcomeQuestion';
import Navbar from '../../components/Navbar/Navbar';

// Images
import BackStep123 from '../../assets/images/WelcomeQuestions/BackStep123.png';
import BackStep0 from '../../assets/images/WelcomeQuestions/BackStep0.png';

// Material Ui
import { Box, useMediaQuery } from '@mui/material';

const WelcomeQuestionsContainerStyles = {
    justifyContent: 'center',
    height: 'max-content',
    alignItems: 'center',
    display: 'flex',
    paddingTop: 0,
    marginLeft: 0,
    width: '100%',
    '@media(min-width: 550px)': {
        paddingTop: '84px',
    },
    '@media(min-width: 1200px)': {
        height: '100vh',
        paddingTop: 0,
    },
    // '@media(min-width: 1300px)': {
    //     width: 'calc(100% - 14rem)',
    //     marginLeft: '14rem',
    // }
}

const WelcomeQuestionsScreen = () => {

    const [welcomeStep, setWelcomeStep] = useState(0);

    const matches = useMediaQuery('(min-width: 1300px)');

    const StepsContainer = {
        filter: 'drop-shadow(0px 98px 98px rgba(57, 57, 57, 0.09)) drop-shadow(0px 25px 54px rgba(57, 57, 57, 0.1))',
        backgroundImage: `url(${welcomeStep === 0 ? BackStep0 : BackStep123})`,
        backgroundSize: 'cover',
        justifyContent: 'center',
        boxSizing: 'border-box',
        flexDirection: 'column',
        alignItems: 'center',
        padding: '1rem 2rem',
        borderRadius: '0px',
        display: 'flex',
        margin: 'auto',
        width: '100%',
        '@media(min-height: 550px)': {
            height: (welcomeStep === 0 || welcomeStep === 3) ? '100%' :'100vh',
        },
        '@media(min-height: 600px)': {
            height:  welcomeStep === 3 ? '100%' :'100vh',
        },
        '@media(min-height: 700px)': {
            height: '100vh',
        },
        '@media(min-width: 550px)': {
            borderRadius: '14px',
            height: '600px',
            width: '550px',
        },
    }

    const handleChangeStep = () => {
        if (welcomeStep < 3) {
            setWelcomeStep( welcomeStep + 1 )
        } else {
            return;
        }
    }

    const RenderChildrensComponents = () => {
        switch (welcomeStep) {
            case 0:
                return <Step0WelcomeQuestion handleChange={ handleChangeStep }/>;
            case 1:
                return <Step1WelcomeQuestion handleChange={ handleChangeStep }/>;
            case 2:
                return <Step2WelcomeQuestion handleChange={ handleChangeStep }/>;
            case 3:
                return <Step3WelcomeQuestion handleChange={ handleChangeStep }/>;
            default:
                break;
        }
    }

    return (
        <Box sx={ WelcomeQuestionsContainerStyles }>
            { matches ? null : null }
            {/* <Navbar /> */}
            <Box sx={ StepsContainer }>
                {
                    RenderChildrensComponents()
                }
            </Box>
        </Box>
    )
}

export default WelcomeQuestionsScreen;