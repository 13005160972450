import React from 'react';

// CSS
import Styles from './Step1.module.css';

const TextsStep1 = () => {
    return (
        <div className={ Styles.TextContainer }>
            <h2>Paso 1: Datos de contacto</h2>
            <h3>¿Qué tengo que hacer?</h3>
            <p>En primer lugar deberás completar los datos de contacto de tu asesor para que tus clientes puedan comunicarse en caso de ser necesario. Es importante que lo completes con información precisa y actualizada.</p>
            <h3>¿Por qué necesito crear un asesor?</h3>
            <p>Whatspro se encargará de responder la mayor cantidad de consultas de forma automática pero siempre es conveniente dar la posibilidad de comunicarse con un asesor en caso de ser necesario.</p>
            <h3>¿Qué cargo elijo?</h3>
            <p>El cargo es importante para que tus clientes sepan con que área se estarán contactando. En caso de tener múltiples asesores es conveniente que especifiques el cargo con precisión para que las consultas las hagan a la persona indicada.</p>
            <h3>¿Puedo crear múltiples asesores?</h3>
            <p>Si, crear múltiples asesores es muy simple y podrás crearlo desde el menú lateral una vez que esté habilitado. Crear múltiples asesores es muy útil para poder tener asesores por área o incluso por turno de trabajo.</p>
        </div>
    )
}

export default TextsStep1;