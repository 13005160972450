import React, { useEffect, useRef } from 'react';

// Components
import CardButton from '../CardButton/CardButton';

// Icons
import Task1Icon from '../../../../assets/icons/Home/Task1Icon';
import Task2Icon from '../../../../assets/icons/Home/Task2Icon';
import Task3Icon from '../../../../assets/icons/Home/Task3Icon';
import Task4Icon from '../../../../assets/icons/Home/Task4Icon';

// Material Ui
import { Card, CardActions, CardContent, Grid, Typography } from '@mui/material';

const TitleSection = {
    paddingLeft: '.5rem',
    alignItems: 'center',
    textAlign: 'left',
    margin: 'auto',
}

const CardComponent = ({ task, name, description, state, handleShowCongratsModal }) => {

    const CardStyles = {
        border: state === 'active' ? '1px solid #2ED588' : '1px solid #E4E4E4',
        boxSizing: 'border-box',
        borderRadius: '20px',
        maxWidth: '340px',
        boxShadow: 'none',
        width: '340px',
    }

    const TitleStyles = {
        color: state === 'blocked' ? '#BFBBBD' : '#2ED588',
        textAlign: 'left',
        fontSize: '.7rem',
        fontWeight: '500',
        '@media(min-width: 800px)': {
            fontSize: '.8rem',
        }
    }
    
    const NameStyles = {
        color: state === 'blocked' ? '#BFBBBD' : '#393939',
        marginTop: '.2rem',
        textAlign: 'left',
        fontSize: '.9rem',
        fontWeight: '600',
        '@media(min-width: 800px)': {
            fontSize: '1.1rem',
        }
    }
    
    const DescriptionStyles = {
        color: state === 'blocked' ? '#BFBBBD' : '#393939',
        fontSize: '.7rem',
        fontWeight: '500',
        textAlign: 'left',
        '@media(min-width: 800px)': {
            fontSize: '.75rem',
        }
    }

    const handleShowIcon = () => {
        switch (task) {
            case 1:
                return <Task1Icon/>;
            case 2:
                return <Task2Icon 
                            color={ state === 'completed' ? '#E3FFF2' : state === 'active' ? '#2ED588' : '#E4E4E4' }
                            fontColor={ state === 'completed' ? '#2ED588' : 'white' }
                        />;
            case 3:
                return <Task3Icon 
                            color={ state === 'completed' ? '#E3FFF2' : state === 'active' ? '#2ED588' : '#E4E4E4' }
                            fontColor={ state === 'completed' ? '#2ED588' : 'white' }
                        />;
            case 4:
                return <Task4Icon 
                            color={ state === 'completed' ? '#E3FFF2' : state === 'active' ? '#2ED588' : '#E4E4E4' }
                            fontColor={ state === 'completed' ? '#2ED588' : 'white' }
                        />;
            default:
                break;
        }
    }

    const activeCardRef = useRef(null);
    const cardRef = useRef(null);

    useEffect(() => {
        setTimeout(function () {
            if ( activeCardRef && activeCardRef.current ) {
                activeCardRef.current.scrollIntoView({ behavior: 'smooth', block: 'center' });
            };
        }, 100);
    }, []);

    return (
        <Card sx={ CardStyles } ref={ state === 'active' ? activeCardRef : cardRef }>
            <CardContent sx={{ paddingBottom: '.5rem' }}>
                <Grid container spacing={0} sx={{ marginBottom: '.6rem' }}>
                    <Grid item xs={2} sx={{ display: 'flex', justifyContent: 'flex-start' }}>
                        { handleShowIcon() }
                    </Grid>
                    <Grid item xs={10} sx={ TitleSection }>
                        <Typography sx={ TitleStyles }>TAREA { task }</Typography>
                        <Typography sx={ NameStyles }>{ name }</Typography>
                    </Grid>
                </Grid>
                <Typography variant="body2" sx={ DescriptionStyles }>
                    { description }
                </Typography>
            </CardContent>
            <CardActions>
                <CardButton 
                    handleShowCongratsModal={ handleShowCongratsModal }
                    state={ state } 
                    task={ task } 
                    size="small" 
                />
            </CardActions>
        </Card>
    )
}

export default CardComponent;