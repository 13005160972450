import { Box, Typography } from '@mui/material'
import { useGoogleLogin } from '@react-oauth/google';
import React, { useEffect, useState } from 'react'
import { connect } from 'react-redux';
import { initLogin } from '../../../actions/auth';
import LoaderFlowy from '../../Loader/LoaderFlowy/LoaderFlowy';
import { useFetch } from 'use-http';
import { MAIN_API_URL } from '../../../configuration';

const SocialLogin = (props) => {
    // const { initLogin } = props;
    const [user, setUser] = useState(null);
    const [loading, setLoading] = useState(false);
    const { post, response} = useFetch(MAIN_API_URL);

    const login = useGoogleLogin({
        onSuccess: (codeResponse) => {
            setUser(codeResponse)
            initLogin()
        },
        onError: (error) => alert(error),
    });

    useEffect(() => {
        const check = async () => {
        if (user) {
            const res = await post('/auth/login-register-google', { accessToken: user.access_token })
            if (response.ok) {
                setLoading(false)
                const data = res.user;
                localStorage.setItem('token', data.token);
                localStorage.setItem('store', data._id);
                localStorage.setItem('refreshToken', data.refreshToken);
                localStorage.setItem('origin', data.origin);
                window.location.href = '/home';
            } else{
                alert('Error al iniciar sesión')
                setLoading(false)
            }
        }
        }
        check()
    }, [user])

    const handleClick = () => {
        setLoading(true);
        login();
    };

    if(loading) return <LoaderFlowy/>

    return (
        <Box
            display={'flex'}
            flexDirection={'row'}
            alignContent={'center'}
            justifyContent={'flex-start'}
            alignItems={'center'}
            sx={{
                width: '94%',
                gap: '10px',
                borderRadius: '6px',
                backgroundColor: '#fff',
                // boxShadow: '0 0 10px 0 rgba(0, 0, 0, 0.1)',
                padding: '12px 10px',
                cursor: 'pointer',
                border: '1px solid #c4c4c4',
                '&:hover': {
                    //backgroundColor black with 0.1 opacity
                    backgroundColor: 'rgba(0, 0, 0, 0.1)',
                },
            }}
            onClick={handleClick}
        >
            <Box
                component={'img'}
                src="https://img.icons8.com/color/48/000000/google-logo.png"
                sx={{
                    height: '30px',
                    width: '30px',
                    borderRadius: '6px',
                }}
            />
            <Typography
                variant={'subtitle1'} sx={{ fontSize: '0.9rem', fontWeight: 400 }}
            >
                Continuar con Google
            </Typography>
        </Box>
    )
}
const mapStateToProps = state => {
    return {
    }
}

const mapDispatchToProps = dispatch => {
    return {
        // initLogin: dispatch(initLogin()),
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(SocialLogin);