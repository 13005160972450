import { globalsTypes, typesUserSteps } from "../types/types";

const initialState = {
    activeStep: 0,
    completeStep: [
        false,
        false,
        false,
        false
    ],
    userEditing: false,
    userCreate: false,
    srcImgProfile: '',
    imgProfileId: '',
    userData: {
        userName: '',
        position: '',
        countryCode: 'ar',
        dialCode: 'ar',
        prefix: '54',
        phoneNumber: '',
    },
    enabledRangeHours: true,
    hours: [
        {
            id: 0,
            index: 0,
            name: `Horario 1`,
            days: ['D','L','M', 'M', 'J', 'V', 'S'],
            start: 540,
            end: 1020,
            activeHour: true,
            state: true,
            disabled: false,
            activeDays: ['D','L','M', 'M', 'J', 'V', 'S'],
        }
    ],
    idHourDelete: 0, 
    welcomeMessage: '¡Hola! 👋🏻 Gracias por comunicarte conmigo, con gusto responderé TODAS tus consultas! 😃',
    allowOutHourMessage: true,
    outHourMessage: 'Gracias por comunicarte conmigo. Actualmente no me encuentro disponible, pero podés dejarme tu mensaje. En cuanto regrese, estare encantado de responder tu consulta 🤗.',
    comunication: {
        whatsApp: true,
        call: false,
        sms: false
    },
    showUserModalComplete: false,
    showErrorModal: false,
    idUserDelete: 0,
    users: [],
    userUid: '',
    errorSetHour: false, 
    imgsPersonalize: [],
    loading: false,
    counterActiveHours: 0
}

export const stepsUserReducer = (state = initialState, action) => {
    switch (action.type) {
        case typesUserSteps.activeStep:
            return { 
                ...state, 
                activeStep: action.payload.activeStep 
            }
        case typesUserSteps.completeStep:
            return { 
                ...state, 
                completeStep: action.payload.completeStep ?? state.completeStep 
            }
        case typesUserSteps.userCreate:
            return { 
                ...state, 
                userCreate: action.payload.userCreate ?? state.userCreate 
            }
        case typesUserSteps.userEditing:
            return { 
                ...state, 
                userEditing: action.payload.userEditing ?? state.userEditing,
                userUid: action.payload.userUid ?? state.userUid, 
            }
        case typesUserSteps.changeProfileImg:
            return { 
                ...state, 
                srcImgProfile: action.payload.srcImgProfile ?? state.srcImgProfile,
                imgProfileId: action.payload.imgProfileId ?? state.imgProfileId 
            }
        case typesUserSteps.setUserData:
            return { 
                ...state,
                userData: {
                    ...state.userData,
                    userName: action.payload.userName ?? state.userData.userName,
                    position: action.payload.position ?? state.userData.position,
                    countryCode: action.payload.countryCode ?? state.userData.countryCode,
                    phoneNumber: action.payload.phoneNumber ?? state.userData.phoneNumber,
                    prefix: action.payload.prefix ?? state.userData.prefix,
                    dialCode: action.payload.dialCode ?? state.userData.dialCode,  
                }
            }
        case typesUserSteps.createHours:
            action.payload.index = state.hours.length ?? 0;
            action.payload.id = state.hours.length ?? 0;
            action.payload.name = `Horario ${state.hours.length + 1}`;
            return {
                ...state,
                hours: [
                    ...state.hours,
                    action.payload
                ]
            }

        //switch para activar/desactivar horario
        case typesUserSteps.setActiveHours:
            let newHours = [...state.hours];
            let index = newHours.findIndex((item) => item.id === action.payload.id);

            if (index === -1) {
                return { 
                    ...state 
                }
            }

            newHours[index].activeHour = !action.payload.activeHour;

            return { 
                ...state,
                activeHour: [...newHours] 
            }
        //selector comienzo de horario
        case typesUserSteps.changeStartRangeHours:
            let hoursCopy = [...state.hours];
            let indexCopy = hoursCopy.findIndex((item) => item.id === action.payload.index);
            
            if (indexCopy === -1) {
                return {
                    ...state
                }
            }
            
            hoursCopy[indexCopy].start = action.payload.startHour;
            
            return {
                ...state,
                hours: [...hoursCopy]
            }
        //selector fin de horario
        case typesUserSteps.changeEndRangeHours:
            let hoursCopy1 = [...state.hours];
            let indexCopy1 = hoursCopy1.findIndex((item) => item.id === action.payload.index);
            
            if (indexCopy1 === -1) {
                return {
                    ...state
                }
            }
            
            hoursCopy1[indexCopy1].end = action.payload.endHour;
            
            return {
                ...state,
                hours: [...hoursCopy1]
            }
        //selector de dias disponibles
        case typesUserSteps.setActiveDaysUser:
            let hoursCopy2 = [...state.hours];
            let indexCopy2 = hoursCopy2.findIndex((item) => item.id === action.payload.index);

            if (indexCopy2 === -1 ) {
                return {
                    ...state
                }
            }

            hoursCopy2[indexCopy2].activeDays = action.payload.activeDays;

            return {
                ...state,
                hours: [...hoursCopy2]
            }
        
        //popup que marca el id a eliminar
        case typesUserSteps.setIdHourDelete:
            return {
                ...state,
                idHourDelete: action.payload
            } 
        //se procede a eliminar el horario
        case typesUserSteps.deleteHour:
            let newHoursArray = [];
            //push and refresh id, index and name
            state.hours.forEach((item, index) => {
                if (item.id !== action.payload.id) {
                    item.id = newHoursArray.length;
                    item.index = newHoursArray.length;
                    item.name = `Horario ${newHoursArray.length + 1}`;
                    newHoursArray.push(item);
                }
            });
            return {
                ...state,
                hours: [...newHoursArray]
            }
        case typesUserSteps.setMessages:
            return {
                ...state,
                allowOutHourMessage: action.payload.allowOutHourMessage ?? state.allowOutHourMessage,
                welcomeMessage: action.payload.welcomeMessage ?? state.welcomeMessage,
                outHourMessage: action.payload.outHourMessage ?? state.outHourMessage,
            }
        case typesUserSteps.setComunication:
            return {
                ...state,
                comunication: {
                    ...state.comunication,
                    whatsApp: action.payload.whatsApp ?? state.comunication.whatsApp,
                    call: action.payload.call ?? state.comunication.call,
                    sms: action.payload.sms ?? state.comunication.sms,
                }
            }
        case typesUserSteps.showUserModalComplete:
            return {
                ...state,
                showUserModalComplete: action.payload
            }
        case typesUserSteps.saveUser:
            return {
                ...state,
                users: [
                    ...state.users,
                    action.payload
                ]
            }
        case typesUserSteps.ShowErrorUserModal:
            return {
                ...state,
                showErrorModal: action.payload
            }
        case typesUserSteps.setIdUserDelete:
            return {
                ...state,
                idHourDelete: action.payload
            } 
        case typesUserSteps.deleteUser:
            let newUsersArray = [...state.users];
            
            newUsersArray.splice(action.payload.id, 1);

            return {
                ...state,
                users: [...newUsersArray] 
            }
        case typesUserSteps.changeStateUser:
            let newUsersArray1 = [...state.users];
            let userIndex = newUsersArray1.findIndex((item) => item.id === action.payload.idUser);

            if (userIndex === -1 ) {
                return {
                    ...state
                }
            }

            newUsersArray1[userIndex].state = action.payload.userState;
            return {
                ...state,
                users: [...newUsersArray1]
            }
        case typesUserSteps.setEnabledRangeHours: 
            return {
                ...state,
                enabledRangeHours: action.payload.enabledRangeHours,
                // hours: initialState.hours
            }
            // if(action.payload.enabledRangeHours){
            // }else{
            //     return {
            //         ...state,
            //         enabledRangeHours: action.payload.enabledRangeHours,
            //         hours: [{
            //             id: 0,
            //             index: 0,
            //             name: 'Horario 1',
            //             start: 0,
            //             end: 1440,
            //             days: ['D','L','M', 'M', 'J', 'V', 'S'],
            //             activeHour: true,
            //             state: true,
            //             disabled: false
            //         }]
            //     }
            // }
        case typesUserSteps.resetHours:
            return {
                ...state,
                hours: [...action.payload.newHours]
            }
        case globalsTypes.resetAll:
            initialState.hours = [{
                id: 0,
                index: 0,
                name: `Horario 1`,
                days: ['D','L','M', 'M', 'J', 'V', 'S'],
                start: 540,
                end: 1020,
                activeHour: true,
                state: true,
                disabled: false ,
                activeDays: ['D','L','M', 'M', 'J', 'V', 'S']
            }]
            return {
                ...initialState,
            }
        case typesUserSteps.errorHour:
            let hoursCopy3 = [...state.hours];
            let indexCopy3 = hoursCopy3.findIndex((item) => item.id === action.payload.id);

            if (indexCopy3 === -1 ) {
                return {
                    ...state
                }
            }

            hoursCopy3[indexCopy3].disabled = action.payload.errorSetHour;

            return {
                ...state,
                hours: [...hoursCopy3],
                errorSetHour: action.payload.errorSetHour
            }
        case typesUserSteps.saveImgPersonalize:
            return {
                ...state,
                imgsPersonalize: [
                    ...state.imgsPersonalize, 
                    action.payload
                ]
            }
        case typesUserSteps.setLoading:
            return {
                ...state,
                loading: action.payload
            }
        case typesUserSteps.setCounterActiveHours:
            return {
                ...state,
                counterActiveHours: action.payload
            }
        default:
            return state;
    }
}
