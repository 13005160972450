import { typesAuth } from "../types/types";

const initialState = {
    isLogIn: false,
    token: localStorage.getItem('token'),
    store: undefined,
    loading: false,
    errorLogin: undefined,
    errorRegister: undefined,
    errorRegisterConnection: undefined,
    error: undefined,
    userRegister: {
        completeName: '',
        email: '',
        password: '',
        userName: '',
        phone: '',
        countryCode: 'ar',
        dialCode: '54',
        acceptTerms: false,
        call: false,
        discountCode: '',
        country: '',
        timeZone: '',
        timeZoneName: ''
    },
    billingData: {
        condicionImpositiva: '',
        documentType: '',
        codigoPostal: '',
        documentNro: '',
        razonSocial: '',
        direccion: '',
        localidad: '',
        provincia: '',
        estado: '',
        email: '',
    },
    endTutorial: true,
    logOut: false,
    id: '',
    isRegistered: '',
    storeId: '',
    storeName: '',
    showRecoverPass1: false,
    showRecoverPass2: false,
    recoverPass: {
        confirmPass: '',
        pass: '',
    },
    endTrialPeriod: false,
    closeBannerSus: true
}

export const authReducer = (state = initialState, action) => {
    switch (action.type) {
        case typesAuth.initLogin:
            return { ...state, loading: true }
        case typesAuth.initLoginBack:
            return { ...state, loading: true }
        case typesAuth.initLoginBackMercadoShops: {
            return { ...state, loading: true }
        }
        case typesAuth.finishLoginBack:
            return { ...state, loading: false }
        case typesAuth.initRegister:
            return { ...state, loading: true }
        case typesAuth.successLogin:
            return { ...state, loading: false, isLogIn: true, errorLogin: undefined }
        case typesAuth.failLogin:
            return { ...state, loading: false, errorLogin: action.payload, isLogIn: false }
        case typesAuth.failRegister:
            return { ...state, loading: false, errorRegister: action.payload, isLogIn: false }
            case typesAuth.failConnectionRegister:
            return { ...state, loading: false, errorRegisterConnection: action.payload, isLogIn: false }
        case typesAuth.changeStore:
            return { ...state, store: { ...action.payload } }
        case typesAuth.changeTokens:
            return {
                ...state,
                token: action.payload.token,
                refreshToken: action.payload.refreshToken,
            }
        case typesAuth.saveResData:
            return {
                ...state,
                id: action.payload.id,
                isRegistered: action.payload.isRegistered,
                storeId: action.payload.storeId,
                storeName: action.payload.storeName,
            }
        case typesAuth.changeIsLogIn:
            return {
                ...state,
                isLogIn: action.payload
            }
        case typesAuth.changeEndTutorial:
            return {
                ...state,
                store: {
                    ...state.store,
                    endTutorial: action.payload
                }
            }
        case typesAuth.changeOnBoardingComplete:
            return {
                ...state,
                store: {
                    ...state.store,
                    onBoardingComplete: action.payload
                }
            }
        case typesAuth.login:
            return {
                ...state,
                userLogin: {
                    ...state.store,
                    email: action.payload.email ?? state.email,
                    password: action.payload.password ?? state.password
                }
            }
        case typesAuth.register:
            return {
                ...state,
                userRegister: {
                    ...state.userRegister,
                    countryCode: action.payload.countryCode ?? state.userRegister.countryCode,
                    dialCode: action.payload.dialCode ?? state.userRegister.dialCode,
                    phone: action.payload.phone ?? state.userRegister.phone,
                    email: action.payload.email ?? state.userRegister.email,
                    userName: action.payload.userName ?? state.userRegister.userName,
                    password: action.payload.password ?? state.userRegister.password,
                    completeName: action.payload.completeName ?? state.userRegister.completeName,
                    acceptTerms: action.payload.acceptTerms ?? state.userRegister.acceptTerms,
                    call: action.payload.call ?? state.userRegister.call,
                    discountCode: action.payload.discountCode ?? state.userRegister.discountCode,
                    country: action.payload.country ?? state.userRegister.country,
                    timeZone: action.payload.timeZone ?? state.userRegister.timeZone,
                    timeZoneName: action.payload.timeZoneName ?? state.userRegister.timeZoneName,
                }
            }
        case typesAuth.setEndTutorial:
            return {
                ...state,
                endTutorial: action.payload.endTutorial,
            }
        case typesAuth.setLogout:
            // localStorage.removeItem('token');
            // localStorage.removeItem('refreshToken');
            // window.location.reload();
            return {
                ...state,
                logOut: action.payload
            }
        case typesAuth.showRecoverPass: 
            return {
                ...state,
                showRecoverPass1: action.payload.showRecoverPass1,
                showRecoverPass2: action.payload.showRecoverPass2,
            }
        case typesAuth.showConfirmPass: 
            return {
                ...state,
                recoverPass: {
                    confirmPass: action.payload.confirmPass,
                    pass: action.payload.pass,
                }
            }
        case typesAuth.changeEndTrialPeriod: 
            return {
                ...state,
                endTrialPeriod: action.payload,
            }
        case typesAuth.changeCloseBannerSus: 
            return {
                ...state,
                closeBannerSus: action.payload,
            }
        case typesAuth.billings:
            return {
                ...state,
                billingData: {
                    ...state.billingData,
                    condicionImpositiva: action.payload.condicionImpositiva ?? state.userRegister.condicionImpositiva,
                    documentType: action.payload.documentType ?? state.userRegister.documentType,
                    codigoPostal: action.payload.codigoPostal ?? state.userRegister.codigoPostal,
                    documentNro: action.payload.documentNro ?? state.userRegister.documentNro,
                    razonSocial: action.payload.razonSocial ?? state.userRegister.razonSocial,
                    direccion: action.payload.direccion ?? state.userRegister.direccion,
                    localidad: action.payload.localidad ?? state.userRegister.localidad,
                    provincia: action.payload.provincia ?? state.userRegister.provincia,
                    estado: action.payload.estado ?? state.userRegister.estado,
                    email: action.payload.email ?? state.userRegister.email,
                }
            }
        case typesAuth.loading:
            return {
                ...state,
                loading: action.payload
            }
        default:
            return state
    }
}