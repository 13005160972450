import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';

// Icons
import DownloadIcon from '../../../assets/icons/Integrations/DownloadIcon';
import KeyboardArrowRight from '@mui/icons-material/KeyboardArrowRight';
import KeyboardArrowLeft from '@mui/icons-material/KeyboardArrowLeft';
import FirstPageIcon from '@mui/icons-material/FirstPage';
import LastPageIcon from '@mui/icons-material/LastPage';

// Material Ui
import { Box, IconButton, Table, TableBody, TableCell, TableFooter, TableHead, TablePagination, TableRow } from '@mui/material';
import { useTheme } from '@mui/material/styles';

// PaymentProvider
import { ProviderPaymentList } from './ProviderPaymentList';

// CSS
import Styles from './BillingsTable.module.css';

const mapMPStatusToText = (status) => {
    switch (status) {
        case 'approved':
            return 'Aprobado';
        case 'pending':
            return 'Pendiente';
            case 'in_process':
            return 'En proceso';
        case 'rejected':
            return 'Rechazado';
        case 'cancelled':
            return 'Cancelado';
        case 'refunded':
            return 'Reembolsado';
        case 'charged_back':
            return 'Reclamado';
        case 'in_mediation':
            return 'En mediación';
        default:
            return 'Desconocido';
    }
};

function TablePaginationActions(props) {
    const { count, page, rowsPerPage, onPageChange } = props;
    const theme = useTheme();
    
    const handleFirstPageButtonClick = (event) => {
        onPageChange(event, 0);
    };
    
    const handleBackButtonClick = (event) => {
        onPageChange(event, page - 1);
    };
    
    const handleNextButtonClick = (event) => {
        onPageChange(event, page + 1);
    };
    
    const handleLastPageButtonClick = (event) => {
        onPageChange(event, Math.max(0, Math.ceil(count / rowsPerPage) - 1));
    };
    
    return (
        <Box sx={{ flexShrink: 0, ml: 2.5, borderRadius: '0px 0px 10px 10px' }}>
            <IconButton
                onClick={handleFirstPageButtonClick}
                disabled={page === 0}
                aria-label="first page"
            >
                {theme.direction === 'rtl' ? <LastPageIcon /> : <FirstPageIcon />}
            </IconButton>
            <IconButton
                onClick={handleBackButtonClick}
                disabled={page === 0}
                aria-label="previous page"
            >
                {theme.direction === 'rtl' ? <KeyboardArrowRight /> : <KeyboardArrowLeft />}
            </IconButton>
            <IconButton
                onClick={handleNextButtonClick}
                disabled={page >= Math.ceil(count / rowsPerPage) - 1}
                aria-label="next page"
            >
                {theme.direction === 'rtl' ? <KeyboardArrowLeft /> : <KeyboardArrowRight />}
            </IconButton>
            <IconButton
                onClick={handleLastPageButtonClick}
                disabled={page >= Math.ceil(count / rowsPerPage) - 1}
                aria-label="last page"
            >
                {theme.direction === 'rtl' ? <FirstPageIcon /> : <LastPageIcon />}
            </IconButton>
        </Box>
    );
}

TablePaginationActions.propTypes = {
    count: PropTypes.number.isRequired,
    onPageChange: PropTypes.func.isRequired,
    page: PropTypes.number.isRequired,
    rowsPerPage: PropTypes.number.isRequired,
};

const TableStyles = { 
    backgroundColor: 'white',
    borderRadius: '10px',
    minWidth: 1000,
}

const TableHeadStyles = {
    backgroundColor: 'white',
    fontFamily: 'Poppins'
}

const TableCellStyle = {
    fontFamily: 'Poppins',
    fontWeight: '500',
    color: '#808080',
    fontSize: '1rem'
}

const BillingsTable = ( props ) => {

    // Externals props
    const { rowsBack } = props;

    const [rowsPerPage, setRowsPerPage] = useState(5);
    const [page, setPage] = useState(0);

    const emptyRows = page > 0 ? Math.max(0, (1 + page) * rowsPerPage - rowsBack.length) : 0;

    const handleChangePage = ( event, newPage ) => {
        setPage(newPage);
    };

    const handleChangeRowsPerPage = (event) => {
        setRowsPerPage(parseInt(event.target.value, 10));
        setPage(0);
    };

    const handleShowCardIcon = ( paymentMethod ) => {
        for (let i = 0; i < ProviderPaymentList.length; i++) {
            if ( paymentMethod === ProviderPaymentList[i].code ) {
                return ProviderPaymentList[i].icon;
            }
        }
    }

    return (
        <Box sx={{ borderRadius: '10px', minWidth: 1000 }} >
            <Table sx={ TableStyles } aria-label="custom pagination table" >
                <TableHead sx={ TableHeadStyles } >
                    <TableRow>
                        <TableCell 
                            align="center" 
                            sx={{ 
                                borderRadius: '20px 0px 0px 0px', 
                                color: '#041C32', 
                                fontWeight: '600', 
                                fontFamily: 'Poppins',
                                fontSize: '1.1rem' 
                            }}
                        >Período de facturación</TableCell>
                        <TableCell 
                            align="center" 
                            sx={{ 
                                fontWeight: '600', 
                                fontFamily: 'Poppins',
                                fontSize: '1.1rem' 
                            }}
                        >Total facturado</TableCell>
                        <TableCell 
                            align="center" 
                            sx={{ 
                                fontWeight: '600', 
                                fontFamily: 'Poppins',
                                fontSize: '1.1rem' 
                            }}
                        >Método de pago</TableCell>
                        <TableCell 
                            align="center" 
                            sx={{ 
                                fontWeight: '600', 
                                fontFamily: 'Poppins',
                                fontSize: '1.1rem' 
                            }}
                        >Estado del pago</TableCell>
                        <TableCell 
                            align="center" 
                            sx={{ 
                                borderRadius: '0px 20px 0px 0px', 
                                color: '#041C32', 
                                fontWeight: '600', 
                                fontFamily: 'Poppins',
                                fontSize: '1.1rem' 
                            }} 
                        >Descargar</TableCell>
                    </TableRow>
                </TableHead>
                <TableBody>
                    {(rowsPerPage > 0
                        ? rowsBack.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                        : rowsBack
                    ).map((row, index) => (
                        <TableRow key={index}>
                            <TableCell 
                                align="center"
                                sx={ TableCellStyle }
                            >
                                { new Date( row.from ).toLocaleDateString() } - { new Date( row.to ).toLocaleDateString() }
                            </TableCell>
                            <TableCell 
                                align="center"
                                sx={ TableCellStyle }
                            >${ row.amount }</TableCell>
                            <TableCell 
                                align="center" 
                                sx={ TableCellStyle }
                            >
                                <div className={ Styles.PaymentMethodRow }>
                                    {
                                        handleShowCardIcon( row.paymentMethodId )
                                    }
                                    {row.lastFourDigits && <p>...{ row.lastFourDigits }</p>}
                                </div>
                            </TableCell>
                            <TableCell 
                                align="center"
                                sx={{
                                    color: row.paymentStatus === "approved" ? '#35C18F' : row.paymentStatus === 'pending' ? '#848282' : '#EB252E', 
                                    fontFamily: 'Poppins',
                                    fontWeight: '500',
                                }}
                            >
                                { mapMPStatusToText( row.paymentStatus ) } 
                            </TableCell>
                            <TableCell 
                                align="center"
                            >
                                <DownloadIcon href={ row.invoiceUrl } />
                            </TableCell>
                        </TableRow>
                    ))}

                    { emptyRows > 0 && (
                        <TableRow style={{ height: 53 * emptyRows }}>
                        <TableCell colSpan={6} />
                        </TableRow>
                    )}
                </TableBody>
                <TableFooter>
                    <TableRow>
                        <TablePagination
                            rowsPerPageOptions={[ 5 ]}
                            colSpan={6}
                            count={ rowsBack.length }
                            rowsPerPage={ rowsPerPage }
                            page={ page }
                            SelectProps={{
                                inputProps: {
                                    'aria-label': 'rows per page',
                                },
                                native: true,
                            }}
                            onPageChange={handleChangePage}
                            onRowsPerPageChange={handleChangeRowsPerPage}
                            ActionsComponent={TablePaginationActions}
                            sx={{
                                '.MuiTablePagination-displayedRows': {
                                    fontFamily: 'Poppins',
                                    fontWeight: '500'
                                }
                            }}
                        />
                    </TableRow>
                </TableFooter>
            </Table>
        </Box>
    )
}

export default BillingsTable