import React from 'react';

const VisaCreditoIcon = () => {
    return (
        <svg width="80" height="50" viewBox="0 0 60 40" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M56.6908 0.725586H3.06437C1.37196 0.725586 0 2.09755 0 3.78995V35.9658C0 37.6582 1.37196 39.0301 3.06436 39.0301H56.6907C58.3832 39.0301 59.7551 37.6582 59.7551 35.9658V3.78995C59.7551 2.09755 58.3832 0.725586 56.6908 0.725586Z" fill="#11286E"/>
            <path d="M39.7141 13.8076C38.7107 13.442 37.65 13.2585 36.5821 13.2658C33.1284 13.2658 30.6892 15.0056 30.672 17.5031C30.6504 19.3378 32.4053 20.3718 33.7328 20.9848C35.0948 21.6151 35.5503 22.0123 35.546 22.5757C35.5373 23.4326 34.458 23.8211 33.4543 23.8211C32.075 23.8211 31.3217 23.629 30.1625 23.1477L29.733 22.9469L29.243 25.8221C30.0827 26.1718 31.5958 26.4718 33.1586 26.4977C36.8303 26.4977 39.2306 24.7687 39.2587 22.1116C39.2889 20.6459 38.3391 19.5386 36.3403 18.6234C35.125 18.0298 34.3717 17.6326 34.3717 17.0303C34.3717 16.4972 35.0171 15.9273 36.3662 15.9273C37.2641 15.9056 38.1564 16.0741 38.9845 16.4216L39.3083 16.5663L39.7983 13.7882L39.7141 13.8076ZM48.6764 13.499H45.9782C45.1385 13.499 44.5061 13.7299 44.1391 14.5631L38.95 26.3186H42.6217L43.3556 24.391L47.8367 24.3953C47.9425 24.8464 48.2663 26.3186 48.2663 26.3186H51.5041L48.6764 13.499ZM25.7051 13.391H29.2041L27.0154 26.2171H23.5207L25.7051 13.3867V13.391ZM16.8227 20.4603L17.1853 22.2411L20.6045 13.499H24.3107L18.8043 26.3013H15.1067L12.0847 15.4611C12.0357 15.2823 11.9208 15.1288 11.7631 15.0315C10.6739 14.4689 9.51979 14.0419 8.32666 13.7601L8.37415 13.4903H14.0058C14.7699 13.5205 15.3851 13.7601 15.5902 14.5761L16.8205 20.4668V20.4603H16.8227ZM44.3593 21.7684L45.7537 18.1808C45.7365 18.2197 46.0408 17.4405 46.2178 16.9591L46.4574 18.0664L47.2669 21.7662H44.3571V21.7684H44.3593Z" fill="white"/>
        </svg>
    )
}

export default VisaCreditoIcon;