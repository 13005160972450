import React, { useEffect, useState } from 'react';
import useFetch from 'use-http';

// Configuration
import { MAIN_API_URL } from '../../../configuration';

// Recharts
import { AreaChart, Area, XAxis, YAxis, CartesianGrid, Tooltip, ResponsiveContainer } from 'recharts';

// CSS
import Styles from './PerformanceComponent.module.css';
import { useMediaQuery } from '@mui/material';
import { connect } from 'react-redux';
import { translateLabels } from '../../../helpers/translateLabels';

const PerformanceComponent = (props) => {

    const { error, get, response } = useFetch(`${MAIN_API_URL}/stats`, {
        headers: {
            "x-token": `${localStorage.getItem("token")}`
        }
    });

    const { startDate, endDate } = props;

    const [data, setData] = useState([]);

    const matches = useMediaQuery('(min-width: 500px)');

    useEffect(() => { getStatsClicks(); }, [startDate, endDate]);

    const getStatsClicks = async () => {
        const resp = await get("/clicks?startDate=" + startDate + "&endDate=" + endDate);
        if (response.ok) {
                const values = resp.data.values;
                const d = [];
                const labelsInFormat = translateLabels(resp.data.labelsValues, resp.data.groupedBy, 'es');
                for (let i = 0; i < values.length; i++) {

                    const newData = {
                        Name: labelsInFormat[i],
                        Clicks: values[i].y
                    };

                    d.push(newData);
                }

                setData([...d]);
        } else {
            console.log(error);
        }
    }

    return (
        <div className={Styles.PerformanceContainer}>
            <div className={Styles.TopSection}>
                <h1>Clientes atendidos</h1>
                {/* <PerformanceMenu /> */}
            </div>
            <div className={Styles.BottomSection}>
                <ResponsiveContainer minWidth={350}>
                    <AreaChart
                        data={data}
                        margin={{
                            left: matches ? 0 : -16,
                            right: 30,
                            bottom: 0,
                            top: 10,
                        }}
                        // display all the x axis labels
                    >
                        <defs>
                            <linearGradient id="colorUv" x1="0" y1="0" x2="0" y2="1">
                                <stop offset="5%" stopColor="#2ED588" stopOpacity={0.8} />
                                <stop offset="95%" stopColor="#2ED588" stopOpacity={0} />
                            </linearGradient>
                        </defs>
                        <CartesianGrid strokeDasharray="3 3" />
                        <XAxis dataKey="Name" />
                        <YAxis />
                        <Tooltip />
                        <Area type="monotone" dataKey="Clicks" stroke="#2ED588" fillOpacity={1} fill="url(#colorUv)" />
                    </AreaChart>
                </ResponsiveContainer>
            </div>
        </div>
    )
}

const mapStateToProps = state => {
    return {
        startDate: state.employees.filterDates.startDate,
        endDate: state.employees.filterDates.endDate,
    }
}

export default connect(mapStateToProps, null)(PerformanceComponent);
