import { BrowserRouter, Route, Routes } from "react-router-dom";

// Components
import GuardRoute from "./components/GuardRoute/Index";
import AdminRoutes from "./routes/AdminRoutes";
import Chat from "./components/ChatFrame";

// Intro.js
import "intro.js/introjs.css";

// CSS
import "./App.css";
import { useMediaQuery, useTheme } from "@mui/material";
import { useEffect } from "react";
import WelcomeQuestionsScreen from "./pages/WelcomeQuestionsScreen/WelcomeQuestionsScreen";
import { PendingSynchronizeView } from "./pages/Syncronize/SyncronizePending";
import { FailureSynchronizeView } from "./pages/Syncronize/SyncronizeFailure";
import { AuthCheck } from "./pages/Auth/AuthCheck";
import { ErrorScreen } from "./pages/ErrorScreen/ErrorScreen";
import { AuthCheckPlatform } from "./pages/Auth/AuthCheckPlatform";
import { Login } from "./pages/Auth/Login";
import { Register } from "./pages/Auth/Register";
import { PasswordReset } from "./pages/Auth/PasswordReset";
import { NewPassword } from "./pages/Auth/NewPassword";
import { PasswordRecoverySent } from "./pages/Auth/PasswordRecoverySent";
import { PasswordRecoveryNotValidToken } from "./pages/Auth/PasswordRecoveryNotValidToken";
import { PasswordSuccessRecovery } from "./pages/Auth/PasswordSuccessRecovery";
import { connect } from "react-redux";
// import LoaderFlowy from "./components/Loader/LoaderFlowy/LoaderFlowy";

function App(props) {
  // const { loading } = props;
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down("sm"));
  useEffect(() => {
    if (!isMobile) {
      const script = document.createElement("script");
      script.src =
        "https://whatspro-kitbfcj7yq-rj.a.run.app/api/script/particular/onLoad.js?store=878as38gc";
      script.async = true;
      document.body.appendChild(script);
    }
  }, []);

  // useEffect(() => {
  //   if(loading) return (<LoaderFlowy/>)
  // }, [loading]);

  return (
    <BrowserRouter>
      <Routes>
        <Route element={<GuardRoute redirectTo="/home" type="public" />}>
          <Route path="/" element={<Login />} />
          <Route path="/password_reset" element={<PasswordReset />} />
          <Route path="/password_reset/token" element={<NewPassword />} />
          <Route
            path="/password_reset/email_sent/:mail"
            element={<PasswordRecoverySent />}
          />
          <Route
            path="/password_reset/not_valid_token"
            element={<PasswordRecoveryNotValidToken />}
          />
          <Route
            path="/password_reset/success_recovery"
            element={<PasswordSuccessRecovery />}
          />
          <Route path="/register" element={<Register />} />
          <Route path="/welcome" element={<WelcomeQuestionsScreen />} />
        </Route>
        <Route
          path="auth/syncronize_pending/:platform"
          element={<PendingSynchronizeView />}
        />
        <Route
          path="auth/syncronize_failure/:platform"
          element={<FailureSynchronizeView />}
        />
        <Route path="/auth/check" element={<AuthCheck />} />
        <Route path="/auth/check/platform" element={<AuthCheckPlatform />} />
        <Route path="/error" element={<ErrorScreen />} />
        <Route path="/chat_from_script" element={<Chat />} />
        <Route element={<GuardRoute redirectTo="/" type="private" />}>
          <Route path="/*" element={<AdminRoutes />} />
        </Route>
      </Routes>
    </BrowserRouter>
  );
}

const mapStateToProps = state => {
  return {
    loading: state.auth.loading,
  }
}

export default connect(mapStateToProps, null)(App);

