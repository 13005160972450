import { Box, Typography } from '@mui/material'
import React from 'react'
import { primaryColor } from '../../../constants'
import { useNavigate } from 'react-router-dom';

export const PasswordRecoveryNotValidTokenComponent = () => {
    const navigate = useNavigate();
    const handleBackClick = () => {
        navigate('/');
    }
    return (
        <>
            <Typography variant={'h4'} sx={{ fontSize: '1.5rem', fontWeight: 400 }}>
                Enlace caducado
            </Typography>
            <Typography variant={'subtitle1'} sx={{ fontSize: '0.9rem', fontWeight: 400 }}>
                Para restablecer su contraseña, por favor vuelva hacia la página de inicio de sesión y seleccione "¿Olvidó su contraseña?" para enviar un nuevo email.
            </Typography>
            <Box
                display={'flex'}
                flexDirection={'column'}
                justifyContent={'center'}
                alignItems={'flex-start'}
                gap={1.5}
                sx={{
                    width: '100%',
                    padding: '5px 20px',
                }}
            >
                <Typography variant={'subtitle1'} sx={{ fontSize: '0.9rem', fontWeight: 400, cursor: 'pointer', alignSelf: 'center' }}>
                    <span
                        style={{ color: primaryColor, fontWeight: 500 }}
                        onClick={handleBackClick}
                    >
                        Volver
                    </span>
                </Typography>
            </Box>
        </>
    )
}
