import React from 'react';

const BillingsIcon = () => {
    return (
        <div style={{ marginRight: '12px', display: 'flex', alignItems: 'center' }}>
            <svg width="22" height="17" viewBox="0 0 22 17" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path d="M19.621 0C20.9313 0 22 1.04569 22 2.33102V13.682C22 14.9883 20.9541 16.013 19.621 16.013H2.37901C1.04587 16.013 0 14.9882 0 13.682V2.33102C0 1.04569 1.06867 0 2.37901 0H19.621ZM19.9751 7.06173H2.02419L2.02454 13.678C2.02454 13.8884 2.20788 13.9842 2.37863 13.9842H19.6206C19.7913 13.9842 19.9747 13.8884 19.9747 13.678L19.9751 7.06173ZM9.6344 9.89084C10.1946 9.89084 10.6468 10.3428 10.6468 10.9032C10.6468 11.4636 10.1926 11.9156 9.6344 11.9156H4.7825C4.22226 11.9156 3.77013 11.4636 3.77013 10.9032C3.77013 10.3428 4.22226 9.89084 4.7825 9.89084H9.6344ZM19.6211 2.02076H2.37909C2.17917 2.02076 2.025 2.18546 2.025 2.32702L2.02465 5.03716H19.9755V2.32702C19.9755 2.18546 19.821 2.02076 19.6211 2.02076Z" fill="#2ED588"/>
            </svg>
        </div>
    )
}

export default BillingsIcon;