import { useRef } from 'react';
import { connect } from 'react-redux';

// Components
import AdvancedScreenTexts from '../../CreateUser/Advanced/AdvancedTexts';
import TextsStep1 from '../../CreateUser/Step1/TextsStep1';
import TextsStep2 from '../../CreateUser/Step2/TextsStep2';
import TextsStep3 from '../../CreateUser/Step3/TextsStep3';

// Helpers
import { useResize } from '../../../helpers/useResize';

// Icon
import CloseOutlinedIcon from '@mui/icons-material/CloseOutlined';

// Material Ui
import { Box, Drawer } from '@mui/material';

const DrawerStyles = {
    '.MuiModal-root .MuiDrawer-root': {
        width: '325px !important',
        zIndex: '0',
    },
    '.MuiDrawer-root': {
        width: '325px',
        zIndex: '0',
    },
    '.MuiPaper-root': {
        width: '325px',
        zIndex: '1',
    },
    '.MuiDrawer-paper': {
        padding: '1rem 2rem 2rem 2rem',
        boxSizing: 'border-box',
        width: '100%',
        '@media(min-width: 350px)': {
            maxWidth: '380px',
            width: '325px',
        }
    },
}

const CrossContainer = {
    position: 'absolute',
    cursor: 'pointer',
    top: '.5rem',
    left: '3%',
}

const ResizeLine = {
    tranform: 'translate(-50%, -50%)',
    padding: '0 .2rem 0 .1rem',
    position: 'absolute',
    cursor: 'col-resize',
    alignItems: 'center',
    display: 'flex',
    left: '.3rem',
    top: '50%',
}

const LineResize = {
    backgroundColor: '#616161',
    height: '100px',
    width: '2px',
}

const DrawerCreateUser = ( props ) => {

    // External props
    const { open, toggleDrawer } = props;

    // States
    const { activeStep } = props;

    const resizeDrawer = useRef();

    const options = {
        step: 10,
        axis: 'horizontal'
    }

    const showTextsStep = () => {
        switch (activeStep) {
            case 0:
                return <TextsStep1/>;
            case 1:
                return <TextsStep2/>;
            case 2:
                return <TextsStep3/>;
            case 3:
                return <AdvancedScreenTexts/>;
            default:
                break;
        }
    }

    const { initResize } = useResize(resizeDrawer, options);

    return (
        <Drawer
            onClose={toggleDrawer(false)}
            ref={ resizeDrawer }
            hideBackdrop={true}
            sx={ DrawerStyles }
            anchor={'right'}
            open={open}
            ModalProps = {{
                disableEnforceFocus: true
            }}
        >
            <Box sx={ CrossContainer } onClick={ toggleDrawer(false) }>
                <CloseOutlinedIcon
                    sx={{
                        color: '#393939 !important',
                        height: '2rem !important',
                        marginRight: '.5rem',
                        fontSize: '1.2rem',
                    }}
                />
            </Box>
            <Box sx={{ userSelect: 'none' }}>
                { showTextsStep() }
            </Box>
            <Box 
                sx={ ResizeLine }
                onMouseDown={initResize}
            >
                <Box sx={ LineResize } ></Box>
            </Box>
        </Drawer>
    )
}

const mapStateToProps = state => {
    return {
        activeStep: state.stepsUser.activeStep,
    }
}

export default connect(mapStateToProps, null)(DrawerCreateUser);