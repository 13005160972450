import { Box, Slide } from '@mui/material'
import React from 'react'
import './../threedots.css'

export const Loading = ({ headerColour }) => {
    return (
        <Slide direction="up" in={true} mountOnEnter unmountOnExit>
        <Box
            sx={{
                background: '#EEEEEE',
                borderRadius: '14px',
                width: '15%',
                padding: '20px 20px',
                alignSelf: 'flex-start',
                cursor: 'pointer',
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center',

            }}
        >
            <Box
                className='dot-flashing'
                sx={{
                    color: headerColour,
                    backgroundColor: headerColour,
                    '&::before': {
                        color: headerColour,
                        backgroundColor: headerColour,
                        animation: 'myDotFlashing 1s infinite alternate',
                        animationDelay: '0s',
                    },
                    '&::after': {
                        color: headerColour,
                        backgroundColor: headerColour,
                        animation: 'myDotFlashing 1s infinite alternate',
                        animationDelay: '1s',
                    },
                    animation: 'myDotFlashing 1s infinite alternate',
                    animationDelay: '.5s',
                    "@keyframes myDotFlashing": {
                        "0%": {
                            backgroundColor: headerColour,
                        },
                        "50%": {
                            backgroundColor: '#DADADA',
                        },
                        "100%": {
                            backgroundColor: '#DADADA',
                        }
                      },
                }}
            >
            </Box>
        </Box>
        </Slide>
    )
}
