import React from 'react';

// Material Ui
import { IconButton, Snackbar } from '@mui/material';

// Icon
import CloseIcon from '@mui/icons-material/Close';

const SnackbarAdvanced = ({ handleClose, open, success }) => {

    const snackbarStyles = {
        backgroundColor: success ? '#2ED588' : '#FF7474',
        borderRadius: '8px',
        color: 'white',
        '.MuiPaper-root': {
            backgroundColor: success ? '#2ED588' : '#FF7474',
            fontFamily: 'Poppins',
            borderRadius: '8px',
            fontWeight: '500'
        }
    }

    const action = (
        <React.Fragment>
            <IconButton
                size="small"
                aria-label="close"
                color="inherit"
                onClick={ handleClose }
                sx={{ backgroundColor: success ? '#14C774' : '#EB5757' }}
            >
                <CloseIcon fontSize="small" />
            </IconButton>
        </React.Fragment>
    );

    return (
        <Snackbar
            open={ open }
            autoHideDuration={ 3000 }
            anchorOrigin={{ 
                vertical: "bottom", 
                horizontal:"right" 
            }}
            onClose={ handleClose }
            message={ success ? "Se guardaron tus cambios" : "Error" }
            action={ action }
            sx={ snackbarStyles }
        />
    )
}

export default SnackbarAdvanced;