import React from 'react'
import { LayoutAuth } from '../../layout/LayoutAuth'
import { PasswordSuccessRecoveryComponent } from '../../components/Auth/views/PasswordSuccessRecoveryComponent'

export const PasswordSuccessRecovery = () => {
  return (
    <LayoutAuth>
        <PasswordSuccessRecoveryComponent/>
    </LayoutAuth>
  )
}
