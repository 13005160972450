import React, { useEffect, useRef, useState } from 'react';
import { connect } from "react-redux";

// Actions
import { setColorsChat } from '../../../actions/stepsChat';

// Components
import SelectColorModal from '../../Modals/SelectColorsChat/SelectColorModal';

// Icon
import ModeEditOutlinedIcon from '@mui/icons-material/ModeEditOutlined';
import CheckColors from '../../../assets/images/Chat/CheckColors';

// Material Ui
import { useMediaQuery } from '@mui/material';

// CSS
import Styles from './SelectColors.module.css';

const SelectColors = (props) => {

    // Actions
    const { setColorsChat } = props;

    // State
    const { backColorState, fontColorState } = props;

    const arrayColors = ['#2ED588', '#E672DF', '#FFBC88', '#E7556A', '#316499'];
    const arrayPredColors = [
        {
            back: '#2ED588',
            font: '#8C8C8C'
        }, 
        {
            back: '#E672DF',
            font: '#FE7CBA'
        }, 
        {
            back: '#FFBC88',
            font: '#FF9798'
        }, 
        {
            back: '#E7556A',
            font: '#9B354B'
        }, 
        {
            back: '#316499',
            font: '#152449'
        }
    ];

    const [openModal, setOpenModal] = useState(false);
    const [typeModal, setTypeModal] = useState('');

    const matches = useMediaQuery('min-width: 640px');
    const colorContainer = useRef(null);
    const backContainer = useRef(null);

    useEffect(() => {
        if (!matches) {
            colorContainer.current.scrollLeft += 10;
            backContainer.current.scrollLeft += 10;
        }
    }, [colorContainer, backContainer, matches]);

    const handleSetBackColor = (e) => {
        if (e.target.id) {
            setColorsChat({
                backColor: e.target.id,
                colorFont: '#2ED588'
            });
        } else {
            setColorsChat({
                backColor: e.target.id,
                colorFont: 'white'
            });
        }
    }
    
    const handleSetColors = (back, font) => {
        setColorsChat({
            backColor: back,
            colorFont: font
        });
    }

    const handleOpenModal = ( type ) => {
        setOpenModal(!openModal);
        setTypeModal(type);
    }
    
    return (
        <section className={Styles.SelectColorsContainer}>
            <h2>Paletas full color</h2>
            <div className={Styles.PalletsColorsContainer} ref={ backContainer }>
                <div className={Styles.LinearGradientContainer}></div>
                <div className={Styles.FullColorsContainer}>
                    <div 
                        className={ Styles.ColorPreviewEdit }
                        onClick={ () => handleOpenModal('full') }
                    >
                        <ModeEditOutlinedIcon
                            sx={{ 
                                height: '2rem !important', 
                                width: '2rem !important', 
                                color: '#BFBBBD', 
                                fontSize: '2rem', 
                            }}
                        />
                    </div>
                    {
                        arrayColors.map((color) => (
                            <div 
                                style={{
                                    boxShadow: (color === backColorState && fontColorState === '#2ED588') ? '0px 4px 4px rgba(0, 0, 0, 0.25)' : 'none', 
                                    backgroundColor: `${color}` 
                                }} 
                                className={ Styles.ColorPreview }
                                onClick={ handleSetBackColor }
                                key={color}
                                id={color}
                            >
                                { (backColorState === color && fontColorState === '#2ED588') ? <CheckColors/> : ''}
                            </div>
                        ))
                    }
                </div>
            </div>
            <h2>Paletas predeterminadas</h2>
            <div className={Styles.PalletsColorsContainer} ref={ colorContainer }>
                <div className={Styles.LinearGradient2Container}></div>
                <div className={Styles.PredColorsContainer}>
                    <div className={Styles.PalletContainerEdit} onClick={ () => handleOpenModal('Pred') }>
                        <div className={Styles.BackColorContainerEdit}>
                            <ModeEditOutlinedIcon
                                sx={{ 
                                    height: '2rem !important', 
                                    width: '2rem !important', 
                                    color: '#BFBBBD',
                                    fontSize: '2rem'
                                }}
                            />
                        </div>
                    </div>
                    {
                        arrayPredColors.map(({back, font, index}) => (
                            <div 
                                key={font} 
                                className={Styles.PalletContainer} 
                                style={{
                                    boxShadow: (back === backColorState && font === fontColorState) ? '0px 4px 4px rgba(0, 0, 0, 0.25)' : 'none', 
                                    backgroundColor: font 
                                }}
                                onClick={ () => handleSetColors( back, font ) }
                                id={`pallet-color-${index}`}
                            >
                                <div 
                                    id={`pallet-color-${index}`} 
                                    key={font} 
                                    className={ Styles.BackColorContainer } 
                                    style={{ backgroundColor: back }}
                                >
                                    { ( back === backColorState && font === fontColorState ) ? <CheckColors/> : ''}
                                </div>
                            </div>
                        ))
                    }
                </div>
            </div>
            <SelectColorModal
                open={ openModal }
                handleClose={ handleOpenModal }
                type={ typeModal }
            />
        </section>
    )
}

const mapStateToProps = state => {
    return {
        backColorState: state.stepsChat.backColor,
        fontColorState: state.stepsChat.colorFont,
    }
}

const mapDispatchToProps = dispatch => {
    return {
        setColorsChat: (value) => dispatch(setColorsChat(value)),
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(SelectColors);




