import { typesUsers, globalsTypes } from "../types/types";
import moment from "moment";

const initialState = {
    employees: [],
    newUser: false,
    editUser: false,
    filterDates: {
        startDate: moment().startOf('month').format('YYYY-MM-DD'),
        endDate: moment().endOf('month').format('YYYY-MM-DD'),
    }
}

export const usersReducer = (state = initialState, action) => {
    switch (action.type) {
        case typesUsers.getUser:
            return {
                ...state,
                employees: action.payload,
            }
        case typesUsers.getEditUser:
            return {
                ...state,
                editUser: action.payload.editUser ?? state.userData.editUser,
            }
        case typesUsers.changeNewUserState:
            return {
                ...state,
                newUser: action.payload.newUser ?? state.userData.newUser,
            }
        case globalsTypes.changeDateRangeState:
            return {
                ...state,
                filterDates: {
                    ...state.filterDates,
                    startDate: action.payload.startDate ?? state.filterDates.startDate,
                    endDate: action.payload.endDate ?? state.filterDates.endDate,
                }
            }
        default:
            return state
    }
}