import React, { useEffect } from 'react'
import { Box, Typography } from '@mui/material'
import { OrComponent } from '../components/OrComponent';
// import { SocialLogin } from './SocialLogin';
import { PlatformLogin } from '../components/PlatformLogin';
import { PlatformList } from '../../../helpers/platformList';
import { UserPasswordRegister } from '../components/UserPasswordRegister';
import SocialLogin from '../components/SocialLogin';

const platformLoginOrder = PlatformList.filter(p => p.hasLoginIntegration).map((platform) => {
  return {
    name: platform.name,
    type: 'platform',
    component: <PlatformLogin
      {...platform}
    />,
  }
})

const regularLoginOrder = [
  {
    name: 'UserPasswordLogin',
    component: <UserPasswordRegister />,
    type: 'userpassword'
  },
  {
    name: 'OrComponent',
    component: <OrComponent />,
  },
  {
    name: 'SocialLogin',
    component: <SocialLogin />,
    type: 'social'
  },
  ...platformLoginOrder,
]

export const RegisterComponent = () => {
  const query = new URLSearchParams(window.location.search);
  const [loginOrder, setLoginOrder] = React.useState([]);

  useEffect(() => {
    const platform = query.get('platform');
    if (platform) {
      const platformLogin = PlatformList.find(p => p.name === platform && p.hasLoginIntegration);
      if (platformLogin) {
        return setLoginOrder([
          {
            name: platformLogin.name,
            component: <PlatformLogin
              {...platformLogin}
            />,
          },
          {
            name: 'OrComponent',
            component: <OrComponent />,
          },
          {
            name: 'UserPasswordLogin',
            component: <UserPasswordRegister />,
          },
          {
            name: 'SocialLogin',
            component: <SocialLogin />,
          }
        ])
      } else {
        if (!regularLoginOrder.find(l => l.type === 'platform')) {
          return setLoginOrder([
            {
              name: 'UserPasswordLogin',
              component: <UserPasswordRegister />,
            },
            {
              name: 'OrComponent',
              component: <OrComponent />,
            },
            {
              name: 'SocialLogin',
              component: <SocialLogin />,
            }
          ])
        } else {
          return setLoginOrder(regularLoginOrder);
        }
      }
    } else {
      if (!regularLoginOrder.find(l => l.type === 'platform')) {
        return setLoginOrder([
          {
            name: 'UserPasswordLogin',
            component: <UserPasswordRegister />,
          },
        ])
      } else {
        return setLoginOrder(regularLoginOrder);
      }
    }
  }, [])




  return (
    <>
      <Typography variant={'h4'} sx={{ fontSize: '1.5rem', fontWeight: 400 }}>
        Bienvenido
      </Typography>
      <Typography variant={'subtitle1'} sx={{ fontSize: '0.9rem', fontWeight: 400 }}>
        Registrate para continuar hacia WhatsPRO
      </Typography>
      <Box
        display={'flex'}
        flexDirection={'column'}
        justifyContent={'center'}
        alignItems={'flex-start'}
        gap={1.5}
        sx={{
          width: '100%',
          padding: '5px 20px',
        }}
      >
        {
          loginOrder.map((component, index) => {
            return (
              <>
                {component.component}
              </>
            )
          })
        }
      </Box>
    </>
  )
}
