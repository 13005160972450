const Task4Icon = ({ color, fontColor }) => {
    return (
        <svg width="52" height="52" viewBox="0 0 52 52" fill="none" xmlns="http://www.w3.org/2000/svg">
            <rect width="52" height="52" rx="10.5507" fill={ color }/>
            <path d="M26.0155 11C25.279 11 24.6817 11.5973 24.6817 12.3338V30.0768L21.0969 26.7066C20.5602 26.202 19.716 26.2282 19.2116 26.7649C18.707 27.3015 18.7332 28.1458 19.2699 28.6501L25.1022 34.1329C25.3589 34.3743 25.6873 34.4949 26.0157 34.4949C26.3441 34.4949 26.6726 34.3743 26.9292 34.1329L32.7616 28.6501C33.2983 28.1455 33.3245 27.3016 32.8198 26.7649C32.3151 26.2282 31.4712 26.2022 30.9345 26.7066L27.3492 30.0768V12.3338C27.3492 11.5973 26.7519 11 26.0154 11H26.0155Z" fill={ fontColor }/>
            <path d="M36.7316 14.1133H32.974C32.2375 14.1133 31.6402 14.7106 31.6402 15.4471C31.6402 16.1836 32.2375 16.7809 32.974 16.7809H36.7316C37.6306 16.7809 38.3617 17.5121 38.3617 18.411V35.8756C38.3617 36.7746 37.6305 37.5057 36.7316 37.5057H15.2977C14.3987 37.5057 13.6676 36.7745 13.6676 35.8756V18.411C13.6676 17.512 14.3988 16.7809 15.2977 16.7809H19.0553C19.7918 16.7809 20.3892 16.1836 20.3892 15.4471C20.3892 14.7106 19.7919 14.1133 19.0553 14.1133H15.2977C12.9279 14.1133 11 16.0412 11 18.411V35.8756C11 38.2454 12.9279 40.1733 15.2977 40.1733H36.7316C39.1014 40.1733 41.0293 38.2454 41.0293 35.8756V18.411C41.0293 16.0412 39.1015 14.1133 36.7316 14.1133Z" fill={ fontColor }/>
        </svg>
    )
}

export default Task4Icon