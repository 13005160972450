import React from 'react'
import { LayoutAuth } from '../../layout/LayoutAuth'
import { PasswordResetComponent } from '../../components/Auth/views/PasswordResetComponent'

export const PasswordReset = () => {
  return (
    <LayoutAuth>
        <PasswordResetComponent/>
    </LayoutAuth>
  )
}
