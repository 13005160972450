import React from 'react';

const GreeUser = () => {
    const vw = Math.max(document.documentElement.clientWidth || 0, window.innerWidth || 0);
    return (
        <div 
            style={{ 
                width: vw < 900 ? '90%' : '100%',
                alignItems: 'center', 
                display: 'flex', 
            }}
        >
            <svg width="73" height="73" viewBox="0 0 93 93" fill="none" xmlns="http://www.w3.org/2000/svg">
                <circle cx="46.1474" cy="46.2608" r="44.5986" stroke="#2ED588" strokeWidth="3"/>
                <path d="M64.2435 59.3996C63.2584 57.0662 61.8288 54.9467 60.0345 53.1592C58.2456 51.3665 56.1264 49.9372 53.7941 48.9502C53.7732 48.9397 53.7523 48.9345 53.7314 48.924C56.9848 46.5741 59.0997 42.7463 59.0997 38.4276C59.0997 31.2733 53.3032 25.4768 46.1489 25.4768C38.9946 25.4768 33.1981 31.2733 33.1981 38.4276C33.1981 42.7463 35.313 46.5741 38.5664 48.9293C38.5455 48.9397 38.5246 48.9449 38.5038 48.9554C36.1642 49.9424 34.065 51.3575 32.2633 53.1644C30.4706 54.9533 29.0413 57.0725 28.0543 59.4048C27.0847 61.6882 26.5618 64.1363 26.5138 66.6165C26.5124 66.6723 26.5222 66.7277 26.5426 66.7796C26.5629 66.8315 26.5935 66.8788 26.6324 66.9187C26.6713 66.9586 26.7179 66.9903 26.7692 67.012C26.8206 67.0336 26.8758 67.0448 26.9316 67.0447H30.0648C30.2946 67.0447 30.4774 66.862 30.4826 66.6374C30.587 62.606 32.2059 58.8304 35.0676 55.9687C38.0285 53.0077 41.9608 51.3784 46.1489 51.3784C50.337 51.3784 54.2693 53.0077 57.2302 55.9687C60.0919 58.8304 61.7108 62.606 61.8152 66.6374C61.8204 66.8672 62.0032 67.0447 62.233 67.0447H65.3662C65.422 67.0448 65.4772 67.0336 65.5286 67.012C65.5799 66.9903 65.6265 66.9586 65.6654 66.9187C65.7043 66.8788 65.7349 66.8315 65.7553 66.7796C65.7756 66.7277 65.7854 66.6723 65.784 66.6165C65.7318 64.1204 65.2148 61.6921 64.2435 59.3996ZM46.1489 47.4096C43.752 47.4096 41.496 46.4749 39.7988 44.7777C38.1016 43.0805 37.1669 40.8246 37.1669 38.4276C37.1669 36.0307 38.1016 33.7747 39.7988 32.0775C41.496 30.3804 43.752 29.4456 46.1489 29.4456C48.5459 29.4456 50.8018 30.3804 52.499 32.0775C54.1962 33.7747 55.1309 36.0307 55.1309 38.4276C55.1309 40.8246 54.1962 43.0805 52.499 44.7777C50.8018 46.4749 48.5459 47.4096 46.1489 47.4096Z" fill="#2ED588"/>
            </svg>
        </div>
    )
}

export default GreeUser;