import { IconButton } from '@mui/material';
import React from 'react';

const DownloadIcon = ({ href }) => {
    return (
        <IconButton sx={{ height: '45px', width: '45px' }}>
            <a href={ href } target="_blank" rel="noopener noreferrer" >
                <svg width="20" height="20" viewBox="0 0 33 33" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path d="M2.75 23.375L3.60387 26.7919C3.75259 27.3868 4.09588 27.915 4.5792 28.2924C5.06253 28.6698 5.65814 28.8749 6.27137 28.875H26.7286C27.3419 28.8749 27.9375 28.6698 28.4208 28.2924C28.9041 27.915 29.2474 27.3868 29.3961 26.7919L30.25 23.375M16.5 20.625V4.125V20.625ZM16.5 20.625L11 15.125L16.5 20.625ZM16.5 20.625L22 15.125L16.5 20.625Z" stroke="#808080" strokeWidth="2.75" strokeLinecap="round" strokeLinejoin="round"/>
                </svg>
            </a>
        </IconButton>
    )
}

export default DownloadIcon;