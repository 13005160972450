import React from 'react';

// Material Ui
import { useMediaQuery } from '@mui/material';

const Step1Icon = ({ color }) => {

    const matches = useMediaQuery('(min-width:600px)');

    return (
        <svg width={ matches ? "31" : "22"} height={ matches ? "34" : "25"} viewBox="0 0 31 34" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path fillRule="evenodd" clipRule="evenodd" d="M27.125 0H3.875C2.84742 0 1.8617 0.408255 1.13512 1.13512C0.40855 1.86168 0 2.84742 0 3.875V29.7083C0 30.7359 0.408255 31.7216 1.13512 32.4482C1.86168 33.1748 2.84742 33.5833 3.875 33.5833H18.4103C19.3433 33.5833 20.2446 33.2469 20.9495 32.6356C22.7953 31.0343 27.6535 26.8205 29.6642 25.0768C30.5127 24.341 31 23.273 31 22.1495V3.875C31 2.84742 30.5917 1.8617 29.8649 1.13512C29.1383 0.40855 28.1526 0 27.125 0ZM28.4167 20.6667V3.875C28.4167 3.53248 28.2806 3.20379 28.0384 2.96161C27.7962 2.71942 27.4675 2.58333 27.125 2.58333H3.875C3.53248 2.58333 3.20379 2.71942 2.96161 2.96161C2.71942 3.20379 2.58333 3.53248 2.58333 3.875V29.7083C2.58333 30.0509 2.71942 30.3795 2.96161 30.6217C3.20379 30.8639 3.53248 31 3.875 31H16.7917V24.5417C16.7917 22.4015 18.5265 20.6667 20.6667 20.6667H28.4167ZM27.8273 23.25H20.6666C19.9533 23.25 19.375 23.8284 19.375 24.5417V30.5816L27.8273 23.25Z" fill={ color }/>
            <path fillRule="evenodd" clipRule="evenodd" d="M7.75065 9.04134H23.2507C23.9637 9.04134 24.5423 8.46269 24.5423 7.74967C24.5423 7.03666 23.9637 6.45801 23.2507 6.45801H7.75065C7.03764 6.45801 6.45898 7.03666 6.45898 7.74967C6.45898 8.46269 7.03764 9.04134 7.75065 9.04134Z" fill={ color }/>
            <path fillRule="evenodd" clipRule="evenodd" d="M7.75065 15.1766H23.2507C23.9637 15.1766 24.5423 14.5976 24.5423 13.8849C24.5423 13.1719 23.9637 12.5933 23.2507 12.5933H7.75065C7.03764 12.5933 6.45898 13.1719 6.45898 13.8849C6.45898 14.5976 7.03764 15.1766 7.75065 15.1766Z" fill={ color }/>
        </svg>
    )
}

export default Step1Icon;