import React, { useEffect, useState } from 'react';
import { connect } from "react-redux";

// Actions
import {  
    setRangeHours, 
    setResetHours, 
    setErrorHour, 
    setHours, 
} from '../../../actions/stepsUser';

// Components
import BannerErrorHour from '../../Banners/BannerErrorHour/BannerErrorHour';
import DeleteModal from '../../Modals/DeleteHourModal/DeleteModal';
import BannerHours from '../../Banners/BannerHours/BannerHours';
import HoursComponent from '../HoursComponent/HoursComponent';
import GreenSwitch from '../../GreenSwitch/GreenSwitch';

// Icons
import ControlPointOutlinedIcon from '@mui/icons-material/ControlPointOutlined';

// Material Ui
import { Button, useMediaQuery } from '@mui/material';

// CSS
import Styles from './Step2.module.css';

const Step2 = (props) => {

    // Actions
    const { setHours, setRangeHours, setErrorHour } = props;

    // States
    const { hours, enabledRangeHours, errorSetHour } = props;

    const matches1000 = useMediaQuery('(min-width: 1000px)');
    const matches = useMediaQuery('(min-width: 750px)');
    const DaysArray = ['D','L','M', 'M', 'J', 'V', 'S'];
    
    const [displayContainer, setDisplayContainer] = useState('block');
    const [showModal, setShowModal] = useState( false );

    const handleCreateHour = () => {
        const data = {
            days: DaysArray,
            start: 540,
            end: 1020,
            activeHour: true,
            state: true,
            disabled: false
        }

        setHours( data );
    };

    const handleShowDeleteModal = () => {
        if (hours.length > 1) {
            setShowModal( !showModal );
        }
    };

    const handleSetRangeHours = () => {
        setRangeHours( !enabledRangeHours );

        if ( enabledRangeHours === false ) {
            for (let i = 0; i < hours.length; i++) {
                hours[i].activeHour = true
            }
        }
    };

    const handleCloseBannerHour = () => {
        setErrorHour({ 
            id: 0,
            errorSetHour: false 
        });
    };

    useEffect(() => {
        if (matches || !matches1000) {
            setDisplayContainer('grid');
        } 

        if (!matches || matches1000) {
            setDisplayContainer('block');
        } 
    }, [matches, matches1000]);
    
    return (
        <>
            <h1 className={Styles.Title}>Horarios</h1>
            <p>Creá y personalizá los horarios de atención que va a tener el asesor</p>
            <BannerHours type={ 'hours' } />
            <section className={Styles.Step2Container}>
                <div 
                    className={Styles.ContainerPersonalizeHours}
                    style={{
                        border: enabledRangeHours ? '1px solid #2ED588' : '1px solid #BFBBBD',
                        height: enabledRangeHours ? 'max-content' : '65px',
                    }}
                >
                    <div className={Styles.TitleSection} style={{ borderBottom: !enabledRangeHours ? 'none' : '1px solid white' }}>
                        <h2>Horarios de atención <span className={Styles.PersonalizeWord}>personalizada</span></h2>
                        <GreenSwitch
                            onClick={ handleSetRangeHours }
                            checked={ enabledRangeHours }
                        />
                    </div>
                    <div className={Styles.HoursContainer} style={{ display: enabledRangeHours ? displayContainer : 'none' }}>
                        {
                            hours.map((hour, index) => (
                                <HoursComponent
                                    handleDelete={ handleShowDeleteModal }
                                    activeDays={ hour.activeDays }
                                    disabled={ hour.disabled }
                                    active={ hour.activeHour }
                                    startHour={ hour.start }
                                    DaysArray={ DaysArray }
                                    hourName={ hour.name }
                                    endHour={ hour.end }
                                    index={ hour.id }
                                    key={ index }
                                    id={ index }
                                    enabledRangeHours={ enabledRangeHours }
                                />
                            ))
                        }
                    </div>
                    <div className={Styles.ButtonContainer}
                        style={{
                            display: enabledRangeHours ? 'block' : 'none'
                        }}
                        // style={{display: 'block'}}
                    >
                        <Button
                            className={'AddHourButton'}
                            onClick={ handleCreateHour }
                            sx={{
                                borderRadius: '8px !important',
                                boxShadow: 'none',
                            }}
                        >
                            <ControlPointOutlinedIcon 
                                sx={{
                                    height: '1.5rem !important', 
                                    color: 'white !important',
                                    width: '1.5rem !important'
                                }}
                            />
                            <p>Agregar horario</p>
                        </Button>
                    </div>
                </div>
            </section>
            <DeleteModal 
                open={ showModal }
                handleClose={ handleShowDeleteModal }
                type={ 'hours' }
            />
            <BannerErrorHour
                open={ errorSetHour }
                handleClose={ handleCloseBannerHour }
            />
        </>
    )
}

const mapStateToProps = state => {
    return {
        enabledRangeHours: state.stepsUser.enabledRangeHours,
        errorSetHour: state.stepsUser.errorSetHour,
        userEditing: state.stepsUser.userEditing,
        loading: state.stepsUser.loading,
        hours: state.stepsUser.hours,
    }
}

const mapDispatchToProps = dispatch => {
    return {
        setErrorHour: ({ id, errorSetHour }) => dispatch(setErrorHour({ id, errorSetHour })),
        setResetHours: ({ newHours }) => dispatch(setResetHours({ newHours })),
        setRangeHours: (value) => dispatch(setRangeHours(value)),
        setHours: (value) => dispatch(setHours(value)),
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(Step2);






