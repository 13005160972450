import React, { useState } from 'react';
import { connect } from "react-redux";

// Actions
import { setActiveHour, setIdHourDeleteA } from '../../actions/stepsUser';

// Icons
import DeleteOutlineOutlinedIcon from '@mui/icons-material/DeleteOutlineOutlined';
import VisibilityOffOutlinedIcon from '@mui/icons-material/VisibilityOffOutlined';
import VisibilityOutlinedIcon from '@mui/icons-material/VisibilityOutlined';
import ModeEditOutlinedIcon from '@mui/icons-material/ModeEditOutlined';
import MoreVertIcon from '@mui/icons-material/MoreVert';
import SaveIcon from '../../assets/icons/SaveIcon';

// Material Ui
import { IconButton, Menu, MenuItem } from '@mui/material';

const MenuComponent = ( props ) => {

    // States
    const { hours, enabledRangeHours = true } = props;

    // Externals props
    const { 
        onClickDisabled, 
        onClickDelete, 
        onClickEdit, 
        disabled, 
        checked, 
        edit, 
        type, 
        id 
    } = props;

    const [anchorEl, setAnchorEl] = useState(null);
    
    const open = Boolean(anchorEl);
    const ITEM_HEIGHT = 48;

    const handleClick = (event) => {
        if(enabledRangeHours){
            setAnchorEl(event.currentTarget);
        } else {
            return;
        }
    };

    const handleActiveHour = (e, id, newChecked) => {
        onClickDisabled(id, newChecked);
        setAnchorEl(null);
    }

    const handleEditHour = () => {
        setAnchorEl(null);
        onClickEdit();
    }

    const handleClose = () => {
        setAnchorEl(null);
    };

    const handleDeleteHour = () => {
        setAnchorEl(null);
        onClickDelete();
    }

    return (
        <>
            <IconButton
                aria-label="more"
                id="long-button"
                aria-controls={ open ? 'long-menu' : undefined }
                aria-expanded={ open ? 'true' : undefined }
                aria-haspopup="true"
                onClick={ handleClick }
            >
                <MoreVertIcon sx={{ height: '2rem !important', width: '1.2rem !important' }}/>
            </IconButton>
            <Menu
                id="long-menu"
                MenuListProps={{
                    'aria-labelledby': 'long-button',
                }}
                anchorEl={ anchorEl }
                open={ open }
                onClose={ handleClose }
                PaperProps={{
                    style: {
                        maxHeight: ITEM_HEIGHT * 4.5,
                        borderRadius: '10px',
                        width: '9.5rem',
                    },
                }}
                sx={{
                    '& .MuiList-root': {
                        paddingBottom: '0',
                        paddingTop: '0',
                    }
                }}
            >
                <MenuItem  
                    onClick={(e) => handleActiveHour(e, id, !checked) } 
                    sx={{ 
                        borderBottom: '1px solid #BFBBBD', 
                        fontFamily: 'Poppins',
                        alignItems: 'center',
                        minHeight: '25px', 
                        display: 'flex',
                        height: '45px', 
                        padding: '0', 
                    }}
                >
                    <IconButton
                        aria-label="more"
                        id="long-button"
                        aria-controls={ open ? 'long-menu' : undefined }
                        aria-expanded={ open ? 'true' : undefined }
                        onClick={ handleClick }
                        aria-haspopup="true"
                        sx={{
                            padding: '0 8px'
                        }}
                    >
                        { 
                            checked ? 
                                <VisibilityOffOutlinedIcon 
                                    sx={{ 
                                        color: '#473C41 !important', 
                                        height: '1.3rem !important', 
                                        width: '1.3rem !important' 
                                    }} 
                                />
                            :
                                <VisibilityOutlinedIcon 
                                    sx={{ 
                                        color: '#473C41 !important', 
                                        height: '1.3rem !important', 
                                        width: '1.3rem !important' 
                                    }} 
                                />
                        }
                    </IconButton>
                    <p
                        style={{
                            color: '#473C41',
                            fontWeight: '500',
                            margin: '0'
                        }}
                    > { checked ? "Deshabilitar" : "Habilitar"} </p>
                </MenuItem>
                {
                    type !== 'hours' ? 
                        <MenuItem  
                            onClick={ handleEditHour } 
                            sx={{ 
                                borderBottom: '1px solid #BFBBBD', 
                                fontFamily: 'Poppins',
                                alignItems: 'center',
                                minHeight: '25px', 
                                display: 'flex',
                                height: '45px', 
                                padding: '0', 
                            }}
                        >
                            <IconButton
                                aria-label="more"
                                id="long-button"
                                aria-controls={ open ? 'long-menu' : undefined }
                                aria-expanded={ open ? 'true' : undefined }
                                aria-haspopup="true"
                                onClick={ handleClick }
                                sx={{
                                    padding: '0 8px'
                                }}
                                disabled={ disabled }
                            >
                                { 
                                    edit ? 
                                    <SaveIcon 
                                        sx={{ 
                                            height: '1.3rem !important', 
                                            width: '1.3rem !important' 
                                        }}
                                    /> : 
                                    <ModeEditOutlinedIcon 
                                        sx={{ 
                                            color: '#473C41 !important', 
                                            height: '1.3rem !important', 
                                            width: '1.3rem !important' 
                                        }} 
                                    />
                                }
                            </IconButton>
                            <p
                                style={{
                                    color: '#473C41',
                                    fontWeight: '500',
                                    margin: '0'
                                }}
                            >{ edit ? 'Guardar' : 'Editar' }</p>
                        </MenuItem>
                    :
                        null
                }
                {
                    ( type === 'hours' && hours.length > 1 ) ?
                        <MenuItem  
                            onClick={ handleDeleteHour } 
                            sx={{ 
                                borderBottom: '1px solid #BFBBBD', 
                                fontFamily: 'Poppins',
                                alignItems: 'center',
                                minHeight: '25px', 
                                display: 'flex',
                                height: '45px', 
                                padding: '0', 
                            }}
                        >
                            <IconButton
                                aria-label="more"
                                id="long-button"
                                aria-controls={ open ? 'long-menu' : undefined }
                                aria-expanded={ open ? 'true' : undefined }
                                aria-haspopup="true"
                                onClick={ handleClick }
                                sx={{
                                    padding: '0 8px'
                                }}
                            > 
                                <DeleteOutlineOutlinedIcon sx={{ color: '#FF7474 !important', height: '1.3rem !important', width: '1.3rem !important' }} />
                            </IconButton>
                            <p
                                style={{
                                    color: '#FF7474',
                                    fontWeight: '500',
                                    margin: '0'
                                }}
                            >Eliminar</p>
                        </MenuItem>
                    : 
                        null
                }
                {
                    ( type === 'user' ) &&
                        <MenuItem  
                            onClick={ handleDeleteHour } 
                            sx={{ 
                                borderBottom: '1px solid #BFBBBD', 
                                fontFamily: 'Poppins',
                                alignItems: 'center',
                                minHeight: '25px', 
                                display: 'flex',
                                height: '45px', 
                                padding: '0', 
                            }}
                        >
                            <IconButton
                                aria-label="more"
                                id="long-button"
                                aria-controls={ open ? 'long-menu' : undefined }
                                aria-expanded={ open ? 'true' : undefined }
                                aria-haspopup="true"
                                onClick={ handleClick }
                                sx={{
                                    padding: '0 8px'
                                }}
                            > 
                                <DeleteOutlineOutlinedIcon sx={{ color: '#FF7474 !important', height: '1.3rem !important', width: '1.3rem !important' }} />
                            </IconButton>
                            <p
                                style={{
                                    color: '#FF7474',
                                    fontWeight: '500',
                                    margin: '0'
                                }}
                            >Eliminar</p>
                        </MenuItem>
                }
            </Menu>
        </>
    )
}

const mapStateToProps = state => {
    return {
        hours: state.stepsUser.hours,
    }
}

const mapDispatchToProps = dispatch => {
    return {
        setIdHourDelete: ({ activeHour, id, index }) => dispatch(setIdHourDeleteA({ activeHour, id, index })),
        setHoursAction: ({ activeHour, id, index }) => dispatch(setActiveHour({ activeHour, id, index })),
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(MenuComponent);



