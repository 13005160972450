import React from 'react';
import { connect } from "react-redux";

// Material Ui
import { Button, Modal } from '@mui/material';

// CSS
import Styles from './SelectColorModal.module.css';
import { setColorsChat } from '../../../actions/stepsChat';;

const SelectColorModal = (props) => {

    // Actions
    const { setColorsChat } = props;

    // States
    const { backColorState, fontColorState } = props;

    // External props
    const { open, handleClose, type } = props;

    const handleChangeBackColor = (e) => {
        setColorsChat({
            backColor: e.target.value
        });
    }

    const handleChangeFontColor = (e) => {
        setColorsChat({
            colorFont: e.target.value
        });
    }

    return (
        <Modal
            open={open}
            onClose={handleClose}
            aria-labelledby="modal-modal-title"
            aria-describedby="modal-modal-description"
        >
            { 
                type === 'full' ? 
                    <div className={Styles.ModalColor}>
                        <h2>Personalizar colores</h2>
                        <p>Seleccioná los colores de tu chat</p>
                        <div className={Styles.InputGeneralContainer}>
                            <div 
                                className={Styles.ColorContainer}
                                style={{
                                    backgroundColor: backColorState
                                }}
                            >
                                <input 
                                    type="color" 
                                    id="selectColor" 
                                    className={Styles.ColorInput}
                                    onChange={ handleChangeBackColor }
                                />
                            </div>
                            <div className={Styles.InputContainer}>
                                <span className={ Styles.SpanColor }>Color: </span>
                                <input 
                                    onChange={ handleChangeBackColor } 
                                    className={ Styles.InputTextColor } 
                                    value={ backColorState }
                                    id="selectColor" 
                                    type="text"
                                />
                            </div>
                        </div>
                        <div className={Styles.ButtonContainer}>
                            <Button
                                sx={{
                                    backgroundColor: '#2ED588',
                                    fontFamily: 'Poppins',
                                    padding: '.5rem 1rem',
                                    textTransform: 'none',
                                    borderRadius: '8px',
                                    fontWeight: '600',
                                    cursor: 'pointer',
                                    fontSize: '1rem',
                                    color: 'white',
                                    '&:hover': {
                                        backgroundColor: '#2ED588',
                                        color: 'white',
                                    }
                                }}
                                onClick = { handleClose }
                            >
                                Guardar
                            </Button>
                        </div>
                    </div>
                :
                    <div className={Styles.ModalColor}>
                        <h2>Personalizar colores</h2>
                        <p>Seleccioná los colores de tu chat</p>
                        <div className={Styles.SelectPredColors}>
                            <h2>Chat de Whatspro</h2>
                            <div className={Styles.InputGeneralContainer}>
                                <div 
                                    className={Styles.ColorContainer}
                                    style={{
                                        backgroundColor: backColorState
                                    }}
                                >
                                    <input 
                                        type="color" 
                                        id="selectColor" 
                                        className={Styles.ColorInput}
                                        onChange={ handleChangeBackColor }
                                    />
                                </div>
                                <div className={Styles.InputContainer}>
                                    <span className={ Styles.SpanColor }>Color: </span>
                                    <input 
                                        onChange={ handleChangeBackColor } 
                                        className={ Styles.InputTextColor } 
                                        value={ backColorState }
                                        id="selectColor" 
                                        type="text"
                                    />
                                </div>
                            </div>
                            <h2>Fuentes</h2>
                            <div className={Styles.InputGeneralContainer}>
                                <div 
                                    className={Styles.ColorContainer}
                                    style={{
                                        backgroundColor: fontColorState
                                    }}
                                >
                                    <input 
                                        type="color" 
                                        id="selectColor" 
                                        className={Styles.ColorInput}
                                        onChange={ handleChangeFontColor }
                                    />
                                </div>
                                <div className={Styles.InputContainer}>
                                    <span className={ Styles.SpanColor }>Color: </span>
                                    <input 
                                        onChange={ handleChangeFontColor } 
                                        className={ Styles.InputTextColor } 
                                        value={ fontColorState }
                                        id="selectColor" 
                                        type="text"
                                    />
                                </div>
                            </div>
                        </div>
                        <div className={Styles.ButtonContainer}>
                            <Button
                                sx={{
                                    backgroundColor: '#2ED588',
                                    fontFamily: 'Poppins',
                                    padding: '.5rem 1rem',
                                    textTransform: 'none',
                                    borderRadius: '8px',
                                    fontWeight: '600',
                                    cursor: 'pointer',
                                    fontSize: '1rem',
                                    color: 'white',
                                    '&:hover': {
                                        backgroundColor: '#2ED588',
                                        color: 'white',
                                    }
                                }}
                                onClick = { handleClose }
                            >
                                Guardar
                            </Button>
                        </div>
                    </div>
            }
        </Modal>
    )
}

const mapStateToProps = state => {
    return {
        backColorState: state.stepsChat.backColor,
        fontColorState: state.stepsChat.colorFont,
    }
}

const mapDispatchToProps = dispatch => {
    return {
        setColorsChat: (value) => dispatch(setColorsChat(value)),
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(SelectColorModal);