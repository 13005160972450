import React from 'react'

const HomeIcon = () => {
    return (
        <div style={{marginRight: '12px', display: 'flex', alignItems: 'center'}}>
            <svg width="19" height="18" viewBox="0 0 19 18" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path fillRule="evenodd" clipRule="evenodd" d="M9.99825 1.76626C9.7051 1.52396 9.28113 1.52414 8.98818 1.76668L1.87169 7.65875C1.68988 7.80927 1.58465 8.03301 1.58465 8.26904V17.1098H0V8.26904C0 7.56095 0.315691 6.88973 0.861104 6.43815L7.97759 0.546092C8.85645 -0.181555 10.1284 -0.182085 11.0078 0.544828L18.1374 6.4377C18.6837 6.88926 19 7.56107 19 8.26985V17.1098H17.4153V8.26985C17.4153 8.03359 17.3099 7.80965 17.1278 7.65913L9.99825 1.76626Z" fill="#2ED588"/>
                <path fillRule="evenodd" clipRule="evenodd" d="M9.5 11.7258C8.19415 11.7258 7.22008 12.7107 7.22008 13.8137H5.63543C5.63543 11.741 7.41655 10.1412 9.5 10.1412C11.5835 10.1412 13.3646 11.741 13.3646 13.8137H11.7799C11.7799 12.7107 10.8059 11.7258 9.5 11.7258Z" fill="#2ED588"/>
                <path fillRule="evenodd" clipRule="evenodd" d="M5.63543 17.1098V13.8137H7.22008V17.1098L5.63543 17.1098Z" fill="#2ED588"/>
                <path fillRule="evenodd" clipRule="evenodd" d="M11.7799 17.1098V13.8137H13.3646V17.1098L11.7799 17.1098Z" fill="#2ED588"/>
            </svg>
        </div>
    )
}

export default HomeIcon