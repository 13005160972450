import { useEffect, useState } from "react";
import useScript from "./useScript";
import { formConfig } from "../helpers/formConfig";

export default function useMercadoPago(store) {

    const [resultPayment, setResultPayment] = useState(undefined);

    const [loader, setLoader] = useState(false);
    const [error, setError] = useState();
    const [success, setSuccess] = useState(false);
    const [cardToken, setCardToken] = useState(false);

    const { MercadoPago } = useScript(
        "https://sdk.mercadopago.com/js/v2",
        "MercadoPago"
    );

    useEffect(() => {

        if (MercadoPago) {
            const mp = new MercadoPago("APP_USR-13fea57c-393b-4892-bc90-137eb2a19a76");

            const cardForm = mp.cardForm({
                amount: "5",
                autoMount: true,
                form: formConfig,
                callbacks: {

                    onFormMounted: (error) => {
                        if (error)
                            return console.warn(
                                "Form Mounted handling error: ",
                                error
                            );
                    },

                    onSubmit: async (event) => {

                        console.log('SUBMIT FORM: ', event);
                        event.preventDefault();

                        try {

                            setLoader(true);
                            const {token} = cardForm.getCardFormData();
                            console.log('TOKEN: ', token);
                            setSuccess(true);
                            setCardToken(token);

                        } catch (err) {
                            setError(err)
                            console.log("Error creando sub y actualizando: ", err);
                            setSuccess(false);
                        }                  
                    },
                    onError: (error, event) => {
                        setError(error, event);
                        setSuccess(false);
                    },
                    onValidityChange: (error, field) => {
                        setError(error, field);
                        setSuccess(false);
                    },
                    onReady: () => {
                    },
                    onFetching: (resource) => {
                    },
                },
            });
        }
    }, [MercadoPago]);
    
    return { resultPayment, loader, setLoader, error, setError, success, cardToken };
}

async function createSub(email, token, storeId) {
    console.log("Entro al try del cliente uid: ", storeId);
    let response = await fetch(`${process.env.REACT_APP_URL_BACK}mercadopago/`, {
        method: 'POST',
        headers: {
            'Content-Type': 'application/json'
        },
        body: JSON.stringify({
            "email": email,
            "token": token,
            "storeId": storeId
        })
    })

    if (response.status !== 200) {
        throw new Error('Ocurrio un error')
    }

    let res = await response.json();

    console.log("RETURN CREATE SUB: ", res.id);

    return res.id;
}

async function updateSub(subId, storeId) {
    try {

        let response = await fetch(`${process.env.REACT_APP_URL_BACK}mercadopago/${subId}`, {
            method: 'PUT',
            headers: {
                'Content-Type': 'application/json'
            },
            body: JSON.stringify({
                "storeId": `${storeId}`
            })
        })

        let res = await response.json();

        return res.id;

    } catch (error) {
        console.log("Error suscribiendo al usuario: ", error);
    }
}