import React from 'react';
import { connect } from 'react-redux';

// Actions
import { onSetQuestionSelected } from '../../actions/integrations';

// Material Ui
import { Box, Grid, Typography } from '@mui/material';

const GeneralContainer = {
    height: 'max-content',
    paddingTop: '40px',
    width: '90%',
    '@media(min-width: 550px)': {
        position: 'absolute',
        paddingTop: 0,
        top: '3%',
    }
}

const TopContainer = {
    height: 'max-content', 
    display: 'block',
    width: '100%'
}

const CounterStep = {
    fontSize: '.8rem',
    textAlign: 'left',
    color: '#2ED588',
}

const CompleteLineStyles = {
    backgroundColor: '#2ED588',
    borderRadius: '5px',
    height: '100%',
    width: '95%',
}

const LineStyles = {
    backgroundColor: '#E4E4E4',
    borderRadius: '5px',
    height: '100%',
    width: '100%',
}

const DataContainer = {
    marginTop: '3rem', 
    '@media(min-width: 400px)': { 
        marginTop: '4rem' 
    }
}

const TitleContainer = {
    textAlign: 'center', 
    marginTop: '2rem',
}

const Title = {
    fontSize: '.7rem',
    fontWeight: '500',
    color: '#2ED588',
    '@media(min-width: 400px)': {
        fontSize: '.8rem',
    }
}

const Subtitle = {
    margin: '.5rem auto auto auto',
    fontSize: '1.1rem',
    fontWeight: '500',
    color: '#393939',
    width: '100%',
    '@media(min-width: 400px)': {
        fontSize: '1.2rem',
        width: '350px',
    }
}

const QuestionsContainer = {
    flexDirection: 'column',
    marginTop: '1rem',
    display: 'flex',
    gap: '10px',
    '@media(min-width: 400px)': {
        marginTop: '3rem',
    }
}

const Question = {
    border: '1px solid #E4E4E4',
    backgroundColor: '#F9F8FF', 
    boxSizing: 'border-box',
    padding: '.8rem .5rem',
    textAlign: 'center',
    borderRadius: '8px',
    fontSize: '.65rem',
    cursor: 'pointer',
    width: '100%',
    ':hover': {
        border: '1px solid #2ED588',
        backgroundColor: '#F1FFF9',
    },
    '@media(min-width: 400px)': {
        fontSize: '.75rem',
    },
    '@media(max-height: 600px)': {
        padding: '.6rem .5rem',
        fontSize: '.55rem',
    }
}

const Step1WelcomeQuestion = ( props ) => {

    // Actions
    const { onSetQuestionSelected } = props;

    // External props
    const { handleChange } = props;

    const questionArray = [
        {
            text: 'Responder preguntas frecuentes automáticamente',
            id: 0
        },
        {
            text: 'Tener más de un numero de soporte para atender consultas',
            id: 1
        },
        {
            text: 'Definir horarios de atención personalizados para no recibir consultas 24/7',
            id: 2
        },
        {
            text: 'Automatizar la atención al cliente',
            id: 3
        },
        {
            text: 'Que mis clientes no salgan de la web para hacer preguntas',
            id: 4
        }
    ]

    const onSelectQuestion = (question) => {
        onSetQuestionSelected({ SelectedQuestion1: question });
        handleChange();
    }

    return (
        <Box sx={ GeneralContainer }>
            <Box sx={ TopContainer }>
                <Typography sx={ CounterStep }>Paso 1/2</Typography>
                <Grid container sx={{ height: '6px', width: '100%' }}>
                    <Grid item xs={ 6 }>
                        <Box sx={ CompleteLineStyles }></Box>
                    </Grid>
                    <Grid item xs={ 6 }>
                        <Box sx={ LineStyles }></Box>
                    </Grid>
                </Grid>
            </Box>
            <Box sx={ DataContainer }>
                <Box sx={ TitleContainer }>
                    <Typography sx={ Title }>ONBOARDING</Typography>
                    <Typography sx={ Subtitle }>¿Cual es la funcionalidad de Whatspro que más te interesa?</Typography>
                </Box>
                <Box sx={ QuestionsContainer }>
                    {
                        questionArray.map( question => (
                            <Box sx={ Question } key={ question.id } onClick={ () => onSelectQuestion(question.text) }>
                                { question.text }
                            </Box>
                        ))
                    }
                </Box>
            </Box>
        </Box>
    )
}

const mapDispatchToProps = dispatch => {
    return {
        onSetQuestionSelected: (data) => dispatch( onSetQuestionSelected(data) ),
    }
}

export default connect(null, mapDispatchToProps)( Step1WelcomeQuestion );