// Components
import SelectHours from '../SelectHours/SelectHours';
import MenuComponent from '../../Menu/MenuComponent';

// CSS
import Styles from './HoursComponentMobile.module.css';

const SelectHourMobile = (props) => {

    // Externals Props
    const {
        onClickDisabledHour,
        isActiveComponent,
        enabledRangeHours,
        onClickDelete,
        onClickEdit,
        DaysArray,
        selectDay,
        startHour,
        hourName,
        showDays,
        disabled,
        endHour,
        active,
        index,
        id
    } = props;

    const styleBox = {
        border: isActiveComponent ? '1px solid #2ED588' : '1px solid #BFBBBD',
    };

    return (
        <div
            className={Styles.HoursComponentsContainerActive}
            style={styleBox}
        >
            <div className={Styles.TitleSection}>
                <h2
                    style={{
                        color: isActiveComponent ? 'black' : '#BFBBBD'
                    }}
                >{hourName}</h2>
                <MenuComponent
                    onClickDisabled={onClickDisabledHour}
                    onClickDelete={onClickDelete}
                    onClickEdit={onClickEdit}
                    disabled={isActiveComponent}
                    checked={active}
                    type={'hours'}
                    enabledRangeHours={enabledRangeHours}
                    id={id}
                />
            </div>
            <div className={Styles.OptionsContainer}>
                <div className={Styles.RangehoursActive}>
                    <div className={Styles.RangehourItem}>
                        {/* <h3 style={{ color: isActiveComponent ? '#473C41' : '#BFBBBD' }} >Desde</h3> */}
                        <SelectHours
                            startHour={startHour}
                            disabled={disabled}
                            id={'startHour'}
                            active={isActiveComponent}
                            index={index}
                        />
                    </div>
                    <div className={Styles.RangehourItem}>
                        {/* <h3
                            style={{
                                color: isActiveComponent ? '#473C41' : '#BFBBBD',
                                marginRight: matches ? '0' : '4px'
                            }}
                        >Hasta</h3> */}
                        <SelectHours
                            disabled={disabled}
                            endHour={endHour}
                            active={isActiveComponent}
                            id={'endHour'}
                            index={index}
                        />
                    </div>
                </div>
                <div className={Styles.BottomContainer}>
                    <div
                        // className={isActiveComponent ? Styles.DaysContainerActive : Styles.DaysContainer}
                        className={Styles.DaysContainerActive}
                    >
                        {
                            DaysArray.map((day, index) =>
                                <div
                                    key={index}
                                    className={Styles.DayContainer}
                                    onClick={() => {selectDay(index, isActiveComponent)}}
                                    style={{
                                        backgroundColor: isActiveComponent ? day ? '#2ED588' : '#F0EFEF' : day ? '#F0EFEF' : '#F0EFEF',
                                        marginLeft: index === 0 ? '0' : '8px'
                                    }}
                                >
                                    <h2>{showDays(index)}</h2>
                                </div>
                            )
                        }
                    </div>
                </div>
            </div>
        </div>
    )
}

export default SelectHourMobile;
