import React from 'react';

import { connect } from 'react-redux';
import { useNavigate } from 'react-router-dom';

// Actions
import { onSetLastTask, setActiveTask } from '../../../actions/tasks';



// Material Ui
import { Box, Typography } from '@mui/material';

// Images
import AvatarTask2 from '../../../assets/images/Home/AvatarTask2.png';
import AvatarTask3 from '../../../assets/images/Home/AvatarTask3.png';

// Layout
import LayoutCongratsModals from '../../../layout/LayoutCongratsModals';

const TitleStyles = {
  fontSize: '1.3rem',
  fontWeight: '600',
}

const SubtitleStyles  = {
  margin: '.5rem 0 1rem 0',
  fontSize: '.9rem',
}

const DaysContainer ={
  border: '2px solid #E1C478',
  backgroundColor: '#FFFFFF',
  padding: '1rem 2rem',
  borderRadius: '20px',
  width: 'max-content',
  textAlign: 'center',
  margin: '1rem auto',
}

const DaysTextStyles = {
  color: '#E1C478',
  fontWeight: '500'
}

const LastTextStyles = {
  margin: '1rem 0 -.5rem 0',
  fontSize: '.8rem',
  fontWeight: '300',
  width: '285px',
}

const Task23CongratsModal = ( props ) => {

  // Actions
  const { onSetLastTask, setActiveTask } = props;
  
  // States
  const { activeTask } = props;

  // External props
  const { open, task } = props;

  const navigate = useNavigate();

  const handleShowCongratsModal = () => {
    if (activeTask === 2) {
      setActiveTask(3);
    } else {
      onSetLastTask(true);
      setActiveTask(4);
    }
    
    navigate('/home');
  }
  

  return (
    <LayoutCongratsModals
      open={ open } 
      handleClose={ handleShowCongratsModal } 
      srcAvatar={ activeTask === 2 ? AvatarTask2 : AvatarTask3 } 
      width1={'100%'} 
      width2={'350px'} 
      width3={'350px'}
      task={ task }
    >
      <Typography sx={ TitleStyles }>¡Felicitaciones!</Typography>
      <Typography sx={ SubtitleStyles }>Por finalizar la tarea ganaste:</Typography>
      <Box sx={ DaysContainer }>
        <Typography sx={ DaysTextStyles }>5 DÍAS <span style={{ fontWeight: '600' }}>GRATIS</span></Typography>
      </Box>
      <Typography sx={ LastTextStyles }>Seguí completando tus tareas para obtener mas beneficios!</Typography>
    </LayoutCongratsModals>
  )
}

const mapStateToProps = state => {
  return {
      activeTask: state.tasks.activeTask,
  }
}

const mapDispatchToProps = dispatch => {
  return {
    setActiveTask: (data) => dispatch(setActiveTask(data)),
    onSetLastTask: (data) => dispatch(onSetLastTask(data)),
  }
}

export default connect(mapStateToProps, mapDispatchToProps)( Task23CongratsModal );