import React from 'react';

// Material Ui
import { useMediaQuery } from '@mui/material';

const Step2Icon = ({ color }) => {

    const matches = useMediaQuery('(min-width:600px)');

    return (
        <svg width={ matches ? "31" : "22"} height={ matches ? "31" : "22"} viewBox="0 0 31 31" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M15.5 0C24.0606 0 31 6.93935 31 15.5C31 24.0606 24.0606 31 15.5 31C6.93935 31 0 24.0606 0 15.5C0 6.93935 6.93935 0 15.5 0ZM15.5 3.1C12.2113 3.1 9.05733 4.40642 6.73188 6.73188C4.40642 9.05733 3.1 12.2113 3.1 15.5C3.1 18.7887 4.40642 21.9427 6.73188 24.2681C9.05733 26.5936 12.2113 27.9 15.5 27.9C18.7887 27.9 21.9427 26.5936 24.2681 24.2681C26.5936 21.9427 27.9 18.7887 27.9 15.5C27.9 12.2113 26.5936 9.05733 24.2681 6.73188C21.9427 4.40642 18.7887 3.1 15.5 3.1ZM15.5 6.2C15.8796 6.20005 16.2461 6.33943 16.5298 6.59171C16.8135 6.84399 16.9947 7.19161 17.0392 7.56865L17.05 7.75V14.8583L21.2458 19.0541C21.5238 19.3331 21.6852 19.7074 21.6973 20.101C21.7093 20.4946 21.571 20.8781 21.3106 21.1734C21.0501 21.4688 20.687 21.654 20.295 21.6913C19.9029 21.7287 19.5114 21.6154 19.1999 21.3745L19.0541 21.2458L14.4042 16.5958C14.1632 16.3547 14.0085 16.041 13.9639 15.703L13.95 15.5V7.75C13.95 7.33891 14.1133 6.94467 14.404 6.65398C14.6947 6.3633 15.0889 6.2 15.5 6.2Z" fill={ color }/>
        </svg>
    )
}

export default Step2Icon;