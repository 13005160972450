import React from 'react';
import { Link } from "react-router-dom";

// Components
import RedAlert from '../../../assets/images/Modal/RedAlert';

// Material Ui
import { Fade, Modal } from '@mui/material';
import Backdrop from '@mui/material/Backdrop';
import { Box } from '@mui/system';

// CSS
import Styles from './EndTrialPeriodModal.module.css';

const EndTrialPeriodModal = ({ open, handleCloseModal }) => {

    const modalStyle = {
        transform: 'translate(-50%, -50%)',
        p: '1rem 1.5rem 1.6rem 1.5rem',
        border: '1px solid #BFBBBD',
        position: 'absolute',
        borderRadius: '10px',
        textAlign: 'center',
        bgcolor: 'white',
        width: '80%',
        left: '50%',
        top: '50%',
        "@media (min-width: 500px)": {
            width: '320px'
        },
        "@media (min-width: 900px)": {
            width: '400px'
        }
    }

    const buttonSus = {
        boxShadow: '0px 2.91309px 2.91309px rgba(0, 0, 0, 0.25)',
        backgroundColor: '#2ED588',
        textDecoration: 'none',
        padding: '.5rem 2rem',
        fontFamily: 'Poppins',
        textTransform: 'none',
        borderRadius: '8px',
        textAlign: 'center',
        fontWeight: '500',
        fontSize: '.9rem',
        color: 'white',
        ':hover': {
            backgroundColor: '#2ED588'
        }
    }

    return (
        <Modal
            aria-labelledby="transition-modal-title"
            aria-describedby="transition-modal-description"
            open={ open }
            closeAfterTransition
            BackdropComponent={ Backdrop }
            BackdropProps={{
                timeout: 500,
            }}
        >
            <Fade in={ open } >
                <Box sx={ modalStyle }>
                    <RedAlert color={ 'green' } />
                    <div className={Styles.ModalContainer}>
                        <h1>Tu <span>período gratuito</span> ha finalizado</h1>
                        <p>Para poder seguir utilizando WhatsPRO, suscribite completando tus datos de facturación</p>
                        <div className={Styles.ButtonsContainer}>
                            <Link
                                to={ '/card' }
                                style={ buttonSus }
                                onClick={ handleCloseModal }
                            >
                                Suscribirme 
                            </Link>
                        </div>
                    </div>
                </Box>
            </Fade>
        </Modal>
    )
}

export default EndTrialPeriodModal;