import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';

// Actions
import { changeProfilePhoto } from '../../../actions/stepsUser';

// Components
import SelectPhotoModal from '../../Modals/SelectPhotoModal/SelectPhotoModal';

// Image
import PhotoIcon from '../../../assets/images/CreateUser/PhotoSelectLogo.png';

// Material Ui
import { Button } from '@mui/material';

// CSS
import Styles from './PhotoSelect.module.css';

const PhotoSelect = (props) => {

    // Actions
    const { changeProfileImg } = props;

    // States
    const { srcImgProfile, userEditing } = props;

    const [openModal, setOpenModal] = useState(false);

    // useEffect(() => {
    //     if (!userEditing) {
    //         changeProfileImg({
    //             srcImgProfile: '',
    //             imgProfileId: ''
    //         });
    //     } else {
    //         return;
    //     }
    // }, [changeProfileImg]);

    const handleOpenModal = () => {
        setOpenModal(!openModal);
    }

    return (
        <>
            <div className={Styles.PhotoSelectContainer}>
                <div className={Styles.ImageSection}>
                    <img 
                        src={ srcImgProfile !== '' ? srcImgProfile : PhotoIcon } 
                        alt="Icon"
                        style={{
                            border: ( srcImgProfile !== '' ) ? '3px solid #2ED588' : 'none'
                        }} 
                    />
                </div>
                <Button
                    onClick={ handleOpenModal }
                    sx={{
                        backgroundColor: '#2ED588',
                        padding: '.6rem 1.5rem',
                        fontFamily: 'Poppins',
                        textTransform: 'none',
                        borderRadius: '8px',
                        boxShadow: 'none',
                        fontWeight: '600',
                        marginTop: '1rem',
                        color: 'white',
                        ':hover': {
                            backgroundColor: '#2ED588',
                            boxShadow: 'none',
                            color: 'white',
                            '@media(min-width: 800px)': {
                                backgroundColor: '#B2F1D4',
                                color: '#2ED588',
                            },
                        },
                        '@media(min-width: 320px)': {
                            fontSize: '.7rem'
                        },
                        '@media(min-width: 1100px)': {
                            fontSize: '.8rem'
                        },
                    }}
                >
                    Seleccionar Imagen
                </Button>
            </div>
            <SelectPhotoModal open={ openModal } handleClose={ handleOpenModal }/>
        </>
    )
}

const mapStateToProps = state => {
    return {
        srcImgProfile: state.stepsUser.srcImgProfile,
        imgProfileId: state.stepsUser.imgProfileId,
        userEditing: state.stepsUser.userEditing,
        userCreate: state.stepsUser.userCreate,
    }
}

const mapDispatchToProps = dispatch => {
    return {
        changeProfileImg: (value) => dispatch(changeProfilePhoto(value)),
    }
}

export default connect( mapStateToProps, mapDispatchToProps )(PhotoSelect);