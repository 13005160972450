import React, { useState } from 'react'
import { primaryColor } from '../../../constants'
import { LoadingButton } from '@mui/lab'
import { Box, Typography } from '@mui/material'
import { useFormik } from 'formik'
import * as Yup from 'yup';
import { useFetch } from 'use-http'
import { MAIN_API_URL } from '../../../configuration'
import { useParams } from 'react-router-dom'

const validationSchema = Yup.object({
    mail: Yup.string().email('Ingrese un correo electrónico válido').required('El correo electrónico es requerido'),
});

export const UserPasswordResendEmail = () => {
    const { post, loading, response } = useFetch(MAIN_API_URL);
    const params = useParams();

    const [msg, setMsg] = useState({
        type: '',
        text: '',
    });

    const formik = useFormik({
        initialValues: {
            mail: params.mail,
        },
        validationSchema: validationSchema,
        onSubmit: async (values) => {
            values.mail = values.mail.toLowerCase().trim();
            const data = await post('/auth/request-password-reset', values)
            if (response.ok) {
                setMsg({
                    type: 'success',
                    text: data
                })
                setTimeout(() => {
                    window.location.reload();
                }, 3000)
            } else {
                setMsg({
                    type: 'error',
                    text: data.msg
                })
            }
        }
    })
    return (
        <>
            <LoadingButton
                variant="contained"
                loadingPosition="center"
                loading={loading}
                disabled={loading}
                loadingIndicator="Cargando..."
                sx={{
                    width: '100%',
                    height: '54px',
                    borderRadius: '6px',
                    color: primaryColor,
                    background: '#fff',
                    boxShadow: 'none',
                    border: '1px solid #c4c4c4',
                    '&:hover': {
                        backgroundColor: primaryColor,
                        color: '#fff',
                    },
                }}
                onClick={formik.handleSubmit}
            >
                <Typography variant={'subtitle1'} sx={{ fontSize: '1rem', fontWeight: 500 }}>
                    Reenviar email
                </Typography>
            </LoadingButton>
            {
                msg.type && (
                    <Typography variant={'subtitle1'} sx={{ fontSize: '1rem', fontWeight: 500, color: msg.type === 'success' ? 'green' : 'red' }}>
                        {msg.text}
                    </Typography>
                )
            }
            <Box
                display={'flex'}
                flexDirection={'column'}
                justifyContent={'flex-start'}
                alignItems={'flex-start'}
                textAlign={'left'}
                gap={1}
            >
                <Typography variant={'subtitle1'} sx={{ fontSize: '0.9rem', fontWeight: 500, color: 'gray', marginBottom: '0.5rem' }}>
                    Tené en cuenta los siguientes puntos antes de reenviar el email:
                </Typography>
                <Typography variant={'subtitle1'} sx={{ fontSize: '0.7rem', fontWeight: 500, color: 'gray' }}>
                    1. Verificá que el email ingresado sea el correcto.
                </Typography>
                <Typography variant={'subtitle1'} sx={{ fontSize: '0.7rem', fontWeight: 500, color: 'gray' }}>
                    2. En algunos casos el proveedor de envíos de correos electrónicos puede demorar hasta 5 minutos en enviar el email.
                </Typography>
                <Typography variant={'subtitle1'} sx={{ fontSize: '0.7rem', fontWeight: 500, color: 'gray' }}>
                    3. Verificá que el correo no esté en la carpeta de Promociones o Spam.
                </Typography>
            </Box>

        </>
    )
}
