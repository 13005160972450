import { Box, CircularProgress } from '@mui/material';
// import { useEffect } from 'react';
// import { useFetch } from 'use-http';
// import { useNavigate } from 'react-router-dom';
// import { MAIN_API_URL } from '../../configuration';

const GeneralContainer = {
    backgroundColor: '#F9F8FF',
    justifyContent: 'center',
    alignItems: 'center',
    display: 'block',
    height: '100vh',
    width: '100vw',
};

const ContainerStyles = {
    transform: 'translate(-50%, -50%)',
    justifyContent: 'space-between',
    flexDirection: 'column',
    alignItems: 'center',
    position: 'absolute',
    textAlign: 'center',
    display: 'flex',
    left: '50%',
    top: '50%',
};

export const AuthCheck = () => {

    // const { get } = useFetch(MAIN_API_URL);
    // const navigate = useNavigate();

    //TODOOOOOOO
    // useEffect(() => {
    //     if (isAuthenticated && !isLoading) {
    //         get('/auth/login/' + user.sub)
    //             .then((data) => {
    //                 console.log(data);
    //                 localStorage.setItem('origin', data.origin);
    //                 localStorage.setItem('token', data.token);
    //                 localStorage.setItem('refreshToken', data.refreshToken);
    //                 localStorage.setItem('store', JSON.stringify(data._id));
    //                 localStorage.setItem('onBoardingVideo', data.onBoardingVideo);
    //                 navigate('/home');
    //             })
    //             .catch((err) => {
    //                 console.log(err);
    //             });
    //     } 
    // }, [isAuthenticated, isLoading, user, get, navigate]);

    return (
        <Box sx={GeneralContainer}>
            <Box sx={ContainerStyles}>
                <CircularProgress
                    size={'5rem'}
                    color='success'
                />
            </Box>
        </Box>
    );
};
