import React, { useState, useEffect } from 'react';
import { connect } from 'react-redux';

import { useFormik } from 'formik';
import useFetch from 'use-http';
import * as yup from 'yup';

// Actions
import { changeProfilePhoto, setNewPersonalizeImg } from '../../../actions/stepsUser';

// Configuration
import { MAIN_API_URL } from '../../../configuration';

// Material UI
import { Fade, IconButton, Modal } from '@mui/material';
import Backdrop from '@mui/material/Backdrop';
import { LoadingButton } from "@mui/lab";
import { Box } from '@mui/system';

// Icons
import CloseOutlinedIcon from '@mui/icons-material/CloseOutlined';

// Image
import SelectImageGreyIcon from '../../../assets/images/CreateUser/SelectImageGreyIcon.png';

// CSS
import Styles from './SetPersonalizeImg.module.css';

const style = {
    transform: 'translate(-50%, -50%)',
    border: '1px solid #BFBBBD',
    position: 'absolute',
    borderRadius: '10px',
    textAlign: 'center',
    bgcolor: 'white',
    p: '1rem 1.5rem',
    width: '75%',
    left: '50%',
    top: '50%',
    "@media (min-width: 500px)": {
        width: '320px'
    },
    "@media (min-width: 900px)": {
        width: '400px'
    }
};

const buttonSubmit = {
    boxShadow: '0px 2.91309px 2.91309px rgba(0, 0, 0, 0.25)',
    backgroundColor: '#2ED588',
    margin: '1rem 0 .5rem 0',
    padding: '.3rem 2.5rem',
    fontFamily: 'Poppins',
    textTransform: 'none',
    borderRadius: '8px',
    fontWeight: '500',
    fontSize: '.9rem',
    color: 'white',
    '&:hover': {
        backgroundColor: '#2ED588'
    }
};

const validationSchema = yup.object({
    img: yup
        .string('Ingresar una imagen')
        .required('Campo obligatorio'),
});

const SetPersonalizeImg = ( props ) => {

    // Actions
    const { changeProfileImg, setNewPersonalizeImg } = props;

    // States
    const { imgsPersonalize } = props;

    // Externals props
    const { open, handleClose } = props;

    const [avatarPreview , setAvatarPreview ] = useState('');
    const [loading, setLoading] = useState( false );

    const { error } = useFetch(`${MAIN_API_URL}/employees/image`, {
        headers: {
            "x-token": `${localStorage.getItem("token")}`
        }
    });

    useEffect(() => {
        setAvatarPreview('');
    }, [open]);

    const formik = useFormik({

        initialValues: {
            img: '',
        },

        validationSchema: validationSchema,

        onSubmit: async (values) => {

            Object.keys(values).forEach(key => {
                if (typeof values[key] === 'string') {
                    values[key] = values[key].trim();
                }
            });

            try {

                if (typeof values.img === 'object') {

                    let formulario = new FormData();
                    formulario.append( "mainAddress", values.img );

                    setLoading( true );

                    let response = await fetch(`${MAIN_API_URL}/employees/image`, {
                        method: 'POST',
                        headers: {
                            "x-token": `${localStorage.getItem("token")}`
                        },
                        body: formulario
                    })

                    let res = await response.json();
                    setLoading( false );
                    
                    if ( response.status === 200 ) {

                        changeProfileImg({
                            srcImgProfile: res.url
                        });

                        setNewPersonalizeImg({
                            src: res.url,
                            id: ( 11 + imgsPersonalize.length )
                        });
                        
                        handleClose();
                        return;
                        
                    } else {
                        console.log( 'Error --> ', error );
                    }
                }
                return;
                
            } catch (error) {
                console.log('error -> ', error);
            }

            formik.resetForm();
        },
    });

    const handleChangeImg = (e) => {
        const fileReader = new FileReader();

        fileReader.onload = () => {
            if ( fileReader.readyState === 2 ) {
                formik.setFieldValue( 'img', e.target.files[0] );
                setAvatarPreview( fileReader.result );
            }
        };

        fileReader.readAsDataURL( e.target.files[0] );
    }

    return (
        <Modal
            aria-labelledby="transition-modal-title"
            aria-describedby="transition-modal-description"
            open={ open }
            onClose={ handleClose }
            closeAfterTransition
            BackdropComponent={ Backdrop }
            BackdropProps={{ timeout: 500 }}
        >
            <Fade in={ open } >
                <Box sx={ style }>
                    <IconButton 
                        sx={{ 
                            position: 'absolute', 
                            right: '1rem', 
                            top: '.5rem' 
                        }}
                        onClick={ handleClose } 
                    >
                        <CloseOutlinedIcon sx={{ color: '#393939' }} />
                    </IconButton>
                    <div className={ Styles.SelectImgModalContainer }>
                        <h1>Seleccionar imagen</h1>
                        <form className={ Styles.FormContainer } encType="multipart/form-data" method="post" name="fileinfo">
                            <div className={ Styles.InputContainer }>
                                <img 
                                    src={ 
                                        avatarPreview !== '' ? 
                                            avatarPreview 
                                        : 
                                            SelectImageGreyIcon 
                                    } 
                                    alt="Logo"
                                    style={{ 
                                        border: avatarPreview !== '' ? '3px solid #2ED588' : 'none',
                                        cursor: 'pointer'
                                    }}
                                />
                                <input
                                    name='img'
                                    accept='image/*'
                                    id='img'
                                    type='file'
                                    onChange={ handleChangeImg }
                                    style={{
                                        position: 'absolute',
                                        borderRadius: '50%',
                                        height: '150px',
                                        width: '150px',
                                        opacity: '0',
                                        cursor: 'pointer'
                                    }}
                                />
                            </div>
                            <LoadingButton
                                onClick={ formik.handleSubmit }
                                disabled={ loading }
                                loading={ loading }
                                sx={ buttonSubmit }
                            >
                                Guardar cambios
                            </LoadingButton>
                        </form>
                    </div>
                </Box>
            </Fade>
        </Modal>
    )
}

const mapStateToProps = state => {
    return {
        imgsPersonalize: state.stepsUser.imgsPersonalize,
        srcImgProfile: state.stepsUser.srcImgProfile,
    }
}

const mapDispatchToProps = dispatch => {
    return {
        setNewPersonalizeImg: (value) => dispatch(setNewPersonalizeImg(value)),
        changeProfileImg: (value) => dispatch(changeProfilePhoto(value)),
        
    }
}

export default connect( mapStateToProps, mapDispatchToProps )( SetPersonalizeImg );