import React from 'react';
import { Link } from 'react-router-dom';

// Material Ui
import { Button } from '@mui/material';

const ButtonStyle = {
    backgroundColor: '#2ED588',
    fontFamily: 'Poppins',
    padding: '.3rem 1rem',
    textTransform: 'none',
    borderRadius: '8px',
    fontSize: '.8rem',
    color: 'white',
    ':hover': {
        backgroundColor: '#2ED588',
    },
    "@media (min-width: 700px)": {
        fontSize: '.9rem',
    },
}

const BillingsButton = ({ text, handleClick, type }) => {
    return (
        <>
            {
                type === 'userData' ?
                    <Button
                        onClick={ handleClick }
                        sx={ ButtonStyle }
                    >
                        { text }
                    </Button>
                :
                    <Link
                        style={{
                            backgroundColor: '#2ED588',
                            textDecoration: 'none',
                            fontFamily: 'Poppins',
                            padding: '.5rem 1rem',
                            textTransform: 'none',
                            position: 'relative',
                            borderRadius: '8px',
                            textAlign: 'center',
                            fontWeight: '600',
                            fontSize: '.9rem',
                            display: 'flex',
                            color: 'white',
                            gap: '5px',
                            '&:hover': {
                                backgroundColor: '#2ED588',
                            }
                        }}
                        to='/card?changePaymentMethod=true'
                    >
                        { text }
                    </Link>
            }
        </>
    )
}

export default BillingsButton;