import React from 'react';

// Icons
import CheckCircleIcon from '@mui/icons-material/CheckCircle';

// Material Ui 
import { Box, Button, Typography } from '@mui/material';

const GeneralContainerEcommerce = {
    padding: '0 2rem 1rem 2rem',
    '@media(min-width: 500px)': {
        padding: '0 2.5rem 1rem 2.5rem',
    }
}

const Subtitle = {
    margin: '.8rem auto',
    fontSize: '.8rem',
    fontWeight: '400',
    '@media(min-width: 500px)': {
        margin: '1rem auto',
        fontSize: '.9rem',
    }
}

const PermissionContainer = {
    margin: '1.5rem auto',
}

const PermissionItem = {
    alignItems: 'center',
    margin: '.5rem 0',
    display: 'flex',
    gap: '5px',
    '@media(min-width: 500px)': {
        margin: 0
    }
}

const CheckIconStyles = {
    color: '#2ED588',
    height: '1.2rem',
    '@media(min-width: 500px)': {
        height: '1.5rem',
    }
}

const PermissionText = {
    marginBottom: '-2px',
    fontSize: '.7rem',
    '@media(min-width: 500px)': {
        fontSize: '.9rem',
    }
}

const Description = {
    margin: '1.5rem auto 1rem auto',
    fontSize: '.5rem',
    textAlign: 'left',
    '@media(min-width: 500px)': {
        margin: '2rem auto 1.5rem auto',
        fontSize: '.7rem',
    }
}

const ButtonsContainer = {
    justifyContent: 'flex-end',
    display: 'block',
    gap: '10px',
    '@media(min-width: 500px)': {
        display: 'flex',
    }
}

const CancelButton = {
    backgroundColor: 'transparent',
    fontFamily: 'Poppins',
    textTransform: 'none',
    borderRadius: '6px',
    fontSize: '.75rem',
    color: '#2ED588',
    width: '100%',
    '@media(min-width: 500px)': {
        fontSize: '.9rem',
        width: 'auto',
    },
    ':hover': {
        backgroundColor: 'transparent',
    }
}

const AceptButton = {
    backgroundColor: '#2ED588',
    fontFamily: 'Poppins',
    textTransform: 'none',
    padding: '.3rem 1rem',
    borderRadius: '6px',
    fontSize: '.75rem',
    color: 'white',
    width: '100%',
    '@media(min-width: 500px)': {
        fontSize: '.9rem',
        padding: '0 1rem',
        width: 'auto',
    },
    ':hover': {
        backgroundColor: '#2ED588',
    }
}

const EcommerceComponent = ({ onClose, body, redirectUrl, origin }) => {
    const handleClick = () => {
        localStorage.setItem('syncronizeId', localStorage.getItem('store'));
        localStorage.removeItem('hasStartLoginWithPlatform');
        window.location.href = redirectUrl;
    }
    return (
        <Box sx={GeneralContainerEcommerce}>
            <Typography sx={Subtitle}>
                Vas a agregar la aplicación <span style={{ fontWeight: '600' }}>WhatsPRO - Vendé Mas y Atendé Mejor</span> a tu tienda. Esta aplicación necesita los siguientes permisos para instalarse en tu tienda:
            </Typography>
            <Box sx={PermissionContainer}>
                <Box sx={PermissionItem}>
                    <CheckCircleIcon
                        sx={CheckIconStyles}
                    />
                    <Typography sx={PermissionText}>Ver y gestionar datos de tu tienda</Typography>
                </Box>
                <Box sx={PermissionItem}>
                    <CheckCircleIcon
                        sx={CheckIconStyles}
                    />
                    <Typography sx={PermissionText}>Ver y gestionar datos de tus clientes</Typography>
                </Box>
            </Box>
            <Typography sx={Description}>{body}</Typography>
            <Box sx={ButtonsContainer}>
                <Button sx={CancelButton} onClick={() => onClose(origin)}>Cancelar</Button>
                <Button
                    sx={AceptButton}
                    onClick={handleClick}
                >Aceptar y empezar a usar</Button>
            </Box>
        </Box>
    )
}

export default EcommerceComponent;