import React from 'react';

const LogoutIcon = () => {
    return (
        <div>
            <svg width="70" height="70" viewBox="0 0 90 90" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path d="M33.75 78.75H18.75C16.7609 78.75 14.8532 77.9598 13.4467 76.5533C12.0402 75.1468 11.25 73.2391 11.25 71.25V18.75C11.25 16.7609 12.0402 14.8532 13.4467 13.4467C14.8532 12.0402 16.7609 11.25 18.75 11.25H33.75" stroke="#2ED588" strokeWidth="7.5" strokeLinecap="round" strokeLinejoin="round"/>
                <path d="M60 63.75L78.75 45L60 26.25" stroke="#2ED588" strokeWidth="7.5" strokeLinecap="round" strokeLinejoin="round"/>
                <path d="M78.75 45H33.75" stroke="#2ED588" strokeWidth="7.5" strokeLinecap="round" strokeLinejoin="round"/>
            </svg>
        </div>
    )
}

export default LogoutIcon;