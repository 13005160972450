import { Box, CircularProgress, Typography } from '@mui/material';
import { useEffect, useState } from 'react';
import { PlusIcon } from '../../assets/icons/Auth/PlusIcon.jsx';
import { PlatformList } from '../../helpers/platformList';
import WhatsPROLogo from '../../assets/logos/WhatsPRO.svg';
import { useNavigate, useParams } from 'react-router-dom';
import { useFetch } from 'use-http';
import { MAIN_API_URL } from '../../configuration/index.js';

const GeneralContainer = {
    backgroundColor: '#F9F8FF',
    justifyContent: 'center',
    alignItems: 'center',
    display: 'block',
    height: '100vh',
    width: '100vw',
};

const ContainerStyles = {
    transform: 'translate(-50%, -50%)',
    justifyContent: 'space-between',
    flexDirection: 'column',
    alignItems: 'center',
    position: 'absolute',
    textAlign: 'center',
    display: 'flex',
    height: '60%',
    width: '80%',
    gap: '2rem',
    left: '50%',
    top: '50%',
    '@media(min-width:500px)': {
        width: '70%',
        height: '40%',
    },
    '@media(min-width:1000px)': {
        width: '50%',
    },
};

const TitleStyles = {
    fontSize: '1.5rem',
    fontWeight: '500',
    color: '#2A2742',
    '@media(min-width:500px)': {
        fontSize: '1.7rem',
    },
};

const LogosContainer = {
    justifyContent: 'space-between',
    flexDirection: 'column',
    alignItems: 'center',
    display: 'flex',
    gap: '1.5rem',
    '@media(min-width:500px)': {
        flexDirection: 'row',
    },
};

const PendingSynchronizeView = () => {

    const params = useParams();
    const navigate = useNavigate();
    const query = new URLSearchParams(window.location.search);
    const { post, put, response } = useFetch(MAIN_API_URL + '/auth');

    const [shopPlatform, setShopPlatform] = useState(null);

    useEffect(() => {
        const platform = PlatformList.find((platform) => platform.name === params.platform);
        if (platform) {
            setShopPlatform(platform);
        } else {
            navigate('/auth/syncronize_failure/' + params.platform + '?error=Ocurrió un error en la sincronización de tu tienda. Por favor, inténtalo de nuevo.');
        }
    }, [params.platform, navigate]);

    useEffect(() => {
        async function fetchData() {
            if (shopPlatform) {
                const res = await post(`${shopPlatform.name}/code`, { code: query.get('code') });
                if (response.ok) {
                    const previousStoreOnboarding = JSON.parse(localStorage.getItem('storeOnboarding') || null);
                    const syncronizeId = localStorage.getItem('syncronizeId');
                    if (previousStoreOnboarding && (previousStoreOnboarding.storeId !== res.response.storeId || previousStoreOnboarding.origin !== shopPlatform.id)) {
                        navigate('/auth/syncronize_failure/' + params.platform + '?error=Ya tienes una sesión iniciada con otra tienda. Por favor, cierra este navegador, borra las cookies o intenta en incógnito con esta nueva tienda.');
                    }
                    localStorage.setItem('storeOnboarding', JSON.stringify({
                        storeId: res.response.storeId,
                        origin: shopPlatform.id,
                    }));
                    const hasStartLoginWithPlatform = JSON.parse(localStorage.getItem('hasStartLoginWithPlatform') || null);
                    if(syncronizeId){
                        localStorage.removeItem('syncronizeId');
                        const newRes = await put(`${shopPlatform.name}/update`, { storeId: res.response.storeId, _id: syncronizeId, code: query.get('code') });
                        if(newRes.status === 200){
                            localStorage.setItem('token', newRes.response.token);
                            localStorage.setItem('refreshToken', newRes.response.refreshToken);
                            localStorage.setItem('store', JSON.stringify(newRes.response._id));
                            localStorage.setItem('origin', newRes.response.origin);
                            localStorage.removeItem('storeOnboarding');
                            navigate('/home');
                        } else{
                            navigate('/auth/syncronize_failure/' + params.platform + '?error=' + newRes.msg);
                        }
                    }
                    if (hasStartLoginWithPlatform) {
                        localStorage.removeItem('hasStartLoginWithPlatform');
                        const newRes = await put(`${shopPlatform.name}/update`, { storeId: res.response.storeId, code: query.get('code') });
                        if(newRes.status === 200){
                            localStorage.setItem('token', newRes.response.token);
                            localStorage.setItem('refreshToken', newRes.response.refreshToken);
                            localStorage.setItem('store', JSON.stringify(newRes.response._id));
                            localStorage.setItem('origin', newRes.response.origin);
                            localStorage.removeItem('storeOnboarding');
                            navigate('/home');
                        } else{
                            navigate('/auth/syncronize_failure/' + params.platform + '?error=' + newRes.msg);
                        }
                    } else {
                        if(res.response.isRegistered){
                            navigate('/login?platform=' + params.platform);
                        } else{
                            navigate('/welcome');
                        }
                    }
                } else {
                    let error = 'unknown_error';
                    if (res.errors && res.errors.length > 0) {
                        error = res.errors[0].msg;
                    } else if(res.msg){
                        error = res.msg;
                    }
                    let errorInFormat = 'Error inesperado';
                    switch (error) {
                        case 'code is mandatory':
                        case 'code must be string':
                            errorInFormat = 'Ocurrió un error en la sincronización de tu tienda. Por favor, intentalo de nuevo';
                            break;
                        default:
                            errorInFormat = 'Ocurrió un error inesperado. Por favor, intentalo de nuevo';
                            break;
                    }
                    navigate('/auth/syncronize_failure/' + params.platform + '?error=' + errorInFormat);
                }
            }
        }
        fetchData();
    }, [shopPlatform]);

    return (
        <Box sx={GeneralContainer}>
            <Box sx={ContainerStyles}>
                <Typography variant="body1" sx={TitleStyles}>
                    Sincronizando tu tienda con WhatsPRO
                </Typography>
                <Box sx={{ display: 'flex' }}>
                    <CircularProgress size={'5rem'} color='success' />
                </Box>
                <Box sx={LogosContainer}>
                    {shopPlatform && (
                        <>
                            <img src={shopPlatform.urlImage} alt="Logo" height={30} />
                            <PlusIcon width={20} height={20} />
                            <img src={WhatsPROLogo} alt="LogoCrossUp" height={30} />
                        </>
                    )}
                </Box>
            </Box>
        </Box>
    );
};

export { PendingSynchronizeView };
