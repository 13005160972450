import React from 'react';

// Images
import CongratsModalBack from '../../../assets/images/Home/CongratsModalBack.png';
import BenefitAvatar from '../../../assets/images/Home/BenefitAvatar.png';

// Material Ui
import { Box, Button, Fade, Modal, Typography } from '@mui/material';

const ContainerStyles = {
    backgroundImage: `url(${CongratsModalBack})`,
    transform: 'translate(-50%, -50%)',
    backgroundRepeat: 'no-repeat',
    border: '1px solid #BFBBBD',
    justifyContent: 'center',
    boxSizing: 'border-box',
    flexDirection: 'column',
    position: 'absolute',
    alignItems: 'center',
    textAlign: 'center',
    overflowY: 'auto',
    bgcolor: 'white',
    p: '1rem 1.5rem',
    display: 'flex',
    height: '100%',
    width: '100%',
    left: '50%',
    top: '50%',
    "@media (min-width: 400px)": {
        height: 'max-content',
        borderRadius: '10px',
        overflowY: 'inherit',
        width: '350px'
    },
    "@media (min-width: 900px)": {
        width: '400px'
    },
    '&:focus-visible': {
        outline: 'none'
    }
};

const AvatarContainer = {
    transform: 'translateX(20%)',
    width: '180px',
    margin: 0,
    '@media(min-width: 350px)': {
        margin: '0 auto',
        width: '200px',
    },
    '@media(min-width: 400px)': {
        margin: '-3.9rem auto auto 50%',
        transform: 'translateX(-30%)',
        width: '160px',
    }
}

const AvatarImg = {
    width: '100%',
}

const TitleStyles = {
    fontSize: '1.2rem',
    fontWeight: '600',
    marginTop: '1.5rem',
    '@media(min-width: 350px)': {
        fontSize: '1.3rem',
    }
}

const SubtitleStyles = {
    margin: '1rem 0 .5rem 0',
    fontSize: '.9rem',
}

const GiftTextContainer = {
    margin: '2rem auto 1rem auto',
    border: '1px solid #E1C478',
    backgroundColor: '#FFFFFF',
    padding: '.6rem 1rem',
    borderRadius: '18px',
    alignItems: 'center',
    display: 'flex',
    gap: '5px',
}

const GiftText = {
    fontWeight: '600',
    fontSize: '1rem',
    color: '#E1C478',
    '@media(min-width: 350px)': {
        fontSize: '1.1rem',
    },
    '@media(min-width: 900px)': {
        fontSize: '1.2rem',
    }
}

const ButtonStyles = {
    backgroundColor: '#E1C478',
    padding: '.3rem 1rem',
    fontFamily: 'Poppins',
    textTransform: 'none',
    borderRadius: '6px',
    fontWeight: '500',
    fontSize: '.8rem',
    marginTop: '1rem',
    color: 'white',
    width: '90%',
    "@media (min-width: 440px)": {
        marginTop: '2rem',
        width: '80%',
    },
    "@media (min-width: 900px)": {
        marginTop: '2rem',
        width: '60%',
    },
    '&:hover': {
        backgroundColor: '#E1C478'
    },
};

const BenefitModal = ({ open, handleClose }) => {
    return (
        <Modal
            open={open}
            onClose={handleClose}
            aria-labelledby="modal-modal-title"
            aria-describedby="modal-modal-description"
        >
            <Fade in={open}>
                <Box sx={ContainerStyles}>
                    <Box sx={AvatarContainer} >
                        <img src={BenefitAvatar} alt="avatar" style={AvatarImg} />
                    </Box>
                    <Typography sx={TitleStyles}>¡Obtené mas beneficios!</Typography>
                    <Typography sx={SubtitleStyles}>Dejá tu reseña sobre WhatsPRO y conseguí:</Typography>
                    <Box sx={GiftTextContainer}>
                        <Typography sx={GiftText}>50% OFF en tu próximo pago</Typography>
                    </Box>
                    <Button
                        onClick={() => {
                            window.open('https://www.tiendanube.com/tienda-aplicaciones-nube/flowy-whatspro/rating', '_blank');
                            handleClose()
                        }}
                        sx={ButtonStyles}
                    >
                        Dejar reseña
                    </Button>
                </Box>
            </Fade>
        </Modal>
    )
}

export default BenefitModal